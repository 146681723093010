import React, { FunctionComponent, useMemo, useState } from 'react';

import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import { DayPickerRangeController, FocusedInputShape } from 'react-dates';

import clsx from 'clsx';
import moment, { Moment } from 'moment';
import 'moment/locale/ru';

import { Button, Chip, TextField, Theme, WithStyles } from '@material-ui/core';
import { createStyles, StyleRules, withStyles } from '@material-ui/styles';
import {
    Cancel as CancelIcon,
    ExpandMore as ExpandMoreIcon,
    ChevronLeft as ChevronLeftIcon,
    ChevronRight as ChevronRightIcon,
} from '@material-ui/icons';

import { Iso } from 'monocle-ts';
import * as O from 'fp-ts/Option';
import * as Eq from 'fp-ts/Eq';
import { getEq } from 'fp-ts/Array';
import { flow, pipe } from 'fp-ts/function';
import { size } from 'fp-ts/Record';

import { CurrenciesPayloadType, eqPartialCombinator, mapNullable, PayersPayloadType } from '../../utils/types';
import { formatCurrencyC, formatMomentDateC } from '../../utils/view';
import { KopecksIso, NullableMomentDateIso } from '../../utils/isomorph';
import { useStateCallback } from '../../hooks/lifecycle';

import type {
    PaymentHistoryFilterAmount as FiltersApiAmount,
    PaymentHistoryFilterPeriod as FiltersApiPeriod,
    PaymentHistoryFiltersPayload,
    PaymentHistoryListFilters,
} from '../../store/payment-history/types';

import { FilterTooltip, FilterTypes } from '../FilterTooltip';
import { PayersPicker } from '../PayersPicker';

import './DatePicker.scss';
import './PaymentHistoryFilters.scss';
import { CurrenciesPicker } from '../CurrencyPicker';

moment.locale('ru');

const styles = (theme: Theme): StyleRules =>
    createStyles({
        filterTooltip: {
            background: 'white',
            boxShadow: '0 2px 4px rgba(0, 0, 0, 0.14), 0 3px 4px rgba(0, 0, 0, 0.12), 0 1px 5px rgba(0, 0, 0, 0.2)',
            borderRadius: 10,
            margin: 0,
            maxWidth: 'none',
            overflow: 'hidden',
            padding: 16,
        },
        filterTooltipWithoutPadding: {
            padding: 0,
        },
        form: {
            alignItems: 'center',
            display: 'flex',
            [theme.breakpoints.down('md')]: {
                flexDirection: 'column',
            },
        },
        formField: {
            '&:not(:last-child)': {
                marginRight: 16,
                [theme.breakpoints.down('md')]: {
                    marginRight: 0,
                },
            },
        },
        numberField: {
            maxWidth: 212,
            [theme.breakpoints.down('md')]: {
                maxWidth: 150,

                '& + &': {
                    marginTop: 12,
                },
            },
        },
        numberFieldInner: {
            borderWidth: 1,
            borderColor: theme.palette.primary.main,
        },
        formButton: {
            [theme.breakpoints.down('md')]: {
                marginTop: 12,
            },
        },
        formHelper: {
            color: theme.palette.primary.main,
            marginTop: theme.spacing(1),
            whiteSpace: 'pre-wrap',
        },
    });

type FiltersAmount = FiltersApiAmount;
type FiltersPayers = PayersPayloadType;
type FiltersCurrencies = CurrenciesPayloadType;
type FiltersPeriod = { startDate: Moment | null; endDate: Moment | null };
type AmountFormData = { from: string; to: string };
type AmountFormDataK = keyof AmountFormData;
type FilterStateUpdate = {
    amount?: FiltersAmount;
    payers?: FiltersPayers;
    period?: FiltersPeriod;
    currency_id?: FiltersCurrencies;
};

type PaymentHistoryFiltersProps = WithStyles<typeof styles> & {
    filterDefaults: PaymentHistoryListFilters;
    filterValues: PaymentHistoryFiltersPayload;
    onChange: (payload: PaymentHistoryFiltersPayload) => void;
    disabled: boolean;
    vertical?: boolean;
};

const formatNullableDate = mapNullable(formatMomentDateC());
const formatCurrencyWithoutTail = formatCurrencyC(true);

const fromNullableKopecks = mapNullable(KopecksIso.to);

const PeriodPayloadIso = new Iso<FiltersPeriod, FiltersApiPeriod>(
    ({ startDate, endDate }) => ({
        from: NullableMomentDateIso.to(startDate),
        to: NullableMomentDateIso.to(endDate),
    }),
    ({ from, to }) => ({
        startDate: NullableMomentDateIso.from(from),
        endDate: NullableMomentDateIso.from(to),
    })
);

const foldAmountLabel = (amount: FiltersApiAmount): JSX.Element | string => {
    if (amount.from && amount.to)
        return (
            <>
                {formatCurrencyWithoutTail(amount.from)} — {formatCurrencyWithoutTail(amount.to)}
            </>
        );
    if (amount.to) return <>До {formatCurrencyWithoutTail(amount.to)}</>;
    if (amount.from) return <>От {formatCurrencyWithoutTail(amount.from)}</>;
    return 'Сумма';
};

const foldDateRangeLabel = (dateRange: FiltersPeriod): string => {
    if (dateRange.startDate === null) return 'Период';
    return `${formatNullableDate(dateRange.startDate)} — ${formatNullableDate(dateRange.endDate) || '...'}`;
};

const getPayersFromPayload = (filterValues: PaymentHistoryFiltersPayload): FiltersPayers =>
    pipe(
        O.fromNullable(filterValues.payer),
        O.getOrElse(() => [] as FiltersPayers)
    );

const getCurrenciesFromPayload = (filterValues: PaymentHistoryFiltersPayload): CurrenciesPayloadType =>
    pipe(
        O.fromNullable(filterValues.currency_id),
        O.getOrElse(() => [] as CurrenciesPayloadType)
    );

const getAmountFormDataFromPayload = (filterValues: PaymentHistoryFiltersPayload): AmountFormData =>
    pipe(
        O.fromNullable(filterValues.amount),
        O.mapNullable(({ from, to }) => ({
            from: fromNullableKopecks(from)?.toString() || '',
            to: fromNullableKopecks(to)?.toString() || '',
        })),
        O.getOrElse(() => ({ from: '', to: '' }))
    );

const getAmountFromPayload = (filterValues: PaymentHistoryFiltersPayload): FiltersAmount =>
    pipe(
        O.fromNullable(filterValues.amount),
        O.getOrElse(() => ({ from: null, to: null } as FiltersAmount))
    );

const getPeriodFromPayload = (filterValues: PaymentHistoryFiltersPayload): FiltersPeriod =>
    pipe(
        O.fromNullable(filterValues.period),
        O.mapNullable(PeriodPayloadIso.from),
        O.getOrElse(() => ({ startDate: null, endDate: null } as FiltersPeriod))
    );

const checkAmountFilterActive = (value: FiltersAmount): boolean => value.from !== null || value.to !== null;
const checkPayersFilterActive = (value: FiltersPayers): boolean => value.length > 0;
const checkCurrenciesFilterActive = (value: FiltersCurrencies): boolean => value.length > 0;
const checkPeriodFilterActive = (value: FiltersPeriod): boolean => value.startDate !== null;

const eqPayloadCurrencies = getEq(Eq.eqNumber);
const eqPayloadPayers = getEq(Eq.eqString);
const eqPayloadAmountOrPeriod = Eq.getStructEq<FiltersApiAmount | FiltersApiPeriod>({
    from: Eq.eqStrict,
    to: Eq.eqStrict,
});

const eqFiltersPayload = Eq.getStructEq<PaymentHistoryFiltersPayload>({
    currency_id: eqPartialCombinator(eqPayloadCurrencies),
    payer: eqPartialCombinator(eqPayloadPayers),
    amount: eqPartialCombinator(eqPayloadAmountOrPeriod),
    period: eqPartialCombinator(eqPayloadAmountOrPeriod),
});

const focusedInputDefaultValue: FocusedInputShape = 'startDate';

const PaymentHistoryFilters: FunctionComponent<PaymentHistoryFiltersProps> = (props) => {
    const { classes, filterDefaults, filterValues, onChange, disabled, vertical = false } = props;

    const [amount, setAmount] = useStateCallback<FiltersAmount>(getAmountFromPayload(filterValues));
    const [payers, setPayers] = useStateCallback<FiltersPayers>(getPayersFromPayload(filterValues));
    const [currencies, setCurrencies] = useStateCallback(getCurrenciesFromPayload(filterValues));

    const [period, setPeriod] = useStateCallback<FiltersPeriod>(getPeriodFromPayload(filterValues));

    const isAmountFilterActive = useMemo(() => checkAmountFilterActive(amount), [amount]);
    const isPayersFilterActive = useMemo(() => checkPayersFilterActive(payers), [payers]);
    const isCurrenciesFilterActive = useMemo(() => checkCurrenciesFilterActive(currencies), [currencies]);
    const isPeriodFilterActive = useMemo(() => checkPeriodFilterActive(period), [period]);

    const collectAndReceivePayloadDiff = (actualData?: FilterStateUpdate): void => {
        const payload: PaymentHistoryFiltersPayload = {};

        let amountData = amount;
        let payersData = payers;
        let currenciesData = currencies;
        let periodData = period;

        if (actualData) {
            if (actualData.amount) amountData = actualData.amount;
            if (actualData.payers) payersData = actualData.payers;
            if (actualData.period) periodData = actualData.period;
            if (actualData.currency_id) currenciesData = actualData.currency_id;
        }

        if (checkAmountFilterActive(amountData)) payload.amount = amountData;
        if (checkPayersFilterActive(payersData)) payload.payer = payersData;
        if (checkCurrenciesFilterActive(currenciesData)) payload.currency_id = currenciesData;
        if (checkPeriodFilterActive(periodData)) payload.period = PeriodPayloadIso.to(periodData);

        if (!eqFiltersPayload.equals(filterValues, payload)) onChange(payload);
    };

    const [focusedInput, setFocusedInput] = useState<FocusedInputShape | null>(focusedInputDefaultValue);
    const [openedFilter, setOpenedFilter] = useState<FilterTypes | null>(null);
    const toggleFilter = (type: FilterTypes): void => setOpenedFilter(openedFilter === type ? null : type);
    const closeAllFilters = (): void => setOpenedFilter(null);
    const collectAndClose = flow(collectAndReceivePayloadDiff, closeAllFilters);

    const [amountFormData, setAmountFormData] = useState<AmountFormData>(getAmountFormDataFromPayload(filterValues));
    const [amountHelperText, setAmountHelperText] = useState<string | null>(null);

    const triggerHelperText = (amountState: AmountFormData = amountFormData): void => {
        const helperTextChunks: string[] = [];

        const fromVal = parseFloat(amountState.from) as Kopecks;
        const toVal = parseFloat(amountState.to) as Kopecks;

        const isFromNumber = !Number.isNaN(fromVal);
        const isToNumber = !Number.isNaN(toVal);

        const amountMin = fromNullableKopecks(filterDefaults.amount.from);
        const amountMax = fromNullableKopecks(filterDefaults.amount.to);

        if (isFromNumber && amountMin !== null && fromVal < amountMin)
            helperTextChunks.push(
                `Значение поля "От" должно быть больше, либо равно ${formatCurrencyWithoutTail(
                    filterDefaults.amount.from!
                )}`
            );

        if (isFromNumber && amountMax !== null && fromVal > amountMax)
            helperTextChunks.push(
                `Значение поля "От" должно быть меньше, либо равно ${formatCurrencyWithoutTail(
                    filterDefaults.amount.to!
                )}`
            );

        if (isToNumber && amountMin !== null && toVal < amountMin)
            helperTextChunks.push(
                `Значение поля "До" должно быть больше, либо равно ${formatCurrencyWithoutTail(
                    filterDefaults.amount.from!
                )}`
            );

        if (isToNumber && amountMax !== null && toVal > amountMax)
            helperTextChunks.push(
                `Значение поля "До" должно быть меньше, либо равно ${formatCurrencyWithoutTail(
                    filterDefaults.amount.to!
                )}`
            );

        if (isFromNumber && isToNumber && fromVal > toVal)
            helperTextChunks.push('Значение поля "От" должно быть меньше, либо равно значению поля "До"');

        setAmountHelperText(helperTextChunks.length > 0 ? helperTextChunks.join('\n') : null);
    };

    const changeHandlerBuilder = (key: AmountFormDataK): React.ChangeEventHandler<HTMLInputElement> => (event) => {
        event.persist();

        let val = event.target.value.replace('e', '');
        const amountMax = fromNullableKopecks(filterDefaults.amount.to);

        if (key === 'to' && amountMax && Number(val) > amountMax) val = amountMax.toString();

        const newAmountFormDataState = { ...amountFormData, [key]: val };

        setAmountFormData(newAmountFormDataState);
        triggerHelperText(newAmountFormDataState);

        setTimeout(() => {
            // eslint-disable-next-line no-param-reassign
            event.target.value = val;
        }, 20);
    };

    const handleAmountSubmit = (): void => {
        const fromVal = parseFloat(amountFormData.from);
        const toVal = parseFloat(amountFormData.to);

        const isFromNumber = !Number.isNaN(fromVal);
        const isToNumber = !Number.isNaN(toVal);

        const amountMin = fromNullableKopecks(filterDefaults.amount.from);
        const amountMax = fromNullableKopecks(filterDefaults.amount.to);

        const isFromAboveMax = amountMax !== null && fromVal > amountMax;
        const isFromBelowMin = amountMin !== null && fromVal < amountMin;
        const isToAboveMax = amountMax !== null && toVal > amountMax;
        const isToBelowMin = amountMin !== null && toVal < amountMin;

        if (isFromNumber && isToNumber && fromVal > toVal) return;

        const amountState: FiltersAmount = { from: null, to: null };
        if (isFromNumber && !isFromAboveMax && !isFromBelowMin) amountState.from = KopecksIso.from(fromVal);
        if (isToNumber && !isToAboveMax && !isToBelowMin) amountState.to = KopecksIso.from(toVal);

        setAmount(amountState, (payload) => collectAndReceivePayloadDiff({ amount: payload }));

        if (!isFromBelowMin && !isToAboveMax) closeAllFilters();
    };

    const onKeyDownInputBehavior: React.KeyboardEventHandler<HTMLInputElement> = (event) => {
        event.persist();
        if (event.which === 13 || event.keyCode === 13) handleAmountSubmit();
        if (event.which === 8 || event.key === 'Backspace') {
            const from = parseFloat(amountFormData.from);
            const amountMin = fromNullableKopecks(filterDefaults.amount.from);
            if (!Number.isNaN(from) && amountMin !== null && from < amountMin) {
                setAmountFormData((h) => ({ ...h, from: '' }));
            }
        }
    };

    const onBlurInputBehavior: (key: AmountFormDataK) => React.FocusEventHandler<HTMLInputElement> = (key) => () => {
        const amountMin = fromNullableKopecks(filterDefaults.amount.from);
        const from = parseFloat(amountFormData.from);
        const to = parseFloat(amountFormData.to);

        let newAmountFormState: AmountFormData | null = null;

        if (key === 'from' && !Number.isNaN(from) && amountMin !== null && from < amountMin)
            newAmountFormState = { to: amountFormData.to, from: amountMin.toString() };

        if (key === 'to' && !Number.isNaN(to) && (to < from || (amountMin && to < amountMin))) {
            const fromString = newAmountFormState?.from || amountFormData.from || amountMin?.toString() || '';
            newAmountFormState = { to: fromString, from: amountFormData.from };
        }

        if (newAmountFormState) {
            setAmountFormData(newAmountFormState);
            triggerHelperText(newAmountFormState);
        }
    };

    const toggleAmount = (): void => {
        toggleFilter(FilterTypes.Price);
        setAmountFormData(getAmountFormDataFromPayload(filterValues));
    };

    const clearAmount = (): void => {
        setAmountFormData({ from: '', to: '' });
        setAmount({ from: null, to: null }, (payload) => collectAndReceivePayloadDiff({ amount: payload }));
        closeAllFilters();
    };

    const togglePeriod = (): void => toggleFilter(FilterTypes.Period);
    const clearPeriod = (): void => {
        setPeriod({ startDate: null, endDate: null }, (payload) => collectAndReceivePayloadDiff({ period: payload }));
        closeAllFilters();
    };

    const togglePayers = (): void => toggleFilter(FilterTypes.Payer);
    const clearPayers = (): void => {
        setPayers([], (payload) => collectAndReceivePayloadDiff({ payers: payload }));
        closeAllFilters();
    };

    const toggleCurrency = (): void => toggleFilter(FilterTypes.Currency);
    const clearCurrencies = (): void => {
        setCurrencies([], (payload) => collectAndReceivePayloadDiff({ currency_id: payload }));
        closeAllFilters();
    };

    const clearFilters = (): void => {
        const emptyFilters: Required<FilterStateUpdate> = {
            amount: { from: null, to: null },
            period: { startDate: null, endDate: null },
            payers: [],
            currency_id: [],
        };

        setAmount(emptyFilters.amount);
        setPeriod(emptyFilters.period);
        setPayers(emptyFilters.payers);
        setCurrencies(emptyFilters.currency_id);

        collectAndReceivePayloadDiff(emptyFilters);
    };

    const hasActiveFilters = useMemo(() => size(filterValues) > 0, [filterValues]);
    const datesMinMaxInterval = useMemo(() => PeriodPayloadIso.from(filterDefaults.period), [filterDefaults]);

    return (
        <div className={clsx(['PaymentHistoryFilters', vertical && 'PaymentHistoryFilters--vertical'])}>


            <FilterTooltip
                type={FilterTypes.Price}
                openedFilter={openedFilter}
                hideOpenedFilter={closeAllFilters}
                title={
                    <div>
                        <form className={classes.form}>
                            <TextField
                                className={clsx(classes.formField, classes.numberField)}
                                label="От"
                                type="number"
                                size="small"
                                variant="outlined"
                                value={amountFormData.from}
                                onBlur={onBlurInputBehavior('from')}
                                onChange={changeHandlerBuilder('from')}
                                onKeyDown={onKeyDownInputBehavior}
                                InputProps={{
                                    classes: { notchedOutline: classes.numberFieldInner },
                                    inputProps: filterDefaults.amount,
                                }}
                            />

                            <TextField
                                className={clsx(classes.formField, classes.numberField)}
                                label="До"
                                type="number"
                                size="small"
                                variant="outlined"
                                value={amountFormData.to}
                                onBlur={onBlurInputBehavior('to')}
                                onChange={changeHandlerBuilder('to')}
                                onKeyDown={onKeyDownInputBehavior}
                                InputProps={{
                                    classes: { notchedOutline: classes.numberFieldInner },
                                    inputProps: filterDefaults.amount,
                                }}
                            />

                            <Button
                                color="primary"
                                className={clsx(classes.formField, classes.formButton)}
                                disabled={amountHelperText !== null}
                                onClick={handleAmountSubmit}
                            >
                                Применить
                            </Button>
                        </form>
                    </div>
                }
            >
                <Chip
                    color="primary"
                    label={foldAmountLabel(amount)}
                    variant={openedFilter === FilterTypes.Price || isAmountFilterActive ? 'default' : 'outlined'}
                    disabled={disabled}
                    deleteIcon={isAmountFilterActive ? <CancelIcon /> : <ExpandMoreIcon />}
                    onClick={toggleAmount}
                    onDelete={isAmountFilterActive ? clearAmount : toggleAmount}
                />
            </FilterTooltip>

            <FilterTooltip
                withoutPadding
                type={FilterTypes.Period}
                openedFilter={openedFilter}
                hideOpenedFilter={collectAndClose}
                title={
                    <DayPickerRangeController
                        minDate={datesMinMaxInterval.startDate}
                        maxDate={datesMinMaxInterval.endDate}
                        navPrev={<ChevronLeftIcon />}
                        navNext={<ChevronRightIcon />}
                        startDate={period.startDate}
                        endDate={period.endDate}
                        numberOfMonths={1}
                        initialVisibleMonth={null}
                        focusedInput={focusedInput}
                        onFocusChange={(input) => setFocusedInput(input || focusedInputDefaultValue)}
                        onDatesChange={({ startDate, endDate }) => {
                            const isFirstValue = focusedInput === focusedInputDefaultValue;

                            setPeriod({
                                startDate: startDate ? moment(startDate.format()).clone() : null,
                                endDate: endDate && !isFirstValue ? moment(endDate.format()).clone() : null,
                            });
                        }}
                    />
                }
            >
                <Chip
                    color="primary"
                    label={foldDateRangeLabel(period)}
                    variant={openedFilter === FilterTypes.Period || isPeriodFilterActive ? 'default' : 'outlined'}
                    disabled={disabled}
                    deleteIcon={isPeriodFilterActive ? <CancelIcon /> : <ExpandMoreIcon />}
                    onClick={togglePeriod}
                    onDelete={isPeriodFilterActive ? clearPeriod : togglePeriod}
                />
            </FilterTooltip>

            <CurrenciesPicker
                currencies={currencies}
                disabled={disabled}
                setCurrencies={setCurrencies}
                currenciesList={filterDefaults.currency_id}
                onClose={collectAndClose}
                onDelete={isCurrenciesFilterActive ? clearCurrencies : toggleCurrency}
            />

            <PayersPicker
                payers={payers}
                disabled={disabled}
                setPayers={setPayers}
                payersList={filterDefaults.payer}
                onClick={togglePayers}
                onClose={collectAndClose}
                onDelete={isPayersFilterActive ? clearPayers : togglePayers}
            />

            {hasActiveFilters && (
                <Button color="primary" onClick={clearFilters}>
                    Очистить фильтры
                </Button>
            )}
        </div>
    );
};

const Component = pipe(PaymentHistoryFilters, withStyles(styles));
export { Component as PaymentHistoryFilters };

import React, { FunctionComponent, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import clsx from 'clsx';

import { pipe } from 'fp-ts/function';

import { Avatar, Button, Tooltip } from '@material-ui/core';

import { ManagerData } from '../../store/orders/types';
import { WorkingHours } from '../../store/settings/types';
import { RootState } from '../../store/root';

import { formatPhone } from '../../utils/view';
import { useCallDisable } from './callDisable';

import { CallModal, ManagersPhones } from './CallModal';

import './ManagerCard.scss';

import DefaultImage from '../../assets/noImage.png';

type StateProps = { workingHours: WorkingHours };
const mapState = (state: RootState): StateProps => ({
    workingHours: state.settings.workingHours,
});

type ManagerCardProps = {
    person: ManagerData;
    phones: ManagersPhones;
    orderNumber: string;
    onCallModalOpen?: () => void;
    onCallModalClose?: () => void;
} & ReturnType<typeof mapState>;

const ManagerCard: FunctionComponent<ManagerCardProps> = (props) => {
    const { person, phones, orderNumber, workingHours, onCallModalOpen, onCallModalClose } = props;

    const [open, setOpen] = useState(false);
    const [src, retinaSrc] = useMemo(
        () => [
            person.photo.sizes.find((size) => size.width === 128)?.url || DefaultImage,
            person.photo.sizes.find((size) => size.width === 256)?.url || DefaultImage,
        ],
        [person]
    );

    const { message: disableCallButtonMessage } = useCallDisable(workingHours, person);

    const canCall = useMemo(() => person.phones.length > 0 || person.phone_switch_internal_code !== null, [person]);

    const isManagerAtWork = disableCallButtonMessage === null;

    return (
        <>
            <div className="ManagerCard">
                <Avatar className="ManagerCard__avatar" alt={person.full_name} src={src} srcSet={`${retinaSrc} 2x`} />
                <div className="ManagerCard__info">
                    <span className="ManagerCard__position">{person.work_position}</span>
                    <span className="ManagerCard__name">{person.full_name}</span>
                </div>
                <div className="ManagerCard__contacts">
                    {person.phones.map((phone, index) => {
                        const formattedPhone = formatPhone(phone);
                        return (
                            <Tooltip
                                arrow
                                placement="right"
                                key={`phone-${index}`}
                                open={isManagerAtWork ? false : undefined}
                                title={disableCallButtonMessage || ''}
                            >
                                <a
                                    className="ManagerCard__phone"
                                    key={`manager-phone-${phone}`}
                                    href={`tel:${formattedPhone}`}
                                >
                                    <span>{formattedPhone}</span>
                                </a>
                            </Tooltip>
                        );
                    })}
                    {person.email !== null && (
                        <a className="ManagerCard__email" href={`mailto:${person.email}`}>
                            {person.email}
                        </a>
                    )}
                </div>
                <div className="ManagerCard__call">
                    {canCall && (
                        <div
                            className={clsx([
                                'ManagerCard__onlineCall',
                                !isManagerAtWork &&
                                    disableCallButtonMessage &&
                                    'ManagerCard__onlineCall--hiddenOnTouch',
                            ])}
                        >
                            <Tooltip
                                arrow
                                placement="bottom"
                                open={isManagerAtWork ? false : undefined}
                                title={disableCallButtonMessage || ''}
                            >
                                <span>
                                    <Button
                                        size="small"
                                        color="primary"
                                        variant="outlined"
                                        disabled={!isManagerAtWork}
                                        onClick={() => {
                                            if (onCallModalOpen) onCallModalOpen();
                                            setOpen(true);
                                        }}
                                    >
                                        Связаться онлайн
                                    </Button>
                                </span>
                            </Tooltip>
                        </div>
                    )}
                </div>
            </div>
            {canCall && (
                <CallModal
                    open={open}
                    handleClose={() => {
                        if (onCallModalClose) onCallModalClose();
                        setOpen(false);
                    }}
                    person={person}
                    phones={phones}
                    orderNumber={orderNumber}
                />
            )}
        </>
    );
};

const EnchantedComponent = pipe(ManagerCard, connect(mapState));
export { EnchantedComponent as ManagerCard };

import React, { FunctionComponent, useState } from 'react';
import { Button, CircularProgress, Dialog, DialogActions, DialogTitle } from '@material-ui/core';
import { pipe } from 'fp-ts/function';
import { connect } from 'react-redux';
import { apiUserLogout, makeMapDispatch } from '../store/dispatch';

const mapDispatch = makeMapDispatch({ emitLogout: apiUserLogout });

type Props = {
    isOpen: boolean;
    setClose: () => void;
} & ReturnType<typeof mapDispatch>;

const LogoutConfirm: FunctionComponent<Props> = (props) => {
    const { isOpen, setClose, emitLogout } = props;

    const [isLoading, setLoading] = useState(false);
    const onLogout = (): void => {
        setLoading(true);
        emitLogout().finally(() => window.location.reload());
    };

    return (
        <Dialog open={isOpen} onClose={setClose} aria-labelledby="logout-confirm-title">
            <DialogTitle id="logout-confirm-title">Вы&nbsp;уверены, что хотите выйти?</DialogTitle>
            <DialogActions>
                <Button
                    disabled={isLoading}
                    onClick={() => {
                        setClose();
                    }}
                    color="primary"
                >
                    Нет
                </Button>
                <Button disabled={isLoading} onClick={onLogout} color="primary" autoFocus>
                    <span>Да</span>
                    {isLoading && <CircularProgress style={{ marginLeft: 4 }} size={12} />}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

const component = pipe(LogoutConfirm, connect(null, mapDispatch));
export { component as LogoutConfirm };

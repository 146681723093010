import { Reducer } from 'redux';
import storage from 'redux-persist/lib/storage';
import { persistReducer } from 'redux-persist';

import { deleteAt, findFirst, findIndex, snoc } from 'fp-ts/Array';
import { fold, getOrElse } from 'fp-ts/Option';
import { pipe } from 'fp-ts/function';

import { Lens } from 'monocle-ts';

import { SettingsState, SettingsActionTypes, SipSettings, WorkingHours } from './types';
import { SafePersistConfig } from '../../utils/types';

const sipDefaults: SipSettings = {
    aor: '',
    username: '',
    password: '',
    phone_switch_host: '',
    websocket_server_url: '',
    auto_phone_system_internal_code: -1,
};

const workingHoursDefault: WorkingHours = {
    start_time_seconds: 0,
    end_time_seconds: 0,
    days_off: [],
};

const initialState: SettingsState = {
    fetched: false,
    orderTypes: [],
    notificationTypes: [],
    sipSettings: sipDefaults,
    workingHours: workingHoursDefault,
    documentTypes: [],
    managersVisible: true,
    lastPaginationPageBeforeSearch: 1,
    customerOrderTypeIds: [],
    syncedOrderTypeIds: [],
    seenBills: [],
    previousLastUserSeenAt: null,
    currentLastUserSeenAt: null,
    isUserSeenGuide: false,
    printFormTypes: [],
    pageSizeConfig: {
        orders: '10',
        bills: '10',
        payments: '20',
    },
    seenHelpInfoKeys: [],
};

const visibleLens = Lens.fromProp<SettingsState>()('managersVisible');

const billsPageSizeLens = Lens.fromPath<SettingsState>()(['pageSizeConfig', 'bills']);
const ordersPageSizeLens = Lens.fromPath<SettingsState>()(['pageSizeConfig', 'orders']);
const paymentsPageSizeLens = Lens.fromPath<SettingsState>()(['pageSizeConfig', 'payments']);

const currentLastUserSeenAtLens = Lens.fromProp<SettingsState>()('currentLastUserSeenAt');
const previousLastUserSeenAtLens = Lens.fromProp<SettingsState>()('previousLastUserSeenAt');

const paginationPageBeforeSearchLens = Lens.fromProp<SettingsState>()('lastPaginationPageBeforeSearch');

const seenBillsLens = Lens.fromProp<SettingsState>()('seenBills');
const isUserSeenGuideLens = Lens.fromProp<SettingsState>()('isUserSeenGuide');
const seenHelpInfoKeysLens = Lens.fromProp<SettingsState>()('seenHelpInfoKeys');

const settingsLens = Lens.fromProps<SettingsState>()([
    'notificationTypes',
    'orderTypes',
    'sipSettings',
    'fetched',
    'workingHours',
    'documentTypes',
    'customerOrderTypeIds',
    'syncedOrderTypeIds',
    'managersVisible',
    'lastPaginationPageBeforeSearch',
    'printFormTypes',
]);

const baseReducer: Reducer<SettingsState, SettingsActionTypes> = (state = initialState, action) => {
    switch (action.type) {
        case 'SET_SETTINGS': {
            return pipe(
                state,
                settingsLens.set({
                    notificationTypes: action.payload.notification_types,
                    orderTypes: action.payload.order_types,
                    sipSettings: action.payload.sip,
                    managersVisible: state.managersVisible,
                    workingHours: action.payload.working_hours,
                    documentTypes: action.payload.document_types,
                    fetched: true,
                    customerOrderTypeIds: action.payload.customer_order_type_ids,
                    syncedOrderTypeIds: action.payload.synced_order_type_ids,
                    lastPaginationPageBeforeSearch: 1,
                    printFormTypes: action.payload.print_form_types,
                })
            );
        }
        case 'FLUSH_SETTINGS':
            return pipe(
                state,
                settingsLens.set({
                    fetched: false,
                    orderTypes: [],
                    notificationTypes: [],
                    sipSettings: sipDefaults,
                    workingHours: workingHoursDefault,
                    documentTypes: [],
                    managersVisible: true,
                    lastPaginationPageBeforeSearch: 1,
                    customerOrderTypeIds: [],
                    syncedOrderTypeIds: [],
                    printFormTypes: [],
                })
            );
        case 'SET_MANAGERS_VISIBLE':
            return pipe(state, visibleLens.set(action.payload));
        case 'SET_ORDER_LIST_PAGE_SIZE':
            return pipe(state, ordersPageSizeLens.set(action.payload));
        case 'SET_BILLS_LIST_PAGE_SIZE':
            return pipe(state, billsPageSizeLens.set(action.payload));
        case 'SET_PAYMENTS_LIST_PAGE_SIZE':
            return pipe(state, paymentsPageSizeLens.set(action.payload));
        case 'SET_LAST_PAGINATION_PAGE_BEFORE_SEARCH':
            return pipe(state, paginationPageBeforeSearchLens.set(action.payload));
        case 'ADD_SEEN_BILL':
            return pipe(
                state,
                seenBillsLens.modify((a) =>
                    pipe(
                        a,
                        findFirst((i) => i === action.payload),
                        fold(
                            () => snoc(a, action.payload),
                            () => a
                        )
                    )
                )
            );
        case 'REMOVE_SEEN_BILL':
            return pipe(
                state,
                seenBillsLens.modify((a) =>
                    pipe(
                        a,
                        findIndex((i) => i === action.payload),
                        fold(
                            () => a,
                            (index) =>
                                pipe(
                                    deleteAt(index)(a),
                                    getOrElse(() => a)
                                )
                        )
                    )
                )
            );
        case 'SET_PREVIOUS_LAST_USER_SEEN_AT':
            return pipe(state, previousLastUserSeenAtLens.set(action.payload));
        case 'SET_CURRENT_LAST_USER_SEEN_AT':
            return pipe(state, currentLastUserSeenAtLens.set(action.payload));
        case 'IS_USER_SEEN_GUIDE':
            return pipe(state, isUserSeenGuideLens.set(action.payload));
        case 'SET_SEEN_INFO_KEY':
            return pipe(
                state,
                seenHelpInfoKeysLens.modify((items) => [...items, action.payload])
            );
        default:
            return state;
    }
};

const persistConfig: SafePersistConfig<SettingsState> = {
    key: 'settings',
    storage,
    whitelist: [
        'managersVisible',
        'pageSizeConfig',
        'seenBills',
        'previousLastUserSeenAt',
        'currentLastUserSeenAt',
        'isUserSeenGuide',
        'seenHelpInfoKeys',
    ],
};

export const settingsReducer = persistReducer(persistConfig, baseReducer);

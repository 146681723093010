import React, { FunctionComponent } from 'react';

import { ConsolidationRow } from '../../store/orders/types';
import { formattedConsolidationTableValue } from '../../utils/view';

import './ConsolidationTable.scss';

type ConsolidationTableProps = {
    tableRows: ConsolidationRow[];
};

export const ConsolidationTable: FunctionComponent<ConsolidationTableProps> = (props) => {
    const { tableRows } = props;

    if (tableRows.length === 0) return null;

    return (
        <div className="ConsolidationTable">
            <div className="ConsolidationTable__row ConsolidationTable__head">
                {tableRows[0].columns.map((column) => (
                    <span className="ConsolidationTable__cell ConsolidationTable__headItem" key={column.column_key}>
                        {column.column_title}
                    </span>
                ))}
            </div>

            {tableRows.map((row) => (
                <div className="ConsolidationTable__row" key={row.position}>
                    {row.columns.map((column) => (
                        <span className="ConsolidationTable__cell" key={column.column_key}>
                            <span className="ConsolidationTable__mobileKey">{column.column_title}</span>
                            <span>{formattedConsolidationTableValue(column.value) !== null ? formattedConsolidationTableValue(column.value) : '—'}</span>
                        </span>
                    ))}
                </div>
            ))}
        </div>
    );
};

import React, { FunctionComponent, useState } from 'react';
import { useForm } from 'react-hook-form';
import { connect } from 'react-redux';

import { pipe } from 'fp-ts/function';
import { flow } from 'fp-ts/function';

import {
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    TextField,
} from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/styles';

import { Bill } from '../store/bills/types';

import { buildNotification } from '../utils/noty';

import { useAlerts } from '../hooks/noty';
import { FormLimits } from '../utils/const';
import { apiBillUpdateDispute, makeMapDispatch } from '../store/dispatch';
import { stripHTMLTags } from '../utils/strings';

const useStyles = makeStyles(() =>
    createStyles({
        loadingButton: {
            position: 'relative',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        },
        loading: {
            position: 'absolute',
        },
        form: {
            display: 'flex',
            flexDirection: 'column',
            maxHeight: 'calc(100% - 64px)',
            overflowY: 'auto',
        },
    })
);

const reportErrorText = {
    title: 'Отправка диспута',
    validation: 'Длина сообщения должна быть не менее 5 и не более 200 символов включительно',
    successNotification: buildNotification(
        'Диспут успешно открыт',
        'Мы постараемся разобраться в сложившейся ситуации как можно скорее'
    ),
};

const mapDispatch = makeMapDispatch({ updateDispute: apiBillUpdateDispute });

type FormData = { message: string };
type DisputeComponentProps = {
    bill: Bill;
    handleClose: () => void;
    afterDisputeUpdate: () => void;
};

type DisputeProps = DisputeComponentProps & ReturnType<typeof mapDispatch>;
const Dispute: FunctionComponent<DisputeProps> = (props) => {
    const { handleClose, bill, updateDispute, afterDisputeUpdate } = props;

    const defaultValues: FormData = { message: bill.dispute.comment || '' };

    const { register, handleSubmit, clearError, setError, setValue, errors, watch } = useForm<FormData>({
        defaultValues,
    });

    const { message } = watch();

    const styles = useStyles();
    const { showNotification, handleApiError } = useAlerts(setError);

    const [pending, setPending] = useState(false);

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const handleSuccess = (): void => {
        handleClose();
        showNotification(reportErrorText.successNotification, { variant: 'success' });
    };

    const onSubmit = (data: FormData): void => {
        if (pending) return;

        clearError();

        const safeValue = stripHTMLTags(data.message.trim());
        setValue('message', safeValue);

        if (safeValue.length < FormLimits.DisputeMessage.Min) {
            setError([{ name: 'message', type: '' }]);
            return;
        }

        setPending(true);

        updateDispute({ bill_id: bill.id, comment: data.message })
            .then(flow(handleSuccess, afterDisputeUpdate))
            .catch(handleApiError);
    };

    return (
        <Dialog
            onClose={handleClose}
            aria-labelledby="dispute-error-title"
            maxWidth="md"
            fullWidth
            disableBackdropClick={pending}
            open={bill !== null}
        >
            <form onSubmit={handleSubmit(onSubmit)} className={styles.form}>
                <DialogTitle id="dispute-error-title">
                    <span
                        dangerouslySetInnerHTML={{
                            __html: reportErrorText.title,
                        }}
                    />
                </DialogTitle>
                <DialogContent>
                    <Grid container spacing={1}>
                        <Grid item xs={12}>
                            <div className="DisputeField">
                                <TextField
                                    inputRef={register({
                                        minLength: FormLimits.DisputeMessage.Min,
                                        maxLength: FormLimits.DisputeMessage.Max,
                                        required: true,
                                    })}
                                    margin="dense"
                                    id="message"
                                    name="message"
                                    label="Сообщение"
                                    rows={3}
                                    variant="outlined"
                                    multiline
                                    fullWidth
                                    error={!!errors.message}
                                    inputProps={{ maxLength: FormLimits.DisputeMessage.Max }}
                                    helperText={
                                        <span className="DisputeField__text">
                                            <span>{reportErrorText.validation}</span>
                                            <span className="DisputeField__counter">
                                                {message ? message.length : 0}/{FormLimits.DisputeMessage.Max}
                                            </span>
                                        </span>
                                    }
                                />
                            </div>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button disabled={pending} onClick={handleClose} color="primary" type="button">
                        Закрыть
                    </Button>

                    <div className={styles.loadingButton}>
                        <Button disabled={pending} variant="contained" color="primary" type="submit">
                            Отправить
                        </Button>
                        {pending && <CircularProgress className={styles.loading} size={16} />}
                    </div>
                </DialogActions>
            </form>
        </Dialog>
    );
};

const EnchantedComponent = pipe(Dispute, connect(null, mapDispatch));
export { EnchantedComponent as Dispute };

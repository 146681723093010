import type { PaymentHistorySortBy } from '../payment-history/types';
import type { OrderListSortBy } from '../orders/types';
import type { SortingData } from '../../utils/types';
import type { BillsSortBy } from '../bills/types';

export type SortingConfig = {
    bills: BillsSortBy;
    orders: OrderListSortBy;
    payments: PaymentHistorySortBy;
};

export type SortingConfigKey = keyof SortingConfig;

export type SortsState = { [K in keyof SortingConfig]: SortingData<SortingConfig[K]> };

export const SORTS_SET_DATA = 'SET_SORTS_DATA';

interface SortsSetDataAction {
    type: typeof SORTS_SET_DATA;
    payload: SortsState;
}

export type SortsActionTypes = SortsSetDataAction;

import React, { FunctionComponent, createContext, useState } from 'react';
import { useSnackbar, VariantType, SnackbarKey } from 'notistack';
import { connect } from 'react-redux';

import type {
    DocumentStatus,
    RequestDocumentResponse,
    GetDocumentResponse,
    GetDocumentRequest,
} from '../types/documents';

import type { BillsFiltersPayload, BillsPackageRequest } from '../store/bills/types';
import {
    makeMapDispatch,
    apiRequestBillPackageDocument,
    apiGetBillPackageDocument,
    apiGetBillListDocument,
    apiRequestBillListDocument,
    apiGetPaymentHistoryDocument,
    apiRequestPaymentHistoryDocument,
    apiOrderContainersPackageRequestDocument,
    apiOrderContainerPackageRequestDocument,
    apiOrderPackageRequestDocument,
    apiOrderPackageGetDocument,
    apiReportsGetDocument,
    apiReportsRequestDocument,
    apiTemplateRequestDocument,
    apiDocumentPackageErrorCount
} from '../store/dispatch';

import { ApiError } from '../api/client/errors';

import { isFailedDocumentStatus, isPreparedDocumentStatus, isPreparedDocument } from '../utils/guards';
import { buildNotification } from '../utils/noty';
import { downloadDocument } from '../utils/location';
import { sleep } from '../utils/debounce';
import { DocumentState } from '../store/documents/types';
import { addDocument, removeDocument } from '../store/documents/actions';

type InternalFailedStatuses = 'fallback';
type TransConfig = { label: string; variant: VariantType; persist?: boolean };

type LoadingListItem = {
    type: 'orderAll' | 'orderContainer' | 'orderType',
    key: string,
};

type DownloadPackageDocumentSignature = (payload: BillsPackageRequest, data: DocumentState) => Promise<void>;
type DownloadListDocumentSignature = (payload: BillsFiltersPayload, data: DocumentState) => Promise<void>;
type DownloadContainersSignature = (payload: Protocol.OrderContainersPackageRequestDocumentRequest, data: DocumentState) => Promise<void>;
type DownloadContainerSignature = (payload: Protocol.OrderContainerPackageRequestDocumentRequest, data: DocumentState) => Promise<void>;
type DownloadPaymentsSignature = (payload: Protocol.PaymentHistoryRequest, data: DocumentState) => Promise<void>;
type DownloadOrderSignature = (payload: Protocol.OrderPackageRequestDocumentRequest, data: DocumentState) => Promise<void>;
type DownloadReportsSignature = (payload: Protocol.ReportsDocumentRequest, data: DocumentState) => Promise<void>;
type DownloadTemplateReportSignature = (payload: Protocol.TemplateDocumentRequest, data: DocumentState) => Promise<void>;

type DocumentLoaderConfig<T> = {
    requestDocument: (payload: T) => Promise<RequestDocumentResponse>;
    getDocument: (payload: GetDocumentRequest) => Promise<GetDocumentResponse>;
    beforeSend?: (payload: T) => void;
    afterSend?: (payload: T) => void;
    needErrorCount?: boolean;
};

export type DocumentLoaderProviderContext = {
    downloadBillsPackageDocument: DownloadPackageDocumentSignature;
    downloadContainersDocument: DownloadContainersSignature;
    downloadContainerDocument: DownloadContainerSignature;
    downloadBillsListDocument: DownloadListDocumentSignature;
    downloadPaymentsDocument: DownloadPaymentsSignature;
    downloadOrderDocument: DownloadOrderSignature;
    downloadReportsDocument: DownloadReportsSignature;
    downloadTemplateReportDocument: DownloadTemplateReportSignature;
    currentOrderId: number | null;
};

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
export const DocumentLoaderContext = createContext<DocumentLoaderProviderContext>(null!);

const SafeLoopConfig = { count: 30, delay: 4000 };
const NotyStatusesMap: Record<DocumentStatus | InternalFailedStatuses, TransConfig> = {
    fallback: {
        label: 'Во время загрузки документа произошла неизвестная ошибка',
        variant: 'error',
    },
    new: {
        label: 'Формирование документа для скачивания',
        variant: 'info',
        persist: true,
    },
    document_prepared: {
        label: 'Документ сформирован',
        variant: 'success',
    },
    requests_processed: {
        label: 'Запрос обработан',
        variant: 'info',
    },
    no_documents_generated: {
        label: 'Не выбраны документы',
        variant: 'error',
    },
    prepared_document_does_not_exist: {
        label: 'Выбранные документы отсутствуют',
        variant: 'error',
    },
};

const mapDispatch = makeMapDispatch({
    getListDocument: apiGetBillListDocument,
    getOrderDocument: apiOrderPackageGetDocument,
    getReportsDocument: apiReportsGetDocument,
    getTemplateReportDocument: apiReportsGetDocument,
    getPackageDocument: apiGetBillPackageDocument,
    getPaymentDocument: apiGetPaymentHistoryDocument,
    requestListDocument: apiRequestBillListDocument,
    requestOrderDocument: apiOrderPackageRequestDocument,
    requestReportsDocument: apiReportsRequestDocument,
    requestTemplateReportDocument: apiTemplateRequestDocument,
    requestPackageDocument: apiRequestBillPackageDocument,
    requestPaymentDocument: apiRequestPaymentHistoryDocument,
    requestContainerPackageDocument: apiOrderContainerPackageRequestDocument,
    requestContainersPackageDocument: apiOrderContainersPackageRequestDocument,
    addDocumentToStore: addDocument,
    removeDocumentFromStore: removeDocument,
    documentPackageErrorCount: apiDocumentPackageErrorCount
});

const DocumentLoaderProvider: FunctionComponent<ReturnType<typeof mapDispatch>> = (props) => {
    const {
        getListDocument,
        getOrderDocument,
        getPaymentDocument,
        getPackageDocument,
        getReportsDocument,
        getTemplateReportDocument,
        requestTemplateReportDocument,
        requestListDocument,
        requestOrderDocument,
        requestPaymentDocument,
        requestPackageDocument,
        requestReportsDocument,
        requestContainerPackageDocument,
        requestContainersPackageDocument,
        addDocumentToStore,
        removeDocumentFromStore,
        documentPackageErrorCount,
        children,
    } = props;

    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    const [currentOrderId, setCurrentOrderId] = useState<number | null>(null);

    const notyStatusBuilder = (documentName: string) => (config: TransConfig): SnackbarKey =>
        enqueueSnackbar(buildNotification(documentName, config.label), {
            persist: Boolean(config.persist),
            variant: config.variant,
            anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'left',
            },
        });

    /* eslint-disable no-await-in-loop */
    const documentLoaderBuilder = <T extends unknown = unknown>(config: DocumentLoaderConfig<T>) => async (
        payload: T,
        data: DocumentState,
    ): Promise<void> => {
        const { getDocument, requestDocument, beforeSend, afterSend, needErrorCount } = config;

        addDocumentToStore(data);

        if (beforeSend) beforeSend(payload);
        try {
            const requestResult = await requestDocument(payload);

            let { status } = requestResult.document;
            const { id: documentId, display_name: documentName } = requestResult.document;

            const showNoty = notyStatusBuilder(documentName);

            if (isFailedDocumentStatus(status)) {
                showNoty(NotyStatusesMap[status]);

                removeDocumentFromStore(data.key);
                if (needErrorCount) documentPackageErrorCount({ document_id: documentId });
                return;
            }

            const notyRef = showNoty(NotyStatusesMap.new);

            do {
                try {
                    const getResult = await getDocument({ document_id: documentId });
                    status = getResult.status;
                    if (isPreparedDocument(getResult)) {
                        closeSnackbar(notyRef);
                        showNoty(NotyStatusesMap.document_prepared);
                        downloadDocument(getResult.document);
                        break;
                    }

                    await sleep(SafeLoopConfig.delay);
                } catch (e) {
                    closeSnackbar(notyRef);

                    let errorMessage = NotyStatusesMap.fallback;
                    if (e instanceof ApiError) {
                        const commonErrorMessage = e.getCommonFirstMessage();
                        if (commonErrorMessage)
                            errorMessage = {
                                variant: 'error',
                                label: commonErrorMessage,
                            };
                    }

                    showNoty(errorMessage);
                    if (data) removeDocumentFromStore(data.key);

                    if (afterSend) afterSend(payload);

                    if (needErrorCount) documentPackageErrorCount({ document_id: documentId });

                    return;
                }
            } while (!isFailedDocumentStatus(status) && !isPreparedDocumentStatus(status));

            if (!isPreparedDocumentStatus(status)) {
                closeSnackbar(notyRef);
                showNoty(NotyStatusesMap.fallback);
            }
        } catch (e) {
            notyStatusBuilder('Запрашиваемый документ')(NotyStatusesMap.fallback);
        }

        if (data) removeDocumentFromStore(data.key);

        if (afterSend) afterSend(payload);
    };
    /* eslint-enable no-await-in-loop */

    return (
        <DocumentLoaderContext.Provider
            value={{
                currentOrderId,
                downloadBillsPackageDocument: documentLoaderBuilder({
                    requestDocument: requestPackageDocument,
                    getDocument: getPackageDocument,
                    needErrorCount: true,
                }),
                downloadBillsListDocument: documentLoaderBuilder({
                    requestDocument: requestListDocument,
                    getDocument: getListDocument,
                }),
                downloadPaymentsDocument: documentLoaderBuilder({
                    requestDocument: requestPaymentDocument,
                    getDocument: getPaymentDocument,
                }),
                downloadReportsDocument: documentLoaderBuilder({
                    requestDocument: requestReportsDocument,
                    getDocument: getReportsDocument,
                }),
                downloadTemplateReportDocument: documentLoaderBuilder({
                    requestDocument: requestTemplateReportDocument,
                    getDocument: getTemplateReportDocument,
                }),
                downloadOrderDocument: documentLoaderBuilder({
                    requestDocument: requestOrderDocument,
                    getDocument: getOrderDocument,
                    beforeSend: (payload) => setCurrentOrderId(payload.order_id),
                    afterSend: () => setCurrentOrderId(null),
                    needErrorCount: true,
                }),
                downloadContainerDocument: documentLoaderBuilder({
                    requestDocument: requestContainerPackageDocument,
                    getDocument: getOrderDocument,
                    needErrorCount: true,
                }),
                downloadContainersDocument: documentLoaderBuilder({
                    requestDocument: requestContainersPackageDocument,
                    getDocument: getOrderDocument,
                    needErrorCount: true,
                }),
            }}
        >
            {children}
        </DocumentLoaderContext.Provider>
    );
};

const EnchantedProvider = connect(null, mapDispatch)(DocumentLoaderProvider);
export { EnchantedProvider as DocumentLoaderProvider };

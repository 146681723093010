import React, { FunctionComponent, useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { BrowserRouter as Router, Route, Redirect, Switch } from 'react-router-dom';

import moment from 'moment';
import 'moment/locale/ru';
import 'moment-timezone';

import { pipe } from 'fp-ts/function';

import { appTitle } from '../../utils/title';
import { isUserGranted } from '../../utils/user';
import { appPath, getCurrentPath, ROUTES } from '../../utils/location';

import { ApiClient } from '../../api';
import { makeMapState } from '../../store/root';
import { setRedirectUrl } from '../../store/user/actions';
import { setUserSeenGuide } from '../../store/settings/actions';
import { apiProfileDetails, apiSettings, apiUserLogout, makeMapDispatch } from '../../store/dispatch';

import { PrivateRoute } from '../../router/PrivateRoute';

import { SearchProvider } from '../../providers/SearchProvider';
import { PagesProvider } from '../../providers/PagesProvider';
import { ReportSubscribersProvider } from '../../providers/ReportSubscribersProvider';

import { RememberGuestRoute } from '../../components/RememberGuestRoute';
import { PagePreview } from '../../components/PagePreview/PagePreview';
import { ErrorReport } from '../../components/ErrorReport';
import { GuideModal } from '../../components/Guide/Guide';
import { Loading } from '../../components/Loading';

import { MutualSettlementsNotifications } from '../../views/MutualSettlementsNotifications/MutualSettlementsNotifications';
import { DocumentsNotifications } from '../../views/DocumentsNotifications/DocumentsNotifications';
import { OrderNotification } from '../../views/OrderNotification/OrderNotification';
import { DownloadSettings } from '../../views/DownloadSettings/DownloadSettings';
import { ProfileSettings } from '../../views/ProfileSettings/ProfileSettings';
import { ContainerNotifications } from '../../views/ContainerNotifications';
import { PaymentHistory } from '../../views/PaymentHistory/PaymentHistory';
import { BillRequests } from '../../views/BillRequests/BillRequests';
import { Downloads } from '../../views/Downloads/Downloads';
import { Employees } from '../../views/Employees/Employees';
import { Order } from '../../views/Order/Order';
import { Files } from '../../views/Files/Files';
import { Bills } from '../../views/Bills/Bills';
import { Auth } from '../../views/Auth/Auth';
import { Feed } from '../../views/Feed/Feed';
import { Orders } from '../../views/Orders';

import { useAlerts } from '../../hooks/noty';

import './App.scss';
import { NewPersonalCabinetInvitation } from '../../components/NewPersonalCabinetInvitation/NewPersonalCabinetInvitation';

moment.locale('ru');

const mapState = makeMapState((state) => ({
    isAuthenticated: state.user.isAuthenticated,
    needFetchProfile: state.user.profile === null,
    user: state.user.profile,
    needFetchSettings: !state.settings.fetched,
    isUserSeenGuide: state.settings.isUserSeenGuide,
    isGrantedManageUsers: isUserGranted(state.user.profile, 'manage_regular_users'),
}));

const mapDispatch = makeMapDispatch({
    fetchProfile: apiProfileDetails,
    fetchSettings: apiSettings,
    logout: apiUserLogout,
    rememberRedirect: setRedirectUrl,
    setSeenGuide: setUserSeenGuide,
});

type Props = ReturnType<typeof mapState> & ReturnType<typeof mapDispatch>;
const App: FunctionComponent<Props> = (props) => {
    const {
        rememberRedirect,
        isAuthenticated,
        needFetchProfile,
        needFetchSettings,
        fetchProfile,
        fetchSettings,
        logout,
        setSeenGuide,
        isUserSeenGuide,
        isGrantedManageUsers,
        user,
    } = props;

    const { handleApiError } = useAlerts();
    const [loading, setLoading] = useState(true);
    const [fetching, setFetching] = useState(false);
    const [hasError, setHasError] = useState(false);

    const isReportsAdmin = useMemo(() => {
        // eslint-disable-next-line no-underscore-dangle
        return user && user._actions.length > 0 && user._actions.includes('manage_order_report_templates');
    }, [user]);

    useEffect(() => {
        if (hasError) return;
        if (isAuthenticated && !fetching) {
            const stack: Promise<unknown>[] = [];
            if (needFetchProfile) stack.push(fetchProfile());
            if (needFetchSettings) stack.push(fetchSettings());
            if (stack.length) {
                setLoading(true);
                setFetching(true);
                setHasError(false);
                Promise.all(stack)
                    .then(() => {
                        setLoading(false);
                        setFetching(false);
                        rememberRedirect(null);
                    })
                    .catch((e) => {
                        handleApiError(e);
                        setHasError(true);
                    });
            }
        } else if (!isAuthenticated) setLoading(false);
    }, [
        isAuthenticated,
        needFetchProfile,
        needFetchSettings,
        fetchProfile,
        fetchSettings,
        handleApiError,
        rememberRedirect,
        loading,
        fetching,
        hasError,
    ]);

    useEffect(() => {
        document.title = `${appTitle()} | Личный кабинет`;

        rememberRedirect(getCurrentPath());

        ApiClient.setInvalidTokenHandler(() => {
            setLoading(true);
            logout().finally(() => {
                setLoading(false);
                // TODO: fix stub
                window.location.reload();
            });
        });
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    if (loading)
        return (
            <Loading
                showReload={hasError}
                reloadCallback={() => {
                    setHasError(false);
                    setFetching(false);
                    setLoading(true);
                }}
            />
        );

    return (
        <PagesProvider>
            {isAuthenticated && !fetching && <NewPersonalCabinetInvitation />}

            <div className="App">
                <Router basename={appPath}>
                    <RememberGuestRoute>
                        <SearchProvider>
                            <Switch>
                                <Route path="/login">
                                    {isAuthenticated && !fetching ? <Redirect to={ROUTES.ORDERS} /> : <Auth />}
                                </Route>

                                {isReportsAdmin ? (
                                    <>
                                        <Route path="/" exact>
                                            <Redirect to={ROUTES.DOWNLOAD_SETTINGS} />
                                        </Route>

                                        <Route path={ROUTES.ORDERS} exact>
                                            <Redirect to={ROUTES.DOWNLOAD_SETTINGS} />
                                        </Route>

                                        <PrivateRoute path={ROUTES.DOWNLOAD_SETTINGS} exact>
                                            <ReportSubscribersProvider>
                                                <Downloads />
                                            </ReportSubscribersProvider>
                                        </PrivateRoute>

                                        <PrivateRoute
                                            path={[ROUTES.DOWNLOAD_SETTINGS_BY_ID, ROUTES.DOWNLOAD_SETTINGS_CREATE]}
                                            exact
                                        >
                                            <DownloadSettings />
                                        </PrivateRoute>
                                    </>
                                ) : (
                                    <>
                                        <Route path="/" exact>
                                            <Redirect to={ROUTES.ORDERS} />
                                        </Route>

                                        <PrivateRoute path={ROUTES.ORDERS} exact>
                                            <Orders />
                                        </PrivateRoute>

                                        <PrivateRoute path={`${ROUTES.ORDERS}/:containerId`} exact>
                                            <Order />
                                        </PrivateRoute>

                                        <PrivateRoute path={`${ROUTES.ORDERS}/:containerId/notifications`} exact>
                                            <ContainerNotifications />
                                        </PrivateRoute>

                                        <PrivateRoute path={`${ROUTES.ORDERS}/:orderId/bills`} exact>
                                            <Bills />
                                        </PrivateRoute>

                                        <PrivateRoute path={ROUTES.PROFILE} exact>
                                            <ProfileSettings />
                                        </PrivateRoute>

                                        <PrivateRoute path={`${ROUTES.ORDERS}/:orderId/files`}>
                                            <Files />
                                        </PrivateRoute>

                                        <PrivateRoute path={ROUTES.BILLS} isWhiteBackground exact>
                                            <Bills />
                                        </PrivateRoute>

                                        <PrivateRoute path={`${ROUTES.BILLS}/:billId/requests`} exact>
                                            <BillRequests />
                                        </PrivateRoute>

                                        <PrivateRoute path={ROUTES.PAYMENT_HISTORY} isWhiteBackground exact>
                                            <PaymentHistory />
                                        </PrivateRoute>

                                        <PrivateRoute path={ROUTES.FEED}>
                                            <Feed />
                                        </PrivateRoute>

                                        <PrivateRoute path={ROUTES.EMPLOYEES} exact>
                                            {isGrantedManageUsers ? <Employees /> : <Redirect to={ROUTES.ORDERS} />}
                                        </PrivateRoute>

                                        <PrivateRoute path={ROUTES.DOWNLOAD_SETTINGS} exact>
                                            <ReportSubscribersProvider>
                                                <Downloads />
                                            </ReportSubscribersProvider>
                                        </PrivateRoute>

                                        <PrivateRoute
                                            path={[ROUTES.DOWNLOAD_SETTINGS_BY_ID, ROUTES.DOWNLOAD_SETTINGS_CREATE]}
                                            exact
                                        >
                                            <DownloadSettings />
                                        </PrivateRoute>

                                        <PrivateRoute
                                            path={[
                                                ROUTES.NOTIFICATION_SETTINGS_BY_ID,
                                                ROUTES.NOTIFICATION_SETTINGS_CREATE,
                                            ]}
                                            exact
                                        >
                                            <OrderNotification />
                                        </PrivateRoute>

                                        <PrivateRoute path={ROUTES.NOTIFICATION_SETTINGS_DOCUMENTS} exact>
                                            <DocumentsNotifications />
                                        </PrivateRoute>

                                        <PrivateRoute path={ROUTES.NOTIFICATION_SETTINGS_MUTUAL_SETTLEMENTS} exact>
                                            <MutualSettlementsNotifications />
                                        </PrivateRoute>

                                        <PrivateRoute path={ROUTES.USER_NOTIFICATION_SETTINGS_BY_ID} exact>
                                            <OrderNotification />
                                        </PrivateRoute>

                                        <PrivateRoute path={ROUTES.USER_NOTIFICATION_SETTINGS_DOCUMENTS} exact>
                                            <DocumentsNotifications />
                                        </PrivateRoute>

                                        <PrivateRoute path={ROUTES.USER_NOTIFICATION_SETTINGS_MUTUAL_SETTLEMENTS} exact>
                                            <MutualSettlementsNotifications />
                                        </PrivateRoute>
                                    </>
                                )}

                                <PrivateRoute path="*">
                                    <Redirect to={ROUTES.ORDERS} />
                                </PrivateRoute>
                            </Switch>
                        </SearchProvider>
                    </RememberGuestRoute>

                    {!isUserSeenGuide && isAuthenticated && !isReportsAdmin && (
                        <GuideModal handleClose={() => setSeenGuide(true)} isOpen={!isUserSeenGuide} />
                    )}
                </Router>
            </div>
            <PagePreview />
            <ErrorReport loading={loading} />
        </PagesProvider>
    );
};

const EnchantedComponent = pipe(App, connect(mapState, mapDispatch));
export { EnchantedComponent as App };

import React, { FunctionComponent } from 'react';
import clsx from 'clsx';

import { ExpandMore } from '@material-ui/icons';

import { OrderStatus, OrderType } from '../../../store/settings/types';
import { OrderTypeEntityRow } from './OrderTypeEntityRow';
import { NotificationEntityTypes } from '../../../utils/notifications';
import { User } from '../../../types/user';

type StatusRowProps = {
    status: OrderStatus;
    orderType: OrderType;
    onChange: () => void;
    employee?: User;
};

const eventsToExclude = ['Размещён на СВХ'];

export const StatusRowHeader: FunctionComponent = () => {
    return (
        <div
            className={clsx([
                'NotificationSettingsTable__headRow',
                'NotificationSettingsTable__headRow--statusTitle',
                // showHeader && 'NotificationSettingsTable__headRow--showAlways',
            ])}
        >
            <div className="NotificationSettingsTable__headCell NotificationSettingsTable__headCell--title">
                <ExpandMore />
                <span>Статусы</span>
            </div>
        </div>
    );
};

export const StatusRow: FunctionComponent<StatusRowProps> = (props) => {
    const { status, orderType, onChange, employee } = props;

    return (
        <div className="NotificationSettingsTable__grid">
            <div className="NotificationSettingsTable__column">
                <div className="NotificationSettingsTable__bodyRow NotificationSettingsTable__bodyRow--statusTitle">
                    <div className="NotificationSettingsTable__bodyCell">{status.name}</div>
                </div>
            </div>
            <div className="NotificationSettingsTable__column">
                {status.events.map((event) => {
                    let excludeTypes: NotificationEntityTypes[] = ['sms'];

                    if (eventsToExclude.includes(event.name)) {
                        excludeTypes = [];
                    }

                    return (
                        <OrderTypeEntityRow
                            key={event.id}
                            kind="event"
                            entity={event}
                            orderType={orderType}
                            onChange={onChange}
                            excludeTypes={excludeTypes}
                            employee={employee}
                        />
                    );
                })}
            </div>
        </div>
    );
};

import React, { FunctionComponent, useCallback, useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';

import { CircularProgress } from '@material-ui/core';

import { flow, pipe } from 'fp-ts/function';
import { findFirst } from 'fp-ts/Array';
import { every } from 'fp-ts/Record';
import * as O from 'fp-ts/Option';

import { resetSearchResult, setConfigPageAction, setConfigPageSizeAction } from '../../store/search/actions';
import { SearchSectionConfig, SearchSections } from '../../store/search/types';
import { apiSearch, makeMapDispatch } from '../../store/dispatch';
import { makeMapState } from '../../store/root';

import { PaginatorPageSizes } from '../../utils/const';
import { makeNonEmptyQuery } from '../../utils/branding';
import { PaginatorPageSize } from '../../utils/types';
import { foldView } from '../../utils/view';

import { useBusEffect } from '../../hooks/bus';
import { useSearch } from '../../hooks/search';
import { useAlerts } from '../../hooks/noty';

import { SortProvider } from '../../providers/SortProvider';

import { SearchResultPageSizeConfig, SearchResultView } from './components/SearchResultView';

const scrollToTop = (): void =>
    queueMicrotask(() => {
        document.getElementById('search-orders-items-container')?.scrollIntoView(true);
        document.getElementById('main-app-container')?.scrollIntoView(true);
    });

const pageSizeConfigGetter = (config: SearchSectionConfig[]) => (type: SearchSections): PaginatorPageSize =>
    pipe(
        config,
        findFirst((cfg) => cfg.type === type),
        O.mapNullable((cfg) => cfg.page_size),
        O.getOrElse(() => '10' as PaginatorPageSize)
    );

const mapState = makeMapState((state) => ({ searchState: state.search }));
const mapDispatch = makeMapDispatch({
    setConfigPageSize: setConfigPageSizeAction,
    setConfigPage: setConfigPageAction,
    resetSearch: resetSearchResult,
    search: apiSearch,
});

type Props = ReturnType<typeof mapState> & ReturnType<typeof mapDispatch>;
const SearchResults: FunctionComponent<Props> = (props) => {
    const {
        search,
        resetSearch,
        setConfigPage,
        setConfigPageSize,
        searchState: { result, config, query },
    } = props;

    const { handleApiError } = useAlerts();
    const { setSearchConfig } = useSearch();

    const [loading, setLoading] = useState(false);
    const emptyQuery = useMemo(() => O.isNone(makeNonEmptyQuery(query)), [query]);
    const isEmptyRes = useMemo(
        () =>
            pipe(
                result,
                every((res) => res.items.length === 0)
            ),
        [result]
    );
    const orderListPageSizeConfig = useMemo<SearchResultPageSizeConfig>(() => {
        const getPageSize = pageSizeConfigGetter(config);
        return {
            bills: {
                value: getPageSize('bills'),
                options: PaginatorPageSizes,
                onChange: flow((pageSize) => setConfigPageSize({ type: 'bills', pageSize }), scrollToTop),
            },
            orders: {
                value: getPageSize('orders'),
                options: PaginatorPageSizes,
                onChange: flow((pageSize) => setConfigPageSize({ type: 'orders', pageSize }), scrollToTop),
            },
            payments: {
                value: getPageSize('payments'),
                options: PaginatorPageSizes,
                onChange: flow((pageSize) => setConfigPageSize({ type: 'payments', pageSize }), scrollToTop),
            },
        };
    }, [config, setConfigPageSize]);

    const searchRequest = useCallback(
        (searchConfig = config) => {
            if (loading) return;
            const searchQuery = makeNonEmptyQuery(query);
            if (O.isSome(searchQuery)) {
                setLoading(true);
                setSearchConfig(searchConfig);
                search({ search_query: searchQuery.value, sections_config: searchConfig })
                    .then(scrollToTop)
                    .catch(handleApiError)
                    .finally(() => setLoading(false));
            }
        },
        [loading, config, search, setLoading, query, handleApiError, setSearchConfig]
    );

    useEffect(searchRequest, [query]); // eslint-disable-line react-hooks/exhaustive-deps

    useBusEffect('onNavigationClick', resetSearch);
    useBusEffect('onConfigPageChange', searchRequest);
    useBusEffect('onConfigPageSizeChange', searchRequest);

    const viewState = foldView(result, loading && isEmptyRes, null, isEmptyRes);
    return (
        <SortProvider dontRememberChanges>
            <div id="search-orders-items-container">
                <div className="Orders__container">
                    {!isEmptyRes && loading && (
                        <div className="App__loader App__center">
                            <CircularProgress className="App__fixedCenter" />
                        </div>
                    )}
                    <section className="Orders">
                        {emptyQuery ? (
                            <div className="Orders__center">
                                <span>Введен слишком короткий запрос</span>
                            </div>
                        ) : (
                            viewState(
                                () => (
                                    <div className="Orders__center">
                                        <span>По данному запросу ничего не найдено</span>
                                    </div>
                                ),
                                () => (
                                    <div className="App__loader App__center">
                                        <CircularProgress className="App__fixedCenter" />
                                    </div>
                                ),
                                () => null,
                                (searchResult) => (
                                    <SearchResultView
                                        data={searchResult}
                                        loading={loading}
                                        onPageChange={(type, page) => setConfigPage({ type, page })}
                                        paginatorProps={orderListPageSizeConfig}
                                    />
                                )
                            )
                        )}
                    </section>
                </div>
            </div>
        </SortProvider>
    );
};

const EnchantedComponent = pipe(SearchResults, connect(mapState, mapDispatch));
export { EnchantedComponent as SearchResults };

import React, { ReactNode, useState, createContext } from 'react';
import { pipe } from 'fp-ts/function';
import { connect } from 'react-redux';

import { SortingConfig, SortingConfigKey, SortsState } from '../store/sorts/types';
import { makeMapDispatch } from '../store/dispatch';
import { sortsSetData } from '../store/sorts/actions';
import { RootState } from '../store/root';

type BuildSortingChangeHandler = <K extends SortingConfigKey>(kind: K) => (by: SortingConfig[K]) => void;
type GlobalSortProviderContext = {
    sorts: SortsState;
    buildHandleChange: BuildSortingChangeHandler;
};

export type SortProviderContext<K extends SortingConfigKey> = {
    sorts: SortsState[K];
    handleChange: (by: SortingConfig[K]) => void;
};

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
export const SortContext = createContext<GlobalSortProviderContext>(null!);

const mapState = (state: RootState) => ({ sortingConfig: state.sorts });
const mapDispatch = makeMapDispatch({ rememberSortData: sortsSetData });

type ProviderProps = {
    children: ReactNode;
    dontRememberChanges?: boolean;
};

type SortProviderProps = ProviderProps & ReturnType<typeof mapState> & ReturnType<typeof mapDispatch>;

const SortProvider = (props: SortProviderProps): JSX.Element => {
    const { children, sortingConfig, rememberSortData, dontRememberChanges = false } = props;

    const [sorts, setSorts] = useState<SortsState>(sortingConfig);

    const buildHandleChange: BuildSortingChangeHandler = (kind) => (by) => {
        const sortsState = {
            ...sorts,
            [kind]:
                sorts[kind].by === by
                    ? { by, direction: sorts[kind].direction === 'desc' ? 'asc' : 'desc' }
                    : { by, direction: 'desc' },
        };

        setSorts(sortsState);
        if (!dontRememberChanges) rememberSortData(sortsState);
    };

    return <SortContext.Provider value={{ sorts, buildHandleChange }}>{children}</SortContext.Provider>;
};

const EnchantedProvider = pipe(SortProvider, connect(mapState, mapDispatch));
export { EnchantedProvider as SortProvider };

import React, { FunctionComponent, useCallback, useEffect, useState } from 'react';
import { Link, NavLink, useParams } from 'react-router-dom';
import { connect } from 'react-redux';

import { findFirst, findIndex, isNonEmpty, map, partitionMap, reduce, unsafeDeleteAt } from 'fp-ts/Array';
import { insertAt, lookup, modifyAt } from 'fp-ts/Record';
import { fold, getOrElse, isNone } from 'fp-ts/Option';
import { left, right } from 'fp-ts/Either';
import { flow, pipe } from 'fp-ts/function';

import {
    Breadcrumbs,
    Button,
    Card,
    CircularProgress,
    ExpansionPanel,
    ExpansionPanelDetails,
    ExpansionPanelSummary,
} from '@material-ui/core';
import { ExpandMore } from '@material-ui/icons';

import type { OrderPackageContainer, OrderPackageListItem, PackageDocumentType } from '../../types/order-package';
import { apiOrderPackageList, makeMapDispatch } from '../../store/dispatch';
import { makeMapState } from '../../store/root';
import { makeInteger } from '../../utils/branding';

import { useDocumentsLoader } from '../../hooks/documentsLoader';

import { DocumentTypeToggler, DocumentTypeTogglerItem } from '../../components/DocumentTypeToggler';
import { Document, DocumentsList, getDocumentName } from '../../components/DocumentsList/DocumentsList';
import { LoadingButton } from '../../components/LoadingButton';
import { FetchError } from '../../components/FetchError';

import { ReactComponent as IconDownload } from '../../assets/file-icons/download.svg';
import { ReactComponent as IconEmpty } from '../../assets/file-icons/empty.svg';

import './Files.scss';

type DocumentsGroupType = PackageDocumentType | 'order';
type DocumentsGroup = {
    id: number;
    name: string;
    kind: DocumentsGroupType;
    items: Document[];
};

type SelectedDocuments = Record<string, string[]>;
type RestoredSelectedDocument = { kind: PackageDocumentType; id: number };

const restoreSelectedDocuments = flow(
    map(
        (item: string): RestoredSelectedDocument => {
            const [kind, number] = item.split(':');
            return { kind: kind as PackageDocumentType, id: Number(number) };
        }
    ),
    partitionMap(({ id, kind }) => (kind === 'type' ? left(id) : right(id))),
    ({ left: types, right: containers }) => ({ types, containers })
);

const documentTypes: DocumentTypeTogglerItem[] = [
    {
        kind: 'type',
        name: 'По типу',
    },
    {
        kind: 'containers',
        name: 'По контейнерам',
    },
];

const totalReducer = reduce(0, (acc, item: DocumentsGroup) => acc + (item.items.length > 0 ? item.items.length : 1));
const foldContainerName = (container: OrderPackageContainer): string =>
    `Контейнер №${container.number} ${container.type.size ? `(${container.type.size}ft)` : ''}`;

const mapContainerToDocument = map<OrderPackageContainer, Document>((item) => ({
    id: item.id,
    name: foldContainerName(item),
    kind: 'containers',
}));

const mapDispatch = makeMapDispatch({ getList: apiOrderPackageList });
const mapState = makeMapState((state) => ({ documentsStorage: state.documents.items }));

type PageRouteParams = { orderId: string };
type FilesProps = ReturnType<typeof mapDispatch> & ReturnType<typeof mapState>;
const Files: FunctionComponent<FilesProps> = (props) => {
    const { getList, documentsStorage } = props;
    const dirtyParams = useParams<PageRouteParams>();
    const { downloadOrderDocument, downloadContainerDocument, downloadContainersDocument } = useDocumentsLoader();

    const [list, setList] = useState<OrderPackageListItem[]>([]);
    const [error, setError] = useState<string | null>(null);
    const [loading, setLoading] = useState(false);
    const [orderId, setOrderId] = useState<number | null>(null);
    const [isInvalid, setIsInvalid] = useState(false);
    const [orderNumber, setOrderNumber] = useState<string | null>(null);
    const [documentType, setDocumentType] = useState<PackageDocumentType>('type');
    const [documentGroups, setDocumentGroups] = useState<DocumentsGroup[]>([]);
    const [documentsTotal, setDocumentsTotal] = useState(0);
    const [selectedDocuments, setSelectedDocuments] = useState<SelectedDocuments>({});

    const pageTitle = `Документы по заказу №${orderNumber}`;

    const foldPackageToTypeGroup = useCallback(
        map<OrderPackageListItem, DocumentsGroup>((item) => {
            const documents = mapContainerToDocument(item.containers);
            if (item.order_id && documents.length)
                documents.unshift({ id: item.print_form_type.id, name: `Заказ №${orderNumber}`, kind: 'type' });

            return {
                id: item.print_form_type.id,
                name: item.print_form_type.name,
                kind: 'type',
                items: documents,
            };
        }),
        [orderNumber]
    );

    const foldPackageToContainersGroup = useCallback(
        (items: OrderPackageListItem[]): DocumentsGroup[] => {
            const groups: DocumentsGroup[] = [];
            const order: DocumentsGroup = { id: orderId!, name: `Заказ №${orderNumber}`, kind: 'order', items: [] };

            items.forEach((item) => {
                const document: Document = {
                    id: item.print_form_type.id,
                    name: item.print_form_type.name,
                    kind: 'type',
                };

                item.containers.forEach((container) => {
                    pipe(
                        groups,
                        findFirst((group) => group.id === container.id),
                        fold(
                            () =>
                                groups.push({
                                    id: container.id,
                                    name: foldContainerName(container),
                                    kind: 'containers',
                                    items: [document],
                                }),
                            (group) => group.items.push(document)
                        )
                    );
                });

                if (item.order_id !== null) order.items.push(document);
            });

            if (order.items.length) groups.unshift(order);

            return groups;
        },
        [orderNumber, orderId]
    );

    useEffect(() => {
        pipe(
            dirtyParams.orderId,
            makeInteger,
            fold(
                () => {
                    setError(`Некорректный идентификатор заказа: ${dirtyParams.orderId}`);
                    setOrderId(null);
                    setIsInvalid(true);
                },
                (value) => {
                    setError(null);
                    setOrderId(value);
                    setIsInvalid(false);
                }
            )
        );
    }, [dirtyParams]);

    useEffect(() => {
        if (orderId === null || error !== null || loading) return;

        setLoading(true);
        setOrderNumber(null);
        getList({ order_id: orderId })
            .then(({ items, order_number: number }) => {
                setList(items);
                setOrderNumber(number);
            })
            .catch(() =>
                setError(`Не удалось получить список документов заказа по идентификатору: ${dirtyParams.orderId}`)
            )
            .finally(() => setLoading(false));
    }, [orderId, error]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        setSelectedDocuments({});

        const foldMethod = documentType === 'type' ? foldPackageToTypeGroup : foldPackageToContainersGroup;
        const groups = foldMethod(list);

        setDocumentGroups(groups);
        setDocumentsTotal(totalReducer(groups));
        setSelectedDocuments(
            groups.reduce((acc: SelectedDocuments, group) => {
                acc[group.id.toString()] = group.items.map(getDocumentName);
                return acc;
            }, {})
        );
    }, [list, documentType, foldPackageToTypeGroup, foldPackageToContainersGroup]);

    const toggleDocumentSelection = (groupId: number) => (documentRef: string): void => {
        setSelectedDocuments(
            pipe(
                selectedDocuments,
                modifyAt(groupId.toString(), (items) =>
                    pipe(
                        items,
                        findIndex((i) => i === documentRef),
                        fold(
                            () => [...items, documentRef],
                            (index) => unsafeDeleteAt(index, items)
                        )
                    )
                ),
                getOrElse(() => pipe(selectedDocuments, insertAt(groupId.toString(), [documentRef])))
            )
        );
    };

    const isAllLoading = (): boolean => {
        if (orderId === null) return false;

        const key = `containers_${orderId}`;

        return Boolean(documentsStorage.find((item) => item.key === key));
    };

    const downloadAll = (): void => {
        if (orderId === null) return;

        const payload = { order_id: orderId };

        downloadContainersDocument(payload, { payload, key: `containers_${orderId}`, type: 'containers' });
    };

    const isGroupLoading = (group: DocumentsGroup): boolean => {
        const selectedGroupDocuments = pipe(selectedDocuments, lookup(group.id.toString()));

        if (isNone(selectedGroupDocuments) || orderId === null) return false;

        const { types, containers } = restoreSelectedDocuments(selectedGroupDocuments.value);

        let key = '';

        if (group.kind === 'order') {
            key = `order_${orderId}_${types.join()}`;
        } else if (group.kind === 'containers') {
            key = `container_${group.id}_${types.join()}`;
        } else {
            key = `containers_${containers.join()}_${group.id}_${isNonEmpty(types)}`;
        }

        return Boolean(documentsStorage.find((item) => item.key === key));
    };

    const downloadGroup = (group: DocumentsGroup): void => {
        const selectedGroupDocuments = pipe(selectedDocuments, lookup(group.id.toString()));

        if (isNone(selectedGroupDocuments) || orderId === null) return;

        const { types, containers } = restoreSelectedDocuments(selectedGroupDocuments.value);

        if (group.kind === 'order') {
            const payload = { order_id: orderId, print_form_type_ids: types };
            downloadOrderDocument(payload, { payload, key: `order_${orderId}_${types.join()}`, type: 'order' });
        } else if (group.kind === 'containers') {
            const payload = { container_id: group.id, print_form_type_ids: types };
            downloadContainerDocument(payload, {
                payload,
                key: `container_${group.id}_${types.join()}`,
                type: 'container',
            });
        } else {
            const payload = {
                order_id: orderId,
                package_request: {
                    container_ids: containers,
                    print_form_type_id: group.id,
                    for_order: isNonEmpty(types),
                },
            };

            downloadContainersDocument(payload, {
                payload,
                key: `containers_${containers.join()}_${group.id}_${isNonEmpty(types)}`,
                type: 'containers',
            });
        }
    };

    const isSingleGroupLoading = (groupId: number): boolean => {
        if (orderId === null) return false;

        const key = `order_${orderId}_${[groupId]}`;

        return Boolean(documentsStorage.find((item) => item.key === key));
    };

    const downloadSingleGroup = (groupId: number): void => {
        if (orderId === null) return;
        const payload = { order_id: orderId, print_form_type_ids: [groupId] };
        downloadOrderDocument(payload, { payload, key: `order_${orderId}_${[groupId]}`, type: 'order' });
    };

    const isSingleDocumentLoading = (group: DocumentsGroup) => (document: Document): boolean => {
        if (orderId === null) return false;

        let key = '';

        if (group.kind === 'order') {
            key = `order_${orderId}_${document.id}`;
        } else if (group.kind === 'containers') {
            key = `container_${group.id}_${document.id}`;
        } else {
            key = `containers_${document.id}_${group.id}_${document.kind === 'type'}`;
        }

        return Boolean(documentsStorage.find((item) => item.key === key));
    };

    const downloadSingleDocument = (group: DocumentsGroup) => (document: Document): void => {
        if (orderId === null) return;

        if (group.kind === 'order') {
            const payload = { order_id: orderId, print_form_type_ids: [document.id] };
            downloadOrderDocument(payload, { payload, key: `order_${orderId}_${document.id}`, type: 'order' });
        } else if (group.kind === 'containers') {
            const payload = { container_id: group.id, print_form_type_ids: [document.id] };
            downloadContainerDocument(payload, {
                payload,
                key: `container_${group.id}_${document.id}`,
                type: 'container',
            });
        } else {
            const payload = {
                order_id: orderId,
                package_request: {
                    container_ids: [document.id],
                    print_form_type_id: group.id,
                    for_order: document.kind === 'type',
                },
            };
            downloadContainersDocument(payload, {
                payload,
                key: `containers_${document.id}_${group.id}_${document.kind === 'type'}`,
                type: 'containers',
            });
        }
    };

    if (error)
        return (
            <FetchError onRetry={isInvalid ? undefined : () => setError(null)} title={error}>
                <Button
                    to="/orders"
                    variant="outlined"
                    style={{ marginTop: '1em', marginRight: '1em' }}
                    component={Link}
                >
                    Вернуться к моим заказам
                </Button>
            </FetchError>
        );

    if (loading)
        return (
            <section className="FilesView FilesView__loading">
                <CircularProgress />
            </section>
        );

    return (
        <section className="FilesView">
            <header className="FilesView__header">
                <Breadcrumbs aria-label="breadcrumb" separator="/">
                    <NavLink className="FilesView__breadcrumbsLink" color="inherit" to="/">
                        Мои заказы
                    </NavLink>

                    <span className="FilesView__breadcrumbsCurrentPage">{pageTitle}</span>
                </Breadcrumbs>

                <h1 className="FilesView__title">{pageTitle}</h1>

                {documentsTotal > 0 && (
                    <div className="FilesView__headerBottom">
                        <div className="FilesView__sortToggle">
                            <DocumentTypeToggler
                                items={documentTypes}
                                selectedItemId={documentType}
                                onDocumentTypeToggle={setDocumentType}
                            />
                        </div>

                        <LoadingButton
                            color="primary"
                            className="FilesView__downloadAllButton"
                            onClick={downloadAll}
                            loading={isAllLoading()}
                        >
                            <IconDownload />
                            <span className="FilesView__downloadAllText">{`Скачать все (${documentsTotal})`}</span>
                        </LoadingButton>
                    </div>
                )}
            </header>

            <div className="content">
                {documentsTotal > 0 ? (
                    documentGroups.map((group) => {
                        const selectedGroupDocuments = pipe(
                            selectedDocuments,
                            lookup(group.id.toString()),
                            getOrElse((): string[] => [])
                        );

                        return (
                            <div className="FilesView__group" key={group.id}>
                                {group.items.length > 0 ? (
                                    <ExpansionPanel className="FilesView__expansionPanel">
                                        <ExpansionPanelSummary className="FilesView__expansionPanelSummary">
                                            <div className="FilesView__expansionPanelHeader">
                                                <div className="FilesView__expansionPanelLeft">
                                                    <span className="FilesView__expansionPanelTitle">{group.name}</span>

                                                    <ExpandMore className="FilesView__expansionPanelIcon" />
                                                </div>

                                                <LoadingButton
                                                    color="primary"
                                                    className="FilesView__expansionPanelButton"
                                                    loaderClass="FilesView__downloadButton--groupMargin"
                                                    disabled={selectedGroupDocuments.length === 0}
                                                    loading={isGroupLoading(group)}
                                                    onClick={(e) => {
                                                        e.stopPropagation();
                                                        downloadGroup(group);
                                                    }}
                                                >
                                                    <span className="FilesView__selectedDocumentsCount">
                                                        {selectedGroupDocuments.length}
                                                    </span>

                                                    <IconDownload />
                                                </LoadingButton>
                                            </div>
                                        </ExpansionPanelSummary>

                                        <ExpansionPanelDetails className="FilesView__expansionPanelContent">
                                            <DocumentsList
                                                items={group.items}
                                                loadingDocuments={isSingleDocumentLoading(group)}
                                                downloadDocument={downloadSingleDocument(group)}
                                                selectedDocuments={selectedGroupDocuments}
                                                toggleDocumentSelection={toggleDocumentSelection(group.id)}
                                            />
                                        </ExpansionPanelDetails>
                                    </ExpansionPanel>
                                ) : (
                                    <Card>
                                        <ExpansionPanelSummary className="FilesView__expansionPanelSummary FilesView__fakeExpansionPanel">
                                            <div className="FilesView__expansionPanelHeader">
                                                <div className="FilesView__expansionPanelLeft">
                                                    <span className="FilesView__expansionPanelTitle">{group.name}</span>
                                                </div>

                                                <LoadingButton
                                                    color="primary"
                                                    loaderClass="FilesView__downloadButton--singleGroupMargin"
                                                    className="FilesView__expansionPanelButton"
                                                    loading={isSingleGroupLoading(group.id)}
                                                    onClick={(e) => {
                                                        e.stopPropagation();
                                                        downloadSingleGroup(group.id);
                                                    }}
                                                >
                                                    <IconDownload />
                                                </LoadingButton>
                                            </div>
                                        </ExpansionPanelSummary>
                                    </Card>
                                )}
                            </div>
                        );
                    })
                ) : (
                    <Card className="FilesView__emptyState">
                        <IconEmpty />

                        <span className="FilesView__emptyStateText">Файлы по заказу/контейнеру отсутствуют</span>
                    </Card>
                )}
            </div>
        </section>
    );
};

const EnchantedComponent = connect(mapState, mapDispatch)(Files);
export { EnchantedComponent as Files };

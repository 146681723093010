import React, { FunctionComponent, useMemo } from 'react';

import { mapWithIndex } from 'fp-ts/Array';

import { MenuItem, Select } from '@material-ui/core';
import { SelectInputProps } from '@material-ui/core/Select/SelectInput';

import { SizeTypeMap, SelectPropsGenerator } from '../../utils/types';

import './PageSizeSelect.scss';

type PageSizeSelectProps = SelectPropsGenerator<SizeTypeMap>;
type OptionsType = PageSizeSelectProps['options'] extends Array<infer A> ? A : never;
type SelectOnChange = SelectInputProps['onChange'];

const foldOptions = mapWithIndex<OptionsType, JSX.Element>((i, option) => (
    <MenuItem key={`PageSizeSelect-option-${i}`} value={option}>
        {option}
    </MenuItem>
));

export const PageSizeSelect: FunctionComponent<PageSizeSelectProps> = (props) => {
    const { value, options, onChange } = props;

    const selectItems = useMemo(() => foldOptions(options), [options]);
    const handleChange: SelectOnChange = (event) => {
        event.persist();
        // NOTE: maybe use io-ts?
        onChange(event.target.value as OptionsType);
    };

    return (
        <div className="PageSizeSelect">
            <span className="PageSizeSelect__label">Показывать по</span>
            <Select
                className="PageSizeSelect__select"
                variant="outlined"
                color="primary"
                value={value}
                onChange={handleChange}
            >
                {selectItems}
            </Select>
        </div>
    );
};

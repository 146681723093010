import React, { FunctionComponent } from 'react';
import { Drawer, DrawerProps, makeStyles, createStyles } from '@material-ui/core';

const useDrawerStyles = makeStyles(() =>
    createStyles({
        paper: {
            maxWidth: '90%',
            width: 800,
        },
    })
);

type RightDrawerProps = Omit<DrawerProps, 'classes' | 'anchor'>;
export const RightDrawer: FunctionComponent<RightDrawerProps> = (props) => {
    const { children, ...drawerProps } = props;
    const drawerStyles = useDrawerStyles();
    return (
        <Drawer classes={drawerStyles} anchor="right" {...drawerProps}>
            {children}
        </Drawer>
    );
};

import React, { FunctionComponent } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import { pipe } from 'fp-ts/lib/function';
import { connect } from 'react-redux';

import { Checkbox } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/styles';

import { useAlerts } from '../../../hooks/noty';
import { OrderEvent, OrderType } from '../../../store/settings/types';

import { NotificationsEntityItems } from '../../../utils/const';
import {
    notificationTypeAvailabilityMessage,
    NotificationEntityKind,
    NotificationEntityTypes,
    NotificationSettingsFormData,
} from '../../../utils/notifications';
import { makeMapState } from '../../../store/root';
import { apiManagedUsersList, makeMapDispatch } from '../../../store/dispatch';
import { User } from '../../../types/user';

const useStyles = makeStyles(() =>
    createStyles({
        checkboxRoot: {
            padding: 2,
            '@media (max-width: 900px)': {
                padding: 7,
            },
        },
    })
);

const mapState = makeMapState((state) => ({
    user: state.user.profile,
}));

const mapDispatch = makeMapDispatch({ getEmployeesList: apiManagedUsersList });

type OrderTypeEntityRowProps = {
    kind: NotificationEntityKind;
    entity: OrderEvent;
    orderType: OrderType;
    onChange?(): void;
    excludeTypes?: NotificationEntityTypes[];
    disabledTypes?: NotificationEntityTypes[];
    employee?: User;
} & ReturnType<typeof mapState> &
    ReturnType<typeof mapDispatch>;

const OrderTypeEntityRow: FunctionComponent<OrderTypeEntityRowProps> = (props) => {
    const { entity, kind, orderType, onChange, excludeTypes, disabledTypes, user, employee } = props;
    const { register } = useFormContext<NotificationSettingsFormData>();

    const classes = useStyles();

    const { showNotification } = useAlerts();

    const isIncluded = (key: NotificationEntityTypes): boolean =>
        excludeTypes === undefined || !excludeTypes.includes(key);

    const onFieldChange = (isChecked: boolean, notificationType: NotificationEntityTypes): void => {
        if (onChange) onChange();

        const currentUser = employee || user;

        if (isChecked && currentUser && notificationTypeAvailabilityMessage(notificationType, currentUser)) {
            showNotification(notificationTypeAvailabilityMessage(notificationType, currentUser), {
                variant: 'warning',
            });
        }
    };

    return (
        <React.Fragment key={entity.id}>
            <input
                type="hidden"
                ref={register}
                name={`data.${orderType.id}_${kind}_${entity.id}.id`}
                value={entity.id}
            />
            <input type="hidden" ref={register} name={`data.${orderType.id}_${kind}_${entity.id}.kind`} value={kind} />
            <input
                type="hidden"
                ref={register}
                name={`data.${orderType.id}_${kind}_${entity.id}.order_type_id`}
                value={orderType.id}
            />

            <div className="NotificationSettingsTable__bodyRow">
                <div className="NotificationSettingsTable__bodyCell NotificationSettingsTable__bodyCell--middle">
                    {entity.name}
                </div>
                <div className="NotificationSettingsTable__bodyRowCheckboxes">
                    {NotificationsEntityItems.map(({ key, name }, index) => (
                        <div
                            className="NotificationSettingsTable__bodyCell NotificationSettingsTable__bodyCell--checkbox"
                            key={index}
                        >
                            {isIncluded(key) && (
                                <>
                                    <Controller
                                        name={`data.${orderType.id}_${kind}_${entity.id}.types.${key}`}
                                        type="checkbox"
                                        as={
                                            <Checkbox
                                                disabled={disabledTypes !== undefined && disabledTypes.includes(key)}
                                                onClick={(e) =>
                                                    onFieldChange((e.target as HTMLInputElement).checked, key)
                                                }
                                                color="primary"
                                                classes={{ root: classes.checkboxRoot }}
                                            />
                                        }
                                        valueName="checked"
                                        defaultValue={false}
                                    />

                                    <div className="NotificationSettingsTable__checkboxLabel">{name}</div>
                                </>
                            )}
                        </div>
                    ))}
                </div>
            </div>
        </React.Fragment>
    );
};

const connected = pipe(OrderTypeEntityRow, connect(mapState, mapDispatch));
export { connected as OrderTypeEntityRow };

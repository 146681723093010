import { USER_LOGIN, USER_LOGOUT, USER_SET_PROFILE, USER_SET_REDIRECT_URL, UserActionTypes } from './types';
import { User } from '../../types/user';

export const userLogin = (): UserActionTypes => ({ type: USER_LOGIN });
export const userLogout = (): UserActionTypes => ({ type: USER_LOGOUT });
export const setUserProfile = (payload: User): UserActionTypes => ({
    type: USER_SET_PROFILE,
    payload,
});
export const setRedirectUrl = (payload: string | null): UserActionTypes => ({
    type: USER_SET_REDIRECT_URL,
    payload,
});

import { Lens } from 'monocle-ts';
import { pipe } from 'fp-ts/function';
import * as R from 'fp-ts/Record';

import type { Reducer } from 'redux';
import type { ReportsActionTypes, ReportsState } from './types';

import { constNotAsked } from '../../utils/requestState';

const listLens = Lens.fromProp<ReportsState>()('list');
const detailsLens = Lens.fromProp<ReportsState>()('details');
const columnsLens = Lens.fromProp<ReportsState>()('columns');

const initialState: ReportsState = {
    list: constNotAsked(),
    details: {},
    columns: constNotAsked(),
};

export const reportReducer: Reducer<ReportsState, ReportsActionTypes> = (state = initialState, action) => {
    switch (action.type) {
        case '@@Reports/SetListRequestState':
            return pipe(state, listLens.set(action.payload));
        case '@@Reports/SetDetailsRequestState':
            return pipe(state, detailsLens.modify(R.upsertAt(action.payload.selector, action.payload.data)));
        case '@@Reports/SetColumnsRequestState':
            return pipe(state, columnsLens.set(action.payload));
        default:
            return state;
    }
};

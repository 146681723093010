import React from 'react';

import { Checkbox, FormControlLabel } from '@material-ui/core';

import { ReactComponent as IconDownload } from '../../assets/file-icons/download.svg';
import { PackageDocumentType } from '../../types/order-package';
import { LoadingButton } from '../LoadingButton';

import './DocumentsList.scss';

export type Document = {
    id: number;
    name: string;
    kind: PackageDocumentType;
};

type DocumentsListProps = {
    items: Document[];
    loadingDocuments: (document: Document) => boolean;
    selectedDocuments: string[];
    downloadDocument: (document: Document) => void;
    toggleDocumentSelection: (documentId: string) => void;
};

export const getDocumentName = (document: Document): string => `${document.kind}:${document.id}`;

const DocumentsList: React.FC<DocumentsListProps> = (props) => {
    const { items, loadingDocuments, selectedDocuments, toggleDocumentSelection, downloadDocument } = props;

    return (
        <ul className="DocumentsList">
            {items.map((item) => {
                const documentName = getDocumentName(item);
                return (
                    <li className="DocumentsList__item" key={item.id}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    name={`document-${item.id}`}
                                    color="primary"
                                    checked={selectedDocuments.includes(documentName)}
                                    onChange={() => toggleDocumentSelection(documentName)}
                                />
                            }
                            label={<span className="DocumentsList__documentName">{item.name}</span>}
                        />

                        <LoadingButton
                            color="primary"
                            className="DocumentsList__downloadButton"
                            loading={loadingDocuments(item)}
                            onClick={() => downloadDocument(item)}
                        >
                            <IconDownload />
                        </LoadingButton>
                    </li>
                );
            })}
        </ul>
    );
};

export { DocumentsList };

import {
    ReportColumnsRequestState,
    ReportDetailsPayload,
    ReportListRequestState,
    ReportsActionTypes,
    SET_REPORT_COLUMNS_REQUEST_STATE,
    SET_REPORT_DETAILS_REQUEST_STATE,
    SET_REPORT_LIST_REQUEST_STATE,
} from './types';

export const setReportListRequestState = (payload: ReportListRequestState): ReportsActionTypes => ({
    type: SET_REPORT_LIST_REQUEST_STATE,
    payload,
});

export const setReportDetailsRequestState = (payload: ReportDetailsPayload): ReportsActionTypes => ({
    type: SET_REPORT_DETAILS_REQUEST_STATE,
    payload,
});

export const setReportColumnsRequestState = (payload: ReportColumnsRequestState): ReportsActionTypes => ({
    type: SET_REPORT_COLUMNS_REQUEST_STATE,
    payload,
});

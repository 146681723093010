import React, { FunctionComponent, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { useBillsSearch } from '../../hooks/billsSearch';
import { useAlerts } from '../../hooks/noty';

import { BillsSearchProvider } from '../../providers/BillsSearchProvider';
import { SortProvider } from '../../providers/SortProvider';

type BillRequestsListProps = {
    billId: number;
};
export const BillRequestsList: FunctionComponent<BillRequestsListProps> = (props) => {
    const { billId } = props;

    const { handleApiError } = useAlerts();
    const { findOrdersByBill } = useBillsSearch();

    useEffect(() => {
        findOrdersByBill(billId).catch(handleApiError);
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    return null;
};

export const BillRequests: FunctionComponent = () => {
    const { billId } = useParams();

    if (billId === undefined) return null;
    return (
        <SortProvider>
            <BillsSearchProvider>
                <BillRequestsList billId={Number(billId)} />
            </BillsSearchProvider>
        </SortProvider>
    );
};

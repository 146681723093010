import React, { createContext, FunctionComponent, useState } from 'react';

import { ReportListItem } from '../types/order-reports';

export type ReportSubscribersProviderContext = {
    reportDetails: ReportListItem | null;
    setReportDetails: (details: ReportListItem | null) => void;
};

type ReportSubscribersProviderProps = {};

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
export const ReportSubscribersContext = createContext<ReportSubscribersProviderContext | null>(null);
export const ReportSubscribersProvider: FunctionComponent<ReportSubscribersProviderProps> = (props) => {
    const [reportDetails, setReportDetails] = useState<ReportListItem | null>(null);

    return (
        <ReportSubscribersContext.Provider value={{ reportDetails, setReportDetails }}>
            {props.children}
        </ReportSubscribersContext.Provider>
    );
};

import React, { FunctionComponent, useState } from 'react';
import { useHistory } from 'react-router-dom';
import clsx from 'clsx';
import { connect } from 'react-redux';

import {
    Button,
    Checkbox,
    ClickAwayListener,
    Collapse,
    FormControlLabel,
    FormGroup,
    IconButton,
    List,
    ListItem,
    Theme,
    Tooltip,
    withStyles,
    WithStyles,
} from '@material-ui/core';
import {
    ExpandMore as ExpandMoreIcon,
    ExpandLess as ExpandLessIcon,
    Info as InfoIcon,
    MoreHoriz as MoreHorizIcon,
    Create as CreateIcon,
    Clear as ClearIcon,
} from '@material-ui/icons';
import { createStyles, StyleRules } from '@material-ui/styles';

import { flow, pipe } from 'fp-ts/function';

import { makeMapState } from '../../store/root';
import type { Bill } from '../../store/bills/types';

import { formatCurrency, formatMomentDateC } from '../../utils/view';
import { MomentDateIso } from '../../utils/isomorph';
import { ROUTES } from '../../utils/location';

import { useDocumentsLoader } from '../../hooks/documentsLoader';
import { useComments } from '../../hooks/comments';
import { useSearch } from '../../hooks/search';

import { TextLiveEdit } from '../input/TextLiveEdit';
import { LoadingButton } from '../LoadingButton';

import { ReactComponent as IconExcelFit } from '../../assets/file-icons/file.svg';

import './BillCard.scss';

export const styles = (theme: Theme): StyleRules =>
    createStyles({
        actionTooltip: {
            background: 'white',
            boxShadow: '0 2px 4px rgba(0, 0, 0, 0.14), 0 3px 4px rgba(0, 0, 0, 0.12), 0 1px 5px rgba(0, 0, 0, 0.2)',
            borderRadius: 10,
            margin: 0,
            maxWidth: 'none',
            overflow: 'hidden',
            padding: 0,
        },
        actionItem: {
            padding: 0,
        },
        actionButton: {
            fontFamily: 'inherit',
            fontSize: 14,
            fontWeight: 500,
            justifyContent: 'flex-start',
            letterSpacing: 0.75,
            lineHeight: 1.15,
            padding: '6px 16px',
            textTransform: 'uppercase',
            width: '100%',
        },
        documentItem: {
            alignItems: 'center',
            display: 'flex',
            fontSize: 14,
            lineHeight: 1.4285,
            color: theme.palette.primary.main,
        },
        documentName: {
            marginLeft: 8,
            textTransform: 'uppercase',
        },
        documentPrice: {
            color: 'rgba(0, 0, 0, 0.87)',
            marginLeft: 16,
        },
        chatBadge: {
            backgroundColor: theme.palette.secondary.light,
            color: 'white',
        },
        downloadButtonContainer: {
            width: 'fit-content',
        },
        dangerButton: {
            color: theme.palette.error.main,
            'html:root &:hover, &:focus': {
                color: theme.palette.error.main,
            },
        },
    });

const formatDate = flow(MomentDateIso.from, formatMomentDateC());

const PaymentTitle: FunctionComponent<{ prefix: string; count: Kopecks, currency: string }> = ({ prefix, count, currency }) => (
    <div className="App__basicTooltip">
        {prefix} <span>{formatCurrency(count)}&nbsp;{currency}</span>
    </div>
);

const BillPaymentStatus: FunctionComponent<{ item: Bill }> = ({ item }) => {
    const titlePrefix = `По счёту ${item.number} от ${formatDate(item.date)} `;
    switch (true) {
        case item.has_overpayment:
            return (
                <Tooltip
                    arrow
                    disableTouchListener
                    title={
                        <PaymentTitle
                            count={-item.amount_debt as Kopecks}
                            prefix={titlePrefix + `имеется переплата в размере`}
                            currency={item.currency.name}
                        />
                    }
                >
                    <InfoIcon className="BillCard__status" htmlColor="#6D9E3D" />
                </Tooltip>
            );
        case item.is_payment_delayed:
            return (
                <Tooltip
                    arrow
                    disableTouchListener
                    title={
                        <PaymentTitle count={item.amount_debt} prefix={titlePrefix + `имеется недоплата в размере`} currency={item.currency.name}
                        />
                    }
                >
                    <InfoIcon className="BillCard__status" color="error" />
                </Tooltip>
            );
        default:
            return null;
    }
};

type BillCardComponentProps = {
    item: Bill;
    isOpen: boolean;
    setOpen: () => void;
    isBillForceSeen: boolean;
    addBillToForceSeen: (bill: Bill) => void;
    setItemToDispute: (bill: Bill) => void;
    setDisputeCancel: (bill: Bill) => void;
    isBillAlreadySeen: boolean;
    isBillNotSeenFake: boolean;
};

const mapState = makeMapState((state) => ({ documentsStorage: state.documents.items }));

type BillCardProps = BillCardComponentProps & WithStyles<typeof styles> & ReturnType<typeof mapState>;
const BillCard: FunctionComponent<BillCardProps> = (props) => {
    const {
        item,
        isOpen,
        setOpen,
        classes,
        isBillForceSeen,
        addBillToForceSeen,
        setItemToDispute,
        setDisputeCancel,
        isBillAlreadySeen,
        isBillNotSeenFake,
        documentsStorage,
    } = props;

    const { push } = useHistory();
    const { buildUpdateBillComment } = useComments();
    const { generateSearchUrl } = useSearch();
    const { downloadBillsPackageDocument } = useDocumentsLoader();

    const [isCommentFocused, setCommentFocused] = useState(false);
    const [isActionsOpen, setActionsOpen] = useState(false);
    const [isBillSelected, setIsBillSelected] = useState(false);
    const [selectedInvoices, setSelectedInvoices] = useState<number[]>([]);

    const toggleBillCheckedState = (): void => setIsBillSelected((h) => !h);
    const toggleInvoice = (id: number): void => {
        setSelectedInvoices((h) => (h.includes(id) ? h.filter((i) => i !== id) : [...h, id]));
    };

    const isBillPackageLoading = (key: string): boolean => {
        return Boolean(documentsStorage.find((document) => document.key === key));
    };

    // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
    const actions = [
        {
            id: 1,
            label: 'Заявки по счету',
            action: () => {
                push(generateSearchUrl(`${ROUTES.BILLS}/${item.id}/requests`, false));
            },
        },
        {
            id: 2,
            label: 'Скачать счет',
            checkDisabled: () => isBillPackageLoading(`bills_package_${item.id}`),
            action: () => {
                if (isBillPackageLoading(`bills_package_${item.id}`)) return;
                const payload = {
                    bill_id: item.id,
                    download_bill: true,
                    invoice_ids: [],
                };

                downloadBillsPackageDocument(payload, {
                    payload,
                    key: `bills_package_${item.id}`,
                    type: 'bills_package',
                });
            },
        },
        {
            id: 3,
            label: 'Диспут',
            action: () => setItemToDispute(item),
        },
    ];

    const getItemKopecks = (amount: Kopecks, orderAmount?: Kopecks): Kopecks =>
        orderAmount !== undefined ? orderAmount : amount;

    const isOrder =
        item.order_amount !== undefined ||
        item.order_amount_payments !== undefined ||
        item.order_amount_debt !== undefined;

    console.log(item);

    return (
        <div
            className={clsx([
                'BillCard',
                !isBillAlreadySeen && 'BillCard--notSeen',
                isBillNotSeenFake && !isBillForceSeen && 'BillCard--notSeenFake',
            ])}
        >
            <div className="App__billsRow">
                <div
                    className={clsx([
                        'BillCard__column',
                        'BillCard__primaryInfo',
                        !item.dispute.is_opened && 'BillCard__primaryInfo--margined',
                    ])}
                >
                    Счёт №{item.number}
                    <span className="BillCard__date">
                        &nbsp;от {formatDate(item.date)}
                        <BillPaymentStatus item={item} />
                    </span>
                    <TextLiveEdit
                        value={item.customer_comment}
                        onSave={buildUpdateBillComment(item.id)}
                        placeholder="Заметка по счёту"
                        onFocusChange={setCommentFocused}
                    />
                    {item.dispute.is_opened && (
                        <p
                            className={clsx([
                                'BillCard__disputeLabel',
                                isCommentFocused && 'BillCard__disputeLabel--commentFocused',
                            ])}
                        >
                            Диспут открыт
                        </p>
                    )}
                </div>
                <div className="BillCard__column App__billsColumn BillCard__payer">{item.payer}</div>
                <Tooltip
                    open={isOrder ? undefined : false}
                    title={
                        <>
                            Общая сумма счёта <strong>{formatCurrency(item.amount)}&nbsp;{item.currency.name}</strong>
                        </>
                    }
                >
                    <div
                        className={clsx([
                            'BillCard__column',
                            'App__billsColumn',
                            'BillCard__column--nowrap',
                            'BillCard__price',
                            item.order_amount !== undefined && 'BillCard__column--order',
                        ])}
                    >
                        {formatCurrency(getItemKopecks(item.amount, item.order_amount))}&nbsp;{item.currency.name}
                    </div>
                </Tooltip>
                <Tooltip
                    open={isOrder ? undefined : false}
                    title={
                        <>
                            Общая сумма &laquo;оплачено&raquo; <strong>{formatCurrency(item.amount_payments)}&nbsp;{item.currency.name}</strong>
                        </>
                    }
                >
                    <div
                        className={clsx([
                            'BillCard__column',
                            'BillCard__column--nowrap',
                            'App__billsColumn',
                            'BillCard__payed',
                            item.order_amount_payments !== undefined && 'BillCard__column--order',
                        ])}
                    >
                        {formatCurrency(getItemKopecks(item.amount_payments, item.order_amount_payments))}&nbsp;{item.currency.name}
                    </div>
                </Tooltip>
                <Tooltip
                    open={isOrder ? undefined : false}
                    title={
                        <>
                            Общая сумма остатка <strong>{formatCurrency(item.amount_debt)}&nbsp;{item.currency.name}</strong>
                        </>
                    }
                >
                    <div
                        className={clsx([
                            'BillCard__column',
                            'App__billsColumn',
                            'BillCard__column--nowrap',
                            'BillCard__remainder',
                            item.is_payment_delayed && 'BillCard__column--delayedPayment',
                            item.order_amount_debt !== undefined && 'BillCard__column--order',
                        ])}
                    >
                        {formatCurrency(getItemKopecks(item.amount_debt, item.order_amount_debt))}&nbsp;{item.currency.name}
                    </div>
                </Tooltip>
                <div className="BillCard__column App__billsColumn BillCard__CFPrice">
                    {formatCurrency(item.amount_invoices_rub)}&nbsp;RUB
                </div>
                <div>
                    <ClickAwayListener onClickAway={() => setActionsOpen(false)}>
                        <div>
                            <Tooltip
                                classes={{ tooltip: classes.actionTooltip }}
                                open={isActionsOpen}
                                placement="bottom-end"
                                interactive
                                disableFocusListener
                                disableHoverListener
                                disableTouchListener
                                title={
                                    <List>
                                        {actions.map((action) => (
                                            <ListItem
                                                className={classes.actionItem}
                                                key={`debt-card-action-item-${action.id}`}
                                            >
                                                <Button
                                                    color="primary"
                                                    className={classes.actionButton}
                                                    disabled={action.checkDisabled && action.checkDisabled()}
                                                    onClick={() => {
                                                        action.action();
                                                        setActionsOpen(false);
                                                    }}
                                                >
                                                    {action.label}
                                                </Button>
                                            </ListItem>
                                        ))}
                                    </List>
                                }
                                PopperProps={{
                                    disablePortal: true,
                                    modifiers: {
                                        offset: {
                                            enabled: true,
                                            offset: '0, 4px',
                                        },
                                    },
                                }}
                            >
                                <IconButton color="primary" onClick={() => setActionsOpen((val) => !val)}>
                                    <MoreHorizIcon />
                                </IconButton>
                            </Tooltip>
                        </div>
                    </ClickAwayListener>
                </div>

                {/* TODO: uncomment when backend ready */}
                {/*<div>*/}
                {/*<IconButton color="primary" onClick={() => {}}>*/}
                {/*    <Badge*/}
                {/*        badgeContent={12}*/}
                {/*        color="secondary"*/}
                {/*        classes={{*/}
                {/*            colorSecondary: classes.chatBadge,*/}
                {/*        }}*/}
                {/*    >*/}
                {/*        <TextsmsIcon />*/}
                {/*    </Badge>*/}
                {/*</IconButton>*/}
                {/*</div>*/}

                <div>
                    <IconButton
                        color="primary"
                        onClick={() => {
                            addBillToForceSeen(item);
                            setOpen();
                        }}
                    >
                        {isOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                    </IconButton>
                </div>
            </div>

            <Collapse in={isOpen} addEndListener={() => { }}>
                <div className="BillCard__wrapper">
                    <div>
                        <FormGroup>
                            <FormControlLabel
                                key={`bills-${item.id}`}
                                classes={{ label: classes.documentCheckbox }}
                                control={
                                    <Checkbox
                                        name={`bills-${item.id}`}
                                        color="primary"
                                        checked={isBillSelected}
                                        onChange={toggleBillCheckedState}
                                    />
                                }
                                label={
                                    <div className={classes.documentItem}>
                                        <IconExcelFit />
                                        <span className={classes.documentName}>{item.document_type_name} №{item.number}</span>
                                    </div>
                                }
                            />
                            {item.invoices.map((document) => (
                                <FormControlLabel
                                    key={`bills-document-${item.id}-${document.id}`}
                                    classes={{ label: classes.documentCheckbox }}
                                    control={
                                        <Checkbox
                                            name={`bills-document-${item.id}-${document.id}`}
                                            color="primary"
                                            value={document.number}
                                            checked={selectedInvoices.includes(document.id)}
                                            onChange={() => toggleInvoice(document.id)}
                                        />
                                    }
                                    label={
                                        <div className={classes.documentItem}>
                                            <IconExcelFit />
                                            <span className={classes.documentName}>
                                                {document.document_type_name} №{document.number} от {formatDate(document.date)}
                                            </span>
                                            <span className={classes.documentPrice}>
                                                {formatCurrency(document.amount_rub)}&nbsp;RUB
                                            </span>
                                        </div>
                                    }
                                />
                            ))}
                        </FormGroup>

                        <div className={classes.downloadButtonContainer}>
                            <LoadingButton
                                color="primary"
                                loading={isBillPackageLoading(
                                    `bills_package_${item.id}_${isBillSelected}_${selectedInvoices.join()}`
                                )}
                                disabled={selectedInvoices.length === 0 && !isBillSelected}
                                onClick={() => {
                                    const key = `bills_package_${item.id}_${isBillSelected}_${selectedInvoices.join()}`;
                                    if (isBillPackageLoading(key)) return;

                                    const payload = {
                                        bill_id: item.id,
                                        download_bill: isBillSelected,
                                        invoice_ids: selectedInvoices,
                                    };

                                    downloadBillsPackageDocument(payload, {
                                        payload,
                                        key,
                                        type: 'bills_package',
                                    });
                                }}
                            >
                                Скачать
                            </LoadingButton>
                        </div>
                    </div>

                    {item.dispute.is_opened && (
                        <div className="BillCardDispute">
                            <h2 className="BillCardDispute__heading">Диспут</h2>
                            <p className="BillCardDispute__description">{item.dispute.comment}</p>
                            <div className="BillCardDispute__buttons">
                                <Button
                                    onClick={() => setItemToDispute(item)}
                                    color="primary"
                                    startIcon={<CreateIcon fontSize="small" />}
                                >
                                    Изменить
                                </Button>
                                <Button
                                    classes={{ root: classes.dangerButton }}
                                    color="default"
                                    onClick={() => setDisputeCancel(item)}
                                    startIcon={<ClearIcon fontSize="small" />}
                                >
                                    Отменить
                                </Button>
                            </div>
                        </div>
                    )}
                </div>
            </Collapse>
        </div>
    );
};

const EnchantedComponent = pipe(BillCard, withStyles(styles), connect(mapState));
export { EnchantedComponent as BillCard };

import React, { FunctionComponent, useState } from 'react';

import { Visibility, VisibilityOff } from '@material-ui/icons';
import { IconButton, InputAdornment, TextField, TextFieldProps as TextFieldPropsType } from '@material-ui/core';

type PasswordFieldProps = {
    isShowToggleIcon?: boolean;
    isForcePasswordShown?: boolean;
    onTogglePasswordVisibility?(newValue: boolean): void;
} & TextFieldPropsType;
export const PasswordField: FunctionComponent<PasswordFieldProps> = (props) => {
    const { isForcePasswordShown, onTogglePasswordVisibility, isShowToggleIcon = true, ...textFieldProps } = props;
    const [isPasswordVisible, setPasswordVisibility] = useState(false);
    const togglePasswordVisibility = (): void => {
        const newValue = !isPasswordVisible;
        setPasswordVisibility(newValue);
        if (onTogglePasswordVisibility !== undefined) onTogglePasswordVisibility(newValue);
    };

    const isShownPassword = isForcePasswordShown || isPasswordVisible;

    return (
        <TextField
            {...textFieldProps}
            type={isShownPassword ? 'text' : 'password'}
            InputProps={{
                ...textFieldProps.InputProps,
                endAdornment: isShowToggleIcon && (
                    <InputAdornment position="end">
                        <IconButton
                            aria-label="Toggle password visibility"
                            onClick={togglePasswordVisibility}
                            onMouseDown={togglePasswordVisibility}
                            edge="end"
                        >
                            {isShownPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                    </InputAdornment>
                ),
            }}
        />
    );
};

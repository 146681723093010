import React, { FunctionComponent, useEffect, useState } from 'react';
import { useLocation, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import { pipe } from 'fp-ts/function';

import { Tab, Tabs, Typography } from '@material-ui/core';

import { makeMapState } from '../../store/root';
import { HelpInfoKey } from '../../store/settings/types';
import {
    apiGetRecipientsList,
    apiProfileSetupShadowRecipient,
    apiSubscriptionSettings,
    makeMapDispatch,
} from '../../store/dispatch';

import { useSearch } from '../../hooks/search';

import { setPageTitle } from '../../utils/title';
import { isUserGranted } from '../../utils/user';

import { HelpInfo } from '../../components/HelpInfo/HelpInfo';
import { TelegramBot } from '../../components/TelegramBot/TelegramBot';

import { ProfileData } from './ProfileData';
import { ProfileCredentials } from './ProfileCredentials';
import { ProfileNotificationManager } from './ProfileNotificationsManager';

import './ProfileSettings.scss';

type TabItem = {
    name: string;
    condition?: boolean;
};

const mapState = makeMapState((state) => ({
    isAdmin: isUserGranted(state.user.profile, 'manage_regular_users'),
    userProfile: state.user.profile,
}));

const mapDispatch = makeMapDispatch({
    getRecipients: apiGetRecipientsList,
    setupShadowRecipient: apiProfileSetupShadowRecipient,
    getSubscriptionSettings: apiSubscriptionSettings,
});

type ProfileSettingsProps = ReturnType<typeof mapState> & ReturnType<typeof mapDispatch>;

enum ProfileTabs {
    Settings,
    Notifications,
}

const ProfileSettings: FunctionComponent<ProfileSettingsProps> = (props) => {
    const { userProfile } = props;

    const { hasSearchData } = useSearch();
    const location = useLocation();

    const [activeTab, setActiveTab] = useState(ProfileTabs.Settings);

    const handleChangeTab = (event: React.ChangeEvent<{}>, newValue: number): void => {
        setActiveTab(newValue);
    };

    const tabs: TabItem[] = [{ name: 'Мои данные' }, { name: 'Настройки уведомлений' }];

    useEffect(() => {
        setPageTitle('Профиль');

        if (location.hash === '#notifications') setActiveTab(ProfileTabs.Notifications);
    }, [hasSearchData, location.hash]);

    return (
        <section className="ProfileSettings">
            <Typography variant="h1" className="ProfileSettings__heading">
                Профиль
            </Typography>

            <Tabs
                value={activeTab}
                onChange={handleChangeTab}
                indicatorColor="primary"
                textColor="primary"
                scrollButtons="on"
                classes={{
                    root: 'ProfileSettings__tabs',
                    flexContainer: 'ProfileSettings__tabsFlexContainer',
                }}
            >
                {tabs.map(
                    (item, index) =>
                        (item.condition === undefined || item.condition) && (
                            <Tab label={item.name} key={index} className="ProfileSettings__tab" />
                        )
                )}
            </Tabs>

            {activeTab === ProfileTabs.Settings && (
                <>
                    <HelpInfo
                        uniqueKey={HelpInfoKey.ProfileSettings}
                        title="Для чего нужны мои контактные данные?"
                        description={
                            <>
                                Контактные данные указанные в&nbsp;форме ниже будут использованы для рассылки
                                уведомлений: в&nbsp;телеграм, по&nbsp;почте, по&nbsp;смс. Для активации подписки
                                на&nbsp;телеграм нажмите на&nbsp;кнопку &laquo;Подключить&raquo;, либо отсканируйте
                                QR-код камерой смартфона.
                            </>
                        }
                        isWithBottomGap
                    />

                    <div className="ProfileSettings__grid">
                        <ProfileCredentials />
                        <ProfileData />

                        {userProfile !== null && userProfile.telegram && (
                            <div className="ProfileSettings__columnFull">
                                <TelegramBot
                                    isSubscribed={userProfile.telegram.subscribed}
                                    link={userProfile.telegram.url}
                                    size={210}
                                />
                            </div>
                        )}
                    </div>
                </>
            )}

            {activeTab === ProfileTabs.Notifications && userProfile !== null && (
                <>
                    <HelpInfo
                        uniqueKey={HelpInfoKey.NotificationsSettings}
                        title="Как настроить уведомления?"
                        description={
                            <>
                                Выберите события о&nbsp;которых вас нужно оповестить и&nbsp;канал по&nbsp;которому будет
                                удобнее всего получить уведомление.
                            </>
                        }
                        isWithBottomGap
                    />

                    <ProfileNotificationManager />
                </>
            )}
        </section>
    );
};

const connected = withRouter(pipe(ProfileSettings, connect(mapState, mapDispatch)));
export { connected as ProfileSettings };

import React, { FC } from 'react';
import clsx from 'clsx';
import { pipe } from 'fp-ts/function';
import { connect } from 'react-redux';

import { Button, Collapse, Typography } from '@material-ui/core';
import InfoIcon from '@material-ui/icons/Info';
import CloseIcon from '@material-ui/icons/Close';

import { RootState } from '../../store/root';
import { makeMapDispatch } from '../../store/dispatch';
import { setInvitationVisibilityAction } from '../../store/app/actions';

import { NewPersonalCabinetUrl } from '../../utils/const';

import { ReactComponent as NewLogoIcon } from '../../assets/new-logo.svg';
import styles from './NewPersonalCabinetInvitation.module.scss';

type StateProps = { isInvitationVisible: boolean };
const mapState = (state: RootState): StateProps => ({ isInvitationVisible: state.app.isInvitationVisible });
const mapDispatch = makeMapDispatch({ setInvitationVisibility: setInvitationVisibilityAction });

type NewPersonalCabinetInvitationProps = ReturnType<typeof mapState> & ReturnType<typeof mapDispatch>;

const NewPersonalCabinetInvitation: FC<NewPersonalCabinetInvitationProps> = (props) => {
    const { isInvitationVisible, setInvitationVisibility } = props;

    const onClose = (): void => {
        setInvitationVisibility(false);
    };

    return (
        <Collapse in={isInvitationVisible}>
            <div className={styles.root}>
                <div className={styles.content}>
                    <NewLogoIcon className={styles.newLogoIcon} />

                    <InfoIcon className={styles.infoIcon} fontSize="small" />
                    <Typography className={styles.text}>Доступна новая версия личного кабинета</Typography>

                    <Button
                        className={clsx(styles.newStyleButton, styles.isPrimaryVariant)}
                        variant="contained"
                        disableRipple
                        disableElevation
                        href={NewPersonalCabinetUrl}
                    >
                        Попробовать
                    </Button>
                </div>

                <Button
                    classes={{
                        root: clsx(styles.closeButton, styles.isTextVariant),
                        endIcon: styles.buttonEndIconWrapper,
                    }}
                    variant="text"
                    disableRipple
                    disableElevation
                    endIcon={<CloseIcon className={styles.buttonEndIcon} />}
                    onClick={onClose}
                >
                    <Typography className={styles.closeButtonText}>Закрыть</Typography>
                </Button>
            </div>
        </Collapse>
    );
};

const component = pipe(NewPersonalCabinetInvitation, connect(mapState, mapDispatch));

export { component as NewPersonalCabinetInvitation };

import { useContext } from 'react';
import { ReportSubscribersContext, ReportSubscribersProviderContext } from '../providers/ReportSubscribersProvider';

export function useReportSubscribers(): ReportSubscribersProviderContext {
    const context = useContext(ReportSubscribersContext);

    if (context === null) {
        throw new Error('Storage is used outside the ReportSubscribersProvider');
    }

    return context;
}

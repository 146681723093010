import React, { FunctionComponent } from 'react';

import { flow } from 'fp-ts/function';

import { PaymentHistoryListItem } from '../../store/payment-history/types';

import { formatCurrency, formatMomentDate } from '../../utils/view';
import { MomentDateIso } from '../../utils/isomorph';

import { SearchHeader } from './components/SearchHeader';
import { SortHeader } from './components/SortHeader';

import './PaymentHistoryList.scss';

type Props = {
    items: PaymentHistoryListItem[];
    fromSearch?: boolean;
};

export const PaymentHistoryList: FunctionComponent<Props> = (props) => {
    const { items, fromSearch = false } = props;

    const formatDate = flow(MomentDateIso.from, formatMomentDate);

    return (
        <div className="PaymentHistoryList">
            {fromSearch ? <SearchHeader /> : <SortHeader />}
            <div className="PaymentHistoryList__list">
                {items.map((item) => (
                    <article className="PaymentHistoryList__row PaymentHistoryList__grid" key={item.number}>
                        <div className="PaymentHistoryList__column">
                            {item.number} от {formatDate(item.date)}
                        </div>

                        <div className="PaymentHistoryList__column">{item.payer}</div>

                        <div className="PaymentHistoryList__column PaymentHistoryList__column--small">
                            {item.details}
                        </div>

                        <div className="PaymentHistoryList__column PaymentHistoryList__column--bold PaymentHistoryList__column--numeric">
                            {formatCurrency(item.amount)}&nbsp;{item.currency.name}
                        </div>
                    </article>
                ))}
            </div>
        </div>
    );
};

import React from 'react';

import { PackageDocumentType } from '../types/order-package';

export type DocumentTypeTogglerItem = {
    kind: PackageDocumentType;
    name: string;
};

type DocumentTypeTogglerProps = {
    items: DocumentTypeTogglerItem[];
    selectedItemId: DocumentTypeTogglerItem['kind'];
    onDocumentTypeToggle: (id: DocumentTypeTogglerItem['kind']) => void;
};

const DocumentTypeToggler: React.FC<DocumentTypeTogglerProps> = (props) => {
    const { items, selectedItemId, onDocumentTypeToggle } = props;

    return (
        <ul className="DocumentStatusChanger DocumentStatusChanger--fullWidthOnMobile">
            {items.map((item) => {
                const prefix = 'document-type-toggler';
                const radioId = `${prefix}-${item.kind}-input`;
                return (
                    <li key={item.kind} className="DocumentStatusChanger__item">
                        <input
                            type="radio"
                            className="DocumentStatusChanger__radio"
                            id={radioId}
                            name={`${prefix}-inputs`}
                            onClick={() => onDocumentTypeToggle(item.kind)}
                            onChange={() => {}}
                            checked={item.kind === selectedItemId}
                        />
                        <label htmlFor={radioId} className="DocumentStatusChanger__label">
                            {item.name}
                        </label>
                    </li>
                );
            })}
        </ul>
    );
};

export { DocumentTypeToggler };

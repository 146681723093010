import React, { FunctionComponent, useEffect, useState } from 'react';
import clsx from 'clsx';

import { Lens } from 'monocle-ts';
import { flow, pipe } from 'fp-ts/function';
import * as I from 'fp-ts/Identity';
import * as O from 'fp-ts/Option';
import * as A from 'fp-ts/Array';

import {
    ExpansionPanel,
    ExpansionPanelDetails,
    ExpansionPanelSummary,
    FormControlLabel,
    Switch,
} from '@material-ui/core';
import { ExpandMore } from '@material-ui/icons';

import { ActionType, ChipData, foldFiltersCount } from '../../shared/filterLogic';

import { ReportDetailsFiltersAndSorting } from '../../types/order-reports';
import { OrderListFilters } from '../../types/order';
import { DocumentsStatus } from '../../types/documents';

import { DocumentsStatusIso } from '../../utils/isomorph';
import { countWithLoading } from '../../utils/view';

import { DocumentStatusChanger, DocumentStatusChangerData } from '../DocumentStatusChanger/DocumentStatusChanger';
import { ExpandableOrderTypesPanel } from '../ExpandableOrderTypesPanel/ExpandableOrderTypesPanel';

const criticalLens = Lens.fromProp<ReportDetailsFiltersAndSorting>()('has_critical_events');
const archivedLens = Lens.fromProp<ReportDetailsFiltersAndSorting>()('is_archived');
const embargoLens = Lens.fromProp<ReportDetailsFiltersAndSorting>()('embargo');

const statusesLens = Lens.fromProp<ReportDetailsFiltersAndSorting>()('status_ids');
const typesLens = Lens.fromProp<ReportDetailsFiltersAndSorting>()('type_ids');

function toggleCollectionId<A>(collection: A[], item: A): (index: O.Option<number>) => A[] {
    return (index) =>
        pipe(
            index,
            O.fold(
                () => pipe(collection, A.append(item)),
                (i) =>
                    pipe(
                        collection,
                        A.deleteAt(i),
                        O.getOrElse(() => collection)
                    )
            )
        );
}

type ExpandableFiltersPanelProps = {
    data: ChipData[];
    counts: OrderListFilters;
    filters: ReportDetailsFiltersAndSorting;
    onFiltersChange: (filters: ReportDetailsFiltersAndSorting, type?: ActionType, id?: number) => void;
    loadingCounts: boolean;
    isReportsAdmin: boolean;
};

export const ExpandableFiltersPanel: FunctionComponent<ExpandableFiltersPanelProps> = (props) => {
    const { data, counts, filters, onFiltersChange, loadingCounts, isReportsAdmin } = props;

    const [isOpen, setOpen] = useState(true);
    const [documentStatus, setDocumentStatus] = useState(DocumentsStatusIso.to(filters.is_archived));

    const foldCount = flow(foldFiltersCount, (val: number) => countWithLoading(val, loadingCounts));

    const archivedOptions: DocumentStatusChangerData[] = [
        {
            key: DocumentsStatus.All,
            label: 'Все',
            possibleCount: foldCount(counts.is_archived, null),
        },
        {
            key: DocumentsStatus.Current,
            label: 'Текущие',
            possibleCount: foldCount(counts.is_archived, false),
        },
        {
            key: DocumentsStatus.Archived,
            label: 'Архив',
            possibleCount: foldCount(counts.is_archived, true),
        },
    ];

    useEffect(() => {
        setDocumentStatus(DocumentsStatusIso.to(filters.is_archived));
    }, [filters]);

    const onStatusChange = (value: DocumentsStatus): void => {
        setDocumentStatus(value);
        onFiltersChange(pipe(filters, archivedLens.set(DocumentsStatusIso.from(value))));
    };

    const onCriticalChange = (value: boolean): void => {
        onFiltersChange(pipe(filters, criticalLens.set(value)));
    };

    const onEmbargoChange = (value: boolean): void => {
        onFiltersChange(pipe(filters, embargoLens.set(value)));
    };

    const onTypesChange = (type: ActionType) => (id: number): void => {
        if (type === 'types') {
            const updateData = pipe(
                I.Do,
                I.bind('typeIndex', () =>
                    pipe(
                        filters.type_ids,
                        A.findIndex((typeId) => typeId === id)
                    )
                ),
                I.bind('types', ({ typeIndex }) => toggleCollectionId(filters.type_ids, id)(typeIndex)),
                I.bind('statuses', ({ typeIndex }) =>
                    pipe(
                        typeIndex,
                        O.fold(
                            () =>
                                pipe(
                                    data,
                                    A.findFirst((item) => item.origin.id === id),
                                    O.map((item) =>
                                        pipe(
                                            item.origin.statuses,
                                            A.map((s) => s.id),
                                            (typeStatusIds) =>
                                                pipe(
                                                    filters.status_ids,
                                                    A.filter((statusId) => !typeStatusIds.includes(statusId))
                                                )
                                        )
                                    ),
                                    O.getOrElse(() => filters.status_ids)
                                ),
                            () => filters.status_ids
                        )
                    )
                )
            );

            onFiltersChange(
                pipe(filters, typesLens.set(updateData.types), statusesLens.set(updateData.statuses)),
                type,
                id
            );
        } else if (type === 'statuses') {
            onFiltersChange(
                pipe(
                    filters,
                    statusesLens.modify((ids) =>
                        pipe(
                            ids,
                            A.findIndex((typeId) => typeId === id),
                            toggleCollectionId(ids, id)
                        )
                    )
                ),
                type,
                id
            );
        }
    };

    return (
        <ExpansionPanel expanded={isOpen} onChange={() => setOpen((value) => !value)}>
            <ExpansionPanelSummary className={clsx('DownloadSettings__summary', 'DownloadSettings__summary--main')}>
                <div className={clsx('DownloadSettings__headingWrapper', 'DownloadSettings__headingWrapper--main')}>
                    <h2 className="DownloadSettings__heading">Параметры отбора заказов</h2>
                    <ExpandMore
                        className={clsx('DownloadSettings__icon', isOpen && 'DownloadSettings__icon--rounded')}
                    />
                </div>

                <div>
                    <DocumentStatusChanger
                        fullWidthOnMobile
                        canChangeQuery={false}
                        data={archivedOptions}
                        isReportsAdmin={isReportsAdmin}
                        selectedValue={documentStatus}
                        onChange={onStatusChange}
                    />
                </div>
            </ExpansionPanelSummary>

            <ExpansionPanelDetails className="DownloadSettings__details">
                <div>
                    <ExpandableOrderTypesPanel
                        data={data}
                        counts={counts}
                        filters={filters}
                        loading={loadingCounts}
                        onChange={onTypesChange}
                        isReportsAdmin={isReportsAdmin}
                    />
                </div>

                <div
                    className={clsx(
                        'DownloadSettings__singleCheckboxwrapper',
                        'DownloadSettings__singleCheckboxwrapper--main'
                    )}
                >
                    <FormControlLabel
                        className={clsx(
                            'DownloadSettings__checkboxControlLabel',
                            'DownloadSettings__singleCheckbox',
                            'DownloadSettings__singleCheckbox--main'
                        )}
                        label={
                            <>
                                С уведомлениями
                                {!isReportsAdmin && <> • {foldCount(counts.has_critical_events, true)}</>}
                            </>
                        }
                        control={<Switch color="primary" />}
                        checked={Boolean(filters.has_critical_events)}
                        onChange={(_, checked) => onCriticalChange(checked)}
                    />
                </div>

                <div
                    className={clsx(
                        'DownloadSettings__singleCheckboxwrapper',
                        'DownloadSettings__singleCheckboxwrapper--main'
                    )}
                >
                    <FormControlLabel
                        className={clsx(
                            'DownloadSettings__checkboxControlLabel',
                            'DownloadSettings__singleCheckbox',
                            'DownloadSettings__singleCheckbox--main'
                        )}
                        label={<>На запрете{!isReportsAdmin && <> • {foldCount(counts.embargo, true)}</>}</>}
                        control={<Switch color="primary" />}
                        checked={Boolean(filters.embargo)}
                        onChange={(_, checked) => onEmbargoChange(checked)}
                    />
                </div>
            </ExpansionPanelDetails>
        </ExpansionPanel>
    );
};

import React, { FunctionComponent } from 'react';
import { Container } from '../../store/orders/types';
import { foldEventDate } from '../../utils/view';

export const Event: FunctionComponent<{ container: Container }> = ({
    container,
}) => (
    <div className="OrdersListRow__fourth OrdersListProduct__fourth">
        {container.latest_event !== null && (
            <>
                <h3 className="OrdersList__mobileLabel SortButton">
                    Последнее событие
                </h3>
                <p className="ContainerRow__messageLabel">
                    {container.latest_event.name}
                </p>
                <p className="ContainerRow__messageDate">
                    {foldEventDate(container.latest_event)}
                </p>
            </>
        )}
    </div>
);

import React, { FC, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Dialog, DialogContent, IconButton, CircularProgress, Tabs, Tab } from '@material-ui/core';
import { Close } from '@material-ui/icons';

import { Container, OrderInfo } from '../../../store/orders/types';
import { NotificationSubscriptionItem } from '../../../types/notification-subscriptions';
import { ReportSubscriberEntity } from '../../../types/order-reports';

import {
    apiGetContainerNotificationSubscribersList,
    apiGetContainerSubscriptionsSets,
    apiNotificationsSettingsOrderDelete,
    apiUpdateContainerNotificationSubscribersList,
    apiUpdateContainerNotificationSubscription,
    apiUpdateOrderNotificationsSubscribersList,
    apiUpdateOrderNotificationSubscription,
    makeMapDispatch,
} from '../../../store/dispatch';
import { makeMapState } from '../../../store/root';
import { useAlerts } from '../../../hooks/noty';
import { isUserGranted } from '../../../utils/user';

import { SubscribersListBlock } from '../../SubscribersListBlock/SubscribersListBlock';
import { NotificationSetsList } from '../NotificationSetsList/NotificationSetsList';

import styles from './ContainerNotificationsSettingsModal.module.scss';

type ContainerNotificationsSettingsModalProps = {
    isOpen: boolean;
    onClose: () => void;
    order: OrderInfo;
    container: Container | null;
    onSuccess?: () => void;
};

const mapState = makeMapState((state) => ({
    isGrantedManageUsers: isUserGranted(state.user.profile, 'manage_user_subscriptions'),
}));

const mapDispatch = makeMapDispatch({
    getNotificationsSet: apiGetContainerSubscriptionsSets,
    updateContainerNotificationSubscription: apiUpdateContainerNotificationSubscription,
    getSubscribersList: apiGetContainerNotificationSubscribersList,
    updateContainerSubscribersList: apiUpdateContainerNotificationSubscribersList,
    updateOrderNotificationSubscription: apiUpdateOrderNotificationSubscription,
    updateOrderNotificationSubscribersList: apiUpdateOrderNotificationsSubscribersList,
    deleteNotification: apiNotificationsSettingsOrderDelete,
});

const ContainerNotificationsSettingsModal: FC<ContainerNotificationsSettingsModalProps &
    ReturnType<typeof mapDispatch> &
    ReturnType<typeof mapState>> = (props) => {
    const {
        isOpen,
        container,
        order,
        onClose,
        onSuccess,
        getNotificationsSet,
        isGrantedManageUsers,
        getSubscribersList,
        updateContainerNotificationSubscription,
        updateContainerSubscribersList,
        updateOrderNotificationSubscription,
        updateOrderNotificationSubscribersList,
        deleteNotification,
    } = props;

    const [isRemovingSet, setRemovingSet] = useState(false);
    const [notificationsSets, setNotificationsSets] = useState<NotificationSubscriptionItem[]>([]);
    const [subscribers, setSubscribers] = useState<ReportSubscriberEntity[]>([]);

    // Selected id become saved only after success saving
    const [savedSelectedSetId, setSavedSelectedSetId] = useState<number | null>(null);
    const [selectedSetId, setSelectedSetId] = useState<number | null>(null);

    const [isFetchLoading, setFetchLoading] = useState(true);
    const [isSettingsSaveLoading, setSettingsSaveLoading] = useState(false);
    const [isSubscribersSaveLoading, setSubscribersSaveLoading] = useState(false);

    const [currentTab, setCurrentTab] = useState<'settings' | 'recipients'>('settings');

    const { handleApiError, showNotification } = useAlerts();

    const isContainerMode = container !== null;

    const fetchNotificationsSets = (): void => {
        const payload: Protocol.NotificationSubscriptionsSetRequest = {
            container_id: container?.id ?? null,
            order_type_id: order.type.id,
        };

        getNotificationsSet(payload)
            .then((res) => {
                setNotificationsSets(res.items);
                const defaultSetId = res.items.find((item) => item.is_default)?.id ?? null;
                if (isContainerMode) {
                    setSelectedSetId(res.selected_container_settings_set_id ?? defaultSetId);
                    setSavedSelectedSetId(res.selected_container_settings_set_id ?? defaultSetId);
                } else {
                    setSelectedSetId(null);
                    setSavedSelectedSetId(null);
                }
            })
            .catch(handleApiError)
            .finally(() => {
                setFetchLoading(false);
            });
    };

    useEffect(() => {
        if (isOpen) {
            setCurrentTab('settings');
        }
    }, [isOpen]);

    useEffect(() => {
        if (isOpen && order !== null) {
            setFetchLoading(true);

            fetchNotificationsSets();

            if (isGrantedManageUsers) {
                getSubscribersList({
                    container_id: container?.id ?? null,
                })
                    .then((res) => {
                        setSubscribers(
                            res.items.map((item) => ({
                                user_id: item.id,
                                name: item.name,
                                is_subscribed: item.is_subscribed,
                            }))
                        );
                    })
                    .catch(handleApiError);
            }
        }
    }, [isOpen]); // eslint-disable-line react-hooks/exhaustive-deps

    const handleSelectedSettingsSetSave = (newSelectedSetId: number): void => {
        if (container === null) {
            const payload: Protocol.NotificationsSubscriptionsOrdersUpdateRequest = {
                notification_settings_set_id: newSelectedSetId,
                order_id: order.id,
            };

            setSettingsSaveLoading(true);

            updateOrderNotificationSubscription(payload)
                .then(() => {
                    showNotification('Настройки заказа успешно сохранены', { variant: 'success' });
                    if (onSuccess !== undefined) onSuccess();
                    setSavedSelectedSetId(newSelectedSetId);
                    // onClose();
                })
                .catch(handleApiError)
                .finally(() => {
                    setSettingsSaveLoading(false);
                });

            return;
        }

        const payload: Protocol.NotificationContainerUpdateRequest = {
            container_id: container.id,
            notification_settings_set_id: newSelectedSetId,
        };

        setSettingsSaveLoading(true);

        updateContainerNotificationSubscription(payload)
            .then(() => {
                showNotification('Настройки контейнера успешно сохранены', { variant: 'success' });
                if (onSuccess !== undefined) onSuccess();
                setSavedSelectedSetId(newSelectedSetId);
                // onClose();
            })
            .catch(handleApiError)
            .finally(() => {
                setSettingsSaveLoading(false);
            });
    };

    const handleSubscribersSave = (newSubscriberIds: number[]): void => {
        if (savedSelectedSetId === null) return;
        if (container === null) {
            const payload: Protocol.NotificationsSubscriptionsOrdersSubscribersUpdateRequest = {
                order_id: order.id,
                user_ids: newSubscriberIds,
                notification_settings_set_id: savedSelectedSetId,
            };

            updateOrderNotificationSubscribersList(payload)
                .then(() => {
                    showNotification('Настройки заказа успешно сохранены', { variant: 'success' });
                    if (onSuccess !== undefined) onSuccess();
                    // onClose();
                })
                .catch(handleApiError)
                .finally(() => {
                    setSubscribersSaveLoading(false);
                });

            return;
        }

        setSubscribersSaveLoading(true);

        const payload: Protocol.NotificationSubscribersListUpdateRequest = {
            container_id: container.id,
            notification_settings_set_id: savedSelectedSetId,
            user_ids: newSubscriberIds,
        };

        updateContainerSubscribersList(payload)
            .then(() => {
                showNotification('Настройки контейнера успешно сохранены', { variant: 'success' });
                if (onSuccess !== undefined) onSuccess();
                // onClose();
            })
            .catch(handleApiError)
            .finally(() => {
                setSubscribersSaveLoading(false);
            });
    };

    const handleDeleteSet = async (setId: number): Promise<void> => {
        setRemovingSet(true);
        return deleteNotification({ notification_settings_set_id: setId })
            .then(() => {
                showNotification('Настройки уведомления успешно удалены', {
                    variant: 'success',
                });

                fetchNotificationsSets();
            })
            .catch(handleApiError)
            .finally(() => setRemovingSet(false));
    };

    return (
        <Dialog onClose={onClose} open={isOpen} classes={{ paper: styles.paper }}>
            <div className={styles.header}>
                <h5 className={styles.title}>
                    {isContainerMode ? 'Настройка уведомлений' : 'Массовая настройка уведомлений в контейнерах'}
                </h5>
                <p className={styles.subtitle}>
                    {isContainerMode
                        ? `Контейнер №${container?.number} — Заказ №${order.number}`
                        : `Заказ №${order.number}`}
                </p>
            </div>

            <IconButton className={styles.closeButton} onClick={onClose}>
                <Close />
            </IconButton>

            <DialogContent className={styles.content}>
                {isFetchLoading ? (
                    <div className={styles.loadingWrapper}>
                        <CircularProgress />
                    </div>
                ) : (
                    <>
                        {isGrantedManageUsers && (
                            <Tabs
                                indicatorColor="primary"
                                className={styles.tabs}
                                value={currentTab}
                                onChange={(event, value) => {
                                    setCurrentTab(value);
                                }}
                            >
                                <Tab className={styles.tab} label="Настройка" value="settings" />
                                <Tab className={styles.tab} label="Получатели" value="recipients" />
                            </Tabs>
                        )}

                        {currentTab === 'settings' && (
                            <NotificationSetsList
                                notificationSets={notificationsSets}
                                selectedSetId={selectedSetId}
                                onSelect={setSelectedSetId}
                                onSave={handleSelectedSettingsSetSave}
                                onDelete={handleDeleteSet}
                                isLoading={isSettingsSaveLoading}
                                isDisabledSave={selectedSetId === null}
                                isRemoving={isRemovingSet}
                                order={order}
                                container={container}
                            />
                        )}

                        {currentTab === 'recipients' && (
                            <SubscribersListBlock
                                subscribers={subscribers}
                                onSave={handleSubscribersSave}
                                isSaving={isSubscribersSaveLoading}
                                isDisabledSave={savedSelectedSetId === null || savedSelectedSetId !== selectedSetId}
                            />
                        )}
                    </>
                )}
            </DialogContent>
        </Dialog>
    );
};

const connectedComponent = connect(mapState, mapDispatch)(ContainerNotificationsSettingsModal);
export { connectedComponent as ContainerNotificationsSettingsModal };

/* eslint-disable @typescript-eslint/camelcase */
import React, { FunctionComponent, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import clsx from 'clsx';

import { pipe } from 'fp-ts/function';
import * as O from 'fp-ts/Option';
import { connect } from 'react-redux';

import { Button, FormControl, TextField } from '@material-ui/core';
import { ClassNameMap } from '@material-ui/styles';

import { User } from '../../../types/user';

import { EventBus } from '../../../hooks/bus';
import { useAlerts } from '../../../hooks/noty';

import { generatePassword } from '../../../utils/view';
import { nullableEmptyStringObject } from '../../../utils/data';

import { apiManagedUsersCreate, apiManagedUsersUpdate, makeMapDispatch } from '../../../store/dispatch';

import { MaskInput } from '../../../components/input/MaskInput';
import { PasswordField } from '../../../components/PasswordField/PasswordField';

import styles from './EmployeeForm.module.scss';
import { LoadingButton } from '../../../components/LoadingButton';

declare module '../../../hooks/bus' {
    interface BusEvents {
        onReFetchEmployees: void;
    }
}

type FormData = Omit<Protocol.ManagedUsersUpdateRequest, 'login' | 'password'> & {
    employee_login: string;
    employee_security_code: string;
};

const mapDispatch = makeMapDispatch({ emitUpdate: apiManagedUsersUpdate, emitCreate: apiManagedUsersCreate });

export enum EmployeeFormKind {
    'create' = 'create',
    'update' = 'update',
}

type EmployeeFormMode =
    | {
          kind: EmployeeFormKind.create;
      }
    | {
          kind: EmployeeFormKind.update;
          user: User;
      };

type ClassKey = 'root';

type EmployeeFormProps = {
    mode: EmployeeFormMode;
    onClose?(): void;
    isShowCancelButton?: boolean;
    classes?: ClassNameMap;
} & ReturnType<typeof mapDispatch>;

const EmployeeForm: FunctionComponent<EmployeeFormProps> = (props) => {
    const { mode, onClose, isShowCancelButton, classes, emitUpdate, emitCreate } = props;

    const [isForcePasswordShown, setForcePasswordShown] = useState(false);

    const getDefaultValue = (key: keyof User): string => {
        if (mode.kind === 'update') {
            return String(mode.user[key] ?? '');
        }

        return key === 'full_name' ? 'Новый сотрудник' : '';
    };

    const defaultValues: FormData = {
        user_id: 0,
        employee_security_code: '',
        phone: getDefaultValue('phone'),
        email: getDefaultValue('email'),
        employee_login: getDefaultValue('login'),
        full_name: getDefaultValue('full_name'),
        shadow_recipient_id: 0,
    };

    const [loading, setLoading] = useState(false);

    const isFormDisabled = mode.kind === 'update' && mode.user.role && mode.user.role.id === 'manager';

    const { register, setValue, errors, setError, clearError, handleSubmit, watch } = useForm<FormData>({
        defaultValues,
    });
    const { handleApiError, showNotification } = useAlerts<FormData>(setError);

    const values = watch();

    const makePassword = (): void => {
        const newPassword = generatePassword();
        setValue('employee_security_code', newPassword);
        setForcePasswordShown(true);
    };

    const onSubmit = (payload: FormData): void => {
        clearError();
        setLoading(true);

        const method = mode.kind === 'update' ? emitUpdate : emitCreate;

        const { employee_login, employee_security_code, ...nullablePayload } = nullableEmptyStringObject(payload);

        if (employee_security_code && employee_security_code.length < 8) {
            showNotification('Слишком короткий пароль', {
                variant: 'error',
            });

            setLoading(false);

            return;
        }

        const formattedPayload: Protocol.ManagedUsersUpdateRequest =
            mode.kind === 'update'
                ? {
                      ...nullablePayload,
                      user_id: mode.user.id,
                      login: employee_login,
                      password: employee_security_code,
                  }
                : { ...nullablePayload, login: employee_login, password: employee_security_code };

        method(formattedPayload)
            .then(() => {
                showNotification(
                    mode.kind === 'update'
                        ? `Данные пользователя (${pipe(
                              O.fromNullable(mode.user.full_name),
                              O.getOrElseW(() => `ID: ${mode.user.id}`)
                          )}) изменены`
                        : 'Сотрудник добавлен',
                    {
                        variant: 'success',
                    }
                );

                EventBus.emit('onReFetchEmployees');
            })
            .catch(handleApiError)
            .finally(() => setLoading(false));
    };

    useEffect(() => {
        register('phone');
    }, [register]);

    return (
        <form onSubmit={handleSubmit(onSubmit)} className={clsx(styles.root, classes?.root)}>
            <div className={styles.outerGrid}>
                <div className={styles.grid}>
                    <div className={styles.column}>
                        <FormControl variant="outlined">
                            <TextField
                                disabled={isFormDisabled}
                                inputRef={register}
                                name="full_name"
                                variant="outlined"
                                label="Имя"
                                error={!!errors.full_name}
                                helperText={errors.full_name?.message}
                            />
                        </FormControl>

                        <FormControl variant="outlined">
                            <MaskInput
                                mask="+7 (999) 999-9999"
                                value={values.phone}
                                onChange={(phoneNumber) => setValue('phone', phoneNumber)}
                                disabled={isFormDisabled}
                            >
                                <TextField
                                    name="phone"
                                    variant="outlined"
                                    label="Телефон для уведомлений"
                                    error={!!errors.phone}
                                    helperText={errors.phone?.message}
                                />
                            </MaskInput>
                        </FormControl>

                        <FormControl variant="outlined">
                            <TextField
                                inputRef={register}
                                disabled={isFormDisabled}
                                name="email"
                                variant="outlined"
                                label="Почта для уведомлений"
                                error={!!errors.email}
                                helperText={errors.email?.message}
                            />
                        </FormControl>
                    </div>

                    <div className={styles.column}>
                        <FormControl variant="outlined">
                            <TextField
                                inputRef={register}
                                disabled={isFormDisabled}
                                name="employee_login"
                                variant="outlined"
                                label="Лoгин (необязательно)"
                                type="text"
                                error={!!errors.employee_login}
                                helperText={errors.employee_login?.message}
                            />
                        </FormControl>

                        <FormControl variant="outlined">
                            <PasswordField
                                disabled={isFormDisabled}
                                inputRef={register}
                                value={values.employee_security_code}
                                onChange={(event) => setValue('employee_security_code', event.currentTarget.value)}
                                name="employee_security_code"
                                variant="outlined"
                                label="Парoль (необязательно)"
                                error={!!errors.employee_security_code}
                                isForcePasswordShown={isForcePasswordShown}
                                onTogglePasswordVisibility={() => setForcePasswordShown(false)}
                                helperText={errors.employee_security_code?.message}
                            />
                        </FormControl>

                        {!isFormDisabled && (
                            <div>
                                <Button
                                    onClick={makePassword}
                                    variant="text"
                                    color="primary"
                                    className={styles.generate}
                                    size="small"
                                >
                                    Сгенерировать
                                </Button>
                            </div>
                        )}
                    </div>
                </div>

                {!isFormDisabled && (
                    <div className={styles.text}>
                        Укажите логин и&nbsp;пароль, чтобы сотрудник мог входить в&nbsp;личный кабинет.
                    </div>
                )}
            </div>

            <footer>
                <div className={styles.buttons}>
                    {!isFormDisabled && (
                        <LoadingButton
                            className={styles.button}
                            type="submit"
                            variant="contained"
                            color="primary"
                            size="large"
                            loading={loading}
                        >
                            {mode.kind === 'update' ? 'Сохранить' : 'Добавить'}
                        </LoadingButton>
                    )}

                    {onClose !== undefined && isShowCancelButton && (
                        <Button
                            className={styles.button}
                            onClick={onClose}
                            type="button"
                            variant="outlined"
                            color="primary"
                            size="large"
                            disabled={loading}
                        >
                            Закрыть
                        </Button>
                    )}
                </div>
            </footer>
        </form>
    );
};

const component = pipe(EmployeeForm, connect(null, mapDispatch));
export { component as EmployeeForm };

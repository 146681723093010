import React, { FunctionComponent, useState } from 'react';

import { Pagination } from '@material-ui/lab';

import type { BillsSearchResult } from '../../../store/search/types';
import type { Bill } from '../../../store/bills/types';
import type { ResultWrapperProps } from '../types';

import { CommentsProvider } from '../../../providers/CommentListProvider';
import { PageSizeSelect } from '../../PageSizeSelect/PageSizeSelect';

import { SearchHeader } from '../../BillsList/components/SearchHeader';
import { BillCard } from '../../BillCard/BillCard';

import { DisputeConfirm } from '../../DisputeConfirm';
import { Dispute } from '../../Dispute';

import '../../BillsList/BillsList.scss';

type BillsResultProps = ResultWrapperProps<BillsSearchResult>;
export const BillsResult: FunctionComponent<BillsResultProps> = (props) => {
    const { data, loading, paginatorProps, onPageChange } = props;

    const [openedCard, setOpenedCard] = useState<number | null>(null);
    const [itemToDispute, setItemToDispute] = useState<Bill | null>(null);
    const [disputeCancel, setDisputeCancel] = useState<Bill | null>(null);

    const isActiveItem = (id: number): boolean => id === openedCard;
    const setCloseConfirm = (): void => setDisputeCancel(null);
    const closeDisputeModal = (): void => setItemToDispute(null);

    return (
        <CommentsProvider>
            <div className="Orders__searchContainer">
                <div className="Orders__searchHeader">
                    <h6 className="Orders__searchResultLabel">
                        Найдено во взаиморасчетах • <span>{data.items_total}</span>
                    </h6>
                </div>
                <div className="BillsList">
                    <div className="BillsList__itemsWrapper">
                        <SearchHeader />
                        <div className="BillsList__items">
                            {data.items.map((item) => (
                                <div key={item.id} className="BillsList__item">
                                    <BillCard
                                        item={item}
                                        isOpen={isActiveItem(item.id)}
                                        setOpen={() => setOpenedCard(isActiveItem(item.id) ? null : item.id)}
                                        isBillAlreadySeen
                                        isBillNotSeenFake
                                        isBillForceSeen
                                        setItemToDispute={setItemToDispute}
                                        setDisputeCancel={setDisputeCancel}
                                        addBillToForceSeen={() => {}}
                                    />
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
                <div className="Orders__listFooter">
                    <PageSizeSelect {...paginatorProps} />
                    {data.page_last > 1 && (
                        <div className="Orders__pagination">
                            <Pagination
                                shape="rounded"
                                page={data.page_current}
                                count={data.page_last}
                                disabled={loading}
                                onChange={(evt, page) => onPageChange('bills', page)}
                            />
                        </div>
                    )}
                </div>
            </div>
            {itemToDispute && (
                <Dispute bill={itemToDispute} handleClose={closeDisputeModal} afterDisputeUpdate={closeDisputeModal} />
            )}
            <DisputeConfirm
                bill={disputeCancel}
                isOpen={disputeCancel !== null}
                setClose={setCloseConfirm}
                afterDisputeClose={setCloseConfirm}
            />
        </CommentsProvider>
    );
};

import React, { FunctionComponent, useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { connect } from 'react-redux';

import { pipe } from 'fp-ts/function';

import { Button, Card, SvgIcon } from '@material-ui/core';
import NotificationImportantIcon from '@material-ui/icons/NotificationImportant';
import AttachFile from '@material-ui/icons/AttachFile';

import { OrderContainer } from '../../store/orders/types';
import { apiUpdateContainerComment, apiUpdateOrderComment, makeMapDispatch } from '../../store/dispatch';

import { ROUTES } from '../../utils/location';

import { ContainerNotificationsSettingsModal } from '../container/NotificationSettingsModal/ContainerNotificationsSettingsModal';
import { RoutePlanningCard } from '../RoutePlanningCard/RoutePlanningCard';
import { ContainerBadge } from '../OrderItem/ContainerBadge';
import { TextLiveEdit } from '../input/TextLiveEdit';
import { OrderNumber } from '../OrderItem/OrderNumber';

import { ReactComponent as RoubleIcon } from '../../assets/rouble.svg';

import './ContainerDetails.scss';

const mapDispatch = makeMapDispatch({
    saveOrder: apiUpdateOrderComment,
    saveContainer: apiUpdateContainerComment,
});

type Props = ReturnType<typeof mapDispatch> & { container: OrderContainer };
const ContainerDetails: FunctionComponent<Props> = (props) => {
    const { container, saveOrder, saveContainer } = props;
    const [isSubscriptionModalOpen, setSubscriptionModalOpen] = useState(false);
    const location = useLocation();

    const handleSaveOrder = (comment: string): Promise<void> => saveOrder({ order_id: container.order.id, comment });

    const handleSaveContainer = (comment: string): Promise<void> =>
        saveContainer({ container_id: container.id, comment });

    useEffect(() => {
        if (location.hash === '#open_modal') {
            setSubscriptionModalOpen(true);
        }
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <Card className="ContainerDetails">
            <div className="ContainerDetails__idInfoWrapper">
                <div className="ContainerDetails__headerWrapper">
                    <OrderNumber order={container.order} />
                    <div className="ContainerDetails__headerActions">
                        <Button
                            variant="text"
                            color="primary"
                            component={Link}
                            to={`${ROUTES.ORDERS}/${container.order.id}/bills`}
                            startIcon={<SvgIcon component={RoubleIcon} color="primary" />}
                        >
                            Счета по заказу
                        </Button>
                        <Button
                            variant="text"
                            color="primary"
                            onClick={() => {
                                setSubscriptionModalOpen(true);
                            }}
                            startIcon={<NotificationImportantIcon color="secondary" />}
                        >
                            Уведомления
                        </Button>
                    </div>
                </div>
                <TextLiveEdit
                    placeholder="Заметка для поиска"
                    onSave={handleSaveOrder}
                    value={container.order.customer_comment || container.order.manager_comment}
                />
            </div>

            <div className="ContainerDetails__header">
                <div className="ContainerDetails__info">
                    <ContainerBadge
                        size={container.type.size}
                        type={null}
                        asLink={false}
                        container={container}
                        withButton
                        isGroupageCargo={container.order.type.is_groupage_cargo}
                    />
                    {!container.order.type.is_groupage_cargo && (
                        <div className="ContainerDetails__weightWrapper">
                            {!!container.cargo_weight && (
                                <p className="ContainerDetails__weight">{container.cargo_weight} кг</p>
                            )}

                            {!!container.cargo_quantity_seats && (
                                <p className="ContainerDetails__places">
                                    Грузовых мест: {container.cargo_quantity_seats}
                                </p>
                            )}
                        </div>
                    )}
                </div>
                <TextLiveEdit
                    placeholder="Заметка для поиска контейнера"
                    onSave={handleSaveContainer}
                    value={container.customer_comment || container.manager_comment}
                    className={container.order.type.is_groupage_cargo && 'ContainerDetails__liveEdit--marginLeft'}
                />

                <div className="ContainerDetails__files">
                    <Button
                        variant="text"
                        color="primary"
                        startIcon={<AttachFile color="primary" />}
                        component={Link}
                        to={`${ROUTES.ORDERS}/${container.order.id}/files`}
                    >
                        Скачать все документы
                    </Button>
                </div>
            </div>

            <div className="ContainerDetails__body ContainerDetailsBody">
                {container.payer && (
                    <div>
                        <h3 className="ContainerDetailsBody__heading">Плательщик</h3>
                        <p className="ContainerDetailsBody__text">{container.payer}</p>
                    </div>
                )}

                {container.shipper && (
                    <div>
                        <h3 className="ContainerDetailsBody__heading">Отправитель</h3>
                        <p className="ContainerDetailsBody__text">{container.shipper}</p>
                    </div>
                )}

                {container.notify && (
                    <div>
                        <h3 className="ContainerDetailsBody__heading">Notify</h3>
                        <p className="ContainerDetailsBody__text">{container.notify}</p>
                    </div>
                )}

                {(!!container.consignee || !!container.consignee_text) && (
                    <div>
                        <h3 className="ContainerDetailsBody__heading">Получатель</h3>
                        {!!container.consignee && <p className="ContainerDetailsBody__text">{container.consignee}</p>}
                        {!!container.consignee_text && (
                            <p className="ContainerDetailsBody__text">{container.consignee_text}</p>
                        )}
                    </div>
                )}

                <RoutePlanningCard container={container} />
            </div>

            <ContainerNotificationsSettingsModal
                isOpen={isSubscriptionModalOpen}
                container={container}
                order={container.order}
                onClose={() => {
                    setSubscriptionModalOpen(false);
                }}
            />
        </Card>
    );
};

const component = pipe(ContainerDetails, connect(null, mapDispatch));
export { component as ContainerDetails };

import React, { CSSProperties, FunctionComponent, useEffect, useState } from 'react';
import { FormContext, useForm } from 'react-hook-form';
import { useHistory, useLocation, useParams, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import clsx from 'clsx';

import { Checkbox, CircularProgress } from '@material-ui/core';

import { pipe } from 'fp-ts/function';
import * as R from 'fp-ts/Record';

import {
    apiManagedUsersList,
    apiNotificationsSettingsDocumentsDetails,
    apiNotificationsSettingsDocumentsUpdate,
    apiUserNotificationsSettingsDetails,
    apiUserNotificationsSettingsDocumentsUpdate,
    makeMapDispatch,
} from '../../store/dispatch';
import { makeMapState } from '../../store/root';

import { useSearch } from '../../hooks/search';

import { NotificationAvailableTypeWithoutSMS, NotificationsSettingsDocumentsDetails } from '../../types/notifications';

import { setPageTitle } from '../../utils/title';
import { NotificationsEntityItems } from '../../utils/const';
import {
    notificationTypeAvailabilityMessage,
    notificationEntityIso,
    NotificationEntityTypes,
    SettingsFormDataEntity,
} from '../../utils/notifications';

import { useAlerts } from '../../hooks/noty';

import { NotificationsHeader } from '../../components/NotificationsHeader/NotificationsHeader';
import { NotificationsActionsBar } from '../../components/NotificationsActionsBar/NotificationsActionsBar';
import { TasksRow } from '../../components/NotificationSettingsTable/Components/TasksRow';

import styles from './DocumentsNotifications.module.scss';
import { ROUTES } from '../../utils/location';
import { User } from '../../types/user';

const mapState = makeMapState((state) => ({
    settings: state.settings,
    user: state.user.profile,
}));

const mapDispatch = makeMapDispatch({
    fetchDocumentsDetails: apiNotificationsSettingsDocumentsDetails,
    updateDocuments: apiNotificationsSettingsDocumentsUpdate,
    fetchEmployeeDocumentsDetails: apiUserNotificationsSettingsDetails,
    updateEmployeeDocuments: apiUserNotificationsSettingsDocumentsUpdate,
    getEmployeesList: apiManagedUsersList,
});

const defaultOrderTypeId = 1;
const pageTitle = 'Настройка уведомлений по документам';
const notificationsItemsToExclude: NotificationEntityTypes[] = ['sms'];

export type NotificationSettingsFormData = {
    data: Record<string, SettingsFormDataEntity>;
};

type Params = { settingsId: string; userId: string };

type DocumentsNotificationsProps = ReturnType<typeof mapDispatch> & ReturnType<typeof mapState>;
const DocumentsNotifications: FunctionComponent<DocumentsNotificationsProps> = (props) => {
    const {
        fetchDocumentsDetails,
        updateDocuments,
        settings,
        user,
        fetchEmployeeDocumentsDetails,
        updateEmployeeDocuments,
        getEmployeesList,
    } = props;

    const { hasSearchData } = useSearch();
    const [isLoading, setLoading] = useState(false);
    const [isUpdating, setUpdating] = useState(false);
    const [employee, setEmployee] = useState<User>();

    const { showNotification, handleApiError } = useAlerts();
    const history = useHistory();

    const { pathname } = useLocation();
    const params = useParams<Params>();

    const isEmployee = pathname.includes('user-notification-settings-documents');

    const defaultValues: NotificationSettingsFormData = {
        data: {},
    };

    const formMethods = useForm<NotificationSettingsFormData>({
        defaultValues,
    });

    const { watch, setValue } = formMethods;
    const value = watch({ nest: true });

    useEffect(() => setPageTitle(pageTitle), [hasSearchData]);

    useEffect(() => {
        if (params.userId) {
            getEmployeesList()
                .then((response) => {
                    setEmployee(response.items.find((item) => item.id === Number(params.userId)));
                })
                .catch(handleApiError);
        }
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const onSubmit = (): void => {
        const data = notificationEntityIso.from(value.data);
        const payload: Protocol.NotificationsSettingsDocumentsUpdateRequest = {
            document_types: data.map((item) => ({
                id: item.id,
                channels: item.types.filter((itemType) => itemType !== 'sms') as NotificationAvailableTypeWithoutSMS[],
            })),
        };

        const successCallback = (): void => {
            showNotification('Настройки уведомлений по документам сохранены', {
                variant: 'success',
            });

            let link = isEmployee ? ROUTES.EMPLOYEES : `${ROUTES.PROFILE}#notifications`;

            if (params.userId) {
                link = `${link}?userId=${params.userId}`;
            }

            history.push(link);
        };

        setUpdating(true);

        if (isEmployee) {
            updateEmployeeDocuments({ ...payload, notification_settings_set_id: Number(params.settingsId) })
                .then(() => successCallback())
                .catch(handleApiError)
                .finally(() => setUpdating(false));
        } else {
            updateDocuments(payload)
                .then(() => successCallback())
                .catch(handleApiError)
                .finally(() => setUpdating(false));
        }
    };

    useEffect(() => {
        setLoading(true);

        if (isEmployee) {
            fetchEmployeeDocumentsDetails({ notification_settings_set_id: Number(params.settingsId) })
                .then((response) => {
                    const formattedData = notificationEntityIso.to(
                        (response.settings_set.details as NotificationsSettingsDocumentsDetails).document_types.map(
                            (item) => ({
                                id: item.id,
                                kind: 'document',
                                types: item.selected_channels,
                                container_id: -1,
                                order_type_id: defaultOrderTypeId,
                            })
                        )
                    );
                    setValue('data', formattedData);
                })
                .catch(handleApiError)
                .finally(() => setLoading(false));
        } else {
            fetchDocumentsDetails()
                .then((response) => {
                    const formattedData = notificationEntityIso.to(
                        response.document_types.map((item) => ({
                            id: item.id,
                            kind: 'document',
                            types: item.selected_channels,
                            container_id: -1,
                            order_type_id: defaultOrderTypeId,
                        }))
                    );
                    setValue('data', formattedData);
                })
                .catch(handleApiError)
                .finally(() => setLoading(false));
        }
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const changeFormType = (isAllActive: boolean, type: NotificationEntityTypes): void => {
        const entities = settings.documentTypes.map((documentType) => ({
            id: documentType.id,
            name: documentType.name,
        }));
        const entityKind = 'document';

        entities.forEach((entity) => {
            const id = `${defaultOrderTypeId}_${entityKind}_${entity.id}`;

            if (value.data[id]) {
                setValue(`data.${id}.types.${type}`, !isAllActive);
            }
        });

        if (!isAllActive && user && notificationTypeAvailabilityMessage(type, user)) {
            showNotification(notificationTypeAvailabilityMessage(type, user), {
                variant: 'warning',
            });
        }
    };

    const availableTypesView = (): JSX.Element[] => {
        const entities = settings.documentTypes.map((documentType) => ({
            id: documentType.id,
            name: documentType.name,
        }));

        const entityKind = 'document';

        const filteredEntities = pipe(
            value.data,
            R.filter((v) => {
                return Number(v.order_type_id) === defaultOrderTypeId && v.kind === entityKind;
            })
        );

        return NotificationsEntityItems.map((type) => {
            const isAllActive =
                !R.isEmpty(filteredEntities) &&
                entities.every((entity) => {
                    const currentEntity = filteredEntities[`${defaultOrderTypeId}_${entityKind}_${entity.id}`];

                    return currentEntity && currentEntity.types[type.key];
                });

            const isSomeActive =
                !isAllActive &&
                entities.some((entity) => {
                    const currentEntity = filteredEntities[`${defaultOrderTypeId}_${entityKind}_${entity.id}`];

                    return currentEntity && currentEntity.types[type.key];
                });
            return (
                <div
                    key={type.key}
                    className="NotificationSettingsTable__newDocumentsHeadItem NotificationSettingsTable__documentTypeRowCheckbox"
                >
                    {!notificationsItemsToExclude.includes(type.key) && (
                        <>
                            <span>{type.name}</span>
                            <Checkbox
                                checked={isSomeActive || isAllActive}
                                indeterminate={isSomeActive}
                                color="primary"
                                onChange={() => changeFormType(isAllActive, type.key)}
                            />
                        </>
                    )}
                </div>
            );
        });
    };

    return (
        <div className={styles.root}>
            <div className="App__container App__container--small">
                <NotificationsHeader userId={params.userId} isEmployee={isEmployee} title={pageTitle} />

                {isLoading && (
                    <div className={styles.loaderWrapper}>
                        <CircularProgress size={24} />
                    </div>
                )}

                <div
                    className={clsx(styles.card, isLoading && styles.formHidden)}
                    style={
                        {
                            '--notifications-table-columns-amount':
                                NotificationsEntityItems.length - notificationsItemsToExclude.length,
                        } as CSSProperties
                    }
                >
                    <FormContext {...formMethods}>
                        <div
                            className={clsx(
                                'NotificationSettingsTable__column',
                                'NotificationSettingsTable__column--hideOnSmall'
                            )}
                        >
                            <div
                                className={clsx(
                                    'NotificationSettingsTable__headRow',
                                    'NotificationSettingsTable__headRow--eventsTitle'
                                )}
                            >
                                <div className="NotificationSettingsTable__headCell NotificationSettingsTable__headCell--title" />
                                <div className="NotificationSettingsTable__headLabels">{availableTypesView()}</div>
                            </div>
                        </div>

                        <TasksRow
                            tasks={settings.documentTypes.map((documentType) => ({
                                id: documentType.id,
                                name: documentType.name,
                            }))}
                            kind="document"
                            orderType={{
                                id: defaultOrderTypeId,
                                name: '',
                                tasks: [],
                                statuses: [],
                            }}
                            excludeTypes={notificationsItemsToExclude}
                            employee={employee}
                        />
                    </FormContext>
                </div>
            </div>

            <NotificationsActionsBar onSubmit={onSubmit} isLoadingSubmit={isUpdating} />
        </div>
    );
};

const connected = withRouter(pipe(DocumentsNotifications, connect(mapState, mapDispatch)));
export { connected as DocumentsNotifications };

import type { captureException as CaptureException } from '@sentry/minimal';
import type { CaptureContext } from '@sentry/types';

import { ApiError, FallbackError } from './errors';
import { Authorization } from './auth';
import * as SafeBase64 from '../../utils/base64';

export enum HttpMethod {
    Get = 'GET',
    Post = 'POST',
}

type CaptureExceptionSignature = typeof CaptureException;
type RequestPayload = Record<string, any> | null;
type RequestBody = string | null;
type RequestContext = {
    url: string;
    body: RequestBody;
    method: HttpMethod;
    headers: HeadersInit;
    payload: RequestPayload;
};

export class ApiClientClass {
    private invalidTokenHandler: () => void = () => {};
    private defaultHeaders = { Accept: 'application/json' };
    private sentryHandler: CaptureExceptionSignature | null = null;

    constructor(private readonly auth: Authorization) {}

    private request<T>(
        method: HttpMethod,
        url: string,
        body: RequestBody,
        payload: RequestPayload,
        headers = {}
    ): Promise<T> {
        const requestHeaders = { ...this.defaultHeaders, ...headers, ...this.auth.getAuthHeaders() };

        const fetchPayload: RequestInit = {
            headers: requestHeaders,
            credentials: 'same-origin',
            mode: 'cors',
            method,
            body,
        };

        const request: RequestContext = { url, body, method, payload, headers: requestHeaders };

        return fetch(url, fetchPayload).then((response) => this.processResponse<T>(request, response));
    }

    private async processResponse<T>(request: RequestContext, response: Response): Promise<T> {
        const captureContext: CaptureContext = { extra: { request, response } };

        const contentType = response.headers.get('content-type');
        if (!contentType || contentType.indexOf('application/json') < 0) {
            this.captureExceptionAndThrow(FallbackError(request.url, request.method, response.status), captureContext);
        }

        const json = await response.json();

        if (!response.ok) {
            if (response.status === 422) {
                const ServerError = new ApiError(
                    'Backend sanitize error',
                    request.url,
                    request.method,
                    response.status,
                    json
                );

                if (ServerError.getCommonCodes().includes('invalid_token')) this.invalidTokenHandler();

                throw ServerError;
            }

            this.captureExceptionAndThrow(FallbackError(request.url, request.method, response.status), captureContext);
        }

        if ('token' in json) this.auth.setToken(json.token);

        // TODO: implement generic validation (io-ts with codec)
        return json as T;
    }

    private captureException(error: ApiError, context: CaptureContext): void {
        if (this.sentryHandler) this.sentryHandler(error, context);
    }

    private captureExceptionAndThrow(error: ApiError, context: CaptureContext): void {
        this.captureException(error, context);
        throw error;
    }

    setSentryHandler(handler: CaptureExceptionSignature): void {
        this.sentryHandler = handler;
    }

    getSentryHandler(): CaptureExceptionSignature | null {
        return this.sentryHandler;
    }

    setInvalidTokenHandler(handler: () => void): void {
        this.invalidTokenHandler = handler;
    }

    get<Response, Request extends RequestPayload = null>(url: string, payload: Request): Promise<Response> {
        let getUrl = url;
        if (payload instanceof Object && Object.keys(payload).length)
            getUrl += '?_body=' + SafeBase64.encode(JSON.stringify(payload));
        return this.request<Response>(HttpMethod.Get, getUrl, null, payload);
    }

    post<Response, Request extends RequestPayload = null>(url: string, payload: Request): Promise<Response> {
        return this.request<Response>(HttpMethod.Post, url, JSON.stringify(payload), payload, {
            'Content-Type': 'application/json',
        });
    }
}

/* eslint-disable no-underscore-dangle */
import React, { ChangeEvent, FunctionComponent, useEffect, useMemo, useState } from 'react';
import { Link, NavLink, useHistory, useParams } from 'react-router-dom';
import { connect } from 'react-redux';

import { flow, pipe } from 'fp-ts/function';
import * as R from 'fp-ts/Record';
import * as O from 'fp-ts/Option';
import * as A from 'fp-ts/Array';
import * as Eq from 'fp-ts/Eq';

import { Breadcrumbs, Button, Typography } from '@material-ui/core';

import { appPath, buildRouteUrl, ROUTES } from '../../utils/location';
import { eqPartialCombinator } from '../../utils/types';
import { setPageTitle } from '../../utils/title';
import { makeInteger } from '../../utils/branding';
import {
    buildSelector,
    constCustomFailure,
    constNotAsked,
    fold3,
    foldOption,
    isSuccess,
    RequestState,
    useStateInitializer,
} from '../../utils/requestState';

import { ActionType, filtersPayloadEquals, foldFiltersCount, mapFiltersIntoData } from '../../shared/filterLogic';

import {
    columnsEq,
    ReportColumn,
    ReportDetails,
    ReportDetailsFiltersAndSorting,
    ReportMailingSettings,
    ReportMailingSettingsType,
} from '../../types/order-reports';
import { OrderListFilters } from '../../types/order';

import { makeMapState } from '../../store/root';
import {
    apiReportsAdd,
    apiReportsClone,
    apiReportsDelete,
    apiReportsDetails,
    apiReportsGetColumns,
    apiReportsGetStats,
    apiReportsAndTemplatesList,
    apiReportsUpdate,
    apiReportTemplateDetails,
    makeMapDispatch,
    apiReportsTemplateDetails,
    apiReportsTemplateAdd,
    apiReportsTemplateUpdate,
} from '../../store/dispatch';

import { useDocumentsLoader } from '../../hooks/documentsLoader';
import { useSearch } from '../../hooks/search';
import { useAlerts } from '../../hooks/noty';

import { DownloadSettingsSidebar } from '../../components/DownloadSettingsSidebar/DownloadSettingsSidebar';
import { ExpandableFiltersPanel } from '../../components/ExpandableFiltersPanel/ExpandableFiltersPanel';
import { ExpandableColumnsPanel } from '../../components/ExpandableСolumnsPanel/ExpandableСolumnsPanel';
import { LoadingFixedCenter } from '../../components/LoadingFixedCenter';
import { FetchError } from '../../components/FetchError';

import './DownloadSettings.scss';
import { useUrl } from '../../hooks/url';
import { OrderEvent } from '../../store/settings/types';

const defaultUnchecked = ['Внутрироссийские отправки', 'Импорт', 'Сборные грузы'];

const makeStatsPayload = R.filter((item) => item !== null);

const foldColumnRequestData = flow(
    (state: RequestState<ReportColumn[]>) => foldOption(state),
    O.getOrElse((): ReportColumn[] => [])
);

const mapDispatch = makeMapDispatch({
    getColumns: apiReportsGetColumns,
    getDetails: apiReportsDetails,
    getTemplateDetails: apiReportsTemplateDetails,
    getStats: apiReportsGetStats,
    addReports: apiReportsAdd,
    updateReports: apiReportsUpdate,
    addTemplateReports: apiReportsTemplateAdd,
    updateTemplateReports: apiReportsTemplateUpdate,
    deleteReports: apiReportsDelete,
    cloneReports: apiReportsClone,
    getTemplateData: apiReportTemplateDetails,
    getReportsAndTemplatesList: apiReportsAndTemplatesList,
});

const mapState = makeMapState((state) => ({
    filters: state.settings.orderTypes,
    columnsRS: state.reports.columns,
    detailsRSSelector: buildSelector(state.reports.details),
    documentsStorage: state.documents.items,
    userProfile: state.user.profile,
}));

const eqMailingSettingsType = Eq.struct<ReportMailingSettingsType>({
    frequency: eqPartialCombinator(Eq.eqString),
    interval_days: eqPartialCombinator(Eq.eqNumber),
    weekdays: eqPartialCombinator(A.getEq(Eq.eqString)),
});

const eqMailingSettings = eqPartialCombinator(
    Eq.struct<ReportMailingSettings>({
        type: eqMailingSettingsType,
    })
);

type Params = { settingsId: string | 'create' };
type DownloadSettingsProps = ReturnType<typeof mapState> & ReturnType<typeof mapDispatch>;
const DownloadSettings: FunctionComponent<DownloadSettingsProps> = (props) => {
    const {
        filters,
        columnsRS,
        detailsRSSelector,
        getColumns,
        getDetails,
        getStats,
        addReports,
        deleteReports,
        cloneReports,
        updateReports,
        documentsStorage,
        getTemplateData,
        getReportsAndTemplatesList,
        getTemplateDetails,
        userProfile,
        addTemplateReports,
        updateTemplateReports,
    } = props;

    const { handleApiError, showNotification } = useAlerts();
    const { downloadReportsDocument } = useDocumentsLoader();
    const { hasSearchData } = useSearch();
    const history = useHistory();
    const params = useParams<Params>();

    const [filterPayload, setFilterPayload] = useState<ReportDetailsFiltersAndSorting>({
        status_ids: [],
        event_ids: [],
        type_ids: [],
        has_critical_events: false,
        is_archived: false,
        embargo: false,
    });

    const [serverCounts, setCounts] = useState<OrderListFilters>({
        events: [],
        statuses: [],
        embargo: [],
        order_types: [],
        is_archived: [],
        has_critical_events: [],
    });

    const [defaultMailingSettings, setDefaultMailingSettings] = useState<ReportMailingSettings>();
    const [defaultColumnsSettings, setDefaultColumnSettings] = useState<ReportColumn[]>([]);
    const [mailingSettings, setMailingSettings] = useState<ReportMailingSettings>();
    const [columnsSettings, setColumnSettings] = useState<ReportColumn[]>([]);
    const [loadingCounts, setLoadingCounts] = useState(false);
    const [templateName, setTemplateName] = useState('');
    const [countsTimer, setCountsTimer] = useState(-1);
    const [isLoading, setLoading] = useState(false);

    const { getQueryParam } = useUrl();

    const isCreate = params.settingsId === 'create';

    const isReportsAdmin = useMemo(() => {
        return Boolean(
            userProfile &&
                userProfile._actions.length > 0 &&
                userProfile._actions.includes('manage_order_report_templates')
        );
    }, [userProfile]);

    const pageTitle = useMemo(() => {
        if (isReportsAdmin) {
            return isCreate ? 'Создание шаблона выгрузки' : 'Настройка шаблона выгрузки';
        }

        return isCreate ? 'Создание выгрузки' : 'Настройка выгрузки';
    }, [isCreate, isReportsAdmin]);

    const getDetailsMethod = isReportsAdmin ? getTemplateDetails : getDetails;

    const settingsId = makeInteger(params.settingsId);
    const detailsRS = pipe(
        settingsId,
        O.fold(
            () =>
                isCreate
                    ? constNotAsked<ReportDetails>()
                    : constCustomFailure<ReportDetails>(`Невалидный идентификатор «${params.settingsId}»`),
            (id) => detailsRSSelector(id.toString())
        )
    );

    const isDocumentLoading = useMemo(() => {
        if (!O.isSome(settingsId)) return false;

        const key = `reports_document_${settingsId.value}`;
        return Boolean(documentsStorage.find((document) => document.key === key));
    }, [documentsStorage, settingsId]);

    const fetchCounts = (payload: ReportDetailsFiltersAndSorting): void => {
        if (loadingCounts) return;
        setLoadingCounts(true);

        let formattedPayload: Record<string, unknown> = makeStatsPayload(payload);

        formattedPayload = {
            ...formattedPayload,
            has_critical_events: formattedPayload.has_critical_events || undefined,
            embargo: formattedPayload.embargo || undefined,
        };
        getStats(makeStatsPayload(formattedPayload))
            .then(setCounts)
            .catch((e) => console.log(e)) // TODO: show noty (useAlert)
            .finally(() => setLoadingCounts(false));
    };

    const handleFiltersChange = (newState: ReportDetailsFiltersAndSorting, type?: ActionType, id?: number): void => {
        if (filtersPayloadEquals(newState, filterPayload)) return;

        if (id !== undefined) {
            if (type === 'types') {
                const changedFilter = filters.find((filter) => filter.id === id);
                const typeValue = newState.type_ids.includes(id);

                if (changedFilter !== undefined) {
                    setColumnSettings((columns) =>
                        columns.map((column) => ({
                            ...column,
                            selected: changedFilter.name === column.group.name ? typeValue : column.selected,
                        }))
                    );
                }
            } else if (type === 'statuses') {
                const statusValue = newState.status_ids.includes(id);

                filters.forEach((filter) => {
                    const changedStatus = filter.statuses.find((status) => status.id === id);

                    if (changedStatus !== undefined) {
                        const checkedStatuses = filter.statuses.filter((status) =>
                            newState.status_ids.includes(status.id)
                        );
                        let eventsListToUncheck: OrderEvent[] = [];

                        if (checkedStatuses.length === 0) {
                            filter.statuses.forEach((status) => {
                                eventsListToUncheck = [...eventsListToUncheck, ...status.events];
                            });
                        }

                        setColumnSettings((columns) =>
                            columns.map((column) => {
                                const needUncheck = eventsListToUncheck.find((event) => event.name === column.name);
                                let isSelected = !needUncheck && column.selected;

                                if (!needUncheck) {
                                    const isNeededColumn =
                                        filter.name === column.group.name &&
                                        changedStatus.events.find((event) => event.name === column.name);

                                    isSelected = isNeededColumn ? statusValue : column.selected;
                                }

                                return {
                                    ...column,
                                    selected: isSelected,
                                };
                            })
                        );
                    }
                });
            }
        }

        setFilterPayload(newState);
    };

    const handleColumnsChange = (columns: ReportColumn[]): void => {
        if (columnsEq.equals(columns, columnsSettings)) return;
        setColumnSettings(columns);
    };

    const handleMailingSettingsChange = (settings?: ReportMailingSettings): void => {
        if (eqMailingSettings.equals(settings, mailingSettings)) return;
        setMailingSettings(settings);
    };

    const handleTemplateNameChange = (e: ChangeEvent<HTMLInputElement>): void => {
        e.persist();

        const name = e.target.value;
        if (Eq.eqString.equals(name, templateName)) return;

        setTemplateName(name);
    };

    const handleSettingsDownload = (): void => {
        if (O.isSome(settingsId)) {
            const payload = {
                id: settingsId.value,
            };

            downloadReportsDocument(payload, {
                payload,
                key: `reports_document_${settingsId.value}`,
                type: 'reports_document',
            });
        }
    };

    const handleSettingsClone = (): void => {
        if (O.isSome(settingsId)) {
            setLoading(true);

            let clonedId: number;

            cloneReports({
                id: settingsId.value,
            })
                .then((result) => {
                    clonedId = result.id;
                    return getReportsAndTemplatesList();
                })
                .then(() =>
                    showNotification(`Копия выгрузки успешно создана`, {
                        variant: 'success',
                        action: (
                            <Button
                                className="App__alertActionButton"
                                href={
                                    (appPath || '') +
                                    buildRouteUrl('DOWNLOAD_SETTINGS_BY_ID', {
                                        settingsId: clonedId,
                                    })
                                }
                            >
                                Перейти
                            </Button>
                        ),
                    })
                )
                .catch(handleApiError)
                .finally(() => setLoading(false));
        }
    };

    const handleSettingsDelete = (): void => {
        if (O.isSome(settingsId)) {
            setLoading(true);

            deleteReports({
                id: settingsId.value,
            })
                .then(() => getReportsAndTemplatesList())
                .then(() =>
                    showNotification(`Выгрузка успешно удалена`, {
                        variant: 'success',
                    })
                )
                .then(() => {
                    history.push(ROUTES.DOWNLOAD_SETTINGS);
                })
                .catch(handleApiError)
                .finally(() => setLoading(false));
        }
    };

    const handleSettingsSave = (): void => {
        setLoading(true);

        const columns = columnsSettings.map((column) => ({
            id: column.id,
            type: column.type.id,
            selected: column.selected,
        }));

        const payload: Protocol.ReportsAddRequest = {
            columns,
            name: templateName,
            mailing_settings: mailingSettings,
        };

        if (filterPayload.is_archived !== null) payload.is_archived = filterPayload.is_archived;
        if (filterPayload.embargo) payload.embargo = filterPayload.embargo;
        if (filterPayload.has_critical_events) payload.has_critical_events = filterPayload.has_critical_events;
        if (filterPayload.type_ids !== null) payload.type_ids = filterPayload.type_ids;
        if (filterPayload.status_ids !== null) payload.status_ids = filterPayload.status_ids;
        if (filterPayload.event_ids !== null) payload.event_ids = filterPayload.event_ids;

        const addMethod = (): Promise<Protocol.ReportsDetailsResponse> =>
            isReportsAdmin ? addTemplateReports(payload) : addReports(payload);

        const updateMethod = (id: number): Promise<Protocol.ReportsDetailsResponse> =>
            isReportsAdmin
                ? updateTemplateReports({ ...payload, template_id: id })
                : updateReports({ ...payload, report_id: id });

        if (isCreate) {
            // TODO: refactor newId
            let newId: number;
            addMethod()
                .then((response) => {
                    newId = response.id;
                    return getReportsAndTemplatesList();
                })
                .then(() => getDetailsMethod({ id: newId }))
                .then(() =>
                    showNotification(`Выгрузка успешно сохранена`, {
                        variant: 'success',
                    })
                )
                .then(() => {
                    history.push(
                        buildRouteUrl('DOWNLOAD_SETTINGS_BY_ID', {
                            settingsId: newId,
                        })
                    );
                })
                .catch(handleApiError)
                .finally(() => setLoading(false));
        } else if (O.isSome(settingsId)) {
            updateMethod(settingsId.value)
                .then(() => getDetailsMethod({ id: settingsId.value }))
                .then(() => getReportsAndTemplatesList())
                .then(() =>
                    showNotification(`Выгрузка успешно сохранена`, {
                        variant: 'success',
                    })
                )
                .catch(handleApiError)
                .finally(() => setLoading(false));
        }
    };

    const mergeColumnsSettings = (): void => {
        if (O.isSome(settingsId) && isSuccess(columnsRS) && A.isNonEmpty(columnsRS.data) && isSuccess(detailsRS)) {
            const updatedColumns = detailsRS.data.columns;

            const defaultColumns = foldColumnRequestData(columnsRS).map((column) => ({
                ...column,
                selected: !defaultUnchecked.includes(column.group.name),
            }));

            defaultColumns.forEach((defaultColumn) => {
                const isColumnMissed = !updatedColumns.find((column) => defaultColumn.id === column.id);

                if (isColumnMissed) {
                    updatedColumns.push(defaultColumn);
                }
            });

            setColumnSettings(updatedColumns);
            setDefaultColumnSettings(updatedColumns);
        }
    };

    useEffect(() => {
        if (!isReportsAdmin) {
            clearTimeout(countsTimer);
            setCountsTimer(window.setTimeout(() => fetchCounts(filterPayload), 1000));
        }
    }, [filterPayload]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => setPageTitle(pageTitle), [hasSearchData, pageTitle]);

    useEffect(() => {
        if (
            O.isNone(settingsId) &&
            A.isEmpty(columnsSettings) &&
            isSuccess(columnsRS) &&
            A.isNonEmpty(columnsRS.data)
        ) {
            const defaultColumns = foldColumnRequestData(columnsRS).map((column) => ({
                ...column,
                selected: !defaultUnchecked.includes(column.group.name),
            }));

            const templateId = getQueryParam('template');

            if (isCreate && templateId) {
                setLoading(true);
                getTemplateData({
                    id: Number(templateId),
                })
                    .then((template) => {
                        const updatedColumns = template.columns;

                        defaultColumns.forEach((defaultColumn) => {
                            const isColumnMissed = !updatedColumns.find((column) => defaultColumn.id === column.id);

                            if (isColumnMissed) {
                                updatedColumns.push(defaultColumn);
                            }
                        });

                        setTemplateName(template.name);
                        setFilterPayload(template.filters_and_sorting);
                        setColumnSettings(updatedColumns);
                    })
                    .catch(handleApiError)
                    .finally(() => setLoading(false));
            } else setColumnSettings(defaultColumns);
        }
    }, [columnsSettings, columnsRS, settingsId]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (isSuccess(detailsRS)) {
            const updatedMailingSettings = detailsRS.data.mailing_settings;
            let mappedMailingSettings: ReportMailingSettings | undefined;

            if (updatedMailingSettings) {
                const type: ReportMailingSettingsType =
                    updatedMailingSettings.frequency === 'weekdays'
                        ? {
                              frequency: 'weekdays',
                              weekdays: updatedMailingSettings.weekdays.map((item) => item.id),
                          }
                        : updatedMailingSettings;

                mappedMailingSettings = {
                    type,
                };
            }

            setTemplateName(detailsRS.data.name);
            setMailingSettings(mappedMailingSettings);
            setDefaultMailingSettings(mappedMailingSettings);
            setFilterPayload(detailsRS.data.filters_and_sorting);

            if (!A.isEmpty(columnsSettings)) {
                mergeColumnsSettings();
            }
        }
    }, [detailsRS]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (A.isEmpty(columnsSettings)) {
            mergeColumnsSettings();
        }
    }, [detailsRS, columnsRS, settingsId]); // eslint-disable-line react-hooks/exhaustive-deps

    useStateInitializer(columnsRS, () => getColumns());
    useStateInitializer(
        detailsRS,
        () =>
            O.isSome(settingsId) &&
            getDetailsMethod({
                id: settingsId.value,
            })
    );

    const isSavingAvailable = useMemo(
        () =>
            eqMailingSettings.equals(defaultMailingSettings, mailingSettings) ||
            columnsEq.equals(defaultColumnsSettings, columnsSettings),
        [mailingSettings, defaultMailingSettings, defaultColumnsSettings, columnsSettings]
    );

    const data = useMemo(() => mapFiltersIntoData(filters, serverCounts), [serverCounts, filters]);

    const totalOrderCount = useMemo(() => {
        let count = 0;

        data.forEach((item) => {
            if (filterPayload.type_ids.includes(item.origin.id))
                count += foldFiltersCount(serverCounts.order_types, item.origin.id);

            item.origin.statuses.forEach((status) => {
                if (filterPayload.status_ids.includes(status.id))
                    count += foldFiltersCount(serverCounts.statuses, status.id);
            });
        });

        const embargo = foldFiltersCount(serverCounts.embargo, filterPayload.embargo);
        const hasCriticalEvents = foldFiltersCount(serverCounts.has_critical_events, filterPayload.has_critical_events);

        if (filterPayload) {
            if (filterPayload.embargo && filterPayload.has_critical_events) return Math.min(embargo, hasCriticalEvents);
            if (filterPayload.embargo) return embargo;
            if (filterPayload.has_critical_events) return hasCriticalEvents;
        }

        return count;
    }, [loadingCounts]); // eslint-disable-line react-hooks/exhaustive-deps

    const detailsViewState = fold3(detailsRS);

    const content = (
        <>
            <h1 className="DownloadSettings__title">{pageTitle}</h1>

            <div className="DownloadSettings__columns">
                <div className="DownloadSettings__column DownloadSettings__column--primary">
                    <ExpandableFiltersPanel
                        data={data}
                        counts={serverCounts}
                        isReportsAdmin={isReportsAdmin}
                        filters={filterPayload}
                        loadingCounts={loadingCounts}
                        onFiltersChange={handleFiltersChange}
                    />
                    {A.isNonEmpty(columnsSettings) && (
                        <ExpandableColumnsPanel columns={columnsSettings} onChange={handleColumnsChange} />
                    )}
                </div>
                <div className="DownloadSettings__column DownloadSettings__column--secondary">
                    <DownloadSettingsSidebar
                        isCreate={isCreate}
                        isSavingAvailable={isSavingAvailable}
                        loadingCounts={loadingCounts}
                        templateName={templateName}
                        totalOrderCount={totalOrderCount}
                        mailingSettings={mailingSettings}
                        defaultMailingSettings={defaultMailingSettings}
                        handleSettingsSave={handleSettingsSave}
                        handleSettingsClone={handleSettingsClone}
                        handleSettingsDelete={handleSettingsDelete}
                        handleSettingsDownload={handleSettingsDownload}
                        handleTemplateNameChange={handleTemplateNameChange}
                        handleMailingSettingsChange={handleMailingSettingsChange}
                        isReportsAdmin={isReportsAdmin}
                    />
                </div>
            </div>
        </>
    );

    return (
        <div className="App__container App__container--small DownloadSettings">
            <div className="DownloadSettings__breadcrumbs">
                <Breadcrumbs aria-label="breadcrumb" separator="/">
                    {!isReportsAdmin && (
                        <NavLink className="DownloadSettings__breadcrumbsLink" color="inherit" to={ROUTES.ORDERS}>
                            Мои заказы
                        </NavLink>
                    )}

                    <NavLink
                        className="DownloadSettings__breadcrumbsLink"
                        color="inherit"
                        to={ROUTES.DOWNLOAD_SETTINGS}
                    >
                        Выгрузки
                    </NavLink>
                    <Typography color="textSecondary">{pageTitle}</Typography>
                </Breadcrumbs>
            </div>

            {isCreate
                ? content
                : detailsViewState(
                      () => <LoadingFixedCenter />,
                      (error) => (
                          <>
                              <h1 className="DownloadSettings__title">{pageTitle}</h1>

                              <FetchError
                                  title={error.getCommonFirstMessage()}
                                  onRetry={
                                      O.isSome(settingsId)
                                          ? () => getDetailsMethod({ id: settingsId.value })
                                          : undefined
                                  }
                              >
                                  <Button
                                      variant="outlined"
                                      color="primary"
                                      style={{
                                          marginTop: '1em',
                                          marginRight: '1em',
                                      }}
                                      component={Link}
                                      to={ROUTES.DOWNLOAD_SETTINGS}
                                  >
                                      Назад к выгрузкам
                                  </Button>
                              </FetchError>
                          </>
                      ),
                      () => content
                  )}
            {isLoading && <LoadingFixedCenter />}
            {isDocumentLoading && <LoadingFixedCenter isDownload />}
        </div>
    );
};

const EnchantedComponent = connect(mapState, mapDispatch)(DownloadSettings);
export { EnchantedComponent as DownloadSettings };

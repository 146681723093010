import React, { ChangeEventHandler, FunctionComponent, MouseEventHandler, useEffect, useState } from 'react';
import clsx from 'clsx';
import moment from 'moment';

import { isSome, Some } from 'fp-ts/Option';
import { dataURL as capture } from '@gripeless/pico/dist';
import { value as getRight } from '@gripeless/pico/dist/error-stack';

import { Button, CircularProgress, IconButton, Theme } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/styles';
import { Delete } from '@material-ui/icons';

import { base64ToBlob, readBlobAsBase64, ImageData } from '../../utils/data';
import { Base64, makeBase64 } from '../../utils/branding';
import { foldBytes, foldView } from '../../utils/view';

import { useAlerts } from '../../hooks/noty';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        emptyLabel: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            width: 75 * 4,
            height: 75 * 3,
            border: '2px dashed rgb(151, 151, 151)',
            borderRadius: 8,
            cursor: 'pointer',
            transition: 'border-color 300ms ease',
            '&:hover': {
                borderColor: 'rgb(97,97,97)',
            },
        },
        withFile: {
            border: 'none',
            '&:hover': {
                border: 'none',
            },
        },
        imageContainer: {
            position: 'relative',
            backgroundColor: 'rgb(151, 151, 151, 0.8)',
            height: '100%',
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            borderRadius: 8,
            overflow: 'hidden',
        },
        image: {
            height: '100%',
            maxHeight: '100%',
        },
        delete: {
            position: 'absolute',
            top: theme.spacing(1),
            right: theme.spacing(1),
            color: 'white',
        },
        info: {
            position: 'absolute',
            left: 0,
            top: 0,
            width: '100%',
            paddingTop: theme.spacing(1),
            paddingLeft: theme.spacing(1),
            paddingRight: theme.spacing(6),
            paddingBottom: theme.spacing(3),
            color: 'white',
            background: 'linear-gradient(180deg, rgba(0, 0, 0, 0.7) 0%, rgba(0, 0, 0,0) 95%)',
        },
        infoName: {
            fontSize: '0.7em',
        },
        infoSize: {
            marginTop: '0.5em',
            fontSize: '0.6em',
        },
        root: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
        },
        screenshotButton: {
            marginTop: theme.spacing(1),
            width: 'fit-content',
        },
    })
);

const selectFileLabel = 'Выберите изображение';
const ignoreList = [
    'noscript',
    '[role="tooltip"]',
    '[role="presentation"]:not(.keepVisible)',
    '[data-error-report-fab]',
];

type FileInfo = {
    url: string;
    name: string;
    size: string;
    isScreenshot: boolean;
};

type Props = {
    shown: boolean;
    onChange: (data: ImageData | null) => void;
};

const getBase64Bytes = (b64: Some<Base64>): Base64 => b64.value.replace(/^data:image\/[^;]+;base64,/, '') as Base64;

export const ImageInput: FunctionComponent<Props> = (props) => {
    const { shown, onChange } = props;

    const styles = useStyles();
    const { showNotification } = useAlerts();

    const [reading, setReading] = useState(false);
    const [fileInfo, setFileInfo] = useState<FileInfo | null>(null);

    const handleChange: ChangeEventHandler<HTMLInputElement> = async (evt) => {
        const { files } = evt.target;

        if (files !== null && files.length !== 0) {
            setReading(true);

            const file = files[0];
            const base64 = await readBlobAsBase64(file);

            if (isSome(base64)) {
                const browserImageUrl = URL.createObjectURL(file);

                setFileInfo({
                    url: browserImageUrl,
                    name: file.name,
                    size: foldBytes(file.size),
                    isScreenshot: false,
                });

                onChange({
                    bytes: getBase64Bytes(base64),
                    name: file.name,
                });
            } else {
                onChange(null);
                showNotification('Не удалось считать файл', {
                    variant: 'warning',
                });
            }

            setReading(false);
        } else setFileInfo(null);
    };

    const onDelete = (): void => {
        onChange(null);
        setFileInfo(null);
    };

    const handleDelete: MouseEventHandler<HTMLButtonElement> = (evt) => {
        evt.preventDefault();
        onDelete();
    };

    const fillFromScreenshot = async (): Promise<void> => {
        setReading(true);

        const rawBase64 = await capture(window, {
            ignore: ignoreList,
        }).then(getRight);

        const base64 = makeBase64(rawBase64);
        const blob = await base64ToBlob(rawBase64);

        if (blob !== null && isSome(base64)) {
            const now = moment();
            const filename = `Снимок экрана ${now.format('DD-MM-YYYY')} в ${now.format('HH mm')}.png`;

            onChange({
                bytes: getBase64Bytes(base64),
                name: filename,
            });

            const url = URL.createObjectURL(blob);

            setFileInfo({
                url,
                name: filename,
                size: foldBytes(blob.size),
                isScreenshot: true,
            });
        } else {
            showNotification('Не удалось создать снимок экрана', {
                variant: 'warning',
            });
        }

        setReading(false);
    };

    useEffect(() => {
        if (shown) fillFromScreenshot();
        else onDelete();
    }, [shown]); // eslint-disable-line react-hooks/exhaustive-deps

    const viewState = foldView(fileInfo, reading, null);

    return (
        <div className={styles.root}>
            <input id="file-input" type="file" accept="image/*" style={{ display: 'none' }} onChange={handleChange} />
            <label
                htmlFor="file-input"
                className={clsx(styles.emptyLabel, fileInfo && styles.withFile)}
                aria-label={selectFileLabel}
            >
                {viewState(
                    () => selectFileLabel,
                    () => (
                        <CircularProgress />
                    ),
                    () => null,
                    (data) => (
                        <div className={styles.imageContainer}>
                            <div className={styles.info}>
                                <div className={styles.infoName}>{data.name}</div>
                                <div className={styles.infoSize}>{data.size}</div>
                            </div>
                            <img src={data.url} alt={data.name} className={styles.image} />
                            <IconButton size="small" onClick={handleDelete} className={styles.delete}>
                                <Delete />
                            </IconButton>
                        </div>
                    )
                )}
            </label>
            <Button
                onClick={fillFromScreenshot}
                disabled={reading || fileInfo?.isScreenshot}
                className={styles.screenshotButton}
            >
                Сделать снимок экрана
            </Button>
        </div>
    );
};

import moment, { Moment } from 'moment';
import React, { useMemo, useState } from 'react';

import { pipe } from 'fp-ts/function';
import { head } from 'fp-ts/Array';
import { toNullable } from 'fp-ts/Option';

import { addSecondsToDatetime, formatDatetimeFromDayBegin, getWeekdayIndex } from '../../utils/date';
import { DayOffPeriod, DayOffWeekDay, WorkingHours } from '../../store/settings/types';
import { useInterval } from '../../hooks/interval';
import { TimezoneIso } from '../../utils/isomorph';
import { ManagerData } from '../../store/orders/types';

enum Reason {
    workingHours,
    weekDay,
    period,
    none,
}
type DayOffWeekDayItem = { type: Reason.weekDay; value: DayOffWeekDay };
type DayOffPeriodItem = { type: Reason.period; value: DayOffPeriod };
type MessagePayload = { result: DayOffWeekDayItem | DayOffPeriodItem | null; reason: Reason };

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const useCallDisable = (workingHours: WorkingHours, manager: ManagerData) => {
    const workingRange = {
        from: formatDatetimeFromDayBegin(workingHours.start_time_seconds),
        to: formatDatetimeFromDayBegin(workingHours.end_time_seconds),
    };

    const getCurrentUTC = (): Moment => moment().utc();
    const [currentUTCTime, setCurrentUTCTime] = useState(getCurrentUTC());
    const [weekDays, setWeekDays] = useState<DayOffWeekDay[]>([]);
    const [periods, setPeriods] = useState<DayOffPeriod[]>([]);
    const secondsToAdd = TimezoneIso.to(manager.timezone);

    useInterval(() => setCurrentUTCTime(getCurrentUTC()), 1000);

    const dateWithUTCOffset = currentUTCTime.clone().add(secondsToAdd, 'seconds');
    const hoursWithUTCOffset = dateWithUTCOffset.clone().hours();

    const isOutOfWorkingHoursPeriodCalc = (
        start: DayOffPeriod['start_date'],
        end: DayOffPeriod['end_date'],
        date = dateWithUTCOffset
    ): boolean => {
        const startDate = moment(start, 'YYYY.MM.DD').startOf('day').utc();
        const endDate = moment(end, 'YYYY.MM.DD').endOf('day').utc();
        return date.isSameOrAfter(startDate) && date.isSameOrBefore(endDate);
    };

    const reasonsOutOfWork: MessagePayload[] = useMemo(
        () =>
            workingHours.days_off
                .map(
                    (dayOff): MessagePayload => {
                        const { period } = dayOff;
                        if (period) {
                            setPeriods((values) => (!values.includes(period) ? [...values, period] : values));
                            if (isOutOfWorkingHoursPeriodCalc(period.start_date, period.end_date)) {
                                return { result: { value: period, type: Reason.period }, reason: Reason.period };
                            }
                        }

                        const { week_day: weekDay } = dayOff;
                        if (weekDay) {
                            setWeekDays((values) => (!values.includes(weekDay) ? [...values, weekDay] : values));
                            const currentWeekday = moment().weekday();
                            const weekDayIndex = getWeekdayIndex(weekDay.id);

                            const isOutOfWorkingHours2 = currentWeekday === weekDayIndex;
                            if (isOutOfWorkingHours2) {
                                return { result: { type: Reason.weekDay, value: weekDay }, reason: Reason.weekDay };
                            }
                        }

                        return { result: null, reason: Reason.none };
                    }
                )
                .filter((item) => item.reason !== Reason.none),
        [workingHours, currentUTCTime] // eslint-disable-line react-hooks/exhaustive-deps
    );

    const isOutOfWorkingHours = hoursWithUTCOffset < workingRange.from || hoursWithUTCOffset >= workingRange.to;
    if (isOutOfWorkingHours) reasonsOutOfWork.push({ result: null, reason: Reason.workingHours });

    const firstReason = pipe(reasonsOutOfWork, head, toNullable);

    const sortPeriods = (start: DayOffPeriod, end: DayOffPeriod): number => {
        const startDate = moment(start.start_date, 'YYYY.MM.DD').startOf('day').utc();
        const endDate = moment(end.start_date, 'YYYY.MM.DD').startOf('day').utc();

        return startDate.isBefore(endDate) ? -1 : 1;
    };

    const buildMessage = (data: MessagePayload): JSX.Element | null => {
        const { result, reason } = data;
        const isSameTimezone = manager.timezone === moment().format('Z');
        const timeFormat = 'HH:mm';
        const startTime = addSecondsToDatetime(workingHours.start_time_seconds);
        const endTime = addSecondsToDatetime(workingHours.end_time_seconds);
        const personUTFOffset = TimezoneIso.to(manager.timezone);
        const userUTCOffset = TimezoneIso.to(moment().format('Z'));
        const workingWeekdays = 'Пн-Пт';

        const managerDifferentTimezoneLabel = (
            <>
                {workingWeekdays},{' '}
                {startTime
                    .clone()
                    .add(userUTCOffset - personUTFOffset, 'seconds')
                    .format(timeFormat)}
                -
                {endTime
                    .clone()
                    .add(userUTCOffset - personUTFOffset, 'seconds')
                    .format(timeFormat)}{' '}
                по вашему времени
            </>
        );

        switch (reason) {
            case Reason.period: {
                const title = <strong>Сегодня праздничный день</strong>;
                if (result !== null && result.type === Reason.period) {
                    let date: Moment | undefined;
                    const lastDate = moment(result.value.end_date, 'YYYY.MM.DD');

                    while (!date) {
                        lastDate.add(1, 'day');
                        let isPossibleToSetValue = true;
                        weekDays.forEach((weekDay) => {
                            const weekdayIndex = getWeekdayIndex(weekDay.id);
                            if (weekdayIndex === lastDate.weekday()) isPossibleToSetValue = false;
                        });

                        periods.sort(sortPeriods).forEach((period) => {
                            if (isOutOfWorkingHoursPeriodCalc(period.start_date, period.end_date, lastDate)) {
                                isPossibleToSetValue = false;
                            }
                        });

                        if (isPossibleToSetValue) date = lastDate;
                    }

                    if (date) {
                        if (isSameTimezone) {
                            return (
                                <>
                                    {title}
                                    <br />
                                    Продолжаем работать с {date.format('DD MMMM')}
                                    <br />
                                    График работы менеджера:
                                    <br />
                                    {workingWeekdays}, {startTime.format(timeFormat)}-{endTime.format(timeFormat)}
                                </>
                            );
                        }

                        return (
                            <>
                                {title}
                                <br />
                                Продолжаем работать с {date.format('DD MMMM')}
                                <br />
                                Менеджер работает в часовом поясе UTC {manager.timezone},<br />
                                {managerDifferentTimezoneLabel}
                            </>
                        );
                    }
                }

                break;
            }

            case Reason.weekDay: {
                const title = <strong>Сегодня выходной</strong>;
                if (isSameTimezone && result !== null && result.type === Reason.weekDay) {
                    return (
                        <>
                            {title}
                            <br />
                            График работы менеджера:
                            <br />
                            {workingWeekdays}, {startTime.format(timeFormat)}-{endTime.format(timeFormat)}
                        </>
                    );
                }

                return (
                    <>
                        {title}
                        <br />
                        Менеджер работает в часовом поясе UTC {manager.timezone},<br />
                        {managerDifferentTimezoneLabel}
                    </>
                );
            }

            case Reason.workingHours: {
                const title = <strong>Рабочий день закончен</strong>;
                if (isSameTimezone) {
                    return (
                        <>
                            {title}
                            <br />
                            График работы менеджера:
                            <br />
                            {workingWeekdays}, {startTime.format(timeFormat)}-{endTime.format(timeFormat)}
                        </>
                    );
                }

                return (
                    <>
                        {title}
                        <br />
                        Менеджер работает в часовом поясе UTC {manager.timezone},<br />
                        {managerDifferentTimezoneLabel}
                    </>
                );
            }
            default:
                return null;
        }

        return null;
    };

    const message = firstReason !== null ? buildMessage(firstReason) : null;

    return { message };
};

import React, { FunctionComponent } from 'react';
import { WithStyles } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import logo from '../../assets/logo.svg';
import { styles } from './styles';

export enum LogoSizeEnum {
    regular,
    small,
}

type Props = { size?: LogoSizeEnum } & WithStyles<typeof styles>;

const Logo: FunctionComponent<Props> = props => {
    const { size = LogoSizeEnum.regular, classes } = props;
    const className = size === LogoSizeEnum.regular ? classes.defaultLogo : classes.smallLogo;
    return (
        <img className={`${classes.logo} ${className}`} src={logo} alt="App Logo" />
    );
};

const component = withStyles(styles)(Logo);
export { component as Logo };

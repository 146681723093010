import React, { FunctionComponent } from 'react';

import { Tooltip } from '@material-ui/core';
import ErrorIcon from '@material-ui/icons/Error';

import { OrderStatusMessages } from '../../shared/labels';

import { OrderInfo } from '../../store/orders/types';

type OrderNumberProps = { order: OrderInfo };
export const OrderNumber: FunctionComponent<OrderNumberProps> = (props) => {
    const { order } = props;

    const hasEmbargoTitle =
        order.embargo.is_not_released || order.embargo.is_prohibited;

    const embargoTitle = order.embargo.is_prohibited
        ? OrderStatusMessages.IsProhibited
        : OrderStatusMessages.IsNotRealized;

    return (
        <div className='ContainerDetails__idTypeInfo'>
            {hasEmbargoTitle ? (
                <Tooltip title={embargoTitle} enterTouchDelay={100} placement='top-start'>
                    <span className='ContainerDetails__id ContainerDetails__id--error'>
                        {order.number}
                        <ErrorIcon />
                    </span>
                </Tooltip>
            ) : (
                <span className='ContainerDetails__id'>{order.number}</span>
            )}
            <div className='ContainerDetails__type'>{order.type.name}</div>
        </div>
    );
};

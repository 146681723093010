import { Reducer } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import { AppActionTypes, AppState } from './types';

import { SafePersistConfig } from '../../utils/types';
import { UserActionTypes } from '../user/types';
import { resetableReducer } from '../../utils/resetable-reducer';

const initialState: AppState = {
    isInvitationVisible: true,
};

const baseReducer: Reducer<AppState, AppActionTypes> = (state = initialState, action) => {
    switch (action.type) {
        case 'SET_INVITATION_VISIBILITY':
            return { ...state, isInvitationVisible: action.payload };
        default:
            return state;
    }
};

const persistConfig: SafePersistConfig<AppState> = {
    key: 'app',
    storage,
    whitelist: ['isInvitationVisible'],
};

export const appReducer = persistReducer(
    persistConfig,
    resetableReducer<AppState, AppActionTypes, UserActionTypes>(baseReducer, 'USER_LOGOUT', 'app')
);

import { Option, none, some } from 'fp-ts/Option';
import { aboveZero, isNumber, isString, isUndefined } from './guards';

/* Brands URIs */
declare const NonEmptyQueryBrand: unique symbol;
declare const YMAccountBrand: unique symbol;
declare const IntegerBrand: unique symbol;
declare const Base64Brand: unique symbol;

type NonEmptyQueryURI = typeof NonEmptyQueryBrand;
type YMAccountURI = typeof YMAccountBrand;
type IntegerURI = typeof IntegerBrand;
type Base64URI = typeof Base64Brand;

/* Branded Types */
export type NonEmptyQuery = Opaque<string, NonEmptyQueryURI>;
export type YMAccount = Opaque<number, YMAccountURI>;
export type Integer = Opaque<number, IntegerURI>;
export type Base64 = Opaque<string, Base64URI>;

/* Helpers */
type NullableString = string | null;
type OptionalString = string | undefined;

/** Refinements */
const integerRefinement = (value: number): value is Integer => Number.isInteger(value);
const YMAccountRefinement = (value: number): value is YMAccount => integerRefinement(value) && aboveZero(value);
const isNonEmptyStringRefinement = <T extends string>(s: NullableString): s is T => s !== null && s.length > 2;

/* Constructors */
export const makeBase64 = (s: string): Option<Base64> => (isNonEmptyStringRefinement<Base64>(s) ? some(s) : none);

export const makeNonEmptyQuery = (s: NullableString): Option<NonEmptyQuery> =>
    isNonEmptyStringRefinement<NonEmptyQuery>(s) ? some(s) : none;

export const makeYMAccount = (account: OptionalString): Option<YMAccount> => {
    if (isUndefined(account)) return none;
    const val = parseInt(account, 10);
    return YMAccountRefinement(val) ? some(val) : none;
};

export const makeInteger = (value: unknown): Option<Integer> => {
    let val = value;
    if (isString(val) && /^\d+$/.test(val)) val = parseInt(val, 10);
    if (!isNumber(val)) return none;
    return integerRefinement(val) ? some(val) : none;
};

import React, { FunctionComponent, useState } from 'react';
import { useForm, FormContext, Controller } from 'react-hook-form';
import { connect } from 'react-redux';

import { pipe } from 'fp-ts/function';

import {
    Card,
    ExpansionPanel,
    ExpansionPanelDetails,
    ExpansionPanelSummary,
    Theme,
    Button,
    Dialog,
    DialogTitle,
    DialogActions,
    CircularProgress,
    Checkbox,
    Tooltip,
    FormControlLabel,
    IconButton,
    ClickAwayListener,
} from '@material-ui/core';
import { ExpandMore, HelpOutline } from '@material-ui/icons';
import { createStyles, makeStyles } from '@material-ui/styles';

import { apiDeleteRecipient, apiUpdateRecipient, makeMapDispatch } from '../store/dispatch';

import { notificationDocumentsIso, notificationEntityIso, RecipientState } from '../utils/notifications';
import { convertHTMLEntity } from '../utils/strings';
import { formatPhone } from '../utils/view';

import { useAlerts } from '../hooks/noty';

import {
    NotificationSettingsFormData,
    NotificationSettingsTable,
} from './NotificationSettingsTable/NotificationSettingsTable';
import { TelegramBot } from './TelegramBot/TelegramBot';

import NotificationContainerImg from '../assets/notificationContainer.png';
import NotificationListImg from '../assets/notificationList.png';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        form: {
            width: '100%',
        },
        card: {
            borderRadius: 10,
            marginBottom: 32,
        },
        cardHeader: {
            display: 'flex',
            justifyContent: 'space-between',
            width: '100%',
        },
        contacts: {
            display: 'flex',
            alignItems: 'center',
            flexWrap: 'wrap',

            '& > div': {
                fontSize: 20,
                fontWeight: 500,

                '& > span': {
                    color: theme.palette.primary.light,
                },

                '&:not(:last-of-type)': {
                    marginRight: 32,
                },

                [theme.breakpoints.down('sm')]: {
                    fontSize: 15,
                },

                [theme.breakpoints.down('xs')]: {
                    fontSize: 12,
                    width: '100%',

                    '&:not(:last-of-type)': {
                        marginRight: 0,
                    },
                },
            },
        },
        MuiAccordionSummaryRoot: {
            paddingTop: 14,
            paddingBottom: 14,
            paddingLeft: 32,
            paddingRight: 32,

            [theme.breakpoints.down('xs')]: {
                paddingLeft: 16,
                paddingRight: 16,
            },
        },
        MuiAccordionDetailsRoot: {
            paddingLeft: 32,
            paddingRight: 32,
            borderTop: '1px solid #bdbdbd',

            [theme.breakpoints.down('xs')]: {
                paddingLeft: 16,
                paddingRight: 16,
            },
        },
        MuiAccordionDetailsRootSimple: {
            borderTop: 'none',
        },
        deleteButton: {
            color: '#BF0000',
            [theme.breakpoints.down('xs')]: {
                fontSize: 12,
            },
        },
        statusSection: {
            '&:not(:last-of-type)': {
                marginBottom: 32,
            },
        },
        statusTitle: {
            margin: 0,
            marginBottom: 32,
            fontSize: 24,
        },
        notificationContainer: {
            display: 'flex',
            alignItems: 'center',
            marginBottom: 32,

            [theme.breakpoints.down('sm')]: {
                marginBottom: 4,

                '& .MuiFormControlLabel-root': {
                    alignItems: 'flex-start',
                },

                '& .MuiCheckbox-root': {
                    paddingTop: 0,
                },
            },

            '& .MuiFormControlLabel-root': {
                marginRight: 0,
            },
        },
        telegram: {
            margin: '12px 0',
        },
        notificationLabelContainer: {
            display: 'flex',
            alignItems: 'center',
            '&:hover > .MuiSvgIcon-root': {
                color: '#004D78',
            },
        },
        notificationLabel: {
            color: 'black',
            fontSize: 14,
            letterSpacing: 0.25,
            marginRight: 0,

            [theme.breakpoints.down('sm')]: {
                marginRight: 24,
            },
        },
        notificationIcon: {
            color: '#666666',
            fontSize: 20,
        },
        notificationButtonHelp: {
            marginLeft: 6,

            [theme.breakpoints.down('sm')]: {
                marginLeft: 0,
                'html:root &': {
                    backgroundColor: 'transparent',
                    paddingTop: 0,
                },
                '& .MuiTouchRipple-root': {
                    display: 'none',
                },
            },
        },
        notificationPopupContainer: {
            display: 'flex',
            flexDirection: 'column',
            padding: 16,
        },
        notificationPopupGallery: {
            alignItems: 'flex-start',
            display: 'flex',
            '& > :not(:last-child)': {
                marginRight: 8,
            },
        },
        notificationMainWrapper: {
            alignItems: 'flex-start',
            display: 'flex',

            [theme.breakpoints.down('sm')]: {
                justifyContent: 'space-between',
                width: '100%',
            },

            '&:hover, &:focus': {
                '& $notificationIcon': {
                    color: '#004D78',
                },
            },
        },
        notificationPopupImage: {
            width: 160,
            height: 90,
            overflow: 'hidden',

            [theme.breakpoints.down('sm')]: {
                height: 'auto',
                maxWidth: 100,
            },
        },
        notificationPopupDescription: {
            marginTop: 12,
            fontSize: 14,
        },
        tooltipOverride: {
            padding: 0,
            maxWidth: 'unset',
            width: 'min-content',
        },
    })
);

type DialogProps = {
    isLoading: boolean;
    isOpen: boolean;
    setClose: () => void;
    data: RecipientState;
    onDeleteRecipient: () => void;
};

export const ContainerRecipientDeleteConfirm: FunctionComponent<DialogProps> = (props) => {
    const { isLoading, isOpen, setClose, data, onDeleteRecipient } = props;

    const getDeleteMessage = (recipient: RecipientState): string => {
        if (recipient.phone && recipient.phone.length > 0)
            return `Удалить получателя уведомлений с&nbsp;номером ${formatPhone(recipient.phone)}?`;
        if (recipient.email && recipient.email.length > 0)
            return `Удалить получателя уведомлений с&nbsp;электронным адресом ${recipient.email}?`;
        return `Удалить получателя уведомлений?`;
    };

    return (
        <Dialog open={isOpen} onClose={setClose} aria-labelledby="recipient-comfirm-delete-title">
            <DialogTitle id="recipient-comfirm-delete-title">
                <span
                    // eslint-disable-next-line react/no-danger
                    dangerouslySetInnerHTML={{
                        __html: convertHTMLEntity(getDeleteMessage(data)),
                    }}
                />
            </DialogTitle>
            <DialogActions>
                <Button
                    disabled={isLoading}
                    color="primary"
                    onClick={() => {
                        setClose();
                    }}
                >
                    Нет
                </Button>
                <Button
                    disabled={isLoading}
                    color="primary"
                    autoFocus
                    onClick={() => {
                        onDeleteRecipient();
                    }}
                >
                    <span>Да</span>
                    {isLoading && <CircularProgress style={{ marginLeft: 4 }} size={12} />}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

const cardMapDispatch = makeMapDispatch({
    updateRecipient: apiUpdateRecipient,
    deleteRecipient: apiDeleteRecipient,
});

type Props = {
    data: RecipientState;
    isOpen?: boolean;
    containerId: number | null;
    containerOrderType: number | null;
    setOpen?: () => void;
    onUpdate?(recipient: RecipientState[]): void;
    isSimpleCard?: boolean;
} & ReturnType<typeof cardMapDispatch>;

const ContainerNotificationCardComponent: FunctionComponent<Props> = (props) => {
    const { containerId, containerOrderType, isOpen, setOpen, data, onUpdate, isSimpleCard, deleteRecipient } = props;

    const classes = useStyles();
    const { handleApiError, showNotification } = useAlerts();

    const [isLoading, setLoading] = useState(false);
    const [isOpenDialog, setOpenDialog] = useState(false);
    const [tooltipIsOpen, setTooltipIsOpen] = useState(false);
    const [isCommentsStateChanged, setCommentsStateChanged] = useState(false);

    const onDeleteRecipient = (): void => {
        setLoading(true);

        deleteRecipient({ recipient_id: data.id, container_id: containerId })
            .then((response: Protocol.DeleteRecipientResponse) => {
                showNotification(
                    `Получатель уведомлений${data.phone ? ` с номером ${formatPhone(data.phone)}` : ''} удалён`,
                    {
                        variant: 'success',
                    }
                );
                setLoading(false);
                if (onUpdate !== undefined) onUpdate(response.items);
            })
            .catch(handleApiError)
            .finally(() => {
                setOpenDialog(false);
            });
    };

    const formMethods = useForm<NotificationSettingsFormData>({
        defaultValues: {
            use_template_with_comments: data.use_template_with_comments,
            events: notificationEntityIso.to(data.settings),
            document_types: notificationDocumentsIso.to(data.document_settings),
        },
    });

    return (
        <>
            <Card className={classes.card}>
                <ExpansionPanel expanded={isOpen} onChange={setOpen}>
                    {!isSimpleCard && (
                        <ExpansionPanelSummary
                            expandIcon={<ExpandMore />}
                            classes={{ root: classes.MuiAccordionSummaryRoot }}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                        >
                            <div className={classes.cardHeader}>
                                <div className={classes.contacts}>
                                    {data.email && (
                                        <div>
                                            <span>{data.email}</span>
                                        </div>
                                    )}

                                    {data.phone && (
                                        <div>
                                            <span>{formatPhone(data.phone)}</span>
                                        </div>
                                    )}
                                </div>
                                <div>
                                    <Button
                                        color="secondary"
                                        classes={{ root: classes.deleteButton }}
                                        onClick={(event) => {
                                            setOpenDialog(true);
                                            event.stopPropagation();
                                        }}
                                    >
                                        Удалить
                                    </Button>
                                </div>
                            </div>
                        </ExpansionPanelSummary>
                    )}

                    <ExpansionPanelDetails
                        classes={{
                            root: isSimpleCard
                                ? classes.MuiAccordionDetailsRootSimple
                                : classes.MuiAccordionDetailsRoot,
                        }}
                    >
                        {(isOpen || isSimpleCard) && (
                            <FormContext {...formMethods}>
                                <form className={classes.form}>
                                    {!isSimpleCard && (
                                        <div className={classes.telegram}>
                                            <TelegramBot link={data.telegram.url} size={146} isWithoutCardStyles />
                                        </div>
                                    )}

                                    <div className={classes.notificationContainer}>
                                        <ClickAwayListener onClickAway={() => setTooltipIsOpen(false)}>
                                            <div>
                                                <Tooltip
                                                    arrow
                                                    placement="right"
                                                    enterDelay={500}
                                                    leaveDelay={200}
                                                    classes={{ tooltip: classes.tooltipOverride }}
                                                    interactive
                                                    open={tooltipIsOpen}
                                                    title={
                                                        <div className={classes.notificationPopupContainer}>
                                                            <div className={classes.notificationPopupGallery}>
                                                                <img
                                                                    className={classes.notificationPopupImage}
                                                                    src={NotificationListImg}
                                                                    alt="Расположение поля для комментария в списке заказов"
                                                                />
                                                                <img
                                                                    className={classes.notificationPopupImage}
                                                                    src={NotificationContainerImg}
                                                                    alt="Расположение поля для комментария в карточке контейнера"
                                                                />
                                                            </div>
                                                            <div className={classes.notificationPopupDescription}>
                                                                Заметку можно добавить в карточке заказа и на странице
                                                                контейнера
                                                            </div>
                                                        </div>
                                                    }
                                                >
                                                    <div className={classes.notificationMainWrapper}>
                                                        <FormControlLabel
                                                            control={
                                                                <Controller
                                                                    name="use_template_with_comments"
                                                                    type="checkbox"
                                                                    valueName="checked"
                                                                    defaultValue={data.use_template_with_comments}
                                                                    as={
                                                                        <Checkbox
                                                                            color="primary"
                                                                            onClick={() =>
                                                                                setCommentsStateChanged(true)
                                                                            }
                                                                        />
                                                                    }
                                                                />
                                                            }
                                                            label={
                                                                <div
                                                                    role="button"
                                                                    className={classes.notificationLabelContainer}
                                                                >
                                                                    <span className={classes.notificationLabel}>
                                                                        Добавить заметку по контейнеру/заказу в
                                                                        уведомлений
                                                                    </span>
                                                                </div>
                                                            }
                                                        />
                                                        <IconButton
                                                            onClick={() => setTooltipIsOpen(true)}
                                                            onTouchStart={() => setTooltipIsOpen(true)}
                                                            className={classes.notificationButtonHelp}
                                                        >
                                                            <HelpOutline className={classes.notificationIcon} />
                                                        </IconButton>
                                                    </div>
                                                </Tooltip>
                                            </div>
                                        </ClickAwayListener>
                                    </div>

                                    <div className={classes.statusSection}>
                                        <NotificationSettingsTable
                                            data={data}
                                            containerId={containerId}
                                            containerOrderType={containerOrderType}
                                            isCommentsStateChanged={isCommentsStateChanged}
                                            afterSave={(result) => {
                                                if (onUpdate !== undefined) onUpdate(result);
                                                setCommentsStateChanged(false);
                                            }}
                                        />
                                    </div>
                                </form>
                            </FormContext>
                        )}
                    </ExpansionPanelDetails>
                </ExpansionPanel>
            </Card>

            <ContainerRecipientDeleteConfirm
                isLoading={isLoading}
                isOpen={isOpenDialog}
                data={data}
                onDeleteRecipient={onDeleteRecipient}
                setClose={() => {
                    setOpenDialog(false);
                }}
            />
        </>
    );
};

export const ContainerNotificationCard = pipe(ContainerNotificationCardComponent, connect(null, cardMapDispatch));

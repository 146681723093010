import { Reducer } from 'redux';

import { insertAt } from 'fp-ts/Record';
import { pipe } from 'fp-ts/function';

import { PagesActionTypes, PagesState } from './types';

const initialState: PagesState = {};

export const pagesReducer: Reducer<PagesState, PagesActionTypes> = (state = initialState, action) => {
    switch (action.type) {
        case 'REMEMBER_PAGE':
            return pipe(state, insertAt(action.payload.url, action.payload.page));
        case 'FLUSH_PAGES':
            return {};
        default:
            return state;
    }
};

import React from 'react';

export const OrderStatusMessages = {
    IsProhibited: (
        <div className='TooltipMessages'>
            По данному заказу установлен запрет.<br />
            Причину установки запрета вы можете уточнить у менеджера.<br />
            Возможные причины:<br />
            <ul className='TooltipMessages-list'>
                <li>Установлен клиентский запрет;</li>
                <li>Отсутствуют оплаты по счетам.</li>
            </ul>
        </div>
    ),
    IsNotRealized: (
        <div className='TooltipMessages'>
            По данному заказу поставщик не выполнил процедуру релиза.<br />
            Причину отсутствия релиза вы можете уточнить у поставщика.<br />
            Возможные причины:<br />
            <ul className='TooltipMessages-list'>
                <li>Нет оплаты локальных от поставщика;</li>
                <li>Нет гарантийного письма от поставщика.</li>
            </ul>
        </div>
    ),
};

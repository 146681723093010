import { Theme } from '@material-ui/core';
import { createStyles, StyleRules } from '@material-ui/styles';

// no idea how to extract default container gutter
const containerGutterMobile = 16;

export const styles = (theme: Theme): StyleRules =>
    createStyles({
        toolBar: {
            display: 'grid',
            gridTemplateAreas: '"logo navigation notifications about profile logout"',
            gridTemplateColumns: 'auto 1fr auto auto auto auto',
            overflow: 'hidden',
            paddingTop: 24,
            paddingBottom: 24,
            [theme.breakpoints.down('md')]: {
                gridTemplateAreas: '"logo notifications about profile logout" "navigation navigation navigation navigation navigation"',
                gridTemplateRows: 'auto auto',
                gridTemplateColumns: '1fr auto auto auto auto',
                gridColumnGap: 8,
                paddingBottom: 0,
                paddingTop: 12,
            },
            [theme.breakpoints.down('sm')]: {
                gridTemplateRows: '40px auto',
            },
        },
        navLink: {
            fontWeight: 500,
            letterSpacing: '0.15px',
            fontSize: '20px',
            lineHeight: 1.15,
            color: 'white',
            textDecoration: 'none',
            '&:hover': {
                textDecoration: 'underline',
            },
            [theme.breakpoints.down('md')]: {
                fontSize: '16px',
            },
            [theme.breakpoints.down('sm')]: {
                fontSize: '14px',
            },
        },
        logo: {
            position: 'relative',
            gridArea: 'logo',
            display: 'flex',
            justifyContent: 'space-between',
            marginRight: 98,
            [theme.breakpoints.down('xl')]: {
                marginRight: 72,
            },
            [theme.breakpoints.down('lg')]: {
                marginRight: 32,
            },
            [theme.breakpoints.down('md')]: {
                marginRight: 0,
                display: 'flex',
                alignItems: 'center',
            },
        },
        logoImage: {
            [theme.breakpoints.down('md')]: {
                maxWidth: 110,
            },
        },
        userName: {
            fontSize: '20px',
            fontWeight: 500,
            lineHeight: 1,
            marginRight: 16,
            [theme.breakpoints.down('lg')]: {
                display: 'none',
            },
        },
        profileButtonWrapper: {
            alignItems: 'center',
            display: 'flex',
            gridArea: 'profile',
        },
        logoutButtonWrapper: {
            alignItems: 'center',
            display: 'flex',
            gridArea: 'logout',
        },
        profileButton: {
            position: 'relative',
        },
        aboutButtonWrapper: {
            alignItems: 'center',
            display: 'flex',
            gridArea: 'about',
        },
        aboutButton: {
            position: 'relative',
        },
        notificationsCount: {
            fontSize: 12,
            backgroundColor: '#F44336',
            borderRadius: 50,
            padding: 6,
            lineHeight: '7px',
            marginLeft: 8,
            [theme.breakpoints.down('sm')]: {
                fontSize: 10,
                padding: 4,
            },
        },
        navigation: {
            position: 'relative',
            display: 'flex',
            alignItems: 'center',
            flexGrow: 1,
            gridArea: 'navigation',
            [theme.breakpoints.down('md')]: {
                borderTop: '1px solid #265886',
                gridRow: 2,
                gridColumn: '1 / -1',
                marginTop: 8,
            },
            [theme.breakpoints.down('sm')]: {
                marginLeft: -containerGutterMobile,
                marginRight: -containerGutterMobile,
            },
        },
        navigationList: {
            margin: 0,
            padding: 0,
            listStyle: 'none',
            display: 'flex',
            maxWidth: '100%',
            [theme.breakpoints.down('md')]: {
                overflowX: 'auto',
                padding: '12px 0',
            },
        },
        navigationItem: {
            alignItems: 'center',
            display: 'inline-flex',
            marginRight: 32,
            [theme.breakpoints.down('xl')]: {
                marginRight: 24,
            },
            [theme.breakpoints.down('md')]: {
                marginRight: 16,
                whiteSpace: 'nowrap',
                '&:first-child': {
                    paddingLeft: containerGutterMobile,
                },
                '&:last-child': {
                    paddingRight: containerGutterMobile,
                },
            },
        },
        profilePopupMenu: {
            display: 'flex',
            alignItems: 'center',
            textDecoration: 'none',
            color: 'inherit',
        },
        button: {
            [theme.breakpoints.down('md')]: {
                padding: 2,
            },
        },
        accountCircleIcon: {
            height: 32,
            width: 32,
            [theme.breakpoints.down('md')]: {
                height: 24,
                width: 24,
            },
            [theme.breakpoints.down('sm')]: {
                height: 20,
                width: 20,
            },
        },
        helpIcon: {
            height: 32,
            width: 32,
            [theme.breakpoints.down('md')]: {
                height: 24,
                width: 24,
            },
            [theme.breakpoints.down('sm')]: {
                height: 20,
                width: 20,
            },
        },
        exitIcon: {
            height: 32,
            width: 32,
            [theme.breakpoints.down('md')]: {
                height: 24,
                width: 24,
            },
            [theme.breakpoints.down('sm')]: {
                height: 20,
                width: 20,
            },
        },
        appBar: {
            backgroundColor: '#204c74',
        },
    });

import { Theme } from '@material-ui/core';
import { createStyles, StyleRules } from '@material-ui/styles';

export const styles = (theme: Theme): StyleRules =>
    createStyles({
        root: {
            backgroundColor: theme.palette.primary.dark,
            minHeight: '100vh',
            boxSizing: 'border-box',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            paddingTop: 128,
            paddingBottom: 128,
            [theme.breakpoints.down('md')]: {
                padding: '72px 24px',
            },
            [theme.breakpoints.down('sm')]: {
                padding: '56px 32px',
            },
        },
        cardsWrapper: {
            display: 'flex',
            margin: '0 auto',
            justifyContent: 'space-between',

            [theme.breakpoints.down('md')]: {
                flexDirection: 'column',
            },
        },
        card: {
            textAlign: 'left',
            width: 400,
            padding: 32,
            [theme.breakpoints.down('md')]: {
                padding: 24,
            },
            [theme.breakpoints.down('sm')]: {
                padding: 16,
                width: '100%',
            },
        },
        darkCard: {
            marginRight: 32,
            backgroundColor: 'rgba(255, 255, 255, 0.1);',
            color: 'white',
            display: 'flex',
            flexDirection: 'column',

            [theme.breakpoints.down('md')]: {
                marginRight: 0,
                marginBottom: 16,
            },
        },
        darkCardText: {
            display: 'block',
            marginBottom: 32,
            color: '#C3CEDD',

            [theme.breakpoints.down('sm')]: {
                fontSize: 14,
            },
        },
        linkButtonWrapper: {
            marginTop: 'auto',
        },
        linkButton: {
            backgroundColor: 'white',
            color: '#0B2549',
            fontSize: 14,
            height: '48px',

            '&:hover, &:focus, &:active': {
                backgroundColor: '#E5E5E5',
                color: '#0B2549',
            },
        },
        heading: {
            marginBottom: 24,
        },
        darkCardHeading: {
            marginBottom: 16,
        },
        form: {
            display: 'flex',
            flexDirection: 'column',
        },
        formInput: {
            marginBottom: 24,
        },
        submitButton: {
            width: '100%',
            fontSize: 14,
            height: '48px',
        },
    });

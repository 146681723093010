import { Reducer } from 'redux';
import moment from 'moment';

import { fromTraversable, Lens } from 'monocle-ts';
import { array } from 'fp-ts/Array';
import { pipe } from 'fp-ts/function';

import { Bill, BillsActionTypes, BillsState } from './types';
import { MomentDateIso } from '../../utils/isomorph';

const responseLens = Lens.fromProp<BillsState>()('response');
const itemsLens = Lens.fromPath<BillsState>()(['response', 'items']);
const itemsTraversal = fromTraversable(array)<Bill>();
const disputeLens = Lens.fromProp<Bill>()('dispute');
const customerCommentLens = Lens.fromProp<Bill>()('customer_comment');

const initialState: BillsState = {
    response: {
        items: [],
        page: 1,
        page_size: 10,
        total: 0,
        totals: [],
        debt_control_options: {
            has_changes: false,
            items: [],
        },
        filters: {
            is_archived: [],
            payer: [],
            currency_id: [],
            is_payment_required: { total: 0 },
            is_payment_delayed: { total: 0 },
            has_debt: { total: 0 },
            has_overpayment: { total: 0 },
        },
    },
};

export const billsReducer: Reducer<BillsState, BillsActionTypes> = (state = initialState, action) => {
    switch (action.type) {
        case 'SET_BILLS_RESULT':
            return pipe(state, responseLens.set(action.payload));
        case 'RESET_BILLS_RESULT':
            return pipe(state, responseLens.set(initialState.response));
        case 'UPDATE_BILLS_DISPUTE': {
            const disputeFocus = itemsLens
                .composeTraversal(itemsTraversal)
                .filter((item) => item.id === action.payload.data.billId)
                .composeLens(disputeLens);

            if (action.payload.kind === 'update') {
                // NOTE: stupid TS
                const comment = action.payload.data.text;
                return pipe(
                    state,
                    disputeFocus.modify((dispute) => ({
                        is_opened: true,
                        is_prohibited: dispute.is_prohibited,
                        opened_at: MomentDateIso.to(moment()),
                        comment,
                    }))
                );
            }

            return pipe(
                state,
                disputeFocus.set({
                    is_opened: false,
                    is_prohibited: false,
                    opened_at: null,
                    comment: null,
                })
            );
        }
        case 'UPDATE_BILLS_CUSTOMER_COMMENT': {
            return pipe(
                state,
                itemsLens
                    .composeTraversal(itemsTraversal)
                    .filter((item) => item.id === action.payload.billId)
                    .composeLens(customerCommentLens)
                    .set(action.payload.comment)
            );
        }
        default:
            return state;
    }
};

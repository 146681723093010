import React, { FunctionComponent } from 'react';

import { Close } from '@material-ui/icons';
import { Card, IconButton } from '@material-ui/core';

import { EmployeeForm, EmployeeFormKind } from '../EmployeeForm/EmployeeForm';

import styles from './NewEmployee.module.scss';

type NewEmployeeProps = {
    onClose?(): void;
};
export const NewEmployee: FunctionComponent<NewEmployeeProps> = (props) => {
    const { onClose } = props;

    return (
        <Card>
            <div className={styles.header}>
                <span className={styles.heading}>Новый сотрудник</span>

                <IconButton onClick={onClose}>
                    <Close />
                </IconButton>
            </div>

            <div className={styles.body}>
                <EmployeeForm mode={{ kind: EmployeeFormKind.create }} onClose={onClose} />
            </div>
        </Card>
    );
};

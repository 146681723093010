import { ADD_DOCUMENT, REMOVE_DOCUMENT, DocumentsActionTypes, DocumentState } from './types';

export const addDocument = (data: DocumentState): DocumentsActionTypes => ({
    type: ADD_DOCUMENT,
    data,
});

export const removeDocument = (key: string): DocumentsActionTypes => ({
    type: REMOVE_DOCUMENT,
    key,
});

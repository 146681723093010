import { ContainerStatus, ManagerGroup } from '../orders/types';

type BillTypeId =
    | 'all_bill_invoices'
    | 'all_bill_invoices_and_reports'
    | 'all_bill_reports'
    | 'bill'
    | 'bill_and_all_documents'
    | 'bill_and_invoices'
    | 'bill_and_reports'
    | 'event'
    | 'invoice'
    | 'invoice_and_report'
    | 'print_form_type'
    | 'service_acceptance_report'
    | 'task';
type CriticalEventTypeId = 'attention_required' | 'regular' | 'very_important';
type FeedItemGroupBillId = 'added' | 'changed' | 'removed';

type TypeItem<T> = { id: T; name: string };
export type NotificationItem = {
    id: number;
    is_hidden: boolean;
};

export type FeedItemGroupBillOrInvoice = {
    id: number;
    came_at: Time.RFC3339;
    notification: NotificationItem;
    type: TypeItem<BillTypeId>
    app_documents_url: string;
    number: string;
    placed_at: Time.RFC3339;
    change_state_type: TypeItem<FeedItemGroupBillId>;
};

export type FeedItemGroupBillAndInvoice = Omit<FeedItemGroupBillOrInvoice, 'change_state_type'>;

export type FeedItemGroupPrintFormTypes = {
    id: number;
    came_at: Time.RFC3339;
    notifications: NotificationItem[];
    type: TypeItem<BillTypeId>;
    app_documents_url: string;
    name: string;
};

export type FeedItemGroupCriticalEvent = {
    id: number;
    came_at: Time.RFC3339;
    notifications: NotificationItem[];
    message: string;
    critical_event_type: TypeItem<CriticalEventTypeId>;
    callback_required: boolean;
};

export type FeedItemOrderGroupEvent = {
    id: number;
    came_at: Time.RFC3339;
    notifications: NotificationItem[];
    name: string;
    value: {
        string?: string;
        date?: Time.Date;
        number?: number;
    };
    hash: string;
};

type FeedItemGroupContainerEventData = {
    id: number;
    name: string;
    value: string | null;
    status_info: ContainerStatus;
};

type FeedItemGroupContainerEvent = {
    hash: string;
    data: FeedItemGroupContainerEventData;
};

export type FeedItemGroupContainer = {
    id: number;
    number: string;
    type: string;
    size: string | null;
    comment: string | null;
    event_details: FeedItemGroupContainerEvent[];
};

export type FeedItemOrderGroup = {
    events: FeedItemOrderGroupEvent[];
    containers: FeedItemGroupContainer[];
    critical_events: FeedItemGroupCriticalEvent[];
    print_form_types: FeedItemGroupPrintFormTypes[];
    bills: FeedItemGroupBillOrInvoice[];
    invoices: FeedItemGroupBillOrInvoice[];
    bill_invoices: FeedItemGroupBillAndInvoice[];
    manager_groups: ManagerGroup[];
};

export type FeedItemOrder = {
    id: number;
    number: string;
    comment: string | null;
    groups: FeedItemOrderGroup[];
};

export type MutualSettlementsReport = {
    title: string | null;
    content: string;
};

export type FeedItem = {
    date: Time.Date;
    orders: FeedItemOrder[];
    mutual_settlements_report: MutualSettlementsReport | null;
};

export type FeedState = {
    stats: number;
    items: FeedItem[];
    earliestShownDate: Time.RFC3339 | null;
};

export const SET_FEED_STATS = 'SET_FEED_STATS';
export const SET_FEED_DATA = 'SET_FEED_DATA';

interface SetFeedStatsAction {
    type: typeof SET_FEED_STATS;
    data: number;
}

interface SetFeedDataAction {
    type: typeof SET_FEED_DATA;
    data: Protocol.FeedListResponse & { isResetData?: boolean };
}

export type FeedActionTypes = SetFeedStatsAction | SetFeedDataAction;

import React, { FunctionComponent, ReactNode, useState } from 'react';
import clsx from 'clsx';

import ReactDrawer from '@material-ui/core/Drawer';
import Button, { ButtonProps } from '@material-ui/core/Button';
import { withStyles } from '@material-ui/styles';
import { WithStyles } from '@material-ui/core';

import { styles } from './styles';

type button = ButtonProps & { text: string };
type anchor = 'right' | 'left';
type DrawerProps = { anchor?: anchor, button: button, children: ReactNode; isLarge?: boolean; } & WithStyles<typeof styles>;
const Drawer: FunctionComponent<DrawerProps> = props => {
    const { button, classes, isLarge = false, anchor = 'right' } = props;
    const [ open, setOpen ] = useState(false);

    const toggleDrawer = (isOpen: boolean) => () => {
        document.body.dataset.drawerOpen = String(isOpen);
        setOpen(isOpen);
    };

    return (
        <div>
            <Button onClick={toggleDrawer(!open)} {...button}>
                {button.text}
            </Button>
            <ReactDrawer anchor={anchor} open={open} onClose={toggleDrawer(false)}>
                <div
                    className={clsx([
                        classes.list,
                        isLarge && classes.listLarge,
                    ])}
                    role="presentation"
                >
                    { props.children }
                </div>
            </ReactDrawer>
        </div>
    );
};

const component = withStyles(styles)(Drawer);
export { component as Drawer };

import { Reducer } from 'redux';
import moment from 'moment';

import { Lens, fromTraversable } from 'monocle-ts';
import { array } from 'fp-ts/Array';
import { pipe } from 'fp-ts/function';

import { SearchActionTypes, SearchSectionConfig, SearchState } from './types';
import { Bill } from '../bills/types';

import { MomentDateIso } from '../../utils/isomorph';
import { tap } from '../../utils/types';

import { EventBus } from '../../hooks/bus';

declare module '../../hooks/bus' {
    interface BusEvents {
        onConfigPageChange: SearchSectionConfig[];
        onConfigPageSizeChange: SearchSectionConfig[];
    }
}

export const searchConfigConst = (): SearchSectionConfig[] => [
    {
        type: 'bills',
        page: 1,
        page_size: '10',
    },
    {
        type: 'orders',
        page: 1,
        page_size: '10',
    },
    {
        type: 'payments',
        page: 1,
        page_size: '10',
    },
];

const initialState: SearchState = {
    query: null,
    config: searchConfigConst(),
    result: {
        bills: {
            items: [],
            items_total: 0,
            page_current: 1,
            page_last: 1,
            page_size: 0,
        },
        orders: {
            items: [],
            items_total: 0,
            page_current: 1,
            page_last: 1,
            page_size: 0,
        },
        payments: {
            items: [],
            items_total: 0,
            page_current: 1,
            page_last: 1,
            page_size: 0,
        },
    },
};

const queryLens = Lens.fromProp<SearchState>()('query');
const resultLens = Lens.fromProp<SearchState>()('result');
const configLens = Lens.fromProp<SearchState>()('config');

const configTraversal = fromTraversable(array)<SearchSectionConfig>();
const billsItemsTraversal = fromTraversable(array)<Bill>();

const disputeLens = Lens.fromProp<Bill>()('dispute');
const billsItemsLens = Lens.fromPath<SearchState>()(['result', 'bills', 'items']);
const customerCommentLens = Lens.fromProp<Bill>()('customer_comment');

const pageLens = Lens.fromProp<SearchSectionConfig>()('page');
const pageAndSizeLens = Lens.fromProps<SearchSectionConfig>()(['page', 'page_size']);

export const searchReducer: Reducer<SearchState, SearchActionTypes> = (state = initialState, action) => {
    switch (action.type) {
        case 'SET_SEARCH_RESULT':
            return pipe(state, resultLens.set(action.payload.result));
        case 'REMEMBER_SEARCH_QUERY':
            return pipe(
                state,
                queryLens.set(action.payload),
                configLens.composeTraversal(configTraversal).composeLens(pageLens).set(1)
            );
        case 'RESET_SEARCH_RESULT':
            return initialState;
        case 'UPDATE_SEARCH_DISPUTE': {
            const disputeFocus = billsItemsLens
                .composeTraversal(billsItemsTraversal)
                .filter((item) => item.id === action.payload.data.billId)
                .composeLens(disputeLens);

            if (action.payload.kind === 'update') {
                // NOTE: stupid TS
                const comment = action.payload.data.text;
                return pipe(
                    state,
                    disputeFocus.modify((dispute) => ({
                        is_opened: true,
                        is_prohibited: dispute.is_prohibited,
                        opened_at: MomentDateIso.to(moment()),
                        comment,
                    }))
                );
            }

            return pipe(
                state,
                disputeFocus.set({
                    is_opened: false,
                    is_prohibited: false,
                    opened_at: null,
                    comment: null,
                })
            );
        }
        case 'UPDATE_SEARCH_BILLS_CUSTOMER_COMMENT':
            return pipe(
                state,
                billsItemsLens
                    .composeTraversal(billsItemsTraversal)
                    .filter((item) => item.id === action.payload.billId)
                    .composeLens(customerCommentLens)
                    .set(action.payload.comment)
            );
        case 'SET_CONFIG':
            return pipe(state, configLens.set(action.payload));
        case 'SET_CONFIG_PAGE':
            return pipe(
                state,
                configLens
                    .composeTraversal(configTraversal)
                    .filter((cfg) => cfg.type === action.payload.type)
                    .composeLens(pageLens)
                    .set(action.payload.page),
                tap(({ config }) => EventBus.emit('onConfigPageChange', config))
            );
        case 'SET_CONFIG_PAGE_SIZE':
            return pipe(
                state,
                configLens
                    .composeTraversal(configTraversal)
                    .filter((cfg) => cfg.type === action.payload.type)
                    .composeLens(pageAndSizeLens)
                    .set({ page_size: action.payload.pageSize, page: 1 }),
                tap(({ config }) => EventBus.emit('onConfigPageSizeChange', config))
            );
        default:
            return state;
    }
};

import React, { FunctionComponent, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import clsx from 'clsx';

import { pipe } from 'fp-ts/function';
import * as A from 'fp-ts/Array';
import * as O from 'fp-ts/Option';

import { ExpandMore, ExpandLess, VisibilityOff, Phone, MoreVert, Visibility, Call } from '@material-ui/icons';
import {
    Menu,
    Button,
    MenuItem,
    IconButton,
    ExpansionPanel,
    ExpansionPanelDetails,
    ExpansionPanelSummary,
} from '@material-ui/core';

import { ManagerGroup } from '../../store/orders/types';
import { FeedItemGroupContainer, FeedItemOrder, FeedItemOrderGroup, NotificationItem } from '../../store/feed/types';

import { ROUTES } from '../../utils/location';
import { pluralize } from '../../utils/strings';

import styles from './NotificationCard.module.scss';

type NotificationCardProps = {
    eventsText: React.ReactNode;
    isImportant?: boolean;
    isCallbackRequired?: boolean;
    notifications: NotificationItem[];
    order: FeedItemOrder;
    group: FeedItemOrderGroup;
    openedAccordionHash: string | null;
    setOpenedAccordionHash(hash: string | null): void;
    updateVisibility(criticalEventIds: number[], isHidden: boolean): void;
    handleModalOpen(managers: ManagerGroup[], orderNumber: string): void;
};

export const NotificationCard: FunctionComponent<NotificationCardProps> = (props) => {
    const {
        eventsText,
        isImportant,
        isCallbackRequired,
        notifications,
        order,
        group,
        setOpenedAccordionHash,
        openedAccordionHash,
        updateVisibility,
        handleModalOpen,
    } = props;

    const menuRef = useRef<Element>(null);

    const [isMenuShown, setMenuShown] = useState(false);

    const makeContainerUrl = (groupContainer: FeedItemGroupContainer): string =>
        `${ROUTES.ORDERS}/${groupContainer.id}`;

    const foldContainerGroupName = (container: FeedItemGroupContainer): string => {
        const containerSize = container.size && container.size !== '0' ? `${container.size}ft` : container.type;

        if(container.number) return `№${container.number}${containerSize && ` (${containerSize})`}` 

        return containerSize && `${containerSize}`
    };

    const hash = `${order.id}-${notifications.length > 0 ? notifications[0].id : order.number}`;
    const isHidden = notifications.some((notification) => notification.is_hidden);
    const isContainersOpened = openedAccordionHash === hash;

    const firstContainer = pipe(
        group.containers,
        A.head,
        O.getOrElseW(() => null)
    );

    const containersLabel =
        group.containers.length > 1
            ? `${group.containers.length} ${pluralize(group.containers.length, [
                  'контейнер',
                  'контейнера',
                  'контейнеров',
              ])}`
            : pipe(
                  O.fromNullable(firstContainer),
                  O.fold(
                      () => null,
                      (container) => (
                          <Link to={makeContainerUrl(container)} className={styles.link}>
                              Контейнер {foldContainerGroupName(container)}
                          </Link>
                      )
                  )
              );

    const foldElement = (condition: boolean, element: React.ReactElement): JSX.Element => {
        if (condition) return element;
        return <span>&nbsp;</span>;
    };

    return (
        <ExpansionPanel
            className={clsx(isContainersOpened && styles.expanded, styles.root)}
            expanded={isContainersOpened}
        >
            <ExpansionPanelSummary
                onClick={() => {
                    if (group.containers.length > 1) {
                        const currentHash = isContainersOpened ? null : hash;
                        setOpenedAccordionHash(currentHash);
                    }
                }}
                classes={{
                    root: clsx(styles.summaryRoot, group.containers.length > 1 && styles.expandable),
                    content: styles.summaryContent,
                }}
            >
                <div>
                    <div className={styles.numberWrapper}>
                        <span className={styles.number}>Заказ №{order.number}</span>

                        {containersLabel !== null && <span className={styles.numberSeparator}>&nbsp;•&nbsp;</span>}
                        {containersLabel}
                    </div>

                    <div className={styles.event}>{eventsText}</div>
                </div>

                <div className={styles.controls}>
                    {foldElement(
                        group.manager_groups.length > 0 && !!isImportant && !!isCallbackRequired,
                        <div className={styles.controlWrapper}>
                            <Button
                                variant="text"
                                startIcon={<Phone />}
                                color="primary"
                                onClick={(e) => {
                                    e.stopPropagation();
                                    handleModalOpen(group.manager_groups, order.number);
                                }}
                                className={styles.callButton}
                            >
                                Позвонить менеджеру
                            </Button>
                        </div>
                    )}

                    {foldElement(
                        group.containers.length > 1,
                        <div className={clsx(styles.expandTrigger, styles.controlWrapper)}>
                            {isContainersOpened ? <ExpandLess /> : <ExpandMore />}
                        </div>
                    )}

                    <div className={styles.controlWrapper}>
                        <IconButton
                            className={styles.iconButton}
                            size="small"
                            onClick={(e) => {
                                if (notifications.length === 0) return;

                                e.stopPropagation();
                                updateVisibility(
                                    notifications.map((notification) => notification.id),
                                    !isHidden
                                );
                            }}
                        >
                            {!isHidden ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                    </div>

                    {foldElement(
                        group.manager_groups.length > 0,
                        <div className={styles.controlWrapper}>
                            <IconButton
                                className={styles.iconButton}
                                size="small"
                                buttonRef={menuRef}
                                onClick={(e) => {
                                    e.stopPropagation();
                                    setMenuShown(true);
                                }}
                            >
                                <MoreVert />
                            </IconButton>

                            <Menu
                                anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
                                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                                anchorEl={menuRef.current}
                                open={isMenuShown}
                                onClose={() => {
                                    setMenuShown(false);
                                }}
                            >
                                <MenuItem
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        handleModalOpen(group.manager_groups, order.number);
                                    }}
                                >
                                    <Call className={styles.menuIcon} />
                                    Позвонить менеджеру
                                </MenuItem>
                            </Menu>
                        </div>
                    )}
                </div>
            </ExpansionPanelSummary>

            {group.containers.length > 1 && (
                <ExpansionPanelDetails className={styles.details}>
                    <div className={styles.containersWrapper}>
                        <div className={styles.containers}>
                            {group.containers.map((groupContainer, index) => (
                                <Link
                                    to={makeContainerUrl(groupContainer)}
                                    className={clsx(styles.link, styles.containerLink)}
                                    key={index}
                                >
                                    {foldContainerGroupName(groupContainer)}
                                </Link>
                            ))}
                        </div>
                    </div>
                </ExpansionPanelDetails>
            )}
        </ExpansionPanel>
    );
};

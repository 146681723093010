import React, { FunctionComponent } from 'react';

type SearchHeaderProps = {};

const sortButtons = [
    { label: 'Дата документа' },
    { label: 'Плательщик' },
    { label: 'Сумма' },
    { label: 'Оплачено' },
    { label: 'Осталось' },
    { label: 'Сумма СФ' },
];

export const SearchHeader: FunctionComponent<SearchHeaderProps> = (props) => {
    return (
        <div className="BillsListHeaders BillsList__headers App__billsRow">
            {sortButtons.map((button, index) => (
                <div className="BillsListHeaders__button BillsListHeaders__button--noMargin" key={`sort-${index}`}>
                    {button.label}
                </div>
            ))}
        </div>
    );
};

import { Reducer } from 'redux';
import storage from 'redux-persist/lib/storage';
import { persistReducer } from 'redux-persist';

import moment from 'moment';

import { Lens } from 'monocle-ts';
import { pipe } from 'fp-ts/function';

import { SyncProblemsState, SyncProblemsActionTypes } from './types';

import { SafePersistConfig } from '../../utils/types';
import { MomentISO8601Iso } from '../../utils/isomorph';

const initialState: SyncProblemsState = {
    items: [],
    lastSyncTime: null,
    syncConfig: {
        checkInterval: 10 as Time.Seconds,
        fetchInterval: 5 as Time.Minutes,
    },
};

const itemsLens = Lens.fromProp<SyncProblemsState>()('items');
const lastSyncLens = Lens.fromProp<SyncProblemsState>()('lastSyncTime');

const baseReducer: Reducer<SyncProblemsState, SyncProblemsActionTypes> = (state = initialState, action) => {
    switch (action.type) {
        case 'SET_SYNC_PROBLEMS':
            return pipe(state, itemsLens.set(action.payload), lastSyncLens.set(MomentISO8601Iso.to(moment())));
        default:
            return state;
    }
};

const persistConfig: SafePersistConfig<SyncProblemsState> = {
    key: 'syncProblems',
    storage,
    whitelist: ['items', 'lastSyncTime'],
};

export const syncProblemsReducer = persistReducer(persistConfig, baseReducer);

import {
    LocalFiltersFlags,
    OrdersActionTypes,
    UpdateCommentPayload,
    SET_FILTERS_COUNTS,
    SET_FILTERS_FLAGS,
    SET_MINIFIED_VIEW,
    SET_ORDERS,
    SET_ORDERS_CONTAINER,
    FLUSH_ORDER_CACHE,
    UPDATE_COMMENT,
    SET_OPENED_CARD,
} from './types';
import { CountsList } from '../../shared/filterLogic';

export const setOrders = (payload: Protocol.OrderListResponse): OrdersActionTypes => ({
    type: SET_ORDERS,
    payload,
});

export const serOrdersContainer = (payload: Protocol.OrderContainerResponse): OrdersActionTypes => ({
    type: SET_ORDERS_CONTAINER,
    payload,
});

export const setFiltersFlags = (payload: LocalFiltersFlags): OrdersActionTypes => ({
    type: SET_FILTERS_FLAGS,
    payload,
});

export const setFiltersCounts = (payload: CountsList): OrdersActionTypes => ({
    type: SET_FILTERS_COUNTS,
    payload,
});

export const flushOrderCache = (): OrdersActionTypes => ({
    type: FLUSH_ORDER_CACHE,
});

export const updateComment = (payload: UpdateCommentPayload): OrdersActionTypes => ({
    type: UPDATE_COMMENT,
    payload,
});

export const setOpenedCardAction = (payload: number): OrdersActionTypes => ({
    type: SET_OPENED_CARD,
    payload,
});

export const setMinifiedViewAction = (payload: boolean): OrdersActionTypes => {
    return {
        type: SET_MINIFIED_VIEW,
        payload,
    };
};

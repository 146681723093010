import type { BooleanFilterType, InfoFilterType, SortingData, StringFilterType } from '../../utils/types';
import { UpdateBillsDisputeActionPayload } from '../../types/dispute';
import { UpdateBillsCustomerCommentPayload } from '../../types/comments';
import { PaymentHistoryFilterCurrency } from '../payment-history/types';

export type BillsSortBy = 'amount' | 'amount_debt' | 'amount_invoices' | 'amount_payments' | 'date' | 'payer';
export type BillsSortingData = SortingData<BillsSortBy>;

export type BillInvoice = {
    id: number;
    number: string;
    date: Time.Date;
    amount_rub: Kopecks;
    document_type_name: string;
};

export type BillDispute = {
    is_prohibited: boolean;
    is_opened: boolean;
    opened_at: Time.Date | null;
    comment: string | null;
};

export type Bill = {
    id: number;
    number: string;
    document_type_name: string;
    date: Time.Date;
    changed_at: Protocol.Types.ApiDatetime;
    is_archived: boolean;
    is_payment_delayed: boolean;
    has_debt: boolean;
    has_overpayment: boolean;
    payer: string;
    amount: Kopecks;
    amount_payments: Kopecks;
    amount_debt: Kopecks;
    amount_invoices_rub: Kopecks;
    orders_count: number;
    invoices: BillInvoice[];
    customer_comment: string | null;
    order_amount?: Kopecks;
    order_amount_payments?: Kopecks;
    order_amount_debt?: Kopecks;
    has_order_debt?: boolean;
    has_order_overpayment?: boolean;
    dispute: BillDispute;
    currency: {
        id: number;
        name: string;
    }
};

export type LightBill = Pick<Bill, 'id' | 'number' | 'date'>;

export type BillsListFilters = {
    is_archived: BooleanFilterType[];
    payer: StringFilterType[];
    currency_id: PaymentHistoryFilterCurrency[];
    is_payment_required: InfoFilterType;
    is_payment_delayed: InfoFilterType;
    has_debt: InfoFilterType;
    has_overpayment: InfoFilterType;
};

export type DebtControlOption = {
    name: string;
    is_changed: boolean;
    descriptions: string[];
};

export type BillsListDebtControlOptions = {
    has_changes: boolean;
    items: DebtControlOption[];
};

export type DebtInfoState = 'unknown' | 'default' | 'hasChanges';

export type BillsFiltersPayload = {
    order_id?: number;
    payer?: string[];
    currency_id?: number[];
    is_archived?: boolean;
    has_debt?: true;
    has_overpayment?: true;
    is_payment_delayed?: true;
    is_payment_required?: true;
};

export type BillsTotals = {
    count: number;
    amount: Kopecks;
    amount_payments: Kopecks;
    amount_debt: Kopecks;
    amount_delayed_debt: Kopecks;
    amount_invoices_rub: Kopecks;
    currency: {
        id: number;
        name: string;
    }

};

export type BillsPackageRequest = {
    bill_id: number;
    invoice_ids: number[];
    download_bill: boolean;
};

export type BillsListResponse = PaginateLikeListWithFilters<Bill, BillsListFilters> & {
    totals: BillsTotals[];
    order_totals?: BillsTotals[];
    order_number?: string;
    debt_control_options: BillsListDebtControlOptions;
};

/// State
export type BillsState = {
    response: BillsListResponse;
};

/// Actions
export const SET_BILLS_RESULT = 'SET_BILLS_RESULT';
export const RESET_BILLS_RESULT = 'RESET_BILLS_RESULT';
export const UPDATE_BILLS_DISPUTE = 'UPDATE_BILLS_DISPUTE';
export const UPDATE_BILLS_CUSTOMER_COMMENT = 'UPDATE_BILLS_CUSTOMER_COMMENT';

export type SetBillsResultAction = {
    type: typeof SET_BILLS_RESULT;
    payload: BillsListResponse;
};

export type ResetBillsResultAction = {
    type: typeof RESET_BILLS_RESULT;
};

export type UpdateBillsDisputeAction = {
    type: typeof UPDATE_BILLS_DISPUTE;
    payload: UpdateBillsDisputeActionPayload;
};

export type UpdateBillsCustomerCommentAction = {
    type: typeof UPDATE_BILLS_CUSTOMER_COMMENT;
    payload: UpdateBillsCustomerCommentPayload;
};

export type BillsActionTypes =
    | SetBillsResultAction
    | ResetBillsResultAction
    | UpdateBillsDisputeAction
    | UpdateBillsCustomerCommentAction;

import React, { FunctionComponent, createContext, useState } from 'react';
import { connect } from 'react-redux';

import { apiGetOrderListWithoutDispatch, makeMapDispatch } from '../store/dispatch';

import { BillsOrdersSearchResult } from '../components/BillsOrdersSearchResult';
import { OrderListItem } from '../store/orders/types';
import { LightBill } from '../store/bills/types';

type FindOrdersByBillSignature = (billId: number) => Promise<void>;

export type BillsSearchProviderContext = { findOrdersByBill: FindOrdersByBillSignature };

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
export const BillsSearchContext = createContext<BillsSearchProviderContext>(null!);

const mapDispatch = makeMapDispatch({ fetchOrders: apiGetOrderListWithoutDispatch });

const BillsSearchProvider: FunctionComponent<ReturnType<typeof mapDispatch>> = (props) => {
    const { fetchOrders, children } = props;

    const [loading, setLoading] = useState(false);
    const [currentBill, setCurrentBill] = useState<LightBill | null>(null);

    const [list, setList] = useState<OrderListItem[]>([]);
    const [paginator, setPaginator] = useState<PaginatorPayload>({
        page: 1,
        page_size: '50',
    });

    const findOrdersByBill: FindOrdersByBillSignature = async (billId, paginatorPayload?: PaginatorPayload) => {
        if (loading) return;

        setLoading(true);

        const paginatorData: PaginatorPayload = {
            page: paginatorPayload?.page || paginator.page,
            page_size: paginatorPayload?.page_size || paginator.page_size,
        };

        setPaginator(paginatorData);

        const { items, bill } = await fetchOrders({ bill_id: billId, ...paginatorData });
        setCurrentBill(bill);

        setList(items);
        setLoading(false);
    };

    return (
        <BillsSearchContext.Provider value={{ findOrdersByBill }}>
            {children}
            <BillsOrdersSearchResult bill={currentBill} list={list} loading={loading} paginator={paginator} />
        </BillsSearchContext.Provider>
    );
};

const EnchantedProvider = connect(null, mapDispatch)(BillsSearchProvider);
export { EnchantedProvider as BillsSearchProvider };

import React, { FunctionComponent, useMemo, useState } from 'react';

import { Badge, Checkbox, Chip, FormControlLabel, FormGroup } from '@material-ui/core';
import { Cancel as CancelIcon, ExpandMore as ExpandMoreIcon } from '@material-ui/icons';
import { createStyles, makeStyles } from '@material-ui/styles';

import { mapWithIndex } from 'fp-ts/Array';

import { PaymentHistoryFilterCurrency as FiltersApiCurrency } from '../store/payment-history/types';
import { CurrenciesPayloadType } from '../utils/types';

import { FilterTooltip, FilterTypes } from './FilterTooltip';

const useStyles = makeStyles(() => createStyles({ formCheckbox: { color: 'black' } }));

type ToggleCurrency = (currency: number, active: boolean) => void;
type CurrencyFilterViewBuilder = (
    labelClass: string,
    checkedByDefault: (currency: number) => boolean,
    toggleCurrency: ToggleCurrency
) => (items: FiltersApiCurrency[]) => JSX.Element[];
const currencyFilterViewBuilder: CurrencyFilterViewBuilder = (labelClass, checkedByDefault, toggleCurrency) =>
    mapWithIndex((index, currency: FiltersApiCurrency) => (
        <FormControlLabel
            key={`filter-${currency.id}-${index}`}
            label={`${currency.name} • ${currency.total}`}
            classes={{ label: labelClass }}
            control={<Checkbox value={currency.id} color="primary" />}
            checked={checkedByDefault(currency.id)}
            onChange={(_, checked) => toggleCurrency(currency.id, checked)}
        />
    ));

type CurrencyPickerProps = {
    currencies: CurrenciesPayloadType;
    disabled: boolean;
    setCurrencies: (currencies: CurrenciesPayloadType) => void;
    currenciesList: FiltersApiCurrency[];
    onDelete: () => void;
    onClose: () => void;
    onClick?: () => void;
};

export const CurrenciesPicker: FunctionComponent<CurrencyPickerProps> = (props) => {
    const { currencies, disabled, currenciesList, setCurrencies, onClick, onClose, onDelete } = props;

    const [opened, setOpened] = useState(false);
    const isCurrenciesFilterActive = useMemo(() => currencies.length > 0, [currencies]);

    const classes = useStyles();

    const foldCurrencies = useMemo(
        () =>
            currencyFilterViewBuilder(
                classes.formCheckbox,
                (currency) => currencies.includes(currency),
                (currency, active) => {
                    const includesCurrency = currencies.includes(currency);
                    if (active && !includesCurrency) setCurrencies([...currencies, currency]);
                    else if (!active && includesCurrency)
                        setCurrencies(currencies.filter((value) => value !== currency));
                }
            ),
        [classes, currencies, setCurrencies]
    );

    return (
        <FilterTooltip
            title={<FormGroup>{foldCurrencies(currenciesList)}</FormGroup>}
            type={FilterTypes.Currency}
            openedFilter={opened ? FilterTypes.Currency : null}
            hideOpenedFilter={() => {
                setOpened(false);
                onClose();
            }}
        >
            <Badge color="error" badgeContent={currencies.length}>
                <Chip
                    disabled={currenciesList.length < 2 || disabled}
                    color="primary"
                    label="Валюта"
                    variant={opened || isCurrenciesFilterActive ? 'default' : 'outlined'}
                    deleteIcon={isCurrenciesFilterActive ? <CancelIcon /> : <ExpandMoreIcon />}
                    onClick={() => {
                        setOpened(!opened);
                        if (onClick instanceof Function) onClick();
                    }}
                    onDelete={() => {
                        if (opened || currencies.length > 0) {
                            setOpened(false);
                            onDelete();
                        } else setOpened(true);
                    }}
                />
            </Badge>
        </FilterTooltip>
    );
};

import React, { FunctionComponent, useCallback, useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import moment from 'moment';

import { IconButton } from '@material-ui/core';
import { Cancel as CancelIcon, Sort } from '@material-ui/icons';

import { fold, fromNullable, getOrElse, isSome, map, Option, some } from 'fp-ts/Option';
import { flow, pipe } from 'fp-ts/function';

import type {
    Bill,
    BillsFiltersPayload,
    BillsListDebtControlOptions,
    DebtControlOption,
    DebtInfoState,
} from '../../store/bills/types';
import { apiDebtControlOptionsMarkAsViewed, apiGetBillList, makeMapDispatch } from '../../store/dispatch';
import {
    addSeenBill,
    setBillsListPageSize,
    setCurrentLastUserSeenAt,
    setPreviousUserLastSeenAt,
} from '../../store/settings/actions';
import { makeMapState } from '../../store/root';

import { DocumentsStatus } from '../../types/documents';

import { foldPageCount, foldView, scrollToTop } from '../../utils/view';
import { DocumentsStatusIso } from '../../utils/isomorph';
import { debounceWrapper } from '../../utils/debounce';
import { castToNullable } from '../../utils/types';
import { ROUTES } from '../../utils/location';

import { ApiError } from '../../api/client/errors';

import { useDocumentsLoader } from '../../hooks/documentsLoader';
import { useBusEffect } from '../../hooks/bus';
import { useSearch } from '../../hooks/search';
import { useAlerts } from '../../hooks/noty';
import { useOnce } from '../../hooks/lifecycle';
import { useUrl } from '../../hooks/url';

import { BillsSearchProvider } from '../../providers/BillsSearchProvider';
import { CommentsProvider } from '../../providers/CommentListProvider';
import { SortProvider } from '../../providers/SortProvider';

import { LoadingFixedCenter } from '../../components/LoadingFixedCenter';
import { DebtInfoDrawer } from '../../components/DebtInfoDrawer';
import { BillsFilters } from '../../components/BillsFilters/BillsFilters';
import { FetchError } from '../../components/FetchError';
import { BillsList } from '../../components/BillsList/BillsList';
import { Drawer } from '../../components/Drawer/Drawer';

import './Bills.scss';

const constDebtOptions = (): DebtControlOption[] => [];
const foldDebtInfoState = (dco: Option<BillsListDebtControlOptions>): DebtInfoState => {
    if (isSome(dco)) return dco.value.has_changes ? 'hasChanges' : 'default';
    return 'unknown';
};

const getDocumentStatusFromPayload = (filterValues: BillsFiltersPayload): DocumentsStatus =>
    DocumentsStatusIso.to(castToNullable(filterValues.is_archived));

const mapState = makeMapState((state) => ({
    sorting: state.sorts.bills,
    previousLastUserSeenAt: state.settings.previousLastUserSeenAt,
    filtersState: state.bills.response.filters,
    list: state.bills.response.items,
    orderNumber: state.bills.response.order_number,
    totals: state.bills.response.totals,
    orderTotals: state.bills.response.order_totals,
    debtControlOptions: some(state.bills.response.debt_control_options),
    pageSize: state.settings.pageSizeConfig.bills,
    paginator: {
        total: state.bills.response.total,
        page: state.bills.response.page,
        page_size: state.bills.response.page_size,
    },
    documentsStorage: state.documents.items,
}));

const mapDispatch = makeMapDispatch({
    getBills: apiGetBillList,
    setUserPreviousLastSeen: setPreviousUserLastSeenAt,
    setUserCurrentLastSeen: setCurrentLastUserSeenAt,
    addBillToStorage: addSeenBill,
    markAsViewed: apiDebtControlOptionsMarkAsViewed,
    changePageSize: setBillsListPageSize,
});

type BillsProps = ReturnType<typeof mapState> & ReturnType<typeof mapDispatch>;
const Bills: FunctionComponent<BillsProps> = (props) => {
    const { orderId: orderIdParam } = useParams();

    const orderId = pipe(
        fromNullable(orderIdParam),
        fold(
            () => null,
            (v) => Number(v)
        )
    );

    const {
        sorting,
        getBills,
        setUserPreviousLastSeen,
        setUserCurrentLastSeen,
        previousLastUserSeenAt,
        addBillToStorage,
        markAsViewed,
        filtersState,
        list,
        orderNumber,
        totals,
        orderTotals,
        paginator,
        debtControlOptions,
        pageSize,
        changePageSize,
        documentsStorage,
    } = props;

    const { handleApiError } = useAlerts();
    const { generateSearchUrl, setSearchState } = useSearch();
    const { downloadBillsListDocument } = useDocumentsLoader();
    const { paginationPageParamValue, initialIsArchivedValue, changePaginationPageUrlParam } = useUrl();

    const initialIsArchived = initialIsArchivedValue(null);

    const [error, setError] = useState<ApiError | null>(null);
    const [loading, setLoading] = useState(false);
    const [currentPage, setCurrentPage] = useState(paginationPageParamValue);
    const [isInitialized, setInitialized] = useState(false);
    const [filtersPayload, setFiltersPayload] = useState<BillsFiltersPayload>({
        is_archived: initialIsArchived !== null ? initialIsArchived : undefined,
        ...(orderId ? { order_id: orderId } : {}),
    });
    const [documentStatus, setDocumentStatus] = useState(getDocumentStatusFromPayload(filtersPayload));
    const [showDebtInfoDrawer, setShowDebtInfoDrawer] = useState(false);

    /* Необходимо для того, чтобы убрать счета, которые были помечены с помощью fakeSeenBillsListIds в BillsList */
    const [billsForceSeenIds, setBillsForceSeenIds] = useState<number[]>([]);

    const addBillsToSeenStorage = (items: Bill[]): void => items.forEach((item) => addBillToStorage(item.id));
    const addBillsToForceSeen = (items: Bill[]): void => {
        items.forEach((item) => {
            setBillsForceSeenIds((billsIds) => {
                addBillsToSeenStorage([item]);
                if (!billsIds.includes(item.id)) {
                    return [...billsIds, item.id];
                }

                return billsIds;
            });
        });
    };

    const pageCount = useMemo(() => foldPageCount(paginator), [paginator]);
    const totalsState = useMemo(() => (orderId !== null && orderTotals !== undefined ? orderTotals : totals), [
        orderId,
        orderTotals,
        totals,
    ]);

    useEffect(() => {
        setUserCurrentLastSeen(previousLastUserSeenAt);
        setUserPreviousLastSeen(moment.now());
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const fetchBills = useCallback(
        debounceWrapper((page: number = currentPage) => {
            setError(null);
            setLoading(true);

            if (page !== currentPage) {
                setCurrentPage(page);
                changePaginationPageUrlParam(page);
            }

            const payload: Protocol.BillListRequest = {
                page,
                sorting,
                page_size: pageSize,
                ...filtersPayload,
            };

            if (orderId) payload.order_id = orderId;

            return getBills(payload)
                .catch((e) => handleApiError(e, setError))
                .finally(() => setLoading(false));
        }),
        [currentPage, pageSize, sorting, filtersPayload]
    );

    const isBillsLoading = (): boolean => {
        const key = 'bills_list';

        return Boolean(documentsStorage.find((item) => item.key === key));
    };

    const fetchDocument = (): void => {
        if (isBillsLoading()) return;

        const payload = {
            ...filtersPayload,
            ...(orderId ? { order_id: orderId } : {}),
        };

        downloadBillsListDocument(payload, { payload, key: 'bills_list', type: 'bills_list' });
    };

    const debtInfoState = foldDebtInfoState(debtControlOptions);
    const [debtInfoNotMarked, setDebtInfoNotMarked] = useState(true);
    const showDebtInfo = (): void => {
        setShowDebtInfoDrawer(true);
        if (debtInfoState === 'hasChanges' && debtInfoNotMarked)
            markAsViewed()
                .then(() => setDebtInfoNotMarked(false))
                .catch(handleApiError);
    };

    useOnce(() => fetchBills().then(() => setInitialized(true)));
    useEffect(() => {
        if (isInitialized) fetchBills();
    }, [sorting, filtersPayload]); // eslint-disable-line react-hooks/exhaustive-deps
    useEffect(() => {
        if (!isInitialized) return;
        const page = Math.min(currentPage, foldPageCount({ total: paginator.total, page_size: Number(pageSize) }));
        if (page < currentPage) setCurrentPage(page);
        fetchBills(page);
    }, [pageSize]); // eslint-disable-line react-hooks/exhaustive-deps

    // eslint-disable-next-line @typescript-eslint/camelcase
    const { is_archived: isArchived, ...activeFilters } = filtersPayload;

    useBusEffect('onNavigationClick', (link) => {
        if (link.key === 'bills' && isArchived !== false) {
            setDocumentStatus(DocumentsStatus.Current);
            setFiltersPayload((v) => ({ ...v, is_archived: false }));
            fetchBills();
        }
    });

    const hasData = list.length > 0;
    const isFiltersActive = Object.keys(activeFilters).length > 0;
    const debtInfoEntries = useMemo(
        () =>
            pipe(
                debtControlOptions,
                map(({ items }) => items),
                getOrElse(constDebtOptions)
            ),
        [debtControlOptions]
    );

    const viewState = foldView(list, loading, error);

    return (
        <SortProvider>
            <CommentsProvider>
                <BillsSearchProvider>
                    <div className="BillsView">
                        <div className="App__container">
                            <div className="BillsView__filtersWrapper">
                                <div className="BillsView__filters">
                                    <BillsFilters
                                        hasData={hasData}
                                        filterDefaults={filtersState}
                                        filterValues={filtersPayload}
                                        onChange={(payload) => {
                                            addBillsToForceSeen(list);
                                            setCurrentPage(1);
                                            setFiltersPayload(payload);
                                        }}
                                        onDownload={fetchDocument}
                                        isDownloading={isBillsLoading()}
                                        debtInfoState={debtInfoState}
                                        showDebtInfo={showDebtInfo}
                                        documentStatus={documentStatus}
                                        setDocumentStatus={setDocumentStatus}
                                    />
                                </div>

                                <div className="BillsView__filters BillsView__filters--mobile">
                                    <Drawer
                                        button={{
                                            className: 'BillsView__filtersButton',
                                            size: 'medium',
                                            text: 'Фильтры',
                                            variant: 'outlined',
                                            startIcon: <Sort />,
                                        }}
                                    >
                                        <BillsFilters
                                            hasData={hasData}
                                            filterDefaults={filtersState}
                                            filterValues={filtersPayload}
                                            onChange={setFiltersPayload}
                                            onDownload={fetchDocument}
                                            isDownloading={isBillsLoading()}
                                            debtInfoState={debtInfoState}
                                            showDebtInfo={showDebtInfo}
                                            documentStatus={documentStatus}
                                            setDocumentStatus={setDocumentStatus}
                                            vertical
                                        />
                                    </Drawer>
                                </div>
                            </div>

                            {orderId && orderNumber && (
                                <h2 className="BillsView__orderHeading">
                                    <span>
                                        Счета по заказу&nbsp;
                                        <button
                                            type="button"
                                            className="BillsView__orderHeadingButton"
                                            onClick={() => {
                                                setSearchState({ query: orderNumber, open: true }, true);
                                                generateSearchUrl(`/orders/${orderId}/bills`, true, orderNumber);
                                            }}
                                        >
                                            № {orderNumber}
                                        </button>
                                    </span>

                                    <IconButton
                                        className="BillsView__orderHeadingIcon"
                                        component={Link}
                                        to={`${ROUTES.BILLS}`}
                                    >
                                        <CancelIcon htmlColor="#a93f97" fontSize="small" />
                                    </IconButton>
                                </h2>
                            )}

                            {viewState(
                                () => (
                                    <div className="App__centerLoader">
                                        {isFiltersActive
                                            ? 'Документы по выбранным параметрам не найдены'
                                            : 'Документы не найдены'}
                                    </div>
                                ),
                                () => null,
                                (apiError) => (
                                    <FetchError title={apiError.getCommonFirstMessage()} onRetry={() => fetchBills()} />
                                ),
                                (nonEmptyList) => (
                                    <div className="BillsView__list">
                                        <BillsList
                                            items={nonEmptyList}
                                            totals={totalsState}
                                            totalPages={pageCount}
                                            currentPage={currentPage}
                                            showPaginator={isInitialized && pageCount > 1}
                                            filtersPayload={filtersPayload}
                                            currentPageSize={pageSize}
                                            disablePaginator={loading}
                                            onPageSizeChange={flow(changePageSize, scrollToTop)}
                                            onSortChange={() => addBillsToForceSeen(nonEmptyList)}
                                            billsForceSeenIds={billsForceSeenIds}
                                            addBillsToForceSeen={addBillsToForceSeen}
                                            addBillsToSeenStorage={addBillsToSeenStorage}
                                            onPageChange={(page) =>
                                                fetchBills(page).then(scrollToTop).catch(handleApiError)
                                            }
                                        />
                                    </div>
                                )
                            )}
                            <LoadingFixedCenter visible={loading} />
                            <DebtInfoDrawer
                                open={showDebtInfoDrawer}
                                entries={debtInfoEntries}
                                onClose={() => setShowDebtInfoDrawer(false)}
                            />
                        </div>
                    </div>
                </BillsSearchProvider>
            </CommentsProvider>
        </SortProvider>
    );
};

const EnchantedComponent = pipe(Bills, connect(mapState, mapDispatch));
export { EnchantedComponent as Bills };

import { Reducer } from 'redux';

import { fromTraversable, Lens } from 'monocle-ts';
import { pipe } from 'fp-ts/function';
import * as R from 'fp-ts/Record';
import * as A from 'fp-ts/Array';

import { OrdersState, OrdersActionTypes, OrderContainer, OrderListItem, OrderInfo } from './types';

const listLens = Lens.fromProp<OrdersState>()('list');
const filtersLens = Lens.fromProp<OrdersState>()('localFilters');
const containersLens = Lens.fromProp<OrdersState>()('containers');
const filtersFlagsLens = Lens.fromPath<OrdersState>()(['localFilters', 'flags']);
const filtersCountsLens = Lens.fromPath<OrdersState>()(['localFilters', 'counts']);

const containersTraversal = fromTraversable(R.Traversable)<OrderContainer>();
const containerOrderLens = Lens.fromProp<OrderContainer>()('order');
const containerOrderInfoLens = Lens.fromProp<OrderInfo>()('customer_comment');
const customerContainerComment = Lens.fromProp<OrderContainer>()('customer_comment');

const listItemsLens = Lens.fromPath<OrdersState>()(['list', 'items']);
const listCommentLens = Lens.fromProp<OrderListItem>()('customer_comment');
const listItemsTraversal = fromTraversable(A.Traversable)<OrderListItem>();

const listDefault: OrdersState['list'] = {
    items: [],
    page: 1,
    total: 0,
    page_size: 0,
    filters: {
        embargo: [],
        is_archived: [],
        order_types: [],
        statuses: [],
        events: [],
        has_critical_events: [],
    },
};

const localFiltersDefault: OrdersState['localFilters'] = {
    flags: { embargo: false, archived: false, has_critical_events: false },
    counts: {},
    search: null,
};

const initialState: OrdersState = {
    containers: {},
    list: listDefault,
    localFilters: localFiltersDefault,
    openedCard: -1,
    isMinifiedView: false,
};

type ByIdPredicate = (struct: { id: number }) => boolean;

export const ordersReducer: Reducer<OrdersState, OrdersActionTypes> = (state = initialState, action) => {
    switch (action.type) {
        case 'SET_ORDERS':
            return pipe(state, listLens.set(action.payload));
        case 'SET_ORDERS_CONTAINER':
            return pipe(state, containersLens.modify(R.upsertAt(action.payload.id.toString(), action.payload)));
        case 'SET_FILTERS_FLAGS':
            return pipe(state, filtersFlagsLens.set(action.payload));
        case 'SET_FILTERS_COUNTS':
            return pipe(state, filtersCountsLens.set(action.payload));
        case 'FLUSH_ORDER_CACHE':
            return pipe(state, listLens.set(listDefault), filtersLens.set(localFiltersDefault), containersLens.set({}));
        case 'UPDATE_COMMENT': {
            switch (action.payload.kind) {
                case 'order': {
                    const orderPredicate: ByIdPredicate = (order) => order.id === action.payload.id;
                    return pipe(
                        state,
                        listItemsLens
                            .composeTraversal(listItemsTraversal)
                            .filter(orderPredicate)
                            .modify(listCommentLens.set(action.payload.text)),
                        containersLens
                            .composeTraversal(containersTraversal)
                            .composeLens(containerOrderLens)
                            .filter(orderPredicate)
                            .composeLens(containerOrderInfoLens)
                            .set(action.payload.text)
                    );
                }
                case 'container': {
                    const containerPredicate: ByIdPredicate = (container) => container.id === action.payload.id;
                    return pipe(
                        state,
                        containersLens
                            .composeTraversal(containersTraversal)
                            .filter(containerPredicate)
                            .composeLens(customerContainerComment)
                            .set(action.payload.text)
                    );
                }
                default:
                    return state;
            }
        }
        case 'SET_OPENED_CARD':
            return { ...state, openedCard: action.payload };
        case 'SET_MINIFIED_VIEW':
            return { ...state, isMinifiedView: action.payload };
        default:
            return state;
    }
};

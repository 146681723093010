import { ActionCreatorsMapObject, BindActionCreators, bindActionCreators, Dispatch } from 'redux';
import type { ThunkAction, ThunkDispatch } from 'redux-thunk';

import type { UpdateBillsDisputeActionPayload } from '../types/dispute';

import type { RootActionType, RootState } from './root';

import { ApiClient } from '../api';
import { ApiError } from '../api/client/errors';

import { reachGoal } from '../utils/metrika';

import { setSearchResult, updateSearchBillsCustomerCommentAction, updateSearchDisputeAction } from './search/actions';
import { flushOrderCache, serOrdersContainer, setOrders, updateComment } from './orders/actions';
import { setUserProfile, userLogin, userLogout } from './user/actions';
import { flushSettingsCache, setSettings } from './settings/actions';
import {
    setReportColumnsRequestState,
    setReportDetailsRequestState,
    setReportListRequestState,
} from './orderReports/actions';
import { SetSyncProblems } from './sync-problems/actions';
import { rememberPage } from './pages/actions';
import {
    resetBillsResultAction,
    setBillsResultAction,
    updateBillsCustomerCommentAction,
    updateBillsDisputeAction,
} from './bills/actions';
import { constFailure, constFetching, constSuccess } from '../utils/requestState';
import { setFeedData, setFeedStats } from './feed/actions';

export type AppThunk<T = void, R = Promise<T>> = ThunkAction<R, RootState, unknown, RootActionType>;
export type AppDispatch<T extends ActionCreatorsMapObject> = (
    dispatch: Dispatch<RootActionType>,
) => BindActionCreators<T>;

type RootDispatch = ThunkDispatch<RootState, unknown, RootActionType>;

type ApiEndpoint = { url: string; method: 'get' | 'post' };
type VoidablePayload<T> = T extends EmptyObject ? void : T;

type CallbackConfig<R, D, P, Dispatch = RootDispatch> = {
    endpoint: ApiEndpoint;
    dispatchCallback?: (dispatch: RootDispatch, response: D, payload: P) => unknown;
};

type DispatchConfig<R, D, E = ApiError, Dispatch = RootDispatch> = {
    endpoint: ApiEndpoint;
    lifecycle: {
        onStart: (dispatch: Dispatch, payload: R) => unknown;
        onSuccess: (dispatch: Dispatch, date: D, payload: R) => unknown;
        onFailure: (dispatch: Dispatch, error: E, payload: R) => unknown;
    };
};

export const makeMapDispatch = <T extends ActionCreatorsMapObject>(actions: T): AppDispatch<T> => (dispatch) =>
    bindActionCreators(actions, dispatch);

const createCallbackApi = <D, R = void, P = VoidablePayload<D>>(config: CallbackConfig<D, R, P>) => (
    payload: P,
): AppThunk<R> => async (dispatch) => {
    const response = await ApiClient[config.endpoint.method]<R, P>(config.endpoint.url, payload);
    if (config.dispatchCallback) config.dispatchCallback(dispatch, response, payload);
    return response;
};

const createDispatchApi = <R, D, P = VoidablePayload<R>>(config: DispatchConfig<P, D>) => (
    payload: P,
): AppThunk<void> => async (dispatch) => {
    config.lifecycle.onStart(dispatch, payload);
    try {
        const response =
            config.endpoint.method === 'get'
                ? await ApiClient.get<D, P>(config.endpoint.url, payload)
                : await ApiClient.post<D, P>(config.endpoint.url, payload);
        config.lifecycle.onSuccess(dispatch, response, payload);
    } catch (e) {
        config.lifecycle.onFailure(dispatch, e, payload);
    }
};

// ----- Protocol -----

export const apiUserLogin = (payload: Protocol.AuthLoginRequest): AppThunk<Protocol.AuthLoginResponse> => async (
    dispatch,
) => {
    const response = await ApiClient.post<Protocol.AuthLoginResponse, Protocol.AuthLoginRequest>(
        '/api/auth/cookie/login',
        payload,
    );
    dispatch(userLogin());
    reachGoal('login', { login: payload.login });
    return response;
};

export const apiUserLogout = (): AppThunk => async (dispatch) => {
    await ApiClient.post<Protocol.AuthLogoutResponse, Protocol.AuthLogoutRequest>('/api/auth/cookie/logout', {});
    dispatch(userLogout());
    dispatch(flushOrderCache());
    dispatch(flushSettingsCache());
    dispatch(resetBillsResultAction());
};

export const apiProfileDetails = createCallbackApi<Protocol.ProfileDetailsRequest, Protocol.ProfileDetailsResponse>({
    endpoint: { method: 'get', url: '/api/user/profile/details' },
    dispatchCallback: (dispatch, response) => dispatch(setUserProfile(response)),
});

export const apiProfileUpdate = createCallbackApi<Protocol.ProfileUpdateRequest, Protocol.ProfileUpdateResponse>({
    endpoint: { method: 'post', url: '/api/user/profile/edit' },
    dispatchCallback: (dispatch, response) => dispatch(setUserProfile(response)),
});

export const apiProfileChangePassword = (payload: Protocol.ProfileChangePasswordRequest): AppThunk => async () => {
    await ApiClient.post<Protocol.ProfileChangePasswordResponse, Protocol.ProfileChangePasswordRequest>(
        '/api/user/profile/change_password',
        payload,
    );
};

export const apiProfileSetupShadowRecipient = createCallbackApi<Protocol.ProfileSetupShadowRecipientRequest, Protocol.ProfileSetupShadowRecipientResponse>({
    endpoint: { method: 'post', url: '/api/user/profile/setup_shadow_recipient' },
});

export const apiSettings = (): AppThunk => async (dispatch) => {
    const response = await ApiClient.get<Protocol.SettingsResponse, Protocol.SettingsRequest>('/api/settings', {});

    dispatch(setSettings(response));
};

export const apiCriticalEventsStats = (): AppThunk => async (dispatch) => {
    const response = await ApiClient.get<Protocol.FeedCriticalEventsStatsResponse, Protocol.FeedCriticalEventsStatsRequest>('/api/feed/critical_events/stats', {});

    dispatch(setFeedStats(response.unread_critical_events));
};

export const apiFeedList = (payload: Protocol.FeedListRequest, isResetData?: boolean): AppThunk => async (dispatch) => {
    const response = await ApiClient.get<Protocol.FeedListResponse, Protocol.FeedListRequest>('/api/feed/list', payload);

    dispatch(setFeedData(response, isResetData));
};

export const apiCriticalEventsUpdateVisibility = createCallbackApi<Protocol.FeedUpdateVisibilityRequest,
    Protocol.FeedUpdateVisibilityResponse>({
    endpoint: { method: 'post', url: '/api/feed/update_visibility' },
});

export const apiCriticalEventsMarkAsViewed = createCallbackApi<Protocol.FeedCriticalEventsMarkAsViewedRequest,
    Protocol.FeedCriticalEventsMarkAsViewedResponse>({
    endpoint: { method: 'post', url: '/api/feed/critical_events/mark_as_viewed' },
});


export const apiGetOrderList = (payload: Protocol.OrderListRequest): AppThunk => async (dispatch) => {
    const response = await ApiClient.get<Protocol.OrderListResponse, Protocol.OrderListRequest>(
        '/api/order/list',
        payload,
    );
    dispatch(setOrders(response));
};

export const apiGetOrderListWithoutDispatch = (
    payload: Protocol.OrderListRequest,
): AppThunk<Protocol.OrderListResponse> => async () =>
    ApiClient.get<Protocol.OrderListResponse, Protocol.OrderListRequest>('/api/order/list', payload);

export const apiGetOrderContainer = (payload: Protocol.OrderContainerRequest): AppThunk => async (dispatch) => {
    const response = await ApiClient.get<Protocol.OrderContainerResponse, Protocol.OrderContainerRequest>(
        '/api/order/container',
        payload,
    );
    dispatch(serOrdersContainer(response));
};

export const apiUpdateOrderComment = (payload: Protocol.OrderUpdateCommentRequest): AppThunk => async (dispatch) => {
    await ApiClient.post('/api/order/update_comment', payload);
    dispatch(
        updateComment({
            id: payload.order_id,
            text: payload.comment,
            kind: 'order',
        }),
    );
};

export const apiUpdateContainerComment = (payload: Protocol.OrderUpdateContainerCommentRequest): AppThunk => async (
    dispatch,
) => {
    await ApiClient.post('/api/order/container/update_comment', payload);
    dispatch(
        updateComment({
            id: payload.container_id,
            text: payload.comment,
            kind: 'container',
        }),
    );
};

export const apiPageData = (payload: Protocol.PageDetailsRequest): AppThunk<Protocol.PageDetailsResponse> => async (
    dispatch,
) => {
    const response = await ApiClient.get<Protocol.PageDetailsResponse, Protocol.PageDetailsRequest>(
        '/api/page/details',
        payload,
    );
    dispatch(
        rememberPage({
            ...payload,
            page: response,
        }),
    );
    return response;
};

export const apiSearch = (payload: Protocol.SearchRequest): AppThunk => async (dispatch) => {
    const response = await ApiClient.get<Protocol.SearchResponse, Protocol.SearchRequest>('/api/search', payload);
    dispatch(
        setSearchResult({
            result: response,
            params: {
                query: payload.search_query,
                config: payload.sections_config || [],
            },
        }),
    );
};

export const apiCallToManager = (
    payload: Protocol.CallToManagerRequest,
): AppThunk<Protocol.CallToManagerResponse> => () => ApiClient.post('/api/call_to_manager', payload);

export const apiFeedbackCreate = (
    payload: Protocol.FeedbackCreateRequest,
): AppThunk<Protocol.FeedbackCreateResponse> => () => ApiClient.post('/api/feedback/create', payload);

export const apiGetRecipientsList = (
    payload: Protocol.RecipientsListRequest = { container_id: null },
): AppThunk<Protocol.RecipientsListResponse> => async () => {
    return ApiClient.post<Protocol.RecipientsListResponse, Protocol.RecipientsListRequest>(
        '/api/recipients/list',
        payload,
    );
};

export const apiSubscriptionSettings = (
    payload: Protocol.SubscriptionSettingsRequest = {},
): AppThunk<Protocol.SubscriptionSettingsResponse> => async () => {
    return ApiClient.get<Protocol.SubscriptionSettingsResponse, Protocol.SubscriptionSettingsRequest>(
        '/api/user/profile/subscription_settings',
        payload,
    );
};

export const apiAddRecipient = (
    payload: Protocol.AddRecipientRequest = { container_id: null },
): AppThunk<Protocol.RecipientsListResponse> => async () => {
    return ApiClient.post<Protocol.AddRecipientResponse, Protocol.AddRecipientRequest>('/api/recipients/add', payload);
};

export const apiDeleteRecipient = (
    payload: Protocol.DeleteRecipientRequest,
): AppThunk<Protocol.DeleteRecipientResponse> => async () => {
    return ApiClient.post<Protocol.DeleteRecipientResponse, Protocol.DeleteRecipientRequest>(
        '/api/recipients/delete',
        payload,
    );
};

export const apiUpdateRecipient = (
    payload: Protocol.UpdateRecipientRequest,
): AppThunk<Protocol.UpdateRecipientResponse> => async () => {
    return ApiClient.post<Protocol.UpdateRecipientResponse, Protocol.UpdateRecipientRequest>(
        '/api/recipients/update',
        payload,
    );
};

export const apiSyncProblemsIssues = (): AppThunk => async (dispatch) => {
    const response = await ApiClient.get<Protocol.SyncProblemsIssuesResponse, Protocol.SyncProblemsIssuesRequest>(
        '/api/sync_problems/issues',
        {},
    );
    dispatch(SetSyncProblems(response.order_types ?? []));
};

export const apiGetPaymentHistoryList = (
    payload: Protocol.PaymentHistoryRequest,
): AppThunk<Protocol.PaymentHistoryResponse> => async () => {
    return ApiClient.get<Protocol.PaymentHistoryResponse, Protocol.PaymentHistoryRequest>('/api/payment/list', payload);
};

export const apiRequestPaymentHistoryDocument = (
    payload: Protocol.PaymentHistoryDocumentRequest,
): AppThunk<Protocol.PaymentHistoryDocumentResponse> => async () =>
    ApiClient.post<Protocol.PaymentHistoryDocumentResponse, Protocol.PaymentHistoryDocumentRequest>(
        '/api/payment/request_document',
        payload,
    );

export const apiGetPaymentHistoryDocument = (
    payload: Protocol.PaymentHistoryGetDocumentRequest,
): AppThunk<Protocol.PaymentHistoryGetDocumentResponse> => async () =>
    ApiClient.post<Protocol.PaymentHistoryGetDocumentResponse, Protocol.PaymentHistoryGetDocumentRequest>(
        '/api/payment/get_document',
        payload,
    );

export const apiRequestBillListDocument = (
    payload: Protocol.BillListDocumentRequest,
): AppThunk<Protocol.BillListDocumentResponse> => async () =>
    ApiClient.post<Protocol.BillListDocumentResponse, Protocol.BillListDocumentRequest>(
        '/api/bill/list/request_document',
        payload,
    );

export const apiGetBillListDocument = (
    payload: Protocol.BillListGetDocumentRequest,
): AppThunk<Protocol.BillListGetDocumentResponse> => async () =>
    ApiClient.post<Protocol.BillListGetDocumentResponse, Protocol.BillListGetDocumentRequest>(
        '/api/bill/list/get_document',
        payload,
    );

export const apiRequestBillPackageDocument = (
    payload: Protocol.BillPackageDocumentRequest,
): AppThunk<Protocol.BillPackageDocumentResponse> => async () =>
    ApiClient.post<Protocol.BillPackageDocumentResponse, Protocol.BillPackageDocumentRequest>(
        '/api/bill/package/request_document',
        payload,
    );

export const apiGetBillList = createCallbackApi<Protocol.BillListRequest, Protocol.BillListResponse>({
    endpoint: { method: 'get', url: '/api/bill/list' },
    dispatchCallback: (dispatch, response) => dispatch(setBillsResultAction(response)),
});

export const apiGetBillPackageDocument = createCallbackApi<Protocol.BillPackageGetDocumentRequest,
    Protocol.BillPackageGetDocumentResponse>({ endpoint: { method: 'post', url: '/api/bill/package/get_document' } });

export const apiBillUpdateDispute = createCallbackApi<Protocol.BillUpdateDisputeRequest,
    Protocol.BillUpdateDisputeResponse>({
    endpoint: { method: 'post', url: '/api/bill/update_dispute' },
    dispatchCallback: (dispatch, response, request) => {
        const payload: UpdateBillsDisputeActionPayload = {
            kind: 'update',
            data: {
                billId: request.bill_id,
                text: request.comment,
            },
        };

        dispatch(updateBillsDisputeAction(payload));
        dispatch(updateSearchDisputeAction(payload));
    },
});

export const apiBillCloseDispute = createCallbackApi<Protocol.BillCloseDisputeRequest,
    Protocol.BillCloseDisputeResponse>({
    endpoint: { method: 'post', url: '/api/bill/close_dispute' },
    dispatchCallback: (dispatch, response, request) => {
        const payload: UpdateBillsDisputeActionPayload = {
            kind: 'delete',
            data: { billId: request.bill_id },
        };

        dispatch(updateBillsDisputeAction(payload));
        dispatch(updateSearchDisputeAction(payload));
    },
});

export const apiUpdateBillComment = createCallbackApi<Protocol.BillUpdateCommentRequest, void>({
    endpoint: { method: 'post', url: '/api/bill/update_comment' },
    dispatchCallback: (dispatch, response, request) => {
        const payload = { billId: request.bill_id, comment: request.comment };
        dispatch(updateBillsCustomerCommentAction(payload));
        dispatch(updateSearchBillsCustomerCommentAction(payload));
    },
});

export const apiDebtControlOptionsMarkAsViewed = createCallbackApi<Protocol.DebtControlOptionsMarkAsViewedRequest,
    Protocol.DebtControlOptionsMarkAsViewedResponse>({
    endpoint: {
        method: 'post',
        url: '/api/debt_control_options/mark_as_viewed',
    },
});

export const apiOrderContainersPackageRequestDocument = createCallbackApi<Protocol.OrderContainersPackageRequestDocumentRequest,
    Protocol.OrderContainersPackageRequestDocumentResponse>({
    endpoint: {
        method: 'post',
        url: '/api/order/containers/package/request_document',
    },
});

export const apiOrderContainerPackageRequestDocument = createCallbackApi<Protocol.OrderContainerPackageRequestDocumentRequest,
    Protocol.OrderContainerPackageRequestDocumentResponse>({
    endpoint: {
        method: 'post',
        url: '/api/order/container/package/request_document',
    },
});

export const apiReportsRequestDocument = createCallbackApi<Protocol.ReportsDocumentRequest,
    Protocol.ReportsDocumentResponse>({
    endpoint: {
        method: 'post',
        url: '/api/order/reports/request_document',
    },
});

export const apiReportsGetDocument = createCallbackApi<Protocol.ReportsGetDocumentRequest,
    Protocol.ReportsGetDocumentResponse>({
    endpoint: {
        method: 'post',
        url: '/api/order/reports/get_document',
    },
});

export const apiGetReportsSubscribersList = createCallbackApi<Protocol.ReportsSubscribersListRequest,
    Protocol.ReportsSubscribersListResponse>({
    endpoint: {
        method: 'get',
        url: '/api/order/reports/subscribers/list',
    },
});

export const apiReplaceReportsSubscribersList = createCallbackApi<Protocol.ReportsSubscribersListReplaceRequest,
    Protocol.ReportsSubscribersListReplaceResponse>({
    endpoint: {
        method: 'post',
        url: '/api/order/reports/subscribers/replace',
    },
});

export const apiTemplateRequestDocument = createCallbackApi<Protocol.TemplateDocumentRequest,
    Protocol.TemplateDocumentResponse>({
    endpoint: {
        method: 'post',
        url: '/api/order/report_templates/request_document',
    },
});

export const apiOrderPackageRequestDocument = createCallbackApi<Protocol.OrderPackageRequestDocumentRequest,
    Protocol.OrderPackageRequestDocumentResponse>({
    endpoint: {
        method: 'post',
        url: '/api/order/package/request_document',
    },
});

export const apiOrderPackageGetDocument = createCallbackApi<Protocol.OrderPackageGetDocumentRequest,
    Protocol.OrderPackageGetDocumentResponse>({ endpoint: { method: 'post', url: '/api/order/package/get_document' } });

export const apiOrderPackageList = createCallbackApi<Protocol.OrderPackageListRequest,
    Protocol.OrderPackageListResponse>({ endpoint: { method: 'get', url: '/api/order/package/list' } });

export const apiOrderConsolidationTable = createCallbackApi<Protocol.OrderConsolidationTableRequest,
    Protocol.OrderConsolidationTableResponse>({ endpoint: { method: 'get', url: '/api/order/consolidation' } });

export const apiOrderContainerPackageList = createCallbackApi<Protocol.OrderContainerPackageListRequest,
    Protocol.OrderContainerPackageListResponse>({
    endpoint: {
        method: 'get',
        url: '/api/order/container/package/list',
    },
});

export const apiReportsGetStats = createCallbackApi<Protocol.ReportsGetStatsRequest, Protocol.ReportsGetStatsResponse>({
    endpoint: { method: 'get', url: '/api/order/reports/get_stats' },
});

export const apiReportsGetColumns = createDispatchApi<Protocol.ReportsGetColumnsRequest,
    Protocol.ReportsGetColumnsResponse>({
    endpoint: { method: 'get', url: '/api/order/reports/get_columns' },
    lifecycle: {
        onStart: (dispatch) => dispatch(setReportColumnsRequestState(constFetching())),
        onFailure: (dispatch, error) => dispatch(setReportColumnsRequestState(constFailure(error))),
        onSuccess: (dispatch, data) => dispatch(setReportColumnsRequestState(constSuccess(data.items))),
    },
});

export const apiReportsAndTemplatesList = createDispatchApi<Protocol.ReportsAndTemplatesListRequest, Protocol.ReportsAndTemplatesListResponse>({
    endpoint: { method: 'get', url: '/api/order/reports_and_templates/list' },
    lifecycle: {
        onStart: (dispatch) => dispatch(setReportListRequestState(constFetching())),
        onFailure: (dispatch, error) => dispatch(setReportListRequestState(constFailure(error))),
        onSuccess: (dispatch, data) => dispatch(setReportListRequestState(constSuccess(data.items))),
    },
});

export const apiReportsDetails = createDispatchApi<Protocol.ReportsDetailsRequest, Protocol.ReportsDetailsResponse>({
    endpoint: { method: 'get', url: '/api/order/reports/details' },
    lifecycle: {
        onStart: (dispatch, payload) =>
            dispatch(
                setReportDetailsRequestState({
                    selector: payload.id.toString(),
                    data: constFetching(),
                }),
            ),
        onFailure: (dispatch, error, payload) =>
            dispatch(
                setReportDetailsRequestState({
                    selector: payload.id.toString(),
                    data: constFailure(error),
                }),
            ),
        onSuccess: (dispatch, data, payload) =>
            dispatch(
                setReportDetailsRequestState({
                    selector: payload.id.toString(),
                    data: constSuccess(data),
                }),
            ),
    },
});

export const apiReportsTemplateDetails = createDispatchApi<Protocol.ReportsTemplateDetailsRequest, Protocol.ReportsTemplateDetailsResponse>({
    endpoint: { method: 'get', url: '/api/order/report_templates/details' },
    lifecycle: {
        onStart: (dispatch, payload) =>
            dispatch(
                setReportDetailsRequestState({
                    selector: payload.id.toString(),
                    data: constFetching(),
                }),
            ),
        onFailure: (dispatch, error, payload) =>
            dispatch(
                setReportDetailsRequestState({
                    selector: payload.id.toString(),
                    data: constFailure(error),
                }),
            ),
        onSuccess: (dispatch, data, payload) =>
            dispatch(
                setReportDetailsRequestState({
                    selector: payload.id.toString(),
                    data: constSuccess(data),
                }),
            ),
    },
});

export const apiReportsTemplateAdd = createCallbackApi<Protocol.ReportsTemplateAddRequest, Protocol.ReportsTemplateAddResponse>({
    endpoint: { method: 'post', url: '/api/order/report_templates/create' },
});

export const apiReportsTemplateUpdate = createCallbackApi<Protocol.ReportsTemplateUpdateRequest, Protocol.ReportsTemplateUpdateResponse>({
    endpoint: { method: 'post', url: '/api/order/report_templates/update' },
});

export const apiReportsAdd = createCallbackApi<Protocol.ReportsAddRequest, Protocol.ReportsAddResponse>({
    endpoint: { method: 'post', url: '/api/order/reports/add' },
});

export const apiReportsUpdate = createCallbackApi<Protocol.ReportsUpdateRequest, Protocol.ReportsUpdateResponse>({
    endpoint: { method: 'post', url: '/api/order/reports/update' },
});

export const apiReportsDelete = createCallbackApi<Protocol.ReportsDeleteRequest, Protocol.ReportsDeleteResponse>({
    endpoint: { method: 'post', url: '/api/order/reports/delete' },
});

export const apiReportsTemplateDelete = createCallbackApi<Protocol.ReportsTemplateDeleteRequest, Protocol.ReportsTemplateDeleteResponse>({
    endpoint: { method: 'post', url: '/api/order/report_templates/delete' },
});

export const apiReportsClone = createCallbackApi<Protocol.ReportsCloneRequest, Protocol.ReportsCloneResponse>({
    endpoint: { method: 'post', url: '/api/order/reports/clone' },
});

export const apiReportsSubscribe = createCallbackApi<Protocol.ReportsSubscribeRequest, Protocol.ReportsSubscribeResponse>({
    endpoint: { method: 'post', url: '/api/order/reports/subscribe' },
});

export const apiReportsUnsubscribe = createCallbackApi<Protocol.ReportsUnsubscribeRequest, Protocol.ReportsUnsubscribeResponse>({
    endpoint: { method: 'post', url: '/api/order/reports/unsubscribe' },
});

export const apiTemplateCopy = createCallbackApi<Protocol.TemplateCopyRequest, Protocol.TemplateCopyResponse>({
    endpoint: { method: 'post', url: '/api/order/report_templates/copy' },
});

export const apiReportTemplateDetails = createCallbackApi<Protocol.ReportsTemplatesDetailsRequest,
    Protocol.ReportsTemplatesDetailsResponse>({
    endpoint: { method: 'get', url: '/api/order/report_templates/details' },
});

export const apiEmailsList = createCallbackApi<Protocol.EmailsListRequest,
    Protocol.EmailsListResponse>({
    endpoint: { method: 'get', url: '/api/order/reports/recipient_candidate_list' },
});

export const apiManagedUsersList = createCallbackApi<Protocol.ManagedUsersListRequest,
    Protocol.ManagedUsersListResponse>({
    endpoint: { method: 'get', url: '/api/managed_users/list' },
});

export const apiManagedUsersCreate = createCallbackApi<Protocol.ManagedUsersCreateRequest,
    Protocol.ManagedUsersCreateResponse>({
    endpoint: { method: 'post', url: '/api/managed_users/create' },
});

export const apiManagedUsersUpdate = createCallbackApi<Protocol.ManagedUsersUpdateRequest,
    Protocol.ManagedUsersUpdateResponse>({
    endpoint: { method: 'post', url: '/api/managed_users/update' },
});

export const apiManagedUserDelete = createCallbackApi<Protocol.ManagedUsersDeleteRequest,
    Protocol.ManagedUsersDeleteResponse>({
    endpoint: { method: 'post', url: '/api/managed_users/delete' },
});

export const apiOnlineCallCount = createCallbackApi<Protocol.OnlineCallCountRequest,
    Protocol.OnlineCallCountResponse>({
    endpoint: { method: 'get', url: '/api/user/online_call/count' },
});

export const apiDocumentPackageErrorCount = createCallbackApi<Protocol.DocumentPackageErrorCountRequest,
    Protocol.DocumentPackageErrorCountResponse>({
    endpoint: { method: 'post', url: '/api/documents_package/error/count' },
});

export const apiNotificationsSettingsOrderList = createCallbackApi<Protocol.NotificationsSettingsOrderListRequest,
    Protocol.NotificationsSettingsOrderListResponse>({
    endpoint: { method: 'get', url: '/api/notification_settings/order/list' },
});

export const apiNotificationsSettingsOrderDelete = createCallbackApi<Protocol.NotificationsSettingsOrderDeleteRequest,
    Protocol.NotificationsSettingsOrderDeleteResponse>({
    endpoint: { method: 'post', url: '/api/notification_settings/order/delete' },
});

export const apiNotificationsSettingsOrderCreate = createCallbackApi<Protocol.NotificationsSettingsOrderCreateRequest,
    Protocol.NotificationsSettingsOrderCreateResponse>({
    endpoint: { method: 'post', url: '/api/notification_settings/order/create' },
});

export const apiNotificationsSettingsOrderUpdate = createCallbackApi<Protocol.NotificationsSettingsOrderUpdateRequest,
    Protocol.NotificationsSettingsOrderUpdateResponse>({
    endpoint: { method: 'post', url: '/api/notification_settings/order/update' },
    });

export const apiNotificationsSettingsOrderDetails = createCallbackApi<Protocol.NotificationsSettingsOrderDetailsRequest,
    Protocol.NotificationsSettingsOrderDetailsResponse>({
    endpoint: { method: 'get', url: '/api/notification_settings/order/details' },
    });

export const apiUserNotificationsSettingsDetails = createCallbackApi<Protocol.UserNotificationsSettingsDetailsRequest,
    Protocol.UserNotificationsSettingsDetailsResponse>({
    endpoint: { method: 'get', url: '/api/regular_user/notification_settings_set/details' },
});

export const apiUserNotificationsSettingsOrderUpdate = createCallbackApi<Protocol.NotificationsSettingsOrderUpdateRequest,
    Protocol.NotificationsSettingsOrderUpdateResponse>({
    endpoint: { method: 'post', url: '/api/regular_user/notification_settings_set/order/update' },
    });

export const apiUserNotificationsSettingsDocumentsUpdate = createCallbackApi<Protocol.UserNotificationsSettingsDocumentsUpdateRequest,
    Protocol.UserNotificationsSettingsDocumentsUpdateResponse>({
    endpoint: { method: 'post', url: '/api/regular_user/notification_settings_set/documents/update' },
});

export const apiUserNotificationsSettingsMutualSettlementsReportsUpdate = createCallbackApi<Protocol.UserNotificationsSettingsMutualSettlementsReportsUpdateRequest,
    Protocol.UserNotificationsSettingsMutualSettlementsReportsUpdateResponse>({
    endpoint: { method: 'post', url: '/api/regular_user/notification_settings_set/mutual_settlements_report/update' },
});

export const apiGetContainerSubscriptionsSets = createCallbackApi<Protocol.NotificationSubscriptionsSetRequest,
    Protocol.NotificationSubscriptionsSetResponse>({
    endpoint: {method: 'get', url: '/api/notification_subscriptions/sets/list'},
});

export const apiUpdateContainerNotificationSubscription = createCallbackApi<Protocol.NotificationContainerUpdateRequest,
    Protocol.NotificationContainerUpdateResponse>({
    endpoint: {method: 'post', url: '/api/notification_subscriptions/containers/update'},
});

export const apiNotificationsSettingsDocumentsDetails = createCallbackApi<Protocol.NotificationsSettingsDocumentsDetailsRequest,
    Protocol.NotificationsSettingsDocumentsDetailsResponse>({
    endpoint: { method: 'get', url: '/api/notification_settings/documents/details' },
});

export const apiNotificationsSettingsDocumentsUpdate = createCallbackApi<Protocol.NotificationsSettingsDocumentsUpdateRequest,
    Protocol.NotificationsSettingsDocumentsUpdateResponse>({
    endpoint: { method: 'post', url: '/api/notification_settings/documents/update' },
});

export const apiNotificationsSettingsMutualSettlementsReportsDetails = createCallbackApi<Protocol.NotificationsSettingsMutualSettlementsReportsDetailsRequest,
    Protocol.NotificationsSettingsMutualSettlementsReportsDetailsResponse>({
    endpoint: { method: 'get', url: '/api/notification_settings/mutual_settlements_report/details' },
});

export const apiNotificationsSettingsMutualSettlementsReportsUpdate = createCallbackApi<Protocol.NotificationsSettingsMutualSettlementsReportsUpdateRequest,
    Protocol.NotificationsSettingsMutualSettlementsReportsUpdateResponse>({
    endpoint: { method: 'post', url: '/api/notification_settings/mutual_settlements_report/update' },
});

export const apiGetContainerNotificationSubscribersList = createCallbackApi<Protocol.GetNotificationSubscribersListRequest,
    Protocol.GetNotificationSubscribersListResponse>({
    endpoint: {method: 'get', url: '/api/notification_subscriptions/subscribers/list'},
});

export const apiUpdateContainerNotificationSubscribersList = createCallbackApi<Protocol.NotificationSubscribersListUpdateRequest,
    Protocol.NotificationSubscribersListUpdateResponse>({
    endpoint: {method: 'post', url: '/api/notification_subscriptions/containers/subscribers/update'},
});

export const apiUpdateOrderNotificationsSubscribersList = createCallbackApi<Protocol.NotificationsSubscriptionsOrdersSubscribersUpdateRequest,
    Protocol.NotificationsSubscriptionsOrdersSubscribersUpdateResponse>({
    endpoint: {method: 'post', url: '/api/notification_subscriptions/orders/subscribers/update'},
});

export const apiUpdateOrderNotificationSubscription = createCallbackApi<Protocol.NotificationsSubscriptionsOrdersUpdateRequest,
    Protocol.NotificationsSubscriptionsOrdersUpdateResponse>({
    endpoint: {method: 'post', url: '/api/notification_subscriptions/orders/update'},
});

export const apiGetOrderDetails = createCallbackApi<Protocol.OrderDetailsRequest,
    Protocol.OrderDetailsResponse>({
    endpoint: {method: 'get', url: '/api/order/details'},
});

import React, { FunctionComponent, useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';

import { cloneDeep } from 'lodash-es';
import { Optional } from 'monocle-ts';

import { pipe } from 'fp-ts/function';
import * as A from 'fp-ts/Array';
import * as R from 'fp-ts/Record';
import * as O from 'fp-ts/Option';

import { withStyles } from '@material-ui/styles';
import { Sort, Tune } from '@material-ui/icons';
import {
    Button,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    Typography,
    WithStyles,
} from '@material-ui/core';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import { setFiltersCounts, setFiltersFlags } from '../../store/orders/actions';
import { makeMapDispatch } from '../../store/dispatch';
import { makeMapState } from '../../store/root';

import { OrderListFilters } from '../../types/order';
import { DocumentsStatus } from '../../types/documents';

import { DocumentsStatusIso } from '../../utils/isomorph';
import { useSorts } from '../../hooks/sorts';
import { useUrl } from '../../hooks/url';
import {
    ActionType,
    ChipData,
    Counts,
    CountsList,
    countsTraversable,
    FiltersChangeHandler,
    foldFiltersCount,
    getSelectedOptions,
    makeIsChecked,
    mapFiltersIntoData,
    reduceFiltersIntoCounts,
    ToggleHandler,
} from '../../shared/filterLogic';

import { DocumentStatusChanger } from '../DocumentStatusChanger/DocumentStatusChanger';
import { FiltersTreeView } from '../FiltersTreeView/FiltersTreeView';
import { SortButton } from '../SortButton/SortButton';
import { Drawer } from '../Drawer/Drawer';

import { StatusFilters } from './Components/StatusFilters';
import { Chips } from './Components/Chips';

import { styles } from './styles';

type ComponentProps = {
    onChange: FiltersChangeHandler;
    listFilters: OrderListFilters;
};

const emptyCountValue: Counts = {
    events: [],
    types: [],
    statuses: [],
};

const defaultFlags = {
    embargo: false,
    archived: false,
    has_critical_events: false,
};

const mapState = makeMapState((state) => ({
    filters: state.settings.orderTypes,
    localFilters: state.orders.localFilters,
}));

const mapDispatch = makeMapDispatch({
    rememberFlags: setFiltersFlags,
    rememberCounts: setFiltersCounts,
});

type Props = ComponentProps & WithStyles<typeof styles> & ReturnType<typeof mapState> & ReturnType<typeof mapDispatch>;
const OrdersFilters: FunctionComponent<Props> = (props) => {
    const {
        classes,
        filters,
        onChange,
        rememberCounts,
        rememberFlags,
        localFilters,
        listFilters, // backend view counts
    } = props;

    const { sorts, handleChange: handleSortButtonClick } = useSorts('orders');

    const [memoizedCounts, setMemoizedCounts] = useState<CountsList | null>(null);
    const [currentChip, setCurrentChip] = useState<ChipData | null>(null);
    const [chipsCounts, setChipsCounts] = useState({
        ...reduceFiltersIntoCounts(filters),
        ...localFilters.counts,
    });

    const chipElements = useMemo(() => mapFiltersIntoData(filters, listFilters), [filters, listFilters]);
    const { initialIsArchivedValue } = useUrl();
    const initialIsArchived = initialIsArchivedValue(false);

    // NOTE: used to not duplicate ID's
    const isSmallScreen = useMediaQuery('(max-width:1199px)');

    const handleToggle: ToggleHandler = (key, action, id) => {
        const lens = Optional.fromPath<CountsList>()([key, action]);

        const optionList = pipe(
            lens.getOption(chipsCounts),
            O.map((list) =>
                pipe(
                    list,
                    A.findIndex((item) => item === id),
                    O.fold(
                        () => A.append(id)(list),
                        (index) => A.unsafeDeleteAt(index, list)
                    )
                )
            )
        );

        if (O.isSome(optionList)) {
            const newState = lens.set(optionList.value)(chipsCounts);
            setChipsCounts(newState);
            rememberCounts(newState);

            return newState;
        }

        return chipsCounts;
    };

    const [flags, setFlags] = useState(localFilters.flags);
    const currentSelectedOptions = useMemo(() => getSelectedOptions(chipsCounts), [chipsCounts]);

    const fireChangeEvent = (): void => {
        const { statuses, events, types } = currentSelectedOptions;
        // Мне было лениво мапить в camelCase из snake_case, простите
        // eslint-disable-next-line @typescript-eslint/camelcase
        const { embargo, archived, has_critical_events } = flags;
        onChange({
            embargo,
            archived,
            has_critical_events,
            type_ids: types,
            event_ids: events,
            status_ids: statuses,
        });
    };

    const resetChipsTypeByKey = (key: string, type: ActionType, state: CountsList): void => {
        const lens = Optional.fromPath<CountsList>()([key, type]);

        const newState = lens.set([])(state);
        setChipsCounts(newState);
        rememberCounts(newState);
    };

    const makeCurrentTypeIsChecked = (state: CountsList): boolean =>
        Boolean(currentChip && makeIsChecked(currentChip.key, state)('types', currentChip.origin.id));
    const isCurrentTypeChecked = makeCurrentTypeIsChecked(chipsCounts);
    const handleTypeChange = (): void => {
        if (!currentChip) return;

        const newState = handleToggle(currentChip.key, 'types', currentChip.origin.id);
        if (makeCurrentTypeIsChecked(newState)) resetChipsTypeByKey(currentChip.key, 'statuses', newState);
    };

    useEffect(fireChangeEvent, [flags]);
    useEffect(() => {
        if (currentChip === null) fireChangeEvent();
    }, [currentChip, currentSelectedOptions]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (currentChip !== null) setMemoizedCounts(cloneDeep(chipsCounts));
        else setMemoizedCounts(null);
    }, [currentChip]); // eslint-disable-line react-hooks/exhaustive-deps

    const handleDialogClose = (): void => setCurrentChip(null);
    const clearFilters = (): void => {
        const newState = countsTraversable.set(emptyCountValue)(chipsCounts);
        setFlags(defaultFlags);
        rememberFlags(defaultFlags);
        setChipsCounts(newState);
        rememberCounts(newState);
    };

    const handleDelete = (chipToDelete: ChipData) => () => {
        const newState = pipe(chipsCounts, R.upsertAt(chipToDelete.key, emptyCountValue));
        setChipsCounts(newState);
        rememberCounts(newState);
    };

    const toggle = (type: 'embargo' | 'archived' | 'has_critical_events') => (): void => {
        const newState = {
            ...flags,
            [type]: !flags[type],
        };

        setFlags(newState);
        rememberFlags(newState);
    };

    const handleDialogCancel = (): void => {
        if (memoizedCounts !== null) setChipsCounts(memoizedCounts);
        handleDialogClose();
    };

    const selectedCounts = R.size(chipsCounts);
    const radioOptions = useMemo(
        () => [
            {
                key: DocumentsStatus.All,
                label: 'Все',
                possibleCount: foldFiltersCount(listFilters.is_archived, null),
            },
            {
                key: DocumentsStatus.Current,
                label: 'Текущие',
                possibleCount: foldFiltersCount(listFilters.is_archived, false),
            },
            {
                key: DocumentsStatus.Archived,
                label: 'Архив',
                possibleCount: foldFiltersCount(listFilters.is_archived, true),
            },
        ],
        [listFilters]
    );

    const [archivedState, setArchivedState] = useState(DocumentsStatusIso.to(initialIsArchived));
    // eslint-disable-next-line no-nested-ternary
    const onArchivedChange = (newStatus: DocumentsStatus): void => {
        const flagState = { ...flags, archived: DocumentsStatusIso.from(newStatus) };
        setFlags(flagState);
        rememberFlags(flagState);
        setArchivedState(newStatus);
    };

    return (
        <div className={classes.filters}>
            <div className={classes.buttons}>
                <Drawer
                    button={{
                        text: selectedCounts ? `Фильтры (${selectedCounts})` : 'Фильтры',
                        startIcon: <Sort />,
                        size: 'medium',
                        variant: 'outlined',
                    }}
                >
                    <div className={classes.drawerHeader}>
                        <Typography variant="h5" component="h2" className={classes.drawerHeading}>
                            Фильтры
                        </Typography>
                        <Button color="primary" onClick={clearFilters} className={classes.resetButton}>
                            Сбросить все
                        </Button>
                    </div>

                    <Divider />

                    {isSmallScreen && (
                        <DocumentStatusChanger
                            idPrefix="mobile-radio-changer"
                            isVertical
                            data={radioOptions}
                            selectedValue={archivedState}
                            onChange={onArchivedChange}
                        />
                    )}

                    <Divider />

                    <Chips
                        classes={classes}
                        chipData={chipElements}
                        chipCounts={chipsCounts}
                        clickHandler={setCurrentChip}
                        deleteHandler={handleDelete}
                        isVertical
                    />

                    <Divider />

                    <StatusFilters
                        classes={classes}
                        isVertical
                        flags={flags}
                        toggleCriticalEventsFilter={toggle('has_critical_events')}
                        toggleEmbargo={toggle('embargo')}
                        listFilters={listFilters}
                    />

                    <Divider />
                </Drawer>

                <Drawer
                    button={{
                        text: 'Сортировка',
                        startIcon: <Tune />,
                        size: 'medium',
                        variant: 'outlined',
                    }}
                >
                    <div className={classes.drawerHeader}>
                        <Typography variant="h5" component="h2" className={classes.drawerHeading}>
                            Сортировка
                        </Typography>
                    </div>

                    <div className={classes.drawerBody}>
                        <div className={classes.sortButton}>
                            <SortButton
                                label="Статус"
                                order={sorts.direction}
                                active={sorts.by === 'latest_status_position'}
                                clickHandler={() => handleSortButtonClick('latest_status_position')}
                            />
                        </div>

                        <div className={classes.sortButton}>
                            <SortButton
                                label="Последнее событие"
                                order={sorts.direction}
                                active={sorts.by === 'latest_event_came_at'}
                                clickHandler={() => handleSortButtonClick('latest_event_came_at')}
                            />
                        </div>

                        <div className={classes.sortButton}>
                            <SortButton
                                label="Уведомления"
                                order={sorts.direction}
                                active={sorts.by === 'critical_event_came_at'}
                                clickHandler={() => handleSortButtonClick('critical_event_came_at')}
                            />
                        </div>
                    </div>
                </Drawer>
            </div>

            <div className={classes.DocumentStatusChanger}>
                {!isSmallScreen && (
                    <DocumentStatusChanger
                        data={radioOptions}
                        onChange={onArchivedChange}
                        selectedValue={archivedState}
                    />
                )}
            </div>

            <Chips
                classes={classes}
                chipData={chipElements}
                chipCounts={chipsCounts}
                deleteHandler={handleDelete}
                clickHandler={setCurrentChip}
            />

            <StatusFilters
                classes={classes}
                flags={flags}
                toggleEmbargo={toggle('embargo')}
                toggleCriticalEventsFilter={toggle('has_critical_events')}
                listFilters={listFilters}
            />

            <Dialog className={classes.modal} open={currentChip !== null} onClose={handleDialogClose} fullWidth>
                {currentChip && (
                    <>
                        <DialogTitle style={{ paddingBottom: 0 }}>
                            <Checkbox color="primary" onClick={handleTypeChange} checked={isCurrentTypeChecked} />
                            {currentChip.label}
                        </DialogTitle>
                        <DialogContent style={{ width: '100%', padding: 0, paddingLeft: 24 }}>
                            <FiltersTreeView
                                data={currentChip}
                                chipsCounts={chipsCounts}
                                toggleFilter={handleToggle}
                                listFilters={listFilters}
                                isCheckboxDisabled={isCurrentTypeChecked}
                            />
                        </DialogContent>
                        <DialogActions style={{ padding: 24 }}>
                            <Button color="primary" variant="outlined" onClick={handleDialogCancel}>
                                Отменить
                            </Button>
                            <Button color="primary" variant="contained" onClick={handleDialogClose}>
                                Применить
                            </Button>
                        </DialogActions>
                    </>
                )}
            </Dialog>
        </div>
    );
};

const component = pipe(OrdersFilters, connect(mapState, mapDispatch), withStyles(styles));
export { component as OrdersFilters };

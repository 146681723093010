import React, { FunctionComponent, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { connect } from 'react-redux';

import { pipe } from 'fp-ts/function';

import { Button, Card, CircularProgress, FormControl, TextField, Typography } from '@material-ui/core';

import { isUserGranted } from '../../utils/user';
import { generatePassword } from '../../utils/view';

import { makeMapState } from '../../store/root';
import { apiProfileChangePassword, makeMapDispatch } from '../../store/dispatch';

import { useAlerts } from '../../hooks/noty';

import { PasswordField } from '../../components/PasswordField/PasswordField';

type FormData = Protocol.ProfileChangePasswordRequest;

const mapState = makeMapState((state) => ({
    userProfile: state.user.profile,
}));
const mapDispatch = makeMapDispatch({
    emitChangePassword: apiProfileChangePassword,
});
type ProfileCredentialsProps = ReturnType<typeof mapState> & ReturnType<typeof mapDispatch>;
const ProfileCredentials: FunctionComponent<ProfileCredentialsProps> = (props) => {
    const { userProfile, emitChangePassword } = props;

    const { register, errors, setError, clearError, handleSubmit, reset, watch, setValue } = useForm<FormData>();
    const { handleApiError, showNotification } = useAlerts<FormData>(setError);

    const [loading, setLoading] = useState(false);
    const [isForceNewPasswordShown, setForceNewPasswordShown] = useState(false);
    const [isForceConfirmPasswordShown, setForceConfirmPasswordShown] = useState(false);
    const [cantUpdate, setCantUpdate] = useState(false);

    const onSubmit = (payload: FormData): void => {
        clearError();
        setLoading(true);
        emitChangePassword(payload)
            .then(() => {
                showNotification('Пароль изменен', { variant: 'success' });
                reset();
            })
            .catch(handleApiError)
            .finally(() => setLoading(false));
    };

    const makePassword = (): void => {
        const newPassword = generatePassword();
        setValue('new_password', newPassword);
        setValue('new_password_confirm', newPassword);
        setForceNewPasswordShown(true);
        setForceConfirmPasswordShown(true);
    };

    const values = watch();
    useEffect(() => {
        setCantUpdate(!(values.old_password && values.new_password && values.new_password_confirm));
    }, [values]);

    return (
        <Card className="ProfileSettings__card">
            <Typography variant="h5" gutterBottom className="ProfileSettings__cardTitle">
                Для входа
            </Typography>

            <form className="ProfileSettings__form" onSubmit={handleSubmit(onSubmit)}>
                <FormControl variant="outlined" className="ProfileSettings__formItem">
                    <TextField
                        inputRef={register}
                        name="login"
                        variant="outlined"
                        label="Логин"
                        type="text"
                        value={userProfile?.login}
                        disabled
                    />
                </FormControl>
                <FormControl variant="outlined" className="ProfileSettings__formItem">
                    <PasswordField
                        inputRef={register}
                        onChange={(event) => setValue('old_password', event.currentTarget.value)}
                        name="old_password"
                        variant="outlined"
                        label="Старый пароль"
                        type="password"
                        error={!!errors.old_password}
                        helperText={errors.old_password?.message}
                        disabled={!isUserGranted(userProfile, 'change_password')}
                    />
                </FormControl>
                <FormControl variant="outlined" className="ProfileSettings__formItem">
                    <PasswordField
                        inputRef={register}
                        onChange={(event) => setValue('new_password', event.currentTarget.value)}
                        name="new_password"
                        variant="outlined"
                        label="Новый пароль"
                        value={values.new_password || ''}
                        error={!!errors.new_password}
                        helperText={errors.new_password?.message}
                        isForcePasswordShown={isForceNewPasswordShown}
                        onTogglePasswordVisibility={() => setForceNewPasswordShown(false)}
                        disabled={!isUserGranted(userProfile, 'change_password')}
                    />
                </FormControl>
                <FormControl
                    variant="outlined"
                    className="ProfileSettings__formItem ProfileSettings__formItem--noGutter"
                >
                    <PasswordField
                        inputRef={register}
                        onChange={(event) => setValue('new_password_confirm', event.currentTarget.value)}
                        name="new_password_confirm"
                        variant="outlined"
                        label="Новый пароль еще раз"
                        value={values.new_password_confirm || ''}
                        error={!!errors.new_password_confirm}
                        helperText={errors.new_password_confirm?.message}
                        isForcePasswordShown={isForceConfirmPasswordShown}
                        onTogglePasswordVisibility={() => setForceConfirmPasswordShown(false)}
                        disabled={!isUserGranted(userProfile, 'change_password')}
                    />
                </FormControl>

                {isUserGranted(userProfile, 'change_password') && (
                    <Button
                        variant="text"
                        color="primary"
                        className="ProfileSettings__generate"
                        size="small"
                        onClick={makePassword}
                    >
                        Сгенерировать
                    </Button>
                )}

                <footer className="ProfileSettings__formFooter">
                    <div className="ProfileSettings__formSubmit">
                        <Button
                            type="submit"
                            variant="contained"
                            size="large"
                            color="primary"
                            disabled={loading || cantUpdate}
                        >
                            Сохранить
                        </Button>

                        {loading && (
                            <CircularProgress
                                className="ProfileSettings__formSubmitLoading"
                                variant="indeterminate"
                                size={16}
                            />
                        )}
                    </div>
                </footer>
            </form>
        </Card>
    );
};

const component = pipe(ProfileCredentials, connect(mapState, mapDispatch));
export { component as ProfileCredentials };

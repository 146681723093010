import { FeedActionTypes, SET_FEED_DATA, SET_FEED_STATS } from './types';

export const setFeedStats = (data: number): FeedActionTypes => ({
    type: SET_FEED_STATS,
    data,
});

export const setFeedData = (data: Protocol.FeedListResponse, isResetData?: boolean): FeedActionTypes => ({
    type: SET_FEED_DATA,
    data: { ...data, isResetData },
});

import { PaginatorPageSize } from './types';
import { WeekdayType } from '../types/order-reports';
import { NotificationEntityTypes } from './notifications';

export interface EnvProperties {
    NODE_ENV: 'development' | 'production' | 'test';
    PUBLIC_URL: string;
    REACT_APP_ENV: 'local' | 'testing' | 'staging' | 'production';
    REACT_APP_SENTRY_DSN: string;
    REACT_APP_SITE_NAME: string;
    REACT_APP_ROOT_PATH: string;
    REACT_APP_YANDEX_METRICA_ACCOUNT: string;
    REACT_APP_FEATURE_DOWNLOAD_ORDER_DOCUMENT: boolean;
}

export const PaginatorPageSizes: PaginatorPageSize[] = ['10', '20', '50'];

export const constZero = (): number => 0;

export const OrderTypeStatusNames = {
    Import: 'Импорт',
};

export const NewPersonalCabinetUrl = 'https://lk.transitllc.ru/new';

// Для случая, когда заходим по прямой ссылке /orders?page=500
// Так как по умолчанию открывается фильтр "текущие заказы",
// а нужно как-то различать текущие и все (все = is_archived = null)
export const IsArchivedNullUrlQueryParam = -1;

export const UrlQueryParams = {
    Page: 'page',
    IsArchived: 'is_archived',
};

export const FormLimits = {
    DisputeMessage: {
        Min: 5,
        Max: 200,
    },
};

export const EnabledFeatures = {};

export const daysOfWeek: WeekdayType[] = [
    {
        id: 'monday',
        name: 'Пн',
    },
    {
        id: 'tuesday',
        name: 'Вт',
    },
    {
        id: 'wednesday',
        name: 'Ср',
    },
    {
        id: 'thursday',
        name: 'Чт',
    },
    {
        id: 'friday',
        name: 'Пт',
    },
    {
        id: 'saturday',
        name: 'Сб',
    },
    {
        id: 'sunday',
        name: 'Вс',
    },
];

type OrderEntitySingleType = {
    key: NotificationEntityTypes;
    name: string;
};
export const NotificationsEntityItems: OrderEntitySingleType[] = [
    { key: 'sms', name: 'SMS' },
    { key: 'email', name: 'Email' },
    { key: 'telegram', name: 'Telegram' },
    { key: 'feed', name: 'На сайте' },
];

import React, { FunctionComponent } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { RootState } from '../store/root';
import { setPageWhiteBackground } from '../utils/view';

type PrivateProps = {
    redirectTo?: string;
    children: JSX.Element;
    exact?: boolean;
    path: string | string[];
    isWhiteBackground?: boolean;
};

type StoreProps = { isAuthenticated: boolean };
const mapState = (state: RootState): StoreProps => ({
    isAuthenticated: state.user.isAuthenticated,
});

type PrivateRouteProps = PrivateProps & StoreProps;

const PrivateRoute: FunctionComponent<PrivateRouteProps> = (props) => {
    const { isAuthenticated, path, redirectTo = '/login', isWhiteBackground = false, exact = false, children } = props;

    return (
        <Route
            path={path}
            exact={exact}
            render={({ location }) => {
                setPageWhiteBackground(isWhiteBackground);

                return isAuthenticated ? (
                    children
                ) : (
                    <Redirect
                        to={{
                            pathname: redirectTo,
                            state: { from: location },
                        }}
                    />
                );
            }}
        />
    );
};

const connected = connect(mapState)(PrivateRoute);
export { connected as PrivateRoute };

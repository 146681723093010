import React, { FunctionComponent } from 'react';

import { makeStyles, createStyles, Button } from '@material-ui/core';
import { WarningTwoTone } from '@material-ui/icons';
import { yellow } from '@material-ui/core/colors';

import { isString, isArray } from '../utils/guards';

const useStyles = makeStyles(() =>
    createStyles({
        container: {
            height: '80vh',
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'column',
            justifyContent: 'center',
        },
        label: {
            marginTop: '0.5em',
            fontSize: '1.25em',
        },
        icon: {
            color: yellow[600],
            fontSize: '8em',
        },
    })
);

type Label = string | null;

type FetchErrorProps = { title: Label | Label[]; onRetry?: () => void };
export const FetchError: FunctionComponent<FetchErrorProps> = (props) => {
    const { title, onRetry, children } = props;

    const classes = useStyles();

    return (
        <section className={classes.container}>
            <WarningTwoTone className={classes.icon} />
            <>
                {isArray(title) ? (
                    title.filter(isString).map((label) => (
                        <div key={btoa(label)} className={classes.label}>
                            {label}
                        </div>
                    ))
                ) : (
                    <div className={classes.label}>{title}</div>
                )}
            </>
            <div>
                {children}
                {onRetry instanceof Function && (
                    <Button variant="outlined" style={{ marginTop: '1em' }} onClick={onRetry}>
                        Повторить
                    </Button>
                )}
            </div>
        </section>
    );
};

import { useLocation, useHistory } from 'react-router-dom';

import { pipe } from 'fp-ts/function';
import { fold, fromNullable } from 'fp-ts/Option';

import { IsArchivedNullUrlQueryParam, UrlQueryParams } from '../utils/const';
import { getPathName } from '../utils/location';
import { OrderListFiltersValues } from '../store/orders/types';

const rootPath = process.env.REACT_APP_ROOT_PATH;

type ArchivedValues = Exclude<OrderListFiltersValues, number | undefined>;

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const useUrl = () => {
    const location = useLocation();
    const history = useHistory();

    const replaceUrl = (queryParams: URLSearchParams): void => {
        history.replace({
            pathname: getPathName(),
            search: queryParams.toString(),
        });
    };

    const addQueryParam = (key: string, value: string, withReplaceUrl = false): URLSearchParams => {
        const queryParams = new URLSearchParams(location.search);
        if (queryParams.has(key)) {
            queryParams.set(key, value);
        } else {
            queryParams.append(key, value);
        }

        if (withReplaceUrl) replaceUrl(queryParams);

        return queryParams;
    };

    const addQueryParams = (params: Record<string, string>, withReplaceUrl = false): URLSearchParams => {
        const queryParams = new URLSearchParams(location.search);

        Object.entries(params).forEach(([key, value]) => {
            if (queryParams.has(key)) {
                queryParams.set(key, value);
            } else {
                queryParams.append(key, value);
            }
        });

        if (withReplaceUrl) replaceUrl(queryParams);

        return queryParams;
    };

    const getQueryParam = (paramName: string): string | null => new URLSearchParams(location.search).get(paramName);

    const removeQueryParam = (paramName: string): boolean => {
        const queryParams = new URLSearchParams(location.search);

        if (queryParams.has(paramName)) {
            queryParams.delete(paramName);
            history.replace({ search: queryParams.toString() });
            return true;
        }

        return false;
    };

    const changePaginationPageUrlParam = (page: number): void => {
        addQueryParam(UrlQueryParams.Page, String(page), true);
    };

    const paginationPageParamValue = pipe(
        fromNullable(getQueryParam(UrlQueryParams.Page)),
        fold(
            () => 1,
            (v) => Number(v)
        )
    );

    const clearUrlRootPath = (path: string): string =>
        rootPath && rootPath !== '/' ? path.replace(rootPath, '') : path;

    const initialIsArchivedValue = (valueOnEmpty: ArchivedValues): ArchivedValues =>
        pipe(
            fromNullable(getQueryParam(UrlQueryParams.IsArchived)),
            fold(
                () => valueOnEmpty,
                (v) => (Number(v) === IsArchivedNullUrlQueryParam ? null : !!Number(v))
            )
        );

    return {
        paginationPageParamValue,
        initialIsArchivedValue,
        addQueryParam,
        addQueryParams,
        getQueryParam,
        removeQueryParam,
        changePaginationPageUrlParam,
        clearUrlRootPath,
    };
};

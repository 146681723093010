import React, { createRef, FunctionComponent, useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';

import { pipe } from 'fp-ts/function';

import { Pagination } from '@material-ui/lab';

import { Bill, BillsFiltersPayload, BillsSortBy, BillsTotals } from '../../store/bills/types';
import { makeMapState } from '../../store/root';
import { makeMapDispatch } from '../../store/dispatch';
import { removeSeenBill } from '../../store/settings/actions';

import { PaginatorPageSizes } from '../../utils/const';
import { PaginatorPageSize } from '../../utils/types';
import { formatCurrency } from '../../utils/view';
import { pluralize } from '../../utils/strings';

import { useSorts } from '../../hooks/sorts';

import { PageSizeSelect } from '../PageSizeSelect/PageSizeSelect';
import { BillCard } from '../BillCard/BillCard';
import { Dispute } from '../Dispute';
import { DisputeConfirm } from '../DisputeConfirm';

import { SortHeader } from './components/SortHeader';

import './BillsList.scss';

const mapState = makeMapState((state) => ({
    seenBills: state.settings.seenBills,
    lastUserSeenAt: state.settings.currentLastUserSeenAt,
}));

const mapDispatch = makeMapDispatch({ removeSeenBillFromStorage: removeSeenBill });

type BillsListProps = {
    items: Bill[];
    totals: BillsTotals[];
    totalPages: number;
    currentPage: number;
    onPageChange: (page: number) => void;
    onSortChange: (sortBy: BillsSortBy) => void;
    currentPageSize: PaginatorPageSize;
    onPageSizeChange: (pageSize: PaginatorPageSize) => void;
    showPaginator: boolean;
    disablePaginator: boolean;
    filtersPayload: BillsFiltersPayload;
    addBillsToSeenStorage: (items: Bill[]) => void;
    billsForceSeenIds: number[];
    addBillsToForceSeen: (bills: Bill[]) => void;
} & ReturnType<typeof mapState> &
    ReturnType<typeof mapDispatch>;

const BillsList: FunctionComponent<BillsListProps> = (props) => {
    const {
        items,
        totals,
        currentPage,
        totalPages,
        currentPageSize,
        onPageChange,
        onSortChange,
        onPageSizeChange,
        showPaginator,
        disablePaginator,
        addBillsToSeenStorage,
        seenBills,
        lastUserSeenAt,
        removeSeenBillFromStorage,
        billsForceSeenIds,
        addBillsToForceSeen,
    } = props;

    /*
        Необходимо при первом заходе добавлять счета, которые видны на экране в список просмотренных.
        window.addEventListener('beforeunload', callback) не добавляет в local storage
        Поэтому, добавляем в список и добавляем фейковую "жирность", мол новый, а при новом заходе уже
        будет обычный просмотренный счёт
    */
    const [fakeSeenBillsListIds, setFakeSeenBillsListIds] = useState<number[]>([]);
    const [openedCard, setOpenedCard] = useState<number | null>(null);
    const [itemToDispute, setItemToDispute] = useState<Bill | null>(null);
    const [disputeCancel, setDisputeCancel] = useState<Bill | null>(null);
    const { sorts, handleChange } = useSorts('bills');
    const [totalsContainerElement, setTotalsContainerElement] = useState<HTMLDivElement | null>(null);

    const closeDisputeModal = (): void => setItemToDispute(null);

    const listWrapperRef = createRef<HTMLDivElement>();

    const totalsContainerRef = useCallback((node) => {
        if (node !== null) {
            setTotalsContainerElement(node);
        }
    }, []);

    const handleElementSyncScroll = (event: React.UIEvent<HTMLElement>, element: HTMLDivElement | null): void => {
        if (!element) return;
        element.scroll(event.currentTarget.scrollLeft, 0);
    };

    const isBillAlreadySeen = (bill: Bill): boolean => seenBills.includes(bill.id);
    const isBillForceSeen = (bill: Bill): boolean => billsForceSeenIds.includes(bill.id);
    const isBillNotSeenFake = (bill: Bill): boolean => fakeSeenBillsListIds.includes(bill.id) && !isBillForceSeen(bill);

    useEffect(() => {
        items.forEach((bill) => {
            if (!isBillAlreadySeen(bill) || lastUserSeenAt === null) {
                setFakeSeenBillsListIds((v) => [...v, bill.id]);
                addBillsToSeenStorage([bill]);
            } else if (moment(bill.changed_at).isAfter(moment(lastUserSeenAt))) {
                removeSeenBillFromStorage(bill.id);
            }
        });
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const totalsOffset = (): number =>
        totalsContainerElement && totalsContainerElement.clientHeight ? totalsContainerElement.clientHeight : 0;

    const isActiveItem = (id: number): boolean => id === openedCard;
    const setCloseConfirm = (): void => setDisputeCancel(null);

    return (
        <div className="BillsList">
            <div
                className="BillsList__itemsWrapper"
                ref={listWrapperRef}
                onScroll={(event) => handleElementSyncScroll(event, totalsContainerElement)}
                style={{ marginBottom: totalsOffset() }}
            >
                <SortHeader
                    sorts={sorts}
                    onChange={(by) => {
                        onSortChange(by);
                        handleChange(by);
                    }}
                />

                <div className="BillsList__items">
                    {items.map((item) => (
                        <div key={item.id} className="BillsList__item">
                            <BillCard
                                item={item}
                                isOpen={isActiveItem(item.id)}
                                setOpen={() => setOpenedCard(isActiveItem(item.id) ? null : item.id)}
                                isBillAlreadySeen={isBillAlreadySeen(item)}
                                isBillNotSeenFake={isBillNotSeenFake(item)}
                                isBillForceSeen={isBillForceSeen(item)}
                                addBillToForceSeen={(bill) => addBillsToForceSeen([bill])}
                                setItemToDispute={setItemToDispute}
                                setDisputeCancel={setDisputeCancel}
                            />
                        </div>
                    ))}
                </div>

                <div className="BillsList__listFooter">
                    <PageSizeSelect value={currentPageSize} options={PaginatorPageSizes} onChange={onPageSizeChange} />
                    {showPaginator && (
                        <div className="BillsList__pagination">
                            <Pagination
                                shape="rounded"
                                page={currentPage}
                                count={totalPages}
                                disabled={disablePaginator}
                                onChange={(_, page) => {
                                    addBillsToForceSeen(items);
                                    onPageChange(page);
                                }}
                            />
                        </div>
                    )}
                </div>
            </div>

            <div className="BillsTotals">
                <div className="App__container BillsTotals__container" ref={totalsContainerRef}>
                    {totals.map((item) => (
                        <div className="App__billsRow">
                            <div className="BillsTotals__column BillsTotals__column--first">
                                <span className="capitalize">Итого</span>: {item.count}{' '}
                                {pluralize(item.count, ['счет', 'счета', 'счетов'])}&nbsp;({item.currency.name})
                            </div>
                            <div className="BillsTotals__column">
                                {formatCurrency(item.amount)}&nbsp;{item.currency.name}
                            </div>
                            <div className="BillsTotals__column">
                                {formatCurrency(item.amount_payments)}&nbsp;{item.currency.name}
                            </div>
                            <div className="BillsTotals__column">
                                {formatCurrency(item.amount_debt)}&nbsp;{item.currency.name}{' '}
                            </div>
                            <div className="BillsTotals__column BillsTotals__column--last">
                                {item.amount_delayed_debt > 0 && (
                                    <span className="alert">
                                        {formatCurrency(item.amount_delayed_debt)}&nbsp;{item.currency.name} просрочено
                                    </span>
                                )}
                            </div>
                        </div>
                    ))}
                </div>
            </div>

            {itemToDispute && (
                <Dispute bill={itemToDispute} handleClose={closeDisputeModal} afterDisputeUpdate={closeDisputeModal} />
            )}
            <DisputeConfirm
                bill={disputeCancel}
                isOpen={disputeCancel !== null}
                setClose={setCloseConfirm}
                afterDisputeClose={setCloseConfirm}
            />
        </div>
    );
};

const EnchantedComponent = pipe(BillsList, connect(mapState, mapDispatch));
export { EnchantedComponent as BillsList };

import React, { FunctionComponent } from 'react';
import Input from 'react-input-mask';
import { fromNullable, isSome } from 'fp-ts/Option';
import { sanitizePhone } from '../../utils/view';
import { stripHTMLTags } from '../../utils/strings';

type Props = {
    mask: string;
    value?: string | null;
    onChange?(value: string): void;
    disabled?: boolean;
};

const transferDataReader = (data: DataTransferItem): Promise<string> =>
    new Promise((resolve) => data.getAsString(resolve));

export const MaskInput: FunctionComponent<Props> = (props) => {
    const save = (value: string): void => {
        if (props.onChange) props.onChange(sanitizePhone(value));
    };

    const handleChange = (evt: React.ChangeEvent<HTMLInputElement>): void => {
        evt.persist();
        save(evt.target.value);
    };

    const handlePaste = async (e: React.ClipboardEvent<HTMLInputElement>): Promise<void> => {
        e.persist();

        const transferData = fromNullable(e.clipboardData.items[0]);
        if (isSome(transferData)) {
            const rawPasteText = await transferDataReader(transferData.value);
            // html tags are removed due to bug Safari which include them
            const stripedRawText = stripHTMLTags(rawPasteText);
            const pasteText = sanitizePhone(stripedRawText).slice(0, 11);
            save(pasteText.length === 11 ? pasteText.replace(/^8/, '7') : pasteText);
        }
    };

    return (
        <Input
            mask={props.mask}
            value={props.value || ''}
            disabled={props.disabled}
            onPaste={handlePaste}
            onChange={handleChange}
        >
            {props.children}
        </Input>
    );
};

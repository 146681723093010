import React, { FunctionComponent, useState } from 'react';
import { DraggableProvidedDragHandleProps } from 'react-beautiful-dnd';
import clsx from 'clsx';

import { ExpandMore, SwapVert } from '@material-ui/icons';
import {
    Checkbox,
    ExpansionPanel,
    ExpansionPanelDetails,
    ExpansionPanelSummary,
    FormControlLabel,
} from '@material-ui/core';

import { ReportColumn } from '../../types/order-reports';

import { DroppableColumns } from '../DroppableColumns/DroppableColumns';

export type ColumnsGroup = {
    name: string;
    selected: boolean;
    columns: ReportColumn[];
    partiallySelected: boolean;
};

type ExpandableColumnsGroupProps = {
    group: ColumnsGroup;
    parentDragHandleProps?: DraggableProvidedDragHandleProps;
    moveColumn: (group: string, currentIndex: number, destinationIndex: number) => void;
    toggleItem: (groupName: string, columnId: number) => void;
    toggleGroup: (groupName: string) => void;
};

export const ExpandableColumnsGroup: FunctionComponent<ExpandableColumnsGroupProps> = (props) => {
    const { group, parentDragHandleProps, toggleItem, moveColumn, toggleGroup } = props;

    const [isTypeOpen, setTypeOpen] = useState(false);

    return (
        <ExpansionPanel
            className='DownloadSettings__card DownloadSettings__card--lastLevel'
            expanded={isTypeOpen}
            onChange={() => setTypeOpen((value) => !value)}
        >
            <ExpansionPanelSummary
                className='DownloadSettings__summary DownloadSettings__summary--lastLevel DownloadSettings__summary--smallOffset'
            >
                <div className='DownloadSettings__headingWrapper'>
                    <FormControlLabel
                        className='DownloadSettings__checkboxControlLabel'
                        label={group.name}
                        onClick={(event) => event.stopPropagation()}
                        onFocus={(event) => event.stopPropagation()}
                        control={
                            <Checkbox
                                indeterminate={group.partiallySelected && !group.selected}
                                color='primary'
                                checked={group.partiallySelected || group.selected}
                                onChange={() => toggleGroup(group.name)}
                            />
                        }
                    />
                    <ExpandMore
                        className={clsx(
                            'DownloadSettings__icon',
                            'DownloadSettings__icon--noMargin',
                            isTypeOpen && 'DownloadSettings__icon--rounded',
                        )}
                    />
                    <div {...parentDragHandleProps} className='DownloadSettings__secondIconWrapper'>
                        <SwapVert />
                    </div>
                </div>
            </ExpansionPanelSummary>

            <ExpansionPanelDetails
                className='DownloadSettings__details DownloadSettings__details--lastLevel DownloadSettings__details--smallOffset'>
                <DroppableColumns
                    toggleItem={toggleItem}
                    moveColumn={moveColumn}
                    columns={group.columns}
                    groupName={group.name}
                />
            </ExpansionPanelDetails>
        </ExpansionPanel>
    );
};

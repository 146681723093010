/* eslint-disable react/no-array-index-key */
import React, { FunctionComponent } from 'react';

import { TableSection } from '../../store/orders/types';
import { formattedConsolidationTableValue } from '../../utils/view';

import './ConsolidationTable.scss';

type SectionTableProps = {
    table: TableSection;
};

export const SectionTable: FunctionComponent<SectionTableProps> = (props) => {
    const { table } = props;

    if (table.value.length === 0) return null;

    return (
        <div className="SectionTable">
            <div className="SectionTable__row SectionTable__head">
                {table.value[0].map((column, index) => (
                    <span className="SectionTable__cell SectionTable__headItem" key={index}>
                        {column.title}
                    </span>
                ))}
            </div>

            {table.value.map((row, index) => (
                <div className="SectionTable__row" key={index}>
                    {row.map((column, columnIndex) => (
                        <span className="SectionTable__cell" key={columnIndex}>
                            <span className="SectionTable__mobileKey">{column.title}</span>
                            <span>{formattedConsolidationTableValue(column.value) !== null ? formattedConsolidationTableValue(column.value) : '—'}</span>
                        </span>
                    ))}
                </div>
            ))}
        </div>
    );
};

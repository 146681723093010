import React, { FunctionComponent, useState } from 'react';
import { connect } from 'react-redux';

import clsx from 'clsx';

import { pipe } from 'fp-ts/function';

import { useHistory } from 'react-router-dom';
import { createStyles, StyleRules, withStyles } from '@material-ui/styles';
import { AttachFile, Error, ExpandMore } from '@material-ui/icons';
import NotificationsIcon from '@material-ui/icons/Notifications';
import {
    Button,
    ExpansionPanel,
    ExpansionPanelDetails,
    ExpansionPanelSummary,
    IconButton,
    SvgIcon,
    Tooltip,
    WithStyles,
} from '@material-ui/core';

import { Container, OrderListItem } from '../store/orders/types';

import { foldEventDate, formatDate } from '../utils/view';
import { ROUTES } from '../utils/location';

import { apiOrderContainerPackageList, makeMapDispatch } from '../store/dispatch';

import { useMousePropagation } from '../hooks/mousePropagation';
import { useComments } from '../hooks/comments';
import { useDocumentsLoader } from '../hooks/documentsLoader';

import { ContainerBadge } from './OrderItem/ContainerBadge';
import { Notification } from './OrderItem/Notification';
import { OrderNumber } from './OrderItem/OrderNumber';
import { Status } from './OrderItem/Status';
import { Event } from './OrderItem/Event';

import { LoadingButton } from './LoadingButton';
import { FetchError } from './FetchError';

import { TextLiveEdit } from './input/TextLiveEdit';

import { ReactComponent as RoubleIcon } from '../assets/rouble.svg';
import { ReactComponent as InProgressIcon } from '../assets/inProgress.svg';
import { ReactComponent as ReadyIcon } from '../assets/ready.svg';
import { makeMapState } from '../store/root';
import { ConsolidationModal } from './ConsolidationModal/ConsolidationModal';
import { pluralize } from '../utils/strings';
import { ContainerNotificationsSettingsModal } from './container/NotificationSettingsModal/ContainerNotificationsSettingsModal';

// Component CSS-in-JS styles
const styles = (): StyleRules =>
    createStyles({
        content: {
            maxWidth: '100%',
            userSelect: 'text',
        },
        focused: {
            'html:root &': {
                backgroundColor: 'initial',
            },
        },
        button: {
            minWidth: 'auto',
        },
    });

const mapDispatch = makeMapDispatch({ getContainerDocuments: apiOrderContainerPackageList });
const mapState = makeMapState((state) => ({ documentsStorage: state.documents.items }));

type ExpandableOrderCardProps = {
    order: OrderListItem;
    isOpen?: boolean;
    setOpen: () => void;
    isMinified?: boolean;
    onRefetch?: () => void;
} & WithStyles<typeof styles> &
    ReturnType<typeof mapDispatch> &
    ReturnType<typeof mapState>;
const ExpandableOrderCard: FunctionComponent<ExpandableOrderCardProps> = (props) => {
    const {
        order,
        classes,
        isOpen,
        setOpen,
        isMinified = false,
        getContainerDocuments,
        documentsStorage,
        onRefetch,
    } = props;

    const { buildUpdateOrderComment } = useComments();
    const mousePropagation = useMousePropagation();
    const { push } = useHistory();
    const { downloadContainerDocument } = useDocumentsLoader();
    const [error, setError] = useState<string | null>(null);
    const [modalOpen, setModalOpen] = useState(false);

    const [selectedContainerForSettings, setSelectedContainerForSettings] = useState<Container | null>(null);
    const [isSubscriptionSettingsModalOpen, setSubscriptionSettingsModalOpen] = useState(false);

    const latestEvent = order.latest_any_critical_event || order.latest_self_critical_event;

    const commentValue = order.customer_comment || order.manager_comment;
    const saveHandler = buildUpdateOrderComment(order.id);

    const statuses = [];
    if (order.earliest_status && order.earliest_status.status_id !== order.latest_status?.status_id)
        statuses.push(order.earliest_status.name);
    if (order.latest_status) statuses.push(order.latest_status.name);

    const isContainerDocumentsLoading = (containerId: number): boolean => {
        const key = `container_${containerId}`;

        return Boolean(documentsStorage.find((item) => item.key === key));
    };

    const partialStatus =
        order.consolidation_row_count !== null &&
        order.consolidation_shipper_ready_count !== null &&
        order.consolidation_shipper_received_count !== null
            ? `Готовность груза: ${order.consolidation_shipper_ready_count} из ${
                  order.consolidation_row_count
              } ${pluralize(order.consolidation_row_count, [
                  'отправителя',
                  'отправителей',
                  'отправителей',
              ])}<br>Консолидировано: ${order.consolidation_shipper_received_count} из ${
                  order.consolidation_row_count
              } ${pluralize(order.consolidation_row_count, ['отправителя', 'отправителей', 'отправителей'])}`
            : null;

    const completedStatus = order.consolidation_status && order.consolidation_status.name;

    const status = order.consolidation_complete ? completedStatus : partialStatus;

    const downloadContainerDocuments = (containerId: number): void => {
        getContainerDocuments({ container_id: containerId })
            .then((response) => {
                const payload = {
                    container_id: containerId,
                    print_form_type_ids: response.items.map((item) => item.id),
                };

                downloadContainerDocument(payload, {
                    payload,
                    key: `container_${containerId}`,
                    type: 'container',
                });
            })
            .catch(() => {
                setError('Не удалось получить документы по контейнеру');
            });
    };

    if (error) {
        return <FetchError onRetry={() => setError(null)} title={error} />;
    }

    return (
        <div
            key={order.id}
            className={clsx('ExpandableCard', isOpen && 'panel-opened', isMinified && 'ExpandableCard--minified')}
        >
            <ExpansionPanel defaultExpanded expanded={isOpen} onChange={setOpen}>
                <ExpansionPanelSummary
                    expandIcon={<ExpandMore />}
                    classes={{ content: classes.content, focused: classes.focused }}
                    className="OrdersListHeader__expandable"
                >
                    <div className="OrdersListRow OrdersListHeader OrdersListRow--large" {...mousePropagation}>
                        <div className="OrdersListRow__first OrdersListHeader__first">
                            <div className="ContainerDetails__idInfoWrapper" style={{ marginBottom: 0 }}>
                                <OrderNumber order={order} />
                            </div>

                            <div
                                onClick={(e) => e.stopPropagation()}
                                className="OrdersListRow__seventh OrdersListHeader__seventh OrdersListRow__liveEdit"
                            >
                                <TextLiveEdit
                                    value={commentValue}
                                    onSave={saveHandler}
                                    placeholder="Заметка для поиска"
                                />
                            </div>
                        </div>

                        <div className="OrdersListRow__second OrdersListHeader__second">
                            {order.container_groups.map((group) => {
                                const containerSize =
                                    group.group_type === 'by_size' ? group.size && `${group.size}ft` : group.type;

                                return (
                                    <p
                                        key={group.group_type === 'by_size' ? Number(group.size) : group.type}
                                        className={clsx(
                                            'OrdersListHeader__collapsedContainerCounters',
                                            isMinified && 'OrdersListHeader__collapsedContainerCounters--minified'
                                        )}
                                    >
                                        {group.containers.length}
                                        {containerSize && ` x ${containerSize}`}
                                    </p>
                                );
                            })}
                        </div>

                        <div className="OrdersListRow__third OrdersListHeader__third">
                            <h3 className="OrdersList__mobileLabel SortButton">Статус</h3>
                            <div
                                className="ContainerRow__statusLabel"
                                dangerouslySetInnerHTML={{
                                    __html: statuses.join('<br><span style="margin-left: 1em"> ••• </span><br>'),
                                }}
                            />
                        </div>

                        <div className="OrdersListRow__fourth OrdersListHeader__fourth">
                            {order.latest_event && (
                                <>
                                    <h3 className="OrdersList__mobileLabel SortButton">Последнее событие</h3>
                                    <p className="ContainerRow__messageLabel">{order.latest_event.name}</p>
                                    <p className="ContainerRow__messageDate">{foldEventDate(order.latest_event)}</p>
                                </>
                            )}
                        </div>

                        <div className="OrdersListRow__fifth OrdersListHeader__fifth">
                            {latestEvent && (
                                <>
                                    <h3 className="OrdersList__mobileLabel SortButton">Уведомления</h3>
                                    <p className="ContainerRow__messageLabel">
                                        {latestEvent.type.id === 'attention_required' && (
                                            <Error className="OrdersListRow__eventIcon OrdersListRow__eventIcon--warning" />
                                        )}

                                        {latestEvent.type.id === 'very_important' && (
                                            <Error className="OrdersListRow__eventIcon OrdersListRow__eventIcon--error" />
                                        )}

                                        <span
                                            dangerouslySetInnerHTML={{
                                                __html: latestEvent.message,
                                            }}
                                        />
                                    </p>
                                    <p className="ContainerRow__messageDate">{formatDate(latestEvent.came_at)}</p>
                                </>
                            )}
                        </div>

                        <div className="OrdersListRow__sixth OrdersListHeader__sixth">
                            <Tooltip title="Настройки уведомлений" placement="left" arrow>
                                <Button
                                    className="OrderCard__button"
                                    classes={{ root: classes.button }}
                                    onClick={(event) => {
                                        event.stopPropagation();
                                        setSubscriptionSettingsModalOpen(true);
                                    }}
                                >
                                    <NotificationsIcon
                                        color={
                                            order.uses_non_default_notifications_settings_set ? 'secondary' : 'inherit'
                                        }
                                    />
                                </Button>
                            </Tooltip>

                            <Tooltip
                                title={status ? <span dangerouslySetInnerHTML={{ __html: status }} /> : ''}
                                placement="left"
                                arrow
                            >
                                <Button
                                    className={clsx('OrderCard__button', !status && 'OrderCard__button--deleted')}
                                    classes={{ root: classes.button }}
                                    onClick={(event) => {
                                        event.stopPropagation();
                                        setModalOpen(true);
                                    }}
                                >
                                    {order.consolidation_complete ? (
                                        <SvgIcon component={ReadyIcon} />
                                    ) : (
                                        <SvgIcon component={InProgressIcon} />
                                    )}
                                </Button>
                            </Tooltip>

                            <Tooltip title="Документы по заказу" placement="left" arrow>
                                <Button
                                    className={clsx(
                                        'OrderCard__button',
                                        order.document_count === 0 && 'OrderCard__button--hidden'
                                    )}
                                    classes={{ root: classes.button }}
                                    onClick={() => push(`${ROUTES.ORDERS}/${order.id}/files`)}
                                >
                                    <span className="OrderCard__buttonDocumentsCount">{order.document_count}</span>
                                    <AttachFile style={{ color: 'rgba(0, 0, 0, 0.6)' }} />
                                </Button>
                            </Tooltip>

                            <Tooltip title="Счета по заказу" placement="left" arrow>
                                <Button
                                    className={clsx(
                                        'OrderCard__button',
                                        order.bill_count === 0 && 'OrderCard__button--hidden'
                                    )}
                                    classes={{ root: classes.button }}
                                    onClick={() => push(`${ROUTES.ORDERS}/${order.id}/bills`)}
                                >
                                    <SvgIcon component={RoubleIcon} htmlColor="rgba(0, 0, 0, 0.6)" />

                                    <span className="OrderCard__buttonLabel">Счета</span>
                                </Button>
                            </Tooltip>
                        </div>
                    </div>
                </ExpansionPanelSummary>

                <ExpansionPanelDetails className="OrdersListWrapper">
                    {order.container_groups.map((group) =>
                        group.containers.map((container) => (
                            <div className="OrdersListRow OrdersListProduct" key={container.id}>
                                <ContainerBadge
                                    container={container}
                                    size={group.group_type === 'by_size' ? group.size : null}
                                    type={group.group_type === 'by_type' ? group.type : null}
                                    isGroupageCargo={order.type.is_groupage_cargo}
                                />
                                <Status container={container} />
                                <Event container={container} />
                                <Notification event={container.latest_critical_event} />

                                <div className="OrdersListRow__sixth OrdersListProduct__sixth">
                                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                                        {container.document_count > 0 && (
                                            <LoadingButton
                                                color="primary"
                                                className="ContainerRow__documentsButton"
                                                loading={isContainerDocumentsLoading(container.id)}
                                                onClick={() => downloadContainerDocuments(container.id)}
                                            >
                                                {`Файлы (${container.document_count})`}
                                            </LoadingButton>
                                        )}

                                        <IconButton
                                            size="small"
                                            onClick={() => {
                                                setSelectedContainerForSettings(container);
                                                setSubscriptionSettingsModalOpen(true);
                                            }}
                                        >
                                            <NotificationsIcon
                                                color={
                                                    container.uses_non_default_notifications_settings_set
                                                        ? 'secondary'
                                                        : 'inherit'
                                                }
                                            />
                                        </IconButton>
                                    </div>
                                </div>
                            </div>
                        ))
                    )}
                </ExpansionPanelDetails>
            </ExpansionPanel>

            <ContainerNotificationsSettingsModal
                isOpen={isSubscriptionSettingsModalOpen}
                container={selectedContainerForSettings}
                order={order}
                onSuccess={onRefetch}
                onClose={() => {
                    setSubscriptionSettingsModalOpen(false);
                    setSelectedContainerForSettings(null);
                }}
            />

            <ConsolidationModal
                updateTime={order.consolidation_updated_at}
                modalOpen={modalOpen}
                handleModalClose={() => setModalOpen(false)}
                orderId={order.id}
            />
        </div>
    );
};

const component = pipe(ExpandableOrderCard, connect(mapState, mapDispatch), withStyles(styles));
export { component as ExpandableOrderCard };

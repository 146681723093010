import React, { FunctionComponent, ReactNode } from 'react';
import { NavLink } from 'react-router-dom';

import { flow } from 'fp-ts/function';

import { Typography, makeStyles, createStyles, Breadcrumbs } from '@material-ui/core';

import type { OrderListItem } from '../store/orders/types';
import type { LightBill } from '../store/bills/types';

import { foldView, formatMomentDateC } from '../utils/view';
import { MomentDateIso } from '../utils/isomorph';

import { useSearch } from '../hooks/search';

import { LoadingFixedCenter } from './LoadingFixedCenter';
import { OrderList } from './OrderList/OrderList';
import { OrdersViewToggler } from './OrdersViewToggler';
import { ROUTES } from '../utils/location';

const useStyles = makeStyles((theme) =>
    createStyles({
        dialogScrollPaper: {
            display: 'block',
            backgroundColor: '#f4f4f4',
        },
        dialogBackdrop: {
            display: 'none',
        },
        togglerMargin: {
            marginBottom: theme.spacing(4),
        },
    })
);

type BillsOrdersSearchProps = {
    bill: LightBill | null;
    list: OrderListItem[];
    loading: boolean;
    paginator: PaginatorPayload;
};

const formatDate = flow(MomentDateIso.from, formatMomentDateC());

export const BillsOrdersSearchResult: FunctionComponent<BillsOrdersSearchProps> = (props) => {
    const { loading, bill, list } = props;

    const classes = useStyles();
    const { searchResultLink } = useSearch();

    const viewState = foldView(list, loading, null);

    let content: ReactNode = null;
    if (bill)
        content = (
            <div className="App__container">
                <div className="BillsView__dialogHeader">
                    <Breadcrumbs aria-label="breadcrumb" separator="•" className="BillsView__breadcrumbs">
                        <NavLink className="FilesView__breadcrumbsLink" color="inherit" to={ROUTES.BILLS}>
                            Взаиморасчеты
                        </NavLink>
                        {searchResultLink('/orders')}
                        {bill && (
                            <Typography color="textSecondary">
                                Заявки по счету №{bill.number} от {formatDate(bill.date)}
                            </Typography>
                        )}
                    </Breadcrumbs>
                </div>

                {viewState(
                    () => 'Пусто',
                    () => 'Загрузка',
                    () => null,
                    (nonEmptyList) => (
                        <>
                            <OrdersViewToggler className={classes.togglerMargin} prefix="bills-view-toggler-item" />
                            <OrderList orders={nonEmptyList} hideSort />
                        </>
                    )
                )}
            </div>
        );

    return (
        <>
            {content}
            <LoadingFixedCenter visible={loading} />
        </>
    );
};

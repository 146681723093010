import React, { FunctionComponent } from 'react';
import { Pagination } from '@material-ui/lab';

import { PaymentsSearchResult } from '../../../store/search/types';
import { ResultWrapperProps } from '../types';

import { PageSizeSelect } from '../../PageSizeSelect/PageSizeSelect';
import { PaymentHistoryList } from '../../PaymentHistoryList/PaymentHistoryList';

import '../../PaymentHistoryList/PaymentHistoryList.scss';

type PaymentsResultProps = ResultWrapperProps<PaymentsSearchResult>;
export const PaymentsResult: FunctionComponent<PaymentsResultProps> = (props) => {
    const { data, loading, paginatorProps, onPageChange } = props;
    return (
        <div className="Orders__searchContainer">
            <div className="Orders__searchHeader">
                <h6 className="Orders__searchResultLabel">
                    Найдено в истории платежей • <span>{data.items_total}</span>
                </h6>
            </div>
            <div className="PaymentHistory__list">
                <PaymentHistoryList items={data.items} fromSearch />
            </div>
            <div className="Orders__listFooter">
                <PageSizeSelect {...paginatorProps} />
                {data.page_last > 1 && (
                    <div className="Orders__pagination">
                        <Pagination
                            shape="rounded"
                            page={data.page_current}
                            count={data.page_last}
                            disabled={loading}
                            onChange={(evt, page) => onPageChange('payments', page)}
                        />
                    </div>
                )}
            </div>
        </div>
    );
};

import React from 'react';
import { ClassNameMap } from '@material-ui/styles';
import { FormControlLabel, Switch } from '@material-ui/core';
import { OrderListFilters } from '../../../types/order';
import { foldFiltersCount } from '../../../shared/filterLogic';

type StatusFiltersProps = {
    classes: ClassNameMap;
    isVertical?: boolean;
    toggleEmbargo: () => void;
    toggleCriticalEventsFilter: () => void;
    flags: {
        embargo: boolean | null;
        archived: boolean | null;
        has_critical_events: boolean | null;
    };
    listFilters: OrderListFilters;
};
export const StatusFilters: React.FC<StatusFiltersProps> = ({
    classes,
    isVertical,
    flags,
    toggleEmbargo,
    toggleCriticalEventsFilter,
    listFilters,
}) => {
    const filtersClasses = isVertical ? classes.statusFiltersVertical : classes.statusFiltersHorizontal;

    const commonFiltersCount =
        flags.has_critical_events && flags.embargo
            ? Math.min(
                  foldFiltersCount(listFilters.has_critical_events, true),
                  foldFiltersCount(listFilters.embargo, true)
              )
            : null;

    return (
        <div className={`${classes.statusFilters} ${filtersClasses}`}>
            <FormControlLabel
                label={`С уведомлениями • ${
                    commonFiltersCount !== null
                        ? commonFiltersCount
                        : foldFiltersCount(listFilters.has_critical_events, true)
                }`}
                checked={flags.has_critical_events || false}
                onClick={toggleCriticalEventsFilter}
                control={<Switch name="banned" color="primary" />}
                className={classes.statusFilter}
                labelPlacement="start"
            />
            <FormControlLabel
                label={`На запрете • ${
                    commonFiltersCount !== null ? commonFiltersCount : foldFiltersCount(listFilters.embargo, true)
                }`}
                checked={flags.embargo || false}
                onClick={toggleEmbargo}
                control={<Switch name="banned" color="primary" />}
                className={classes.statusFilter}
                labelPlacement="start"
            />
        </div>
    );
};

import { Action, AnyAction, Reducer } from 'redux';
import storage from 'redux-persist/lib/storage';

type PropType<T, P extends keyof T> = T[P];

type ActionBaseType = Action & { type: string };

export const resetableReducer = <
    State extends {},
    Actions extends ActionBaseType = AnyAction,
    TriggerActions extends ActionBaseType = Actions
>(
    baseReducer: Reducer<State, Actions>,
    triggerAction: PropType<TriggerActions, 'type'>,
    storageKey?: string
): Reducer<State, Actions> => (state, action) => {
    switch (action.type) {
        case triggerAction:
            if (storageKey !== undefined) {
                storage.removeItem(`persist:${storageKey}`);
            }
            return baseReducer(undefined, action);
        default:
            return baseReducer(state, action);
    }
};

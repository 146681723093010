import React, { FunctionComponent, useState } from 'react';
import { Button, CircularProgress, Dialog, DialogActions, DialogTitle } from '@material-ui/core';

import { pipe } from 'fp-ts/function';
import { connect } from 'react-redux';

import { apiManagedUserDelete, makeMapDispatch } from '../../../store/dispatch';

import { EventBus } from '../../../hooks/bus';
import { useAlerts } from '../../../hooks/noty';

import { User } from '../../../types/user';

import styles from './EmployeeDeleteConfirm.module.scss';

const mapDispatch = makeMapDispatch({ emitDelete: apiManagedUserDelete });

type EmployeeDeleteConfirmProps = {
    employee: User;
    isOpen: boolean;
    onClose(): void;
} & ReturnType<typeof mapDispatch>;
const EmployeeDeleteConfirm: FunctionComponent<EmployeeDeleteConfirmProps> = (props) => {
    const { employee, isOpen, onClose, emitDelete } = props;

    const { handleApiError, showNotification } = useAlerts();

    const [isLoading, setLoading] = useState(false);
    const onConfirm = (): void => {
        if (isLoading) return;

        setLoading(true);
        emitDelete({ user_id: employee.id })
            .then(() => {
                showNotification('Сотрудник удалён', {
                    variant: 'info',
                });
                EventBus.emit('onReFetchEmployees');
                onClose();
            })
            .catch(handleApiError)
            .finally(() => setLoading(false));
    };

    return (
        <Dialog open={isOpen} onClose={onClose} aria-labelledby="logout-confirm-title">
            <DialogTitle id="logout-confirm-title">
                Вы&nbsp;уверены, что хотите удалить {employee.full_name}?
            </DialogTitle>

            <DialogActions>
                <Button disabled={isLoading} onClick={onClose} color="primary">
                    Нет
                </Button>

                <Button disabled={isLoading} onClick={onConfirm} color="primary" autoFocus>
                    <span>Да</span>

                    {isLoading && <CircularProgress className={styles.buttonLoader} size={12} />}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

const component = pipe(EmployeeDeleteConfirm, connect(null, mapDispatch));
export { component as EmployeeDeleteConfirm };

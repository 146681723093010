import { Reducer } from 'redux';

import { Lens } from 'monocle-ts';
import { pipe } from 'fp-ts/function';

import { FeedActionTypes, FeedState, SET_FEED_DATA, SET_FEED_STATS } from './types';

const statsLens = Lens.fromProp<FeedState>()('stats');
const itemsLens = Lens.fromProp<FeedState>()('items');
const earliestShownDateLens = Lens.fromProp<FeedState>()('earliestShownDate');

const initialState: FeedState = {
    stats: 0,
    items: [],
    earliestShownDate: null,
};

const baseReducer: Reducer<FeedState, FeedActionTypes> = (state = initialState, action) => {
    switch (action.type) {
        case SET_FEED_STATS:
            return pipe(state, statsLens.set(action.data));
        case SET_FEED_DATA: {
            return pipe(
                state,
                itemsLens.modify((items) => (action.data.isResetData ? action.data.items : [...items, ...action.data.items])),
                earliestShownDateLens.set(action.data.earliest_shown_date)
            );
        }
        default:
            return state;
    }
};

export const feedReducer = baseReducer;

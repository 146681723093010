import {pipe} from 'fp-ts/function';
import * as Eq from 'fp-ts/Eq';
import * as A from 'fp-ts/Array';

import type {OrderListFilterPayload} from './order';

export type ColumnTypeId = 'fixed_column' | 'status';
export type ColumnType = { id: ColumnTypeId; name: string };
export type ReportColumn = {
    type: ColumnType;
    id: number;
    selected: boolean;
    group: {
        type: 'fixed';
        name: string;
    } | {
        type: 'fixed';
        name: string;
        id: number;
        is_groupage_cargo: boolean;
        is_import: boolean;
    };
    name: string;
};

export type WeekdayTypeId = 'friday' | 'monday' | 'saturday' | 'sunday' | 'thursday' | 'tuesday' | 'wednesday';
export type WeekdayType = {
    id: WeekdayTypeId;
    name: string;
};

export type ReportMailingIntervalSettings = {
    frequency: 'interval_days';
    interval_days: number;
};

export type ReportMailingWeekdaysSettingsWithName = {
    frequency: 'weekdays';
    weekdays: WeekdayType[];
};

export type ReportMailingWeekdaysSettings = {
    frequency: 'weekdays';
    weekdays: WeekdayTypeId[];
};

export type ReportMailingSettingsType = ReportMailingIntervalSettings | ReportMailingWeekdaysSettings;

export type ReportMailingSettings = {
    type: ReportMailingSettingsType;
}

export type ReportMailingSettingsWithWeekdayNames = ReportMailingIntervalSettings | ReportMailingWeekdaysSettingsWithName;

export type ReportListItem = {
    id: number;
    name: string;
    mailing_settings?: ReportMailingIntervalSettings | ReportMailingWeekdaysSettingsWithName;
    is_subscribed: boolean;
};

export type GroupedReportListItem = {
    order_type: ReportsOrderType | null;
    reports: ReportListItem[];
    templates: TemplatesListItem[];
}

export type ReportsOrderType = {
    id: number;
    name: string;
    is_groupage_cargo: boolean;
    is_import: boolean;
}

export type ReportDetailsFiltersAndSorting = InverseOptional<Omit<OrderListFilterPayload, 'bill_id'>>;

export type ReportDetails = {
    id: number;
    name: string;
    columns: ReportColumn[];
    filters_and_sorting: ReportDetailsFiltersAndSorting;
    mailing_settings?: ReportMailingSettingsWithWeekdayNames;
};

export type ReportAddFixedColumn = {
    type: 'fixed_column';
    id: number;
    selected: boolean;
};

export type ReportAddStatusColumn = {
    type: 'status';
    id: number;
    selected: boolean;
};

export type ReportAddColumn = ReportAddFixedColumn | ReportAddStatusColumn;

export type TemplatesListItem = { id: number; name: string };

export type TemplateDetails = Omit<ReportDetails, 'mailing_settings'>;

type WeakColumn = Pick<ReportColumn, 'id' | 'selected'>;

export type ActionButtonDetails = {
    onClick: () => Promise<void> | void;
    icon: JSX.Element;
    text: string;
    id: number;
    color?: string;
};

export type ReportSubscriberEntity = {
    user_id: number;
    name: string;
    is_subscribed: boolean;
};

export const columnEq = pipe(
    Eq.struct<WeakColumn>({id: Eq.eqStrict, selected: Eq.eqStrict}),
    Eq.contramap((column: ReportColumn) => ({id: column.id, selected: column.selected}))
);

export const columnsEq = A.getEq(columnEq);

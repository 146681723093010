import React, { FunctionComponent } from 'react';
import { connect } from 'react-redux';
import clsx from 'clsx';
import { Link } from 'react-router-dom';

import { pipe } from 'fp-ts/function';

import { withStyles } from '@material-ui/styles';
import { Button, WithStyles } from '@material-ui/core';

import { setOpenedCardAction } from '../../store/orders/actions';
import { makeMapDispatch } from '../../store/dispatch';
import { OrderListItem } from '../../store/orders/types';
import { makeMapState } from '../../store/root';

import { useSorts } from '../../hooks/sorts';

import { ROUTES } from '../../utils/location';

import { CommentsProvider } from '../../providers/CommentListProvider';

import { ExpandableOrderCard } from '../ExpandableOrderCard';
import { OrdersViewToggler } from '../OrdersViewToggler';
import { SortButton } from '../SortButton/SortButton';
import { OrderCard } from '../OrderCard';

import { styles } from './styles';
import './OrderList.scss';

import iconExcelFit from '../../assets/file-icons/excel-fit.svg';

type ComponentProps = {
    orders: OrderListItem[];
    hideSort?: boolean;
    onRefetch?: () => void;
};

const mapState = makeMapState((state) => ({
    openedCard: state.orders.openedCard,
    isMinifiedView: state.orders.isMinifiedView,
}));

const mapDispatch = makeMapDispatch({
    setOpenedCard: setOpenedCardAction,
});

type Props = ComponentProps & WithStyles<typeof styles> & ReturnType<typeof mapState> & ReturnType<typeof mapDispatch>;
const OrderList: FunctionComponent<Props> = (props) => {
    const { orders, hideSort, openedCard, setOpenedCard, isMinifiedView, onRefetch } = props;
    const { sorts, handleChange: handleSortButtonClick } = useSorts('orders');

    return (
        <div className="OrdersListBase">
            <CommentsProvider>
                {!hideSort && (
                    <div
                        className={clsx(
                            'OrdersListContainer',
                            'OrdersListRow',
                            'OrdersList__filters',
                            isMinifiedView && 'OrdersListContainer--minified'
                        )}
                    >
                        <div className="OrdersListRow__first">
                            <OrdersViewToggler />
                        </div>

                        <div className="OrdersListRow__third">
                            <SortButton
                                label="Статус"
                                order={sorts.direction}
                                active={sorts.by === 'latest_status_position'}
                                isMinifiedView={isMinifiedView}
                                clickHandler={() => handleSortButtonClick('latest_status_position')}
                            />
                        </div>

                        <div className="OrdersListRow__fourth">
                            <SortButton
                                label="Последнее событие"
                                order={sorts.direction}
                                active={sorts.by === 'latest_event_came_at'}
                                isMinifiedView={isMinifiedView}
                                clickHandler={() => handleSortButtonClick('latest_event_came_at')}
                            />
                        </div>

                        <div className="OrdersListRow__fifth">
                            <SortButton
                                label="Уведомления"
                                order={sorts.direction}
                                active={sorts.by === 'critical_event_came_at'}
                                isMinifiedView={isMinifiedView}
                                clickHandler={() => handleSortButtonClick('critical_event_came_at')}
                            />
                        </div>

                        <div className="OrdersListRow__sixth">
                            <Button
                                variant="text"
                                color="primary"
                                size="large"
                                className="Orders__downloadButton"
                                component={Link}
                                to={ROUTES.DOWNLOAD_SETTINGS}
                            >
                                <img alt="Excel" src={iconExcelFit} style={{ marginRight: 8 }} />
                                <span>Выгрузка&nbsp;.xlsx</span>
                            </Button>
                        </div>
                    </div>
                )}

                {orders.length > 0 && (
                    <div className={clsx('OrdersListBase__items', isMinifiedView && 'OrdersListBase__items--minified')}>
                        {orders.map((order) => {
                            if (
                                order.container_groups.length === 1 &&
                                order.container_groups[0].containers.length === 1
                            ) {
                                return <OrderCard key={order.id} order={order} isMinified={isMinifiedView} onRefetch={onRefetch} />;
                            }
                            return (
                                <ExpandableOrderCard
                                    key={order.id}
                                    order={order}
                                    isOpen={order.id === openedCard}
                                    isMinified={isMinifiedView}
                                    onRefetch={onRefetch}
                                    setOpen={() => {
                                        setOpenedCard(order.id === openedCard ? -1 : order.id);
                                    }}
                                />
                            );
                        })}
                    </div>
                )}
            </CommentsProvider>
        </div>
    );
};

const component = pipe(OrderList, connect(mapState, mapDispatch), withStyles(styles));
export { component as OrderList };

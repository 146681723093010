import React, { FunctionComponent, ReactNode } from 'react';
import clsx from 'clsx';

import './DocumentStatusChanger.scss';
import { useUrl } from '../../hooks/url';
import { IsArchivedNullUrlQueryParam, UrlQueryParams } from '../../utils/const';
import { DocumentsStatusIso } from '../../utils/isomorph';
import { DocumentsStatus } from '../../types/documents';

export type DocumentStatusChangerData = {
    key: DocumentsStatus;
    label: string;
    possibleCount: ReactNode;
};

type DocumentStatusChangerProps = {
    onChange: (data: DocumentsStatus) => void;
    selectedValue: DocumentsStatus;
    data: DocumentStatusChangerData[];
    isVertical?: boolean;
    idPrefix?: string;
    canChangeQuery?: boolean;
    fullWidthOnMobile?: boolean;
    isReportsAdmin?: boolean;
};

export const DocumentStatusChanger: FunctionComponent<DocumentStatusChangerProps> = (props) => {
    const {
        onChange,
        selectedValue,
        data,
        isVertical,
        idPrefix,
        fullWidthOnMobile,
        canChangeQuery = true,
        isReportsAdmin,
    } = props;
    const prefix = idPrefix !== undefined && idPrefix !== '' ? idPrefix : 'radio-changer';

    const { addQueryParams } = useUrl();

    const setIsArchivedQueryParam = (status: DocumentsStatus): void => {
        const value = DocumentsStatusIso.from(status);
        const isShouldBeNull = status === DocumentsStatus.All || value === null;
        addQueryParams(
            {
                [UrlQueryParams.IsArchived]: String(isShouldBeNull ? IsArchivedNullUrlQueryParam : Number(value)),
                [UrlQueryParams.Page]: String(1),
            },
            true
        );
    };

    return (
        <ul
            className={clsx(
                'DocumentStatusChanger',
                isVertical && 'DocumentStatusChanger--vertical',
                fullWidthOnMobile && 'DocumentStatusChanger--fullWidthOnMobile'
            )}
        >
            {data.map((radio) => {
                const fieldId = `${prefix}-${radio.key}`;
                return (
                    <li key={radio.key} className="DocumentStatusChanger__item">
                        <input
                            type="radio"
                            className="DocumentStatusChanger__radio"
                            id={fieldId}
                            checked={selectedValue === radio.key}
                            onChange={() => {
                                onChange(radio.key);
                                if (canChangeQuery) setIsArchivedQueryParam(radio.key);
                            }}
                        />
                        <label htmlFor={fieldId} className="DocumentStatusChanger__label">
                            {radio.label}
                            {!isReportsAdmin && <> • {radio.possibleCount}</>}
                        </label>
                    </li>
                );
            })}
        </ul>
    );
};

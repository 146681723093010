import React, { ChangeEvent, FunctionComponent, useEffect, useMemo, useState } from 'react';
import clsx from 'clsx';

import { Button, Card, FormControlLabel, Radio, RadioGroup, Switch, TextField, Tooltip } from '@material-ui/core';
import { DeleteOutlined as DeleteOutlinedIcon, FileCopyOutlined as FileCopyOutlinedIcon } from '@material-ui/icons';
import SaveIcon from '@material-ui/icons/Save';

import { ReportMailingSettings, WeekdayTypeId } from '../../types/order-reports';

import { countWithLoading } from '../../utils/view';
import { daysOfWeek } from '../../utils/const';
import { pluralize } from '../../utils/strings';

import './DownloadSettingsSidebar.scss';

type SelectedDaysOfWeek = Record<string, boolean | undefined>;

type DownloadSettingsSidebarProps = {
    isCreate: boolean;
    isSavingAvailable: boolean;
    loadingCounts: boolean;
    totalOrderCount: number;
    templateName: string;
    handleTemplateNameChange: (e: ChangeEvent<HTMLInputElement>) => void;
    handleSettingsDownload: () => void;
    defaultMailingSettings?: ReportMailingSettings;
    mailingSettings?: ReportMailingSettings;
    handleMailingSettingsChange: (settings?: ReportMailingSettings) => void;
    handleSettingsSave: () => void;
    handleSettingsClone: () => void;
    handleSettingsDelete: () => void;
    isReportsAdmin: boolean;
};
export const DownloadSettingsSidebar: FunctionComponent<DownloadSettingsSidebarProps> = (props) => {
    const {
        isCreate,
        isSavingAvailable,
        handleMailingSettingsChange,
        totalOrderCount,
        templateName,
        handleTemplateNameChange,
        handleSettingsSave,
        handleSettingsDownload,
        handleSettingsClone,
        handleSettingsDelete,
        defaultMailingSettings,
        loadingCounts,
        isReportsAdmin,
    } = props;

    const [isMailingAvailable, setMailingAvailable] = useState(false);
    const [isPeriod, setIsPeriod] = useState(true);

    const [templateNameError, setTemplateNameError] = useState<string>('');
    const [intervalError, setIntervalError] = useState<string>('');

    const [selectedDaysOfWeek, setSelectedDaysOfWeek] = useState<SelectedDaysOfWeek>({});
    const [interval, setInterval] = useState<number>(1);

    useEffect(() => {
        if (!defaultMailingSettings) return;

        setMailingAvailable(true);

        if (defaultMailingSettings.type.frequency === 'interval_days' && defaultMailingSettings.type.interval_days) {
            setIsPeriod(true);
            setInterval(defaultMailingSettings.type.interval_days);
        }

        if (defaultMailingSettings.type.frequency === 'weekdays' && defaultMailingSettings.type.weekdays) {
            const weekdays: SelectedDaysOfWeek = {};

            defaultMailingSettings.type.weekdays.forEach((item) => {
                weekdays[item] = true;
            });

            setIsPeriod(false);
            setSelectedDaysOfWeek(weekdays);
        }
    }, [defaultMailingSettings]);

    useEffect(() => {
        let payload: ReportMailingSettings | undefined;

        if (isMailingAvailable) {
            if (isPeriod) {
                payload = {
                    type: {
                        frequency: 'interval_days',
                        interval_days: interval,
                    },
                };
            } else {
                const weekdays = (Object.keys(selectedDaysOfWeek) as WeekdayTypeId[]).filter(
                    (day) => selectedDaysOfWeek[day]
                );

                payload = {
                    type: {
                        frequency: 'weekdays',
                        weekdays,
                    },
                };
            }
        }

        handleMailingSettingsChange(payload);
    }, [selectedDaysOfWeek, interval, isMailingAvailable, isPeriod]); // eslint-disable-line react-hooks/exhaustive-deps

    const dayOfWeekChecker = useMemo(
        () => (
            <div className="DownloadSettingsSidebar__days">
                {daysOfWeek.map((item) => (
                    <Button
                        variant="outlined"
                        key={item.id}
                        disabled={!isMailingAvailable}
                        className={clsx(
                            'DownloadSettingsSidebar__day',
                            selectedDaysOfWeek[item.id] && 'DownloadSettingsSidebar__day--active'
                        )}
                        onClick={() => {
                            setSelectedDaysOfWeek((days) => ({ ...days, [item.id]: !(days && days[item.id]) }));
                        }}
                    >
                        {item.name}
                    </Button>
                ))}
            </div>
        ),
        [selectedDaysOfWeek, isMailingAvailable]
    );

    const onIntervalChange = (e: ChangeEvent<HTMLInputElement>): void => {
        const value = Number(e.target.value.replace(/\D/g, ''));

        setInterval(value);
    };

    const onSave = (): void => {
        const hasIntervalError = isMailingAvailable && isPeriod && (interval > 30 || interval < 1);
        const hasTemplateNameError = templateName === '';

        setIntervalError(hasIntervalError ? 'Количество дней должно быть не меньше 1 не больше 30' : '');
        setTemplateNameError(hasTemplateNameError ? 'Введите название выгрузки' : '');

        if (!hasIntervalError && !hasTemplateNameError) handleSettingsSave();
    };

    const textBlurHandler = (): void => {
        setTemplateNameError(templateName.length === 0 ? 'Введите название выгрузки' : '');
    };

    const actionButtons = [
        {
            onClick: handleSettingsClone,
            icon: <FileCopyOutlinedIcon />,
            text: 'Создать копию',
            id: 1,
        },
        {
            onClick: handleSettingsDelete,
            icon: <DeleteOutlinedIcon />,
            text: 'Удалить',
            id: 2,
            color: 'red',
        },
    ];

    return (
        <div className="DownloadSettingsSidebar">
            <Card className="DownloadSettingsSidebar__card">
                <section className={clsx('DownloadSettingsSidebar__section', 'DownloadSettingsSidebar__section--name')}>
                    {!isReportsAdmin && (
                        <span className="DownloadSettingsSidebar__ordersCount">
                            {countWithLoading(
                                `${totalOrderCount} ${pluralize(totalOrderCount, ['заказ', 'заказа', 'заказов'])}`,
                                loadingCounts
                            )}
                        </span>
                    )}

                    <TextField
                        className={clsx('DownloadSettingsSidebar__input', 'DownloadSettingsSidebar__input--name')}
                        name="name"
                        variant="outlined"
                        label="Название"
                        size="medium"
                        value={templateName}
                        error={Boolean(templateNameError)}
                        helperText={templateNameError}
                        onBlur={textBlurHandler}
                        onChange={handleTemplateNameChange}
                    />
                </section>

                {!isReportsAdmin && (
                    <section
                        className={clsx(
                            'DownloadSettingsSidebar__section',
                            'DownloadSettingsSidebar__section--mailing'
                        )}
                    >
                        <div className="DownloadSettingsSidebar__mailingHeadingWrapper">
                            <span className="DownloadSettingsSidebar__mailingHeading">Рассылка</span>
                            <Switch
                                name="need-mailing"
                                color="primary"
                                className="DownloadSettingsSidebar__mailingSwitch"
                                checked={isMailingAvailable}
                                onChange={(e) => setMailingAvailable((value) => !value)}
                            />
                        </div>
                        <RadioGroup
                            className="DownloadSettingsSidebar__mailingPeriod"
                            name="mailing-type"
                            value={isPeriod ? 'period' : 'days-of-week'}
                            onChange={(e) => setIsPeriod(e.target.value === 'period')}
                        >
                            <FormControlLabel
                                disabled={!isMailingAvailable}
                                value="period"
                                control={<Radio color="primary" />}
                                label="Раз в N дней"
                            />
                            <FormControlLabel
                                disabled={!isMailingAvailable}
                                value="days-of-week"
                                control={<Radio color="primary" />}
                                label="По дням недели"
                            />
                        </RadioGroup>

                        {isPeriod ? (
                            <TextField
                                disabled={!isMailingAvailable}
                                className={clsx(
                                    'DownloadSettingsSidebar__input',
                                    'DownloadSettingsSidebar__input--daysCount'
                                )}
                                name="period"
                                variant="outlined"
                                label="Количество дней"
                                size="small"
                                type="number"
                                error={Boolean(intervalError)}
                                helperText={intervalError}
                                onBlur={() => setIntervalError('')}
                                onChange={onIntervalChange}
                                value={interval.toString()}
                            />
                        ) : (
                            dayOfWeekChecker
                        )}
                    </section>
                )}

                <section className={clsx('DownloadSettingsSidebar__section', 'DownloadSettingsSidebar__section--save')}>
                    {!isReportsAdmin &&
                        (isSavingAvailable && !isCreate ? (
                            <Button
                                className="DownloadSettingsSidebar__downloadButton"
                                variant="outlined"
                                color="primary"
                                onClick={handleSettingsDownload}
                            >
                                Скачать .xlsx
                            </Button>
                        ) : (
                            <Tooltip placement="top" title="Cкачивание доступно только после сохранения">
                                <div>
                                    <Button
                                        disabled
                                        className="DownloadSettingsSidebar__downloadButton"
                                        variant="outlined"
                                        color="primary"
                                    >
                                        Скачать .xlsx
                                    </Button>
                                </div>
                            </Tooltip>
                        ))}

                    <Button
                        className="DownloadSettingsSidebar__saveButton"
                        variant="contained"
                        color="primary"
                        startIcon={<SaveIcon />}
                        onClick={onSave}
                    >
                        Сохранить
                    </Button>
                </section>
            </Card>

            {!isCreate && !isReportsAdmin && (
                <Card className="DownloadSettingsSidebar__card DownloadSettingsSidebar__card--buttons">
                    {actionButtons.map((button) => (
                        <Button
                            className={clsx(
                                'DownloadSettingsSidebar__textButton',
                                button.color && button.color === 'red' && 'DownloadSettingsSidebar__textButton--red'
                            )}
                            onClick={() => {
                                button.onClick();
                            }}
                            startIcon={button.icon}
                            key={button.id}
                        >
                            {button.text}
                        </Button>
                    ))}
                </Card>
            )}
        </div>
    );
};

import React, { useMemo, useState, ChangeEvent, FunctionComponent, useRef } from 'react';
import { Prompt } from 'react-router-dom';

import clsx from 'clsx';

import { pipe } from 'fp-ts/function';
import { eqStrict } from 'fp-ts/Eq';

import { Input, CircularProgress, FormHelperText, Fade, Tooltip } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/styles';

import { useAlerts } from '../../hooks/noty';
import { TextLiveEditConfirm } from './components/TextLiveEditConfirm';

const useStyles = makeStyles(() =>
    createStyles({
        root: {
            position: 'relative',
            display: 'flex',
            alignItems: 'flex-start',
            height: 40,
            width: '100%',
            marginRight: 'auto',
        },
        wrapper: {
            top: 10,
            left: -16,
            position: 'absolute',
        },
        input: {
            margin: 0,
            minWidth: '100%',
            color: '#4479A7',
        },
        helperWrapper: {
            position: 'absolute',
            bottom: 0,
            left: 0,
        },
        full: {
            width: '100%',
        },
        realInput: {
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
        },
    })
);

type Props = {
    value: string | null;
    onSave: (value: string) => Promise<void>;
    placeholder?: string;
    maxLength?: number;
    onFocusChange?: (isFocused: boolean) => void;
    className?: string | false;
};

const maxLength = 100;

const TextLiveEdit: FunctionComponent<Props> = (props) => {
    const { value, onSave, placeholder, onFocusChange, className } = props;

    const classes = useStyles();
    const { handleApiError } = useAlerts();

    const [timer, setTimer] = useState(-1);
    const [loading, setLoading] = useState(false);
    const [isTyping, setIsTyping] = useState(false);
    const [isModalOpen, setModalOpen] = useState(false);
    const [inMouseOver, setInMouseOver] = useState(false);
    const [showLimitTooltip, setShowLimitTooltip] = useState(false);

    const [localValue, setLocalValue] = useState(value || '');

    const inputRef = useRef<HTMLInputElement>();
    const isNotEquals = useMemo(() => !eqStrict.equals(value || '', localValue), [value, localValue]);
    const isTruncated = useMemo(
        () => (inputRef.current ? inputRef.current.offsetWidth < inputRef.current.scrollWidth : false),
        [inputRef, localValue] // eslint-disable-line react-hooks/exhaustive-deps
    );

    const isEmptyValueProp = (value || '').length === 0;

    const tooltipVisible = isTyping ? showLimitTooltip : inMouseOver && isTruncated;
    const tooltipTitle = isTyping ? `Максимальная длина комментария ${maxLength} символов` : localValue;

    const handleChange = (evt: ChangeEvent<HTMLInputElement>): void => {
        evt.persist();
        const val = evt.target.value;
        if (val.length > maxLength) {
            setShowLimitTooltip(true);
            clearTimeout(timer);
            setTimer(window.setTimeout(() => setShowLimitTooltip(false), 3000));
        } else setLocalValue(val);
    };

    const handleSave = async (): Promise<void> => {
        if (!isNotEquals || loading) return;

        setLoading(true);
        setIsTyping(false);

        try {
            await onSave(localValue);
        } catch (e) {
            handleApiError(e);
        } finally {
            setLoading(false);
        }
    };

    const setPreviousValueOnDeny = (): void => {
        setLocalValue(value || '');
    };

    const handleOpenModal = (): void => {
        if (!isNotEquals || isEmptyValueProp) return;
        setModalOpen(true);
    };

    const handleFocus: React.FocusEventHandler<HTMLInputElement> = (evt) => {
        setIsTyping(true);
        evt.persist();
        setTimeout(() => {
            const { target } = evt;
            target.setSelectionRange(100, 100);
            target.scrollLeft += 1000;
        }, 20);
        if (onFocusChange) onFocusChange(true);
    };

    const handleBlur = (): void => {
        setIsTyping(false);

        if (!isEmptyValueProp) handleOpenModal();
        else handleSave();

        if (onFocusChange) onFocusChange(false);
    };

    return (
        <>
            <div
                className={clsx('TextLiveEdit', classes.root, className)}
                onMouseEnter={() => setInMouseOver(true)}
                onMouseLeave={() => setInMouseOver(false)}
            >
                <Prompt when={loading} message="Изменения не сохранены, вы хотите продолжить?" />
                <Tooltip placement="top" open={tooltipVisible} title={tooltipTitle} arrow>
                    <div className={classes.full}>
                        <Input
                            value={localValue}
                            onBlur={handleBlur}
                            onFocus={handleFocus}
                            disabled={loading}
                            inputRef={inputRef}
                            onChange={handleChange}
                            placeholder={placeholder}
                            disableUnderline
                            className={clsx('TextLiveEdit__input', classes.input)}
                            inputProps={{ className: classes.realInput }}
                            onKeyDown={(evt) => {
                                evt.persist();
                                if (isNotEquals && (evt.which === 13 || evt.keyCode === 13)) {
                                    evt.preventDefault();
                                    if (isEmptyValueProp) handleSave();
                                    else handleOpenModal();
                                }
                            }}
                        />
                    </div>
                </Tooltip>
                <div className={clsx('TextLiveEdit__helperWrapper', classes.helperWrapper)}>
                    <Fade in={isTyping}>
                        <FormHelperText>
                            {localValue.length}/{maxLength}
                        </FormHelperText>
                    </Fade>
                </div>
                <div className={classes.wrapper}>{loading && <CircularProgress size={12} />}</div>
            </div>

            <TextLiveEditConfirm
                isOpen={isModalOpen}
                setClose={() => setModalOpen(false)}
                setPreviousValue={setPreviousValueOnDeny}
                handleSave={handleSave}
            />
        </>
    );
};

const component = pipe(TextLiveEdit);
export { component as TextLiveEdit };

import { OrderListItem } from '../orders/types';
import { PaginatorPageSize } from '../../utils/types';
import { Bill } from '../bills/types';
import { PaymentHistoryListItem } from '../payment-history/types';
import { UpdateBillsDisputeActionPayload } from '../../types/dispute';
import { UpdateBillsCustomerCommentPayload } from '../../types/comments';

export type SearchResultPagination<T> = {
    items: T[];
    items_total: number;
    page_current: number;
    page_last: number;
    page_size: number;
};

export type SearchQuery = string | null;

export type BillsSearchResult = SearchResultPagination<Bill>;
export type OrdersSearchResult = SearchResultPagination<OrderListItem>;
export type PaymentsSearchResult = SearchResultPagination<PaymentHistoryListItem>;

/// State
export type SearchResultData = {
    bills: BillsSearchResult;
    orders: OrdersSearchResult;
    payments: PaymentsSearchResult;
};

export type SearchSections = keyof SearchResultData;
export type SearchSectionConfig = {
    type: SearchSections;
    page: number;
    // NOTE: умышленно приведено к явному типу, т.к. проблемы объявить декодер + это конфиг на фронте
    page_size: PaginatorPageSize;
};

export type SearchState = {
    query: string | null;
    config: SearchSectionConfig[];
    result: SearchResultData;
};

export type SetSearchConfigPagePayload = {
    type: SearchSections;
    page: number;
};

export type SetSearchConfigPageSizePayload = {
    type: SearchSections;
    pageSize: PaginatorPageSize;
};

/// Actions
export const SET_SEARCH_RESULT = 'SET_SEARCH_RESULT';
export const RESET_SEARCH_RESULT = 'RESET_SEARCH_RESULT';
export const REMEMBER_SEARCH_QUERY = 'REMEMBER_SEARCH_QUERY';
export const UPDATE_SEARCH_DISPUTE = 'UPDATE_SEARCH_DISPUTE';
export const UPDATE_SEARCH_BILLS_CUSTOMER_COMMENT = 'UPDATE_SEARCH_BILLS_CUSTOMER_COMMENT';
export const SET_CONFIG = 'SET_CONFIG';
export const SET_CONFIG_PAGE = 'SET_CONFIG_PAGE';
export const SET_CONFIG_PAGE_SIZE = 'SET_CONFIG_PAGE_SIZE';

export type SearchResultPayload = {
    params: Pick<SearchState, 'query' | 'config'>;
    result: SearchResultData;
};

export type SetSearchResultAction = {
    type: typeof SET_SEARCH_RESULT;
    payload: SearchResultPayload;
};

export type ResetSearchResultAction = {
    type: typeof RESET_SEARCH_RESULT;
};

export type RememberSearchQuery = {
    type: typeof REMEMBER_SEARCH_QUERY;
    payload: SearchQuery;
};

export type UpdateSearchDisputeAction = {
    type: typeof UPDATE_SEARCH_DISPUTE;
    payload: UpdateBillsDisputeActionPayload;
};

export type UpdateSearchBillsCustomerCommentAction = {
    type: typeof UPDATE_SEARCH_BILLS_CUSTOMER_COMMENT;
    payload: UpdateBillsCustomerCommentPayload;
};

export type SetConfigAction = {
    type: typeof SET_CONFIG;
    payload: SearchSectionConfig[];
};

export type SetConfigPageAction = {
    type: typeof SET_CONFIG_PAGE;
    payload: SetSearchConfigPagePayload;
};

export type SetConfigPageSizeAction = {
    type: typeof SET_CONFIG_PAGE_SIZE;
    payload: SetSearchConfigPageSizePayload;
};

export type SearchActionTypes =
    | SetSearchResultAction
    | ResetSearchResultAction
    | RememberSearchQuery
    | UpdateSearchDisputeAction
    | UpdateSearchBillsCustomerCommentAction
    | SetConfigAction
    | SetConfigPageAction
    | SetConfigPageSizeAction;

export type PageUrl = string;

export type ImageSize = {
    url: string;
    width: number;
    height: number;
};

export type ImageType = {
    sizes: ImageSize[];
};

export type PageDetails = {
    title: string;
    title_image: ImageType;
    text: string | null;
    gallery: ImageType[];
};

// ----- Actions ------
export const REMEMBER_PAGE = 'REMEMBER_PAGE';
export const FLUSH_PAGES = 'FLUSH_PAGES';

export type RememberPayload = {
    page: PageDetails;
    url: PageUrl;
};

interface RememberPageAction {
    type: typeof REMEMBER_PAGE;
    payload: RememberPayload;
}

interface FlushPagesAction {
    type: typeof FLUSH_PAGES;
}

export type PagesActionTypes = RememberPageAction | FlushPagesAction;

// ----- State ------
export type PagesState = Record<PageUrl, PageDetails>;

import React, { FunctionComponent, useState } from 'react';
import { useHistory } from 'react-router-dom';
import clsx from 'clsx';

import { Button, Card, SvgIcon, Theme, Tooltip, WithStyles } from '@material-ui/core';
import { createStyles, StyleRules, withStyles } from '@material-ui/styles';
import { AttachFile } from '@material-ui/icons';

import { useComments } from '../hooks/comments';

import { OrderListItem } from '../store/orders/types';
import { ROUTES } from '../utils/location';

import { ContainerBadge } from './OrderItem/ContainerBadge';
import { Notification } from './OrderItem/Notification';
import { OrderNumber } from './OrderItem/OrderNumber';
import { Status } from './OrderItem/Status';
import { Event } from './OrderItem/Event';

import { TextLiveEdit } from './input/TextLiveEdit';

import { ReactComponent as RoubleIcon } from '../assets/rouble.svg';
import { ReactComponent as InProgressIcon } from '../assets/inProgress.svg';
import { ReactComponent as ReadyIcon } from '../assets/ready.svg';
import { ConsolidationModal } from './ConsolidationModal/ConsolidationModal';
import { pluralize } from '../utils/strings';
import { ContainerNotificationsSettingsModal } from './container/NotificationSettingsModal/ContainerNotificationsSettingsModal';
import NotificationsIcon from '@material-ui/icons/Notifications';

// Component CSS-in-JS styles
const styles = (theme: Theme): StyleRules =>
    createStyles({
        expandIcon: {
            [theme.breakpoints.down('lg')]: {
                position: 'absolute',
                right: 16,
                top: 24,
            },
            [theme.breakpoints.down('sm')]: {
                right: 30,
                top: 12,
            },
        },
        content: {
            maxWidth: '100%',
        },
        button: {
            minWidth: 'auto',
        },
    });

type Props = { order: OrderListItem; isMinified?: boolean; onRefetch?: () => void } & WithStyles<typeof styles>;
const OrderCard: FunctionComponent<Props> = (props) => {
    const { order, classes, onRefetch, isMinified = false } = props;

    const [modalOpen, setModalOpen] = useState(false);
    const [isSubscriptionSettingsModalOpen, setSubscriptionSettingsModalOpen] = useState(false);

    const { buildUpdateOrderComment } = useComments();
    const { push } = useHistory();

    const container = order.container_groups[0].containers[0];

    const latestEvent =
        order.latest_any_critical_event || order.latest_self_critical_event || container.latest_critical_event;

    const commentValue = order.customer_comment || order.manager_comment;
    const saveHandler = buildUpdateOrderComment(order.id);

    const partialStatus =
        order.consolidation_row_count !== null &&
        order.consolidation_shipper_ready_count !== null &&
        order.consolidation_shipper_received_count !== null
            ? `Готовность груза: ${order.consolidation_shipper_ready_count} из ${
                  order.consolidation_row_count
              } ${pluralize(order.consolidation_row_count, [
                  'отправителя',
                  'отправителей',
                  'отправителей',
              ])}<br>Консолидировано: ${order.consolidation_shipper_received_count} из ${
                  order.consolidation_row_count
              } ${pluralize(order.consolidation_row_count, ['отправителя', 'отправителей', 'отправителей'])}`
            : null;

    const completedStatus = order.consolidation_status && order.consolidation_status.name;

    const status = order.consolidation_complete ? completedStatus : partialStatus;

    return (
        <Card className={clsx('OrderCard', isMinified && 'OrderCard--minified')}>
            <div className="OrdersListRow OrdersListHeader">
                <div className="OrdersListRow__first OrdersListHeader__first">
                    <div className="ContainerDetails__idInfoWrapper" style={{ marginBottom: 0 }}>
                        <OrderNumber order={order} />
                    </div>

                    <div className="OrdersListRow__liveEdit">
                        <TextLiveEdit value={commentValue} onSave={saveHandler} placeholder="Заметка для поиска" />
                    </div>
                </div>

                <ContainerBadge
                    container={container}
                    isGroupageCargo={order.type.is_groupage_cargo}
                    size={order.container_groups[0].group_type === 'by_size' ? order.container_groups[0].size : null}
                    type={order.container_groups[0].group_type === 'by_type' ? order.container_groups[0].type : null}
                    isHeader
                />
                <Status container={container} isHeader />
                <Event container={container} />
                <Notification event={latestEvent} />

                <div className="OrdersListRow__sixth OrdersListHeader__sixth">
                    <Tooltip title="Настройка уведомлений" placement="left" arrow>
                        <Button
                            className="OrderCard__button"
                            classes={{ root: classes.button }}
                            onClick={(event) => {
                                event.stopPropagation();
                                setSubscriptionSettingsModalOpen(true);
                            }}
                        >
                            <NotificationsIcon
                                color={order.uses_non_default_notifications_settings_set ? 'secondary' : 'inherit'}
                            />
                        </Button>
                    </Tooltip>

                    <Tooltip
                        title={status ? <span dangerouslySetInnerHTML={{ __html: status }} /> : ''}
                        placement="left"
                        arrow
                    >
                        <Button
                            className={clsx('OrderCard__button', !status && 'OrderCard__button--deleted')}
                            classes={{ root: classes.button }}
                            onClick={(event) => {
                                event.stopPropagation();
                                setModalOpen(true);
                            }}
                        >
                            {order.consolidation_complete ? (
                                <SvgIcon component={ReadyIcon} />
                            ) : (
                                <SvgIcon component={InProgressIcon} />
                            )}
                        </Button>
                    </Tooltip>

                    <Tooltip title="Документы по заказу" placement="left" arrow>
                        <Button
                            className={clsx(
                                'OrderCard__button',
                                order.document_count === 0 && 'OrderCard__button--hidden'
                            )}
                            classes={{ root: classes.button }}
                            onClick={() => push(`${ROUTES.ORDERS}/${order.id}/files`)}
                        >
                            <span className="OrderCard__buttonDocumentsCount">{order.document_count}</span>
                            <AttachFile style={{ color: 'rgba(0, 0, 0, 0.6)' }} />
                        </Button>
                    </Tooltip>

                    <div>
                        <Tooltip title="Счета по заказу" placement="left" arrow>
                            <Button
                                className={clsx(
                                    'OrderCard__button',
                                    order.bill_count === 0 && 'OrderCard__button--hidden'
                                )}
                                classes={{ root: classes.button }}
                                onClick={() => push(`${ROUTES.ORDERS}/${order.id}/bills`)}
                            >
                                <SvgIcon component={RoubleIcon} htmlColor="rgba(0, 0, 0, 0.6)" />

                                <span className="OrderCard__buttonLabel">Счета</span>
                            </Button>
                        </Tooltip>
                    </div>
                </div>
            </div>

            <ContainerNotificationsSettingsModal
                isOpen={isSubscriptionSettingsModalOpen}
                container={null}
                order={order}
                onSuccess={onRefetch}
                onClose={() => {
                    setSubscriptionSettingsModalOpen(false);
                }}
            />

            <ConsolidationModal
                updateTime={order.consolidation_updated_at}
                modalOpen={modalOpen}
                handleModalClose={() => setModalOpen(false)}
                orderId={order.id}
            />
        </Card>
    );
};

const component = withStyles(styles)(OrderCard);
export { component as OrderCard };

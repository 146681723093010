import React, { FunctionComponent } from 'react';
import { NavLink } from 'react-router-dom';
import clsx from 'clsx';
import { VariantType } from 'notistack';

import { ReactComponent as IconCopy } from '../../assets/copy.svg';
import { pluralCargoSeats } from '../../utils/view';
import { Container } from '../../store/orders/types';
import { useSearch } from '../../hooks/search';
import { useAlerts } from '../../hooks/noty';

type CopyButtonProps = {
    container: Container;
    isTouchDevice?: boolean;
};
export const CopyButton: FunctionComponent<CopyButtonProps> = (props) => {
    const { container, isTouchDevice = false } = props;

    const { showNotification } = useAlerts();

    const showNoty = (message: string, variant: VariantType): void => {
        showNotification(message, {
            variant,
            anchorOrigin: {
                horizontal: 'left',
                vertical: 'bottom',
            },
        });
    };

    const copyContainerNumberToClipboard = (): void => {
        if (container.number === null) return;

        window.navigator.clipboard
            .writeText(container.number)
            .then(() => showNoty('Номер контейнера скопирован', 'info'))
            .catch(() => showNoty('Номер контейнера не был скопирован', 'warning'));
    };

    if (container.number === null) return null;

    return (
        <button
            type="button"
            className={clsx(['ContainerBadgeWrapper__copy', isTouchDevice && 'ContainerBadgeWrapper__copy--touch'])}
            onClick={copyContainerNumberToClipboard}
        >
            <IconCopy />
            <span className="ContainerBadgeWrapper__copyText">Скопировать номер</span>
        </button>
    );
};

type Props = {
    container: Container;
    size: string | null;
    type: string | null;
    isGroupageCargo: boolean;
    asLink?: boolean;
    isHeader?: boolean;
    withButton?: boolean;
    withoutGridArea?: boolean;
    autoWidth?: boolean;
    encodedNotificationPageParams?: string;
};
export const ContainerBadge: FunctionComponent<Props> = (props) => {
    const {
        container,
        size,
        type,
        isGroupageCargo,
        asLink = true,
        isHeader = false,
        withButton = true,
        withoutGridArea,
        autoWidth,
        encodedNotificationPageParams,
    } = props;

    const { generateSearchUrl } = useSearch();

    const details: string[] = [];

    if (isGroupageCargo) {
        if (container.cargo_quantity_seats) details.push(pluralCargoSeats(container.cargo_quantity_seats));
        if (container.cargo_weight) details.push(`${container.cargo_weight.toFixed(2).replace('.00', '')} кг`);
    } else {
        if (size) {
            details.push(`${size}ft`);
        } else if (type) {
            details.push(type);
        }
        if (container.number) details.push(container.number);
    }

    let destination = 'Нет назначения';
    if (container.final_destination) {
        destination = `→ ${container.final_destination}`;
    } else if (container.destination) {
        destination = `→ ${container.destination}`;
    }

    let link = `/orders/${container.id}`;

    if (encodedNotificationPageParams) {
        link += `?encodedNotificationPageParams=${encodedNotificationPageParams}`;
    }
    return asLink ? (
        <div
            className={clsx([
                !withoutGridArea && 'OrdersListRow__second',
                !withoutGridArea && 'OrdersListProduct__second',
                'ContainerBadgeWrapper',
                isHeader && 'OrdersListHeader__second',
                withoutGridArea && 'OrdersListRow__withoutGridArea',
                autoWidth && 'OrdersListRow__autoWidth',
            ])}
        >
            <div className="ContainerRow__badge">
                <NavLink to={generateSearchUrl(link, false)}>
                    {details.length > 0 && <p>{details.join(' • ')}</p>}
                    {container.booking_number && <p>{container.booking_number}</p>}
                    <p>{destination}</p>
                </NavLink>

                <CopyButton container={container} />
            </div>

            <CopyButton container={container} isTouchDevice />
        </div>
    ) : (
        <div className="ContainerBadgeWrapper">
            <div className={clsx(['ContainerBadge', withButton && 'ContainerBadge--relative'])}>
                {details.length > 0 && <p>{details.join(' • ')}</p>}
                <p>{destination}</p>
                {container.booking_number && <p>{container.booking_number}</p>}

                {withButton && <CopyButton container={container} />}
            </div>

            {withButton && <CopyButton container={container} isTouchDevice />}
        </div>
    );
};

import React, { FunctionComponent, useEffect, useState } from 'react';
import { Button, Card, CircularProgress, TextField, Theme } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/styles';
import { Alert } from '@material-ui/lab';
import { AddCircle } from '@material-ui/icons';
import { useForm } from 'react-hook-form';
import { connect } from 'react-redux';
import { pipe } from 'fp-ts/function';
import { MaskInput } from '../../components/input/MaskInput';
import { formatPhone, sanitizePhone } from '../../utils/view';
import { testEmail } from '../../utils/strings';
import { useAlerts } from '../../hooks/noty';
import { apiAddRecipient, makeMapDispatch } from '../../store/dispatch';
import { RecipientState } from '../../utils/notifications';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        form: {
            maxWidth: 386,
            width: '100%',
            display: 'flex',
            alignItems: 'flex-end',
            flexDirection: 'column',
            '& > .MuiFormControl-root': {
                width: '100%',
                marginBottom: 28,
            },
        },
        formAlert: {
            marginBottom: 20,
            marginTop: 16,
        },
        formTitle: {
            fontSize: 20,
            fontWeight: 500,
            lineHeight: 1.15,
            margin: 0,
        },
        formCard: {
            borderRadius: 10,
            paddingLeft: 32,
            paddingRight: 32,
            paddingBottom: 32,
            paddingTop: 32,

            [theme.breakpoints.down('xs')]: {
                paddingLeft: 16,
                paddingRight: 16,
            },
        },
        buttonContainer: {
            display: 'flex',
            justifyContent: 'center',
        },
        buttonWrapper: {
            position: 'relative',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        },
        loading: {
            position: 'absolute',
        },
    })
);

type FormData = Required<Protocol.AddRecipientRequestData>;

const mapDispatch = makeMapDispatch({
    addRecipient: apiAddRecipient,
});

type Props = {
    recipients: RecipientState[];
    containerId: number | null;
    onAdd: (recipient: RecipientState[]) => void;
} & ReturnType<typeof mapDispatch>;
const AddRecipientComponent: FunctionComponent<Props> = (props) => {
    const { containerId, recipients, addRecipient, onAdd } = props;

    const classes = useStyles();

    const defaultValues = {
        phone: '',
        email: '',
    };
    const { register, errors, setError, reset, clearError, handleSubmit, watch } = useForm<FormData>({ defaultValues });
    const { handleApiError, showNotification } = useAlerts<FormData>(setError);
    const values = watch();

    const [phone, setPhone] = useState('');
    const [addRecipientLoading, setRecipientLoading] = useState(false);
    const [canUpdate, setCanUpdate] = useState(false);
    const [isFormVisible, setFormVisible] = useState(false);

    const clearForm = (): void => {
        setPhone('');
        reset();
        setFormVisible(false);
    };

    const getSuccessMessage = (email?: string): string => {
        if (phone.length > 0) return `Получатель уведомлений с номером ${formatPhone(phone)} добавлен`;
        if (email && email.length > 0) return `Получатель уведомлений с электронным адресом ${email} добавлен`;
        return `Получатель уведомлений добавлен`;
    };

    // TODO: need refactor
    const isNewRecipient = (recipientsList: RecipientState[]): boolean =>
        recipientsList.some((recipient) => recipients.map((item) => item.id).indexOf(recipient.id) >= 0);

    const onAddRecipient = (data: FormData): void => {
        clearError();
        setRecipientLoading(true);
        const payload = {
            container_id: containerId,
        } as Protocol.AddRecipientRequest;
        if (phone) payload.phone = phone;
        if (data.email) payload.email = data.email;
        addRecipient(payload)
            .then((response: Protocol.AddRecipientResponse) => {
                if (isNewRecipient(response.items)) {
                    showNotification('Получатель уведомлений с введёными контактными данными уже был добавлен ранее', {
                        variant: 'warning',
                    });
                } else {
                    showNotification(getSuccessMessage(data.email), {
                        variant: 'success',
                    });
                    onAdd(response.items);
                }
                clearForm();
            })
            .catch(handleApiError)
            .finally(() => {
                setRecipientLoading(false);
            });
    };

    useEffect(() => {
        const testPhone = phone !== defaultValues.phone && sanitizePhone(phone).length === 11;
        const testEmailAddress = values.email !== defaultValues.email && testEmail(values.email);
        setCanUpdate(testPhone || testEmailAddress);
    }, [values, defaultValues, phone]);

    return (
        <>
            {isFormVisible ? (
                <Card className={classes.formCard}>
                    <h4 className={classes.formTitle}>Новый получатель уведомлений</h4>
                    <Alert severity="info" className={classes.formAlert}>
                        Внимательно проверьте электронную почту и&nbsp;телефон получателя: после добавления карточки эти
                        данные не&nbsp;редактируются. Если вы&nbsp;ошиблись, создайте новую карточку.
                    </Alert>
                    <form className={classes.form} onSubmit={handleSubmit(onAddRecipient)}>
                        <TextField
                            inputRef={register}
                            id="new-noti-email"
                            label="Эл. почта"
                            name="email"
                            defaultValue={defaultValues.email}
                            variant="outlined"
                            error={!!errors.email}
                            helperText={errors.email?.message}
                        />

                        <MaskInput
                            mask="+7 (999) 999-9999"
                            value={phone}
                            onChange={(phoneNumber) => setPhone(phoneNumber)}
                        >
                            <TextField
                                id="new-noti-phone"
                                name="phone"
                                variant="outlined"
                                label="Телефон"
                                error={!!errors.phone}
                                helperText={errors.phone?.message}
                            />
                        </MaskInput>

                        <div className={classes.buttonWrapper}>
                            <Button
                                type="submit"
                                variant="contained"
                                color="primary"
                                disabled={!canUpdate || addRecipientLoading}
                            >
                                Добавить
                            </Button>
                            {addRecipientLoading && <CircularProgress className={classes.loading} size={16} />}
                        </div>
                    </form>
                </Card>
            ) : (
                <div className={classes.buttonContainer}>
                    <Button
                        type="button"
                        color="primary"
                        onClick={() => {
                            setFormVisible(true);
                        }}
                    >
                        <AddCircle style={{ marginRight: 8 }} />
                        {recipients.length > 0
                            ? 'Добавить еще получателя уведомлений'
                            : 'Добавить получателя уведомлений'}
                    </Button>
                </div>
            )}
        </>
    );
};

const component = pipe(AddRecipientComponent, connect(null, mapDispatch));

export { component as AddRecipient };

import React, { FunctionComponent, useEffect, useState } from 'react';
import { connect } from 'react-redux';

import { pipe } from 'fp-ts/lib/function';

import { CircularProgress } from '@material-ui/core';

import { NotificationsSettingsOrderListItem } from '../../types/notifications';

import {
    apiNotificationsSettingsOrderList,
    apiNotificationsSettingsOrderDelete,
    apiNotificationsSettingsDocumentsDetails,
    apiNotificationsSettingsMutualSettlementsReportsDetails,
    makeMapDispatch,
} from '../../store/dispatch';

import { useAlerts } from '../../hooks/noty';

import { buildRouteUrl, ROUTES } from '../../utils/location';

import { ProfileNotificationCard } from '../../components/ProfileNotificationCard/ProfileNotificationCard';

const mapDispatch = makeMapDispatch({
    getList: apiNotificationsSettingsOrderList,
    deleteItem: apiNotificationsSettingsOrderDelete,
    fetchDocumentsDetails: apiNotificationsSettingsDocumentsDetails,
    fetchMutualSettlementsDetails: apiNotificationsSettingsMutualSettlementsReportsDetails,
});

type ProfileNotificationManagerProps = ReturnType<typeof mapDispatch>;

const ProfileNotificationManager: FunctionComponent<ProfileNotificationManagerProps> = (props) => {
    const { getList, deleteItem, fetchDocumentsDetails, fetchMutualSettlementsDetails } = props;

    const [
        notificationsDocuments,
        setNotificationsDocuments,
    ] = useState<Protocol.NotificationsSettingsDocumentsDetailsResponse | null>(null);
    const [
        mutualSettlements,
        setMutualSettlements,
    ] = useState<Protocol.NotificationsSettingsMutualSettlementsReportsDetailsResponse | null>(null);
    const [orderList, setOrderList] = useState<NotificationsSettingsOrderListItem[]>([]);
    const [isLoading, setLoading] = useState(false);

    const { handleApiError } = useAlerts();

    const fetchList = (): Promise<void> =>
        getList()
            .then((result) => setOrderList(result.items))
            .catch(handleApiError);

    const fetchNotificationsDocuments = (): Promise<void> =>
        fetchDocumentsDetails()
            .then(setNotificationsDocuments)
            .catch(handleApiError);

    const fetchNotificationsMutualSettlements = (): Promise<void> =>
        fetchMutualSettlementsDetails()
            .then(setMutualSettlements)
            .catch(handleApiError);

    useEffect(() => {
        setLoading(true);
        Promise.all([fetchList(), fetchNotificationsDocuments(), fetchNotificationsMutualSettlements()])
            .catch(handleApiError)
            .finally(() => setLoading(false));
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const deleteListItem = (id: number): Promise<void> => {
        setLoading(true);

        return deleteItem({ notification_settings_set_id: id })
            .then((result) => setOrderList(result.items))
            .catch(handleApiError)
            .finally(() => setLoading(false));
    };

    if (isLoading) {
        return (
            <div className="App__loader App__center" style={{ height: '50vh', position: 'static' }}>
                <CircularProgress />
            </div>
        );
    }

    return (
        <div>
            <div>
                {orderList
                    .filter((item) => item.is_default)
                    .map((item) => {
                        const { id } = item;
                        const link = buildRouteUrl('NOTIFICATION_SETTINGS_BY_ID', { settingsId: id });
                        return (
                            <ProfileNotificationCard
                                id={id}
                                name={item.name}
                                link={link}
                                isDefault={item.is_default}
                                selectedChannels={item.selected_channels}
                                onDelete={deleteListItem}
                                key={id}
                            />
                        );
                    })}

                <ProfileNotificationCard
                    name="Уведомления по документам"
                    link={ROUTES.NOTIFICATION_SETTINGS_DOCUMENTS}
                    selectedChannels={notificationsDocuments?.selected_channels ?? []}
                    isDefault
                />

                <ProfileNotificationCard
                    name="Уведомления по взаиморасчетам"
                    link={ROUTES.NOTIFICATION_SETTINGS_MUTUAL_SETTLEMENTS}
                    selectedChannels={mutualSettlements?.selected_channels ?? []}
                    isDefault
                />
            </div>
        </div>
    );
};

const component = pipe(ProfileNotificationManager, connect(null, mapDispatch));

export { component as ProfileNotificationManager };

import React, { FunctionComponent, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { pipe } from 'fp-ts/function';
import { connect } from 'react-redux';
import { Button, Checkbox, FormControl, TextField, Typography } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';

import { ReportSubscriberEntity } from '../../types/order-reports';

import { nullableEmptyStringObject } from '../../utils/data';

import { useAlerts } from '../../hooks/noty';

import { apiManagedUsersCreate, makeMapDispatch } from '../../store/dispatch';

import { MaskInput } from '../input/MaskInput';
import { LoadingButton } from '../LoadingButton';

import styles from './SubscribersListBlock.module.scss';

type FormData = Protocol.ManagedUsersUpdateRequest;

const defaultFormValues: FormData = {
    user_id: 0,
    login: null,
    password: null,
    full_name: 'Новый сотрудник',
    phone: '',
    email: '',
    shadow_recipient_id: 0,
};

const mapDispatch = makeMapDispatch({ createManagedUser: apiManagedUsersCreate });

type SubscribersListBlockProps = {
    subscribers: ReportSubscriberEntity[];
    onSave?: (checkedSubscriberIds: number[]) => void;
    isSaving?: boolean;
    className?: string;
    isDisabledSave?: boolean;
};

const SubscribersListBlock: FunctionComponent<SubscribersListBlockProps & ReturnType<typeof mapDispatch>> = (props) => {
    const { subscribers, onSave, isSaving, createManagedUser, className, isDisabledSave = false } = props;
    const [isNewManagedUserBlockVisible, setNewManagedUserBlockVisibilityState] = useState(false);
    const [isLoading, setLoading] = useState(false);
    const firstStateOfSubscriberIds = subscribers
        .filter((subscriber) => subscriber.is_subscribed)
        .map((subscriber) => subscriber.user_id);
    const [checkedSubscriberIds, setCheckedSubscriberIds] = useState<number[]>(firstStateOfSubscriberIds);
    const areSubscriberIdsChanged =
        // Если длины изначального и конечного состояния не совпадают, то состояние изменилось
        checkedSubscriberIds.length !== firstStateOfSubscriberIds.length ||
        /**
         * Сделано чтобы не было сложности O(n^2)
         *
         * Проверка каждого id в новом состоянии:
         *  - Если id уже было в изначальном состоянии, то длины построенного и изначального массивов равны => состояние не изменилось
         *  - Если id не было в изначальном состоянии, то в построенном массиве будет на один элемент больше => состояние изменилось
         */
        !checkedSubscriberIds.every(
            (id) => Array.from(new Set([...firstStateOfSubscriberIds, id])).length === firstStateOfSubscriberIds.length
        );

    const { register, setValue, errors, setError, clearError, handleSubmit, watch } = useForm<FormData>({
        defaultValues: defaultFormValues,
    });
    const { handleApiError, showNotification } = useAlerts<FormData>(setError);
    const values = watch();

    const handleCheckboxChange = (userId: number, isChecked: boolean): void => {
        if (isChecked && !checkedSubscriberIds.includes(userId)) {
            setCheckedSubscriberIds([...checkedSubscriberIds, userId]);
        }

        if (!isChecked && checkedSubscriberIds.includes(userId)) {
            setCheckedSubscriberIds(checkedSubscriberIds.filter((id) => id !== userId));
        }
    };

    const handleAddSubscriberButtonClick = (): void => {
        setNewManagedUserBlockVisibilityState(true);
    };

    const handleNewManagedUserBlockClose = (): void => {
        setNewManagedUserBlockVisibilityState(false);
    };

    const handleSaveButtonClick = (): void => {
        if (onSave !== undefined) {
            onSave(checkedSubscriberIds);
        }
    };

    const onSubmit = (payload: FormData): void => {
        clearError();
        setLoading(true);

        const formattedPayload = {
            ...nullableEmptyStringObject(payload),
            login: null,
            password: null,
        };

        createManagedUser(formattedPayload)
            .then((result) => {
                if (result.items.length > 0) {
                    const newUser = result.items[0];

                    subscribers.push({
                        user_id: newUser.id,
                        name: newUser.full_name !== null ? newUser.full_name : '',
                        is_subscribed: false,
                    });

                    setNewManagedUserBlockVisibilityState(false);

                    showNotification('Сотрудник добавлен', {
                        variant: 'success',
                    });
                } else {
                    showNotification('Что-то пошло не так', {
                        variant: 'warning',
                    });
                }
            })
            .catch(handleApiError)
            .finally(() => setLoading(false));
    };

    useEffect(() => {
        register('phone');
    }, [register]);

    return (
        <div className={className}>
            {/* Subscriber list */}
            <ul className={styles.subscriberList}>
                {subscribers.map((subscriber) => (
                    <li key={subscriber.user_id} className={styles.subscriber}>
                        <Checkbox
                            disabled={isSaving}
                            checked={checkedSubscriberIds.includes(subscriber.user_id)}
                            className={styles.subscriberCheckbox}
                            color="primary"
                            onChange={(e) => handleCheckboxChange(subscriber.user_id, e.target.checked)}
                        />

                        <Typography>{subscriber.name}</Typography>
                    </li>
                ))}
            </ul>

            {/* Creating a new managed user */}
            <div className={styles.newManagedUserBlockWrapper}>
                {!isNewManagedUserBlockVisible ? (
                    <Button
                        variant="text"
                        color="secondary"
                        startIcon={<AddIcon />}
                        onClick={handleAddSubscriberButtonClick}
                        className={styles.addSubscriberButton}
                    >
                        Добавить получателя
                    </Button>
                ) : (
                    <form onSubmit={handleSubmit(onSubmit)} className={styles.newManagedUserBlock}>
                        <div className={styles.newManagedUserBlockHeader}>
                            <Typography variant="h4">Новый сотрудник</Typography>
                        </div>

                        {/* Inputs */}
                        <div className={styles.inputs}>
                            <FormControl className={styles.input}>
                                <TextField
                                    inputRef={register}
                                    id="full-name-input"
                                    name="full_name"
                                    label="ФИО"
                                    variant="outlined"
                                    error={errors.full_name !== undefined}
                                    helperText={errors.full_name?.message}
                                />
                            </FormControl>

                            <FormControl className={styles.input}>
                                <MaskInput
                                    mask="+7 (999) 999-9999"
                                    value={values.phone}
                                    onChange={(phoneNumber) => setValue('phone', phoneNumber)}
                                >
                                    <TextField
                                        id="phone-input"
                                        name="phone"
                                        label="Телефон"
                                        variant="outlined"
                                        type="tel"
                                        error={errors.phone !== undefined}
                                        helperText={errors.phone?.message}
                                    />
                                </MaskInput>
                            </FormControl>

                            <FormControl className={styles.input}>
                                <TextField
                                    inputRef={register}
                                    id="email-input"
                                    name="email"
                                    label="Почта"
                                    variant="outlined"
                                    type="email"
                                    error={errors.email !== undefined}
                                    helperText={errors.email?.message}
                                />
                            </FormControl>
                        </div>

                        {/* Buttons */}
                        <div className={styles.buttons}>
                            <LoadingButton
                                variant="contained"
                                color="primary"
                                type="submit"
                                disableElevation
                                loading={isLoading}
                                containerClass={styles.button}
                            >
                                Добавить
                            </LoadingButton>
                            <Button
                                disabled={isLoading}
                                variant="outlined"
                                color="default"
                                classes={{ outlined: styles.closeButton }}
                                onClick={handleNewManagedUserBlockClose}
                            >
                                Отменить
                            </Button>
                        </div>
                    </form>
                )}
            </div>

            <div className={styles.saveButtonWrapper}>
                <LoadingButton
                    loading={isSaving !== undefined ? isSaving : false}
                    disabled={isDisabledSave || !areSubscriberIdsChanged}
                    variant="contained"
                    color="primary"
                    disableElevation
                    onClick={handleSaveButtonClick}
                    containerClass={styles.saveButton}
                >
                    Сохранить
                </LoadingButton>
                {isDisabledSave && (
                    <Typography variant="caption">Для начала сохраните выбранный набор настроек</Typography>
                )}
            </div>
        </div>
    );
};

const component = pipe(SubscribersListBlock, connect(null, mapDispatch));
export { component as SubscribersListBlock };

import React, { FunctionComponent } from 'react';

import { ExpansionPanel, ExpansionPanelDetails, ExpansionPanelSummary } from '@material-ui/core';
import { ExpandMore } from '@material-ui/icons';

import { Section } from '../../store/orders/types';

import { SectionTable } from '../ConsolidationTable/SectionTable';

import { formatDate, formattedConsolidationTableValue } from '../../utils/view';

import './ExpandableConsolidationSection.scss';

type ExpandableConsolidationSectionProps = {
    section: Section;
};

export const ExpandableConsolidationSection: FunctionComponent<ExpandableConsolidationSectionProps> = (props) => {
    const { section } = props;

    return (
        <ExpansionPanel className="ExpandableConsolidationSection">
            <ExpansionPanelSummary expandIcon={<ExpandMore />} className="ExpandableConsolidationSection__summary">
                <h2 className="ExpandableConsolidationSection__heading">{section.name}</h2>
                {section.data_updated_at && (
                    <span className="ExpandableConsolidationSection__date">
                        Обновлено {formatDate(section.data_updated_at, true)}
                    </span>
                )}
            </ExpansionPanelSummary>

            <ExpansionPanelDetails className="ExpandableConsolidationSection__details">
                {section.type === 'string' && section.value}
                {section.type === 'structure' && (
                    <div className="ExpandableConsolidationSection__list">
                        {section.value.map((item) => (
                            <div key={`${item.title}${formattedConsolidationTableValue(item.value)}`} className="ExpandableConsolidationSection__pair">
                                <span className="ExpandableConsolidationSection__key">{item.title}</span>
                                {formattedConsolidationTableValue(item.value) !== null ? formattedConsolidationTableValue(item.value) : '—'}
                            </div>
                        ))}
                    </div>
                )}
                {section.type === 'table' && <SectionTable table={section} />}
            </ExpansionPanelDetails>
        </ExpansionPanel>
    );
};

import React, { FunctionComponent, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Dialog, DialogTitle, DialogContent, CircularProgress, IconButton, Typography } from '@material-ui/core';
import { Close as CloseIcon } from '@material-ui/icons';

import {
    ReportListItem,
    ReportMailingIntervalSettings,
    ReportMailingWeekdaysSettingsWithName,
    ReportSubscriberEntity,
} from '../../types/order-reports';

import { apiGetReportsSubscribersList, apiReplaceReportsSubscribersList, makeMapDispatch } from '../../store/dispatch';

import { pluralize } from '../../utils/strings';
import { getWeekdayIndex } from '../../utils/date';

import { useAlerts } from '../../hooks/noty';

import { SubscribersListBlock } from '../SubscribersListBlock/SubscribersListBlock';

import styles from './DownloadSubscriptionsModal.module.scss';

type DownloadSubscriptionsModalProps = {
    reportDetails: ReportListItem | null;
    onClose: () => void;
    isOpen: boolean;
};

const mapDispatch = makeMapDispatch({
    getReportSubscribersList: apiGetReportsSubscribersList,
    updateReportSubscribersList: apiReplaceReportsSubscribersList,
});

const DownloadSubscriptionsModal: FunctionComponent<DownloadSubscriptionsModalProps &
    ReturnType<typeof mapDispatch>> = (props) => {
    const { reportDetails, isOpen, onClose, getReportSubscribersList, updateReportSubscribersList } = props;
    const [isLoading, setLoading] = useState(false);
    const [isSaving, setSaving] = useState(false);
    const [subscribers, setSubscribers] = useState<ReportSubscriberEntity[]>();

    const { handleApiError, showNotification } = useAlerts();

    const getWeekdaysFrequencyString = (
        mailingSettings: ReportMailingIntervalSettings | ReportMailingWeekdaysSettingsWithName
    ): string => {
        if (mailingSettings.frequency === 'interval_days') {
            const countOfDays = mailingSettings.interval_days;

            if (countOfDays === 1) {
                return 'Каждый день';
            }

            return pluralize(countOfDays, [
                `Каждый ${countOfDays} день`,
                `Каждые ${countOfDays} дня`,
                `Каждые ${countOfDays} дней`,
            ]);
        }

        return mailingSettings.weekdays
            .sort((prev, next) => getWeekdayIndex(prev.id) - getWeekdayIndex(next.id))
            .map((weekday) => weekday.name)
            .join(', ');
    };

    const fetchSubscribersList = (): void => {
        setLoading(true);

        if (reportDetails !== null) {
            getReportSubscribersList({ report_id: reportDetails.id })
                .then((result) => {
                    setSubscribers(result.items);
                })
                .catch(handleApiError)
                .finally(() => {
                    setLoading(false);
                });
        }
    };

    const handleModalClose = (): void => {
        if (!isLoading && !isSaving) {
            onClose();
        }
    };

    const handleSubscribersListSave = (checkedSubscriberIds: number[]): void => {
        setSaving(true);

        if (reportDetails !== null) {
            const payload: Protocol.ReportsSubscribersListReplaceRequest = {
                report_id: reportDetails.id,
                user_ids: checkedSubscriberIds,
            };

            updateReportSubscribersList(payload)
                .then(() => {
                    showNotification('Получатели изменены', { variant: 'success' });
                })
                .catch(handleApiError)
                .finally(() => {
                    setSaving(false);

                    onClose();
                });
        }
    };

    useEffect(
        () => {
            if (isOpen) {
                fetchSubscribersList();
            }
        },
        [isOpen] // eslint-disable-line react-hooks/exhaustive-deps
    );

    if (reportDetails === null) {
        return <></>;
    }

    return (
        <Dialog
            scroll="body"
            open={isOpen}
            onClose={handleModalClose}
            classes={{ container: styles.root, paper: styles.modalBody }}
        >
            {/* Close button */}
            <div className={styles.closeButtonWrapper}>
                <IconButton disabled={isLoading || isSaving} onClick={handleModalClose} size="small">
                    <CloseIcon fontSize="small" />
                </IconButton>
            </div>

            {/* Header */}
            <DialogTitle disableTypography className={styles.header}>
                <Typography variant="h2" className={styles.title}>
                    {reportDetails.name}
                </Typography>
                <Typography>
                    {reportDetails.mailing_settings !== undefined && reportDetails.mailing_settings !== null
                        ? `Включена рассылка: ${getWeekdaysFrequencyString(reportDetails.mailing_settings)}`
                        : 'Рассылка отключена'}
                </Typography>
            </DialogTitle>

            {/* Content */}
            <DialogContent className={styles.content}>
                {isLoading ? (
                    <div className={styles.progressWrapper}>
                        <CircularProgress />
                    </div>
                ) : (
                    <>
                        {subscribers !== undefined && (
                            <SubscribersListBlock
                                subscribers={subscribers}
                                onSave={handleSubscribersListSave}
                                isSaving={isSaving}
                            />
                        )}
                    </>
                )}
            </DialogContent>
        </Dialog>
    );
};

const EnchantedProvider = connect(null, mapDispatch)(DownloadSubscriptionsModal);
export { EnchantedProvider as DownloadSubscriptionsModal };

import React, { FunctionComponent } from 'react';
import { Checkbox } from '@material-ui/core';
import { Controller } from 'react-hook-form';
import { NotificationAvailableType } from '../../../store/settings/types';

type DocumentTypeRowProps = {
    id: string;
    name: string;
    disabled?: boolean;
    availableTypes: NotificationAvailableType[];
    activeItems?: NotificationAvailableType[];
    onChange(key: NotificationAvailableType): void;
};

export const DocumentTypeRow: FunctionComponent<DocumentTypeRowProps> = (props) => {
    const { name, id, disabled, availableTypes, activeItems, onChange } = props;

    return (
        <div className="NotificationSettingsTable__documentTypeRow">
            <span>{name}</span>

            <div className="NotificationSettingsTable__documentTypeRowCheckboxes">
                {availableTypes.map((item) => {
                    const key = `document_types.id_${id}.${item}`;
                    return (
                        <div key={key} className="NotificationSettingsTable__documentTypeRowCheckbox">
                            {activeItems === undefined ? (
                                <Controller
                                    name={key}
                                    type="checkbox"
                                    valueName="checked"
                                    defaultValue={false}
                                    disabled={disabled}
                                    as={
                                        <Checkbox
                                            onClick={() => onChange(item)}
                                            color="primary"
                                        />
                                    }
                                />
                            ) : (
                                <Checkbox
                                    checked={activeItems.includes(item)}
                                    onClick={() => onChange(item)}
                                    color="primary"
                                />
                            )}
                        </div>
                    );
                })}
            </div>
        </div>
    );
};

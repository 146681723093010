import React, { FunctionComponent } from 'react';
import { makeStyles } from '@material-ui/styles';
import { Button, ButtonProps, CircularProgress, createStyles } from '@material-ui/core';
import clsx from 'clsx';

const useStyles = makeStyles(() =>
    createStyles({
        buttonContainer: {
            position: 'relative',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        },
        loading: {
            position: 'absolute',
        },
    })
);

type LoadingButtonProps = { loading: boolean; loaderClass?: string; containerClass?: string } & ButtonProps;
export const LoadingButton: FunctionComponent<LoadingButtonProps> = (props) => {
    const { loading, loaderClass, disabled, containerClass, ...buttonProps } = props;
    const classes = useStyles();

    return (
        <div className={clsx(classes.buttonContainer, containerClass)}>
            <Button disabled={loading || disabled} {...buttonProps}>
                {props.children}
            </Button>
            {loading && <CircularProgress className={clsx(classes.loading, loaderClass)} size={16} />}
        </div>
    );
};

import React, { FunctionComponent, useMemo, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { connect } from 'react-redux';

import { Button, Dialog, DialogContent, IconButton } from '@material-ui/core';
import { Help } from '@material-ui/icons';

import { pipe } from 'fp-ts/function';

import { makeMapDispatch } from '../../store/dispatch';
import { setUserSeenGuide } from '../../store/settings/actions';

import { ROUTES } from '../../utils/location';

import GuideGif1 from '../../assets/guide/1.gif';
import GuideGif2 from '../../assets/guide/2.gif';
import GuideGif3 from '../../assets/guide/3.gif';
import GuideGif4 from '../../assets/guide/4.gif';
import GuideGif5 from '../../assets/guide/5.gif';
import GuideGif6 from '../../assets/guide/6.gif';
import GuideGif7 from '../../assets/guide/7.gif';

import './Guide.scss';

type GuideModalProps = {
    isOpen: boolean;
    handleClose: () => void;
};
export const GuideModal: FunctionComponent<GuideModalProps> = (props) => {
    const { isOpen, handleClose } = props;
    const [stepIndex, setStepIndex] = useState(0);
    const { pathname } = useLocation();

    const notificationsLink = (text: string): JSX.Element =>
        pathname !== ROUTES.PROFILE ? (
            <Link to={ROUTES.PROFILE}>{text}</Link>
        ) : (
            <strong role="button" onClick={handleClose}>
                {text}
            </strong>
        );

    const steps: JSX.Element[] = [
        <>
            <h2>Как настроить уведомления по всем заказам?</h2>
            <p>
                Если вы&nbsp;хотите получать уведомления о&nbsp;событиях, происходящих в&nbsp;процессе перевозки
                по&nbsp;любому из&nbsp;ваших заказов&nbsp;&mdash; вам необходимо перейти на&nbsp;страницу{' '}
                {notificationsLink('настроек профиля')}.
            </p>
            <img src={GuideGif1} alt="" />
        </>,

        <>
            <h2>Как настроить уведомления по всем заказам?</h2>
            <p>
                На&nbsp;странице настроек с&nbsp;помощью кнопки &laquo;Добавить получателя уведомлений&raquo; создайте
                нового получателя, заполните контактные данные и&nbsp;нажмите &laquo;Добавить&raquo;. Затем выберите
                в&nbsp;списке интересующие вас события и&nbsp;способ уведомления (Telegram или&nbsp;эл. почта),
                и&nbsp;нажмите &laquo;Сохранить&raquo;
            </p>
            <img src={GuideGif2} alt="" />
        </>,

        <>
            <h2>Как настроить уведомления по отдельному контейнеру?</h2>
            <p>
                Если вам нужно получать уведомления по&nbsp;отдельному контейнеру, откройте экран контейнера, нажмите
                на&nbsp;кнопку &laquo;Уведомления&raquo;, выберите уже существующего получателя уведомлений или создайте
                нового, укажите события о&nbsp;которых вас необходимо оповещать и&nbsp;нажмите &laquo;Сохранить&raquo;
            </p>
            <img src={GuideGif3} alt="" />
            <p>
                Рассылка уведомлений по&nbsp;контейнеру учитывает <b>все выбранные настройки</b>.
            </p>
        </>,

        <>
            <h2>Заметки в уведомлениях</h2>
            <p>
                В&nbsp;уведомления возможно добавить заметку по&nbsp;контейнеру/заказу.
                <br />
                Заметка создается в&nbsp;карточке заказа и&nbsp;на&nbsp;странице контейнера.
            </p>
            <img src={GuideGif4} alt="" />
            <img src={GuideGif5} alt="" />
        </>,

        <>
            <h2>Как подписаться на&nbsp;телеграм-уведомления?</h2>
            <p>
                Если вы&nbsp;хотите получать рассылку в&nbsp;Telegram, подпишитесь на&nbsp;специального бота
                в&nbsp;вашем личном кабинете.
            </p>
            <img src={GuideGif6} alt="" />
        </>,

        <>
            <h2>Как подписаться на&nbsp;телеграм-уведомления?</h2>
            <p>
                После перехода в&nbsp;чат с&nbsp;ботом нажмите на&nbsp;кнопку &laquo;Start&raquo;. Теперь все рассылки
                согласно выбранным настройкам будут отправляться в&nbsp;данный чат.
            </p>
            <img src={GuideGif7} alt="" />
            <ul>
                <li>
                    Если у&nbsp;вас не&nbsp;установлена десктопная версия мессенджера Telegram, подпишитесь
                    на&nbsp;рассылку, зайдя в&nbsp;личный кабинет с&nbsp;мобильного телефона.
                </li>
                <li>
                    Одновременно по&nbsp;ссылке может быть подписан только один получатель. Рассылка учитывает все{' '}
                    <b>выбранные настройки</b>.
                </li>
                <li>
                    Если вы&nbsp;обновляете настройки для Telegram и&nbsp;уже подписаны на&nbsp;бота, повторная подписка
                    не&nbsp;требуется.
                </li>
            </ul>
        </>,
    ];

    const currentStep = useMemo(() => steps[stepIndex] || steps[0], [stepIndex, steps]);

    const changeStep = (amount: number): void => {
        setStepIndex((value) => {
            const newValue = value + amount;
            if (newValue < 0) return 0;
            if (newValue > steps.length - 1) return steps.length - 1;
            return newValue;
        });
    };

    const isNextDisabled = stepIndex >= steps.length - 1;
    const handleNextClick = (): void => {
        if (isNextDisabled) {
            setStepIndex(0);
            handleClose();
        } else {
            changeStep(+1);
        }
    };

    return (
        <Dialog
            aria-labelledby="guide-dialog-title"
            onClose={handleClose}
            open={isOpen}
            className="GuideModal"
            maxWidth="md"
            scroll="body"
            fullWidth
        >
            <DialogContent className="GuideModal__content">
                {currentStep}

                <div className="GuideModal__footer">
                    <Button
                        color="primary"
                        onClick={() => changeStep(-1)}
                        type="button"
                        variant="contained"
                        disabled={stepIndex === 0}
                    >
                        Назад
                    </Button>

                    <span>
                        {stepIndex + 1} / {steps.length}
                    </span>

                    <Button color="primary" onClick={handleNextClick} type="button" variant="contained">
                        {isNextDisabled ? 'Ок, понятно' : 'Вперёд'}
                    </Button>
                </div>
            </DialogContent>
        </Dialog>
    );
};

const mapDispatch = makeMapDispatch({ setSeenGuide: setUserSeenGuide });

type GuideProps = ReturnType<typeof mapDispatch>;
const Guide: FunctionComponent<GuideProps> = (props) => {
    const { setSeenGuide } = props;

    const [isOpen, setOpen] = useState(false);

    return (
        <>
            <GuideModal
                isOpen={isOpen}
                handleClose={() => {
                    setSeenGuide(true);
                    setOpen(false);
                }}
            />

            <IconButton onClick={() => setOpen((v) => !v)} color="primary">
                <Help />
            </IconButton>
        </>
    );
};

const EnchantedComponent = pipe(Guide, connect(null, mapDispatch));
export { EnchantedComponent as Guide };

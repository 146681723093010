import { useEffect, useRef, useState, SetStateAction } from 'react';

type Noop = () => void;

export const useOnDestroy = (callback: Noop): void => {
    useEffect(() => {
        return callback;
    }, []); // eslint-disable-line react-hooks/exhaustive-deps
};

export const useOnce = (callback: Noop): void => {
    useEffect(() => {
        callback();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps
};

type StateCallback<A> = (state: A) => void;
type SetStateCallback<A> = (value: SetStateAction<A>, callback?: StateCallback<A>) => void;
export function useStateCallback<A>(initialState: A): [A, SetStateCallback<A>] {
    const [state, setState] = useState(initialState);
    const cbRef = useRef<StateCallback<A>>();

    const setStateCallback: SetStateCallback<A> = (value, callback): void => {
        cbRef.current = callback;
        return setState(value);
    };

    useEffect(() => {
        if (cbRef.current) {
            cbRef.current(state);
            cbRef.current = undefined;
        }
    }, [state]);

    return [state, setStateCallback];
}

import React, { FunctionComponent } from 'react';
import clsx from 'clsx';

import { ExpandMore } from '@material-ui/icons';

import { OrderTaskType, OrderType } from '../../../store/settings/types';

import { NotificationsEntityItems } from '../../../utils/const';
import { NotificationEntityKind, NotificationEntityTypes } from '../../../utils/notifications';

import { OrderTypeEntityRow } from './OrderTypeEntityRow';
import { User } from '../../../types/user';

type TasksHeaderLabelsProps = {
    withoutBottomBorder?: boolean;
    excludeTypes?: NotificationEntityTypes[];
};
export const TasksHeaderLabels: FunctionComponent<TasksHeaderLabelsProps> = (props) => {
    const { withoutBottomBorder, excludeTypes } = props;

    const isIncluded = (key: NotificationEntityTypes): boolean =>
        excludeTypes === undefined || !excludeTypes.includes(key);

    return (
        <div
            className={clsx(
                'NotificationSettingsTable__column',
                'NotificationSettingsTable__column--hideOnSmall',
                withoutBottomBorder && 'NotificationSettingsTable__column--withoutBorder'
            )}
        >
            <div
                className={clsx(
                    'NotificationSettingsTable__headRow',
                    'NotificationSettingsTable__headRow--eventsTitle'
                )}
            >
                <div className="NotificationSettingsTable__headCell NotificationSettingsTable__headCell--title" />
                <div className="NotificationSettingsTable__headLabels">
                    {NotificationsEntityItems.map(({ name, key }, index) => (
                        <div className="NotificationSettingsTable__headCell" key={index}>
                            {isIncluded(key) && name}
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export const TasksRowHeader: FunctionComponent = () => {
    return (
        <div
            className={clsx(['NotificationSettingsTable__headRow', 'NotificationSettingsTable__headRow--statusTitle'])}
        >
            <div className="NotificationSettingsTable__headCell NotificationSettingsTable__headCell--title">
                <ExpandMore />
                <span>Уведомления</span>
            </div>
        </div>
    );
};

type TasksRowProps = {
    kind?: NotificationEntityKind;
    tasks: OrderTaskType[];
    orderType: OrderType;
    onChange?(): void;
    excludeTypes?: NotificationEntityTypes[];
    disabledTypes?: NotificationEntityTypes[];
    employee?: User;
};

export const TasksRow: FunctionComponent<TasksRowProps> = (props) => {
    const { tasks, orderType, onChange, excludeTypes, disabledTypes, employee, kind = 'task' } = props;

    return (
        <div className="NotificationSettingsTable__grid NotificationSettingsTable__grid--full NotificationSettingsTable__grid--tasks">
            <div className="NotificationSettingsTable__column">
                {tasks.map((task) => (
                    <OrderTypeEntityRow
                        key={task.id}
                        kind={kind}
                        entity={task}
                        orderType={orderType}
                        onChange={onChange}
                        excludeTypes={excludeTypes}
                        disabledTypes={disabledTypes}
                        employee={employee}
                    />
                ))}
            </div>
        </div>
    );
};

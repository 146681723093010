import React, { FunctionComponent } from 'react';
import clsx from 'clsx';

import { ClickAwayListener, Tooltip, TooltipProps, useMediaQuery, createStyles, makeStyles } from '@material-ui/core';

export enum FilterTypes {
    Price,
    Period,
    Payer,
    Currency,
}

const useStyles = makeStyles(() =>
    createStyles({
        filterTooltip: {
            background: 'white',
            boxShadow: '0 2px 4px rgba(0, 0, 0, 0.14), 0 3px 4px rgba(0, 0, 0, 0.12), 0 1px 5px rgba(0, 0, 0, 0.2)',
            borderRadius: 10,
            margin: 0,
            maxWidth: 'none',
            overflow: 'hidden',
            padding: 16,
        },
        filterTooltipWithoutPadding: {
            padding: 0,
        },
    })
);

type ComponentProps = {
    type: FilterTypes;
    openedFilter: FilterTypes | null;
    withoutPadding?: boolean;
    hideOpenedFilter: () => void;
};

type FilterTooltipProps = ComponentProps & TooltipProps;
export const FilterTooltip: FunctionComponent<FilterTooltipProps> = (props) => {
    const { children, type, openedFilter, hideOpenedFilter, withoutPadding = false, ...tooltipProps } = props;

    const classes = useStyles();
    const isSmallScreen = useMediaQuery('(max-width:639px)');

    const handleClickAway = (): void => {
        if (type === openedFilter) hideOpenedFilter();
    };

    return (
        <ClickAwayListener onClickAway={handleClickAway}>
            <div className="PaymentHistoryFilters__item">
                <Tooltip
                    open={openedFilter === type}
                    placement={isSmallScreen ? 'bottom-end' : 'bottom-start'}
                    interactive
                    disableFocusListener
                    disableHoverListener
                    disableTouchListener
                    classes={{
                        tooltip: clsx(classes.filterTooltip, withoutPadding && classes.filterTooltipWithoutPadding),
                    }}
                    PopperProps={{
                        disablePortal: true,
                        modifiers: {
                            offset: {
                                enabled: true,
                                offset: '0, 10px',
                            },
                        },
                    }}
                    {...tooltipProps}
                >
                    {props.children}
                </Tooltip>
            </div>
        </ClickAwayListener>
    );
};

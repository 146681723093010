import React, { FC, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { Add, DeleteOutline, Edit, MoreVert as MoreVertIcon } from '@material-ui/icons';
import { Button, CircularProgress, IconButton, Menu, MenuItem, Radio, RadioGroup, Typography } from '@material-ui/core';

import { buildRouteUrl } from '../../../utils/location';
import { NotificationsEntityItems } from '../../../utils/const';
import { Container, OrderInfo } from '../../../store/orders/types';

import { NotificationChannels, NotificationSubscriptionItem } from '../../../types/notification-subscriptions';
import { NotificationChannelIndicator } from '../NotificationSettingsModal/NotificationChannelIndicator';
import { LoadingButton } from '../../LoadingButton';

import styles from '../NotificationSettingsModal/ContainerNotificationsSettingsModal.module.scss';

type NotificationSetsListProps = {
    notificationSets: NotificationSubscriptionItem[];
    selectedSetId: number | null;
    onSelect(newSelectedId: number): void;
    onSave(newSelectedId: number): void;
    onDelete?(setId: number): Promise<void>;
    isLoading: boolean;
    isDisabledSave: boolean;
    isRemoving?: boolean;
    order: OrderInfo;
    container: Container | null;
};

export const NotificationSetsList: FC<NotificationSetsListProps> = (props) => {
    const {
        notificationSets,
        selectedSetId,
        onSave,
        onSelect,
        onDelete,
        isLoading,
        isDisabledSave,
        isRemoving,
        order,
        container,
    } = props;

    const [menuRef, setMenuRef] = useState<HTMLButtonElement | null>(null);
    const [menuSetId, setMenuSetId] = useState<number | null>(null);
    const [menuIsDefault, setMenuIsDefault] = useState(false);

    const history = useHistory();

    const onNewSettingClick = (): void => {
        let link = `/notification-settings/create?order=${order.id}&type=${order.type.id}`;

        if (container !== null) {
            link = `${link}&container=${container.id}`;
        }

        history.push(link);
    };

    const onSaveClick = (): void => {
        if (selectedSetId === null) return;
        onSave(selectedSetId);
    };

    const sortChannels = (selectedChannels: NotificationChannels[]): NotificationChannels[] => {
        const result: NotificationChannels[] = [];

        NotificationsEntityItems.forEach((item) => {
            if (selectedChannels.find((channel) => channel === item.key)) {
                result.push(item.key);
            }
        });

        return result;
    };

    const closeMenu = (): void => {
        setMenuSetId(null);
        setMenuRef(null);
    };

    const onEditSet = (setId: number): void => {
        let link = buildRouteUrl('NOTIFICATION_SETTINGS_BY_ID', {
            settingsId: setId,
        });

        link = `${link}?order=${order.id}`;

        if (container !== null) {
            link = `${link}&container=${container.id}`;
        }

        history.push(link);
        closeMenu();
    };

    return (
        <>
            <RadioGroup
                value={selectedSetId}
                onChange={(event, value) => {
                    onSelect(Number(value));
                }}
            >
                {notificationSets.map((set) => (
                    <label key={set.id} className={styles.setRowLabel}>
                        <div className={styles.rowContent}>
                            <Radio color="primary" value={set.id} />
                            {set.name}
                        </div>

                        <div className={styles.channelsWrapper}>
                            <div className={styles.channels}>
                                {sortChannels(set.selected_channels).map((channelType) => (
                                    <NotificationChannelIndicator key={channelType} channelType={channelType} />
                                ))}
                            </div>

                            <IconButton
                                size="small"
                                color="default"
                                onClick={(event) => {
                                    setMenuSetId(set.id);
                                    setMenuRef(event.currentTarget);
                                    setMenuIsDefault(set.is_default);
                                }}
                                className={styles.menuButton}
                            >
                                <MoreVertIcon />
                            </IconButton>
                        </div>
                    </label>
                ))}
            </RadioGroup>

            <Menu
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                anchorEl={menuRef}
                open={menuSetId !== null}
                onClose={closeMenu}
            >
                <MenuItem
                    onClick={() => {
                        if (menuSetId !== null) onEditSet(menuSetId);
                    }}
                >
                    <Edit className={styles.menuIcon} />
                    Редактировать
                </MenuItem>

                {!menuIsDefault && (
                    <MenuItem
                        onClick={() => {
                            if (onDelete !== undefined && menuSetId !== null)
                                return onDelete(menuSetId).then(closeMenu);
                            return null;
                        }}
                        className={styles.deleteMenuItem}
                    >
                        <div className={styles.menuIcon}>
                            {isRemoving ? <CircularProgress size={24} /> : <DeleteOutline />}
                        </div>
                        Удалить
                    </MenuItem>
                )}
            </Menu>

            <div className={styles.buttons}>
                <Button onClick={onNewSettingClick} className={styles.newSettingButton} startIcon={<Add />}>
                    Новая настройка
                </Button>

                <div className={styles.saveButtonWrapper}>
                    <LoadingButton
                        loading={isLoading}
                        onClick={onSaveClick}
                        className={styles.saveButton}
                        color="primary"
                        variant="contained"
                        disableElevation
                        disabled={isDisabledSave}
                    >
                        Сохранить
                    </LoadingButton>
                    {isDisabledSave && <Typography variant="caption">Выберите набор настроек</Typography>}
                </div>
            </div>
        </>
    );
};

import { IconButton, Modal } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useAlerts } from '../../hooks/noty';
import { apiOrderConsolidationTable, makeMapDispatch } from '../../store/dispatch';
import { ConsolidationRow } from '../../store/orders/types';
import { formatDate } from '../../utils/view';
import { ConsolidationTable } from '../ConsolidationTable/ConsolidationTable';
import { LoadingFixedCenter } from '../LoadingFixedCenter';

import './ConsolidationModal.scss';

type ConsolidationModalProps = {
    handleModalClose: () => void;
    modalOpen: boolean;
    updateTime: string | null;
    orderId: number;
};

const mapDispatch = makeMapDispatch({
    getTable: apiOrderConsolidationTable,
});

const ConsolidationModal: FunctionComponent<ConsolidationModalProps & ReturnType<typeof mapDispatch>> = (props) => {
    const { modalOpen, handleModalClose, updateTime, getTable, orderId } = props;
    const [isLoading, setLoading] = useState(false);
    const [table, setTable] = useState<ConsolidationRow[]>([]);

    const { handleApiError } = useAlerts();

    useEffect(() => {
        if (modalOpen) {
            setLoading(true);

            getTable({ order_id: orderId })
                .then((result) => {
                    setTable(result.consolidation.table_rows);
                })
                .catch(handleApiError)
                .finally(() => {
                    setLoading(false);
                });
        }
    }, [modalOpen]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <Modal open={modalOpen} onClose={handleModalClose} className="ConsolidationModal">
            <div className="ConsolidationModal__modalBody">
                <IconButton className="ConsolidationModal__modalClose" onClick={handleModalClose}>
                    <Close />
                </IconButton>

                <h2 className="ConsolidationModal__heading">Консолидация груза</h2>

                {updateTime && (
                    <span className="ConsolidationModal__date">Обновлено {formatDate(updateTime, true)}</span>
                )}

                {isLoading ? (
                    <LoadingFixedCenter />
                ) : (
                    <div className="ConsolidationModal__table">
                        <ConsolidationTable tableRows={table} />
                    </div>
                )}
            </div>
        </Modal>
    );
};

const EnchantedProvider = connect(null, mapDispatch)(ConsolidationModal);
export { EnchantedProvider as ConsolidationModal };

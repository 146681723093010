import React, { FunctionComponent } from 'react';
import { connect } from 'react-redux';
import clsx from 'clsx';

import { pipe } from 'fp-ts/function';

import { Button } from '@material-ui/core';
import { HelpOutline } from '@material-ui/icons';

import { makeMapState } from '../../store/root';
import { makeMapDispatch } from '../../store/dispatch';
import { HelpInfoKey } from '../../store/settings/types';
import { setSeenInfoKey } from '../../store/settings/actions';

import styles from './HelpInfo.module.scss';

const mapState = makeMapState((state) => ({
    seenKeys: state.settings.seenHelpInfoKeys,
}));

const mapDispatch = makeMapDispatch({
    setSeen: setSeenInfoKey,
});

type HelpInfoProps = {
    uniqueKey: HelpInfoKey;
    title: React.ReactNode;
    description?: React.ReactNode;
    isWithBottomGap?: boolean;
    isWithTopGap?: boolean;
} & ReturnType<typeof mapState> &
    ReturnType<typeof mapDispatch>;
const HelpInfo: FunctionComponent<HelpInfoProps> = (props) => {
    const { uniqueKey, title, description, isWithBottomGap, isWithTopGap, seenKeys, setSeen } = props;

    const onHide = (): void => setSeen(uniqueKey);

    if (seenKeys.includes(uniqueKey)) return null;

    return (
        <div
            className={clsx(
                styles.root,
                isWithBottomGap && styles.rootWithBottomGap,
                isWithTopGap && styles.rootWithTopGap
            )}
        >
            <HelpOutline color="primary" className={styles.icon} />

            <div className={styles.info}>
                <h3 className={styles.heading}>{title}</h3>

                {description !== undefined && <div className={styles.description}>{description}</div>}
            </div>

            <div className={styles.buttonWrapper}>
                <Button color="primary" variant="contained" size="medium" onClick={onHide}>
                    Скрыть
                </Button>
            </div>
        </div>
    );
};

const component = pipe(HelpInfo, connect(mapState, mapDispatch));
export { component as HelpInfo };

import React, { FunctionComponent, useEffect, useMemo, useState } from 'react';
import { useHistory, useLocation, useParams, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { FormContext, useForm } from 'react-hook-form';

import { FormControlLabel, Radio, RadioGroup, TextField } from '@material-ui/core';

import { pipe } from 'fp-ts/function';
import * as O from 'fp-ts/Option';

import {
    apiNotificationsSettingsOrderCreate,
    apiNotificationsSettingsOrderDelete,
    apiNotificationsSettingsOrderDetails,
    apiNotificationsSettingsOrderUpdate,
    apiUserNotificationsSettingsDetails,
    apiUserNotificationsSettingsOrderUpdate,
    makeMapDispatch,
} from '../../store/dispatch';

import { useSearch } from '../../hooks/search';

import { ROUTES } from '../../utils/location';
import { makeInteger } from '../../utils/branding';
import { setPageTitle } from '../../utils/title';

import { SettingsState } from '../../store/settings/types';
import { RootState } from '../../store/root';
import { OrderTypeRow } from '../../components/NotificationSettingsTable/Components/OrderTypeRow';
import { NotificationSettingsEntities, NotificationsSettingsDetails } from '../../types/notifications';
import { NotificationFormData, NotificationSettingsFormData, reduceFormDataIntoTypes } from '../../utils/notifications';

import { useAlerts } from '../../hooks/noty';
import { useUrl } from '../../hooks/url';

import { NotificationsHeader } from '../../components/NotificationsHeader/NotificationsHeader';
import { NotificationsActionsBar } from '../../components/NotificationsActionsBar/NotificationsActionsBar';

import '../../components/NotificationSettingsTable/NotificationSettingsTable.scss';
import './OrderNotification.scss';

type StateProps = { settings: SettingsState };
const mapState = (state: RootState): StateProps => ({
    settings: state.settings,
});

const mapDispatch = makeMapDispatch({
    createNotification: apiNotificationsSettingsOrderCreate,
    updateNotification: apiNotificationsSettingsOrderUpdate,
    updateEmployeeNotification: apiUserNotificationsSettingsOrderUpdate,
    getEmployeeNotificationsDetails: apiUserNotificationsSettingsDetails,
    getNotificationsDetails: apiNotificationsSettingsOrderDetails,
    deleteNotification: apiNotificationsSettingsOrderDelete,
});

type OrderNotificationProps = ReturnType<typeof mapDispatch> & ReturnType<typeof mapState>;

type Params = { userId: string; settingsId: string | 'create' };

const mapSettingsPayload = (data: NotificationFormData, orderTypeId: number): NotificationSettingsEntities => {
    const entities: NotificationSettingsEntities = {
        events: [],
        tasks: [],
    };

    Object.keys(data).forEach((key) => {
        const entity = {
            channels: reduceFormDataIntoTypes(data[key].types),
            id: Number(data[key].id),
        };

        if (Number(data[key].order_type_id) === orderTypeId) {
            if (data[key].kind === 'event') {
                entities.events.push(entity);
            } else {
                entities.tasks.push(entity);
            }
        }
    });

    return entities;
};

const pageTitle = 'Настройка уведомлений';

const OrderNotification: FunctionComponent<OrderNotificationProps> = (props) => {
    const {
        createNotification,
        updateNotification,
        deleteNotification,
        getNotificationsDetails,
        getEmployeeNotificationsDetails,
        updateEmployeeNotification,
        settings,
    } = props;

    const params = useParams<Params>();
    const history = useHistory();
    const { pathname } = useLocation();
    const { getQueryParam } = useUrl();
    const { hasSearchData } = useSearch();

    const [name, setName] = useState('');
    const [isSaving, setSaving] = useState(false);
    const [orderTypeId, setOrderTypeId] = useState(0);
    const [isDefaultsLoaded, setDefaultsLoaded] = useState(false);
    const [
        defaultNotificationsSettingsDetails,
        setDefaultNotificationsSettingsDetails,
    ] = useState<NotificationsSettingsDetails | null>(null);

    const isEmployee = pathname.includes('user-notification-settings');
    const queryType = pipe(getQueryParam('type'), makeInteger);

    const { showNotification, handleApiError } = useAlerts();

    const isCreate = params.settingsId === 'create';
    const isDefault = defaultNotificationsSettingsDetails && defaultNotificationsSettingsDetails.is_default;

    const defaultValues: NotificationSettingsFormData = {
        data: {},
    };

    const formMethods = useForm<NotificationSettingsFormData>({
        defaultValues,
    });

    const { watch, setValue } = formMethods;
    const value = watch({ nest: true });

    const filteredOrderTypes = useMemo(
        () => settings.orderTypes.filter((type) => settings.syncedOrderTypeIds.includes(type.id)),
        [settings.orderTypes] // eslint-disable-line react-hooks/exhaustive-deps
    );

    useEffect(() => setPageTitle(pageTitle), [hasSearchData]);

    useEffect(() => {
        if (!isCreate) {
            if (isEmployee) {
                getEmployeeNotificationsDetails({ notification_settings_set_id: Number(params.settingsId) })
                    .then((result) => {
                        setOrderTypeId(result.settings_set.details.order_type.id);
                        setName(result.settings_set.details.name);
                        setDefaultNotificationsSettingsDetails(
                            result.settings_set.details as NotificationsSettingsDetails
                        );
                    })
                    .catch(handleApiError);
            } else {
                getNotificationsDetails({ notification_settings_set_id: Number(params.settingsId) })
                    .then((result) => {
                        setOrderTypeId(result.order_type.id);
                        setName(result.name);
                        setDefaultNotificationsSettingsDetails(result);
                    })
                    .catch(handleApiError);
            }
        } else if (O.isSome(queryType)) {
            setOrderTypeId(queryType.value);
        } else if (filteredOrderTypes.length > 0 && filteredOrderTypes[0].id) {
            setOrderTypeId(filteredOrderTypes[0].id);
        }
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (!isDefaultsLoaded && defaultNotificationsSettingsDetails && Object.keys(value.data).length !== 0) {
            const newData = value.data;

            // eslint-disable-next-line @typescript-eslint/camelcase
            const { events, tasks, order_type } = defaultNotificationsSettingsDetails;

            events.forEach((event) => {
                event.selected_channels.forEach((item) => {
                    if (newData[`${order_type.id}_event_${event.id}`])
                        newData[`${order_type.id}_event_${event.id}`].types[item] = true;
                });
            });

            tasks.forEach((task) => {
                task.selected_channels.forEach((item) => {
                    if (newData[`${order_type.id}_task_${task.id}`])
                        newData[`${order_type.id}_task_${task.id}`].types[item] = true;
                });
            });

            setValue('data', newData);
            setDefaultsLoaded(true);
        }
    }, [value.data]); // eslint-disable-line react-hooks/exhaustive-deps

    const onDelete = (): void => {
        deleteNotification({ notification_settings_set_id: Number(params.settingsId) })
            .then(() => {
                showNotification('Настройки уведомления успешно удалены', {
                    variant: 'success',
                });

                history.push(`${ROUTES.PROFILE}#notifications`);
            })
            .catch(handleApiError);
    };

    const onSubmit = (): void => {
        if (isSaving) return;

        if (!name) {
            showNotification('Введите название', {
                variant: 'warning',
            });

            return;
        }

        setSaving(true);

        const updateMethod = isEmployee ? updateEmployeeNotification : updateNotification;
        const method = isCreate ? createNotification : updateMethod;

        const payload = {
            ...mapSettingsPayload(value.data, orderTypeId),
            name,
            order_type_id: isCreate ? orderTypeId : undefined,
            notification_settings_set_id: isCreate ? undefined : Number(params.settingsId),
        };

        method(payload)
            .then(() => {
                showNotification('Настройки уведомления успешно сохранены', {
                    variant: 'success',
                });

                let link = isEmployee ? ROUTES.EMPLOYEES : `${ROUTES.PROFILE}#notifications`;

                if (isEmployee) {
                    if (params.userId) link = `${link}?userId=${params.userId}`;
                } else {
                    const container = getQueryParam('container');
                    const order = getQueryParam('order');

                    if (order) {
                        link = `${ROUTES.ORDERS}?order=${order}`;

                        if (container) link = `${ROUTES.ORDERS}/${container}#open_modal`;
                    }
                }

                history.push(link);
            })
            .catch(handleApiError)
            .finally(() => setSaving(false));
    };

    return (
        <>
            <div className="App__container App__container--small">
                <NotificationsHeader userId={params.userId} isEmployee={isEmployee} title={pageTitle} />

                <div className="OrderNotification">
                    <div className="OrderNotification__body">
                        <TextField
                            className="OrderNotification__name"
                            variant="outlined"
                            label="Название"
                            value={name}
                            disabled={Boolean(isDefault)}
                            onChange={(e) => {
                                setName(e.target.value);
                            }}
                        />

                        {isCreate && O.isNone(queryType) && (
                            <div className="OrderNotification__typesSection">
                                <h2 className="OrderNotification__subheading">Тип заказов</h2>
                                <RadioGroup
                                    row
                                    value={orderTypeId}
                                    name="order_type_id"
                                    className="OrderNotification__radioGroup"
                                    onChange={(e) => {
                                        setOrderTypeId(Number(e.target.value));
                                    }}
                                >
                                    {filteredOrderTypes.map((ot) => (
                                        <FormControlLabel
                                            key={ot.id}
                                            value={ot.id}
                                            control={<Radio color="primary" />}
                                            label={ot.name}
                                        />
                                    ))}
                                </RadioGroup>
                            </div>
                        )}

                        <div className="OrderNotification__eventsSection">
                            <FormContext {...formMethods}>
                                {filteredOrderTypes
                                    .filter((ot) => ot.id === orderTypeId)
                                    .map((ot) => (
                                        <OrderTypeRow
                                            key={`${params.settingsId}-type-${ot.id}`}
                                            orderType={ot}
                                            onChange={() => {}}
                                        />
                                    ))}
                            </FormContext>
                        </div>
                    </div>
                </div>
            </div>

            <NotificationsActionsBar
                isLoadingSubmit={isSaving}
                onSubmit={onSubmit}
                onDelete={!isEmployee && !isDefault ? onDelete : undefined}
            />
        </>
    );
};

const connected = withRouter(pipe(OrderNotification, connect(mapState, mapDispatch)));
export { connected as OrderNotification };

/* eslint-disable no-underscore-dangle */
import React, { FunctionComponent, useEffect, useMemo, useState } from 'react';
import { Link, NavLink, useLocation, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import clsx from 'clsx';

import { pipe } from 'fp-ts/function';

import { AppBar, IconButton, Toolbar, WithStyles } from '@material-ui/core';
import AccountCircle from '@material-ui/icons/AccountCircle';
import HelpIcon from '@material-ui/icons/Help';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import { withStyles } from '@material-ui/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import { EventBus } from '../../hooks/bus';

import { makeMapState } from '../../store/root';
import { apiCriticalEventsStats, makeMapDispatch } from '../../store/dispatch';

import { isUserGranted } from '../../utils/user';
import { APP_LINKS, LinkData, REPORT_ADMIN_APP_LINKS, ROUTES } from '../../utils/location';

import { LogoutConfirm } from '../LogoutConfirm';
import { SearchBar } from '../SearchBar/SearchBar';

import logo from '../../assets/logo.svg';

import { styles } from './styles';

declare module '../../hooks/bus' {
    interface BusEvents {
        onNavigationClick: LinkData;
    }
}

const mapState = makeMapState((state) => ({
    userProfile: state.user.profile,
    isAuthenticated: state.user.isAuthenticated,
    notificationsCount: state.criticalEvents.stats,
}));

const mapDispatch = makeMapDispatch({
    getCriticalEventsStats: apiCriticalEventsStats,
});

type Props = ReturnType<typeof mapState> & WithStyles<typeof styles> & ReturnType<typeof mapDispatch>;

const TopBar: FunctionComponent<Props> = (props) => {
    const { userProfile, isAuthenticated, classes, notificationsCount, getCriticalEventsStats } = props;

    // Profile icon dropdown menu
    const isSmallScreen = useMediaQuery('(max-width:999px)');
    const [isOpenConfirm, setOpenConfirm] = useState(false);
    // Router pathname handler
    const { pathname } = useLocation();

    const isReportsAdmin = useMemo(() => {
        return (
            userProfile &&
            userProfile._actions.length > 0 &&
            userProfile._actions.includes('manage_order_report_templates')
        );
    }, [userProfile]);

    useEffect(() => {
        if (isAuthenticated && !isReportsAdmin) getCriticalEventsStats();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const logoImage = <img src={logo} alt="logo" className={classes.logoImage} />;
    const links = isReportsAdmin
        ? REPORT_ADMIN_APP_LINKS
        : APP_LINKS(notificationsCount, isUserGranted(userProfile, 'manage_regular_users'));

    if (!isAuthenticated) return null;

    return (
        <>
            {/*<SyncTopLine />*/}
            <AppBar position="static" color="primary" className={classes.appBar}>
                <Toolbar className={classes.toolBar}>
                    {/* Logo */}
                    <div className={classes.logo}>
                        {isReportsAdmin ? (
                            logoImage
                        ) : (
                            <>
                                <Link to={ROUTES.ORDERS}>{logoImage}</Link>
                                {isSmallScreen && <SearchBar />}
                            </>
                        )}
                    </div>

                    <div className={classes.navigation}>
                        <ul className={classes.navigationList}>
                            {links.map((link) => (
                                <li className={classes.navigationItem} key={link.key}>
                                    <NavLink
                                        className={classes.navLink}
                                        style={{
                                            textDecoration:
                                                pathname === link.path || pathname === link.rootPath
                                                    ? 'underline'
                                                    : undefined,
                                        }}
                                        to={link.path}
                                        onClick={() => EventBus.emit('onNavigationClick', link)}
                                    >
                                        {link.label}
                                    </NavLink>

                                    {link.amount !== undefined && link.amount > 0 && (
                                        <div className={classes.notificationsCount}>
                                            {link.amount > 99 ? '99+' : link.amount}
                                        </div>
                                    )}
                                </li>
                            ))}
                        </ul>

                        {!isSmallScreen && !isReportsAdmin && <SearchBar />}
                    </div>

                    {!isReportsAdmin && (
                        <>
                            <div className={classes.aboutButtonWrapper}>
                                <IconButton
                                    className={clsx(classes.button, classes.aboutButton)}
                                    href="https://transitllc.ru/personal-cabinet"
                                    target="_blank"
                                    color="inherit"
                                >
                                    <HelpIcon className={classes.helpIcon} />
                                </IconButton>
                            </div>

                            <div className={classes.profileButtonWrapper}>
                                <IconButton
                                    className={clsx(classes.button, classes.profileButton)}
                                    component={Link}
                                    to={ROUTES.PROFILE}
                                    color="inherit"
                                >
                                    <AccountCircle className={classes.accountCircleIcon} />
                                </IconButton>
                            </div>
                        </>
                    )}

                    <div className={classes.logoutButtonWrapper}>
                        <IconButton
                            aria-label="logout"
                            className={classes.button}
                            onClick={() => setOpenConfirm(true)}
                            color="inherit"
                        >
                            <ExitToAppIcon className={classes.exitIcon} />
                        </IconButton>
                    </div>
                </Toolbar>
                <LogoutConfirm
                    isOpen={isOpenConfirm}
                    setClose={() => {
                        setOpenConfirm(false);
                    }}
                />
            </AppBar>
        </>
    );
};

const component = pipe(TopBar, withStyles(styles), connect(mapState, mapDispatch), withRouter);

export { component as TopBar };

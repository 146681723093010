import React from 'react';

import clsx from 'clsx';

import { fold, fromNullable } from 'fp-ts/Option';
import { Chip } from '@material-ui/core';
import { pipe } from 'fp-ts/function';

import './Chips.scss';

export type ChipData<K = unknown> = {
    key: K;
    label: string;
    count: number | null;
};

type ChipsProps<T extends string = string> = {
    vertical?: boolean;
    chipData: ChipData<T>[];
    onChange: (active: T[]) => void;
    activeFlags: T[];
};

const getChipLabel = (data: ChipData): string =>
    pipe(
        fromNullable(data.count),
        fold(
            () => data.label,
            (total) => `${data.label} • ${total}`
        )
    );

export const Chips = <T extends string>(props: ChipsProps<T>): JSX.Element => {
    const { chipData, onChange, activeFlags, vertical = false } = props;

    const isActiveChip = (chip: ChipData<T>): boolean => activeFlags.includes(chip.key);
    const disableChip = (chip: ChipData<T>): void => onChange(activeFlags.filter((item) => item !== chip.key));
    const enableChip = (chip: ChipData<T>): void => {
        if (!isActiveChip(chip)) onChange([...activeFlags, chip.key]);
    };

    return (
        <ul className={clsx('BillsFiltersChips', vertical && 'BillsFiltersChips--vertical')}>
            {chipData.map((chip) => {
                const isActive = activeFlags.includes(chip.key);
                return (
                    <li className="BillsFiltersChips__item" key={chip.key}>
                        <Chip
                            disabled={chip.count === null || chip.count < 1}
                            color="primary"
                            label={getChipLabel(chip)}
                            variant={isActive ? 'default' : 'outlined'}
                            onClick={() => enableChip(chip)}
                            onDelete={isActive ? () => disableChip(chip) : undefined}
                        />
                    </li>
                );
            })}
        </ul>
    );
};

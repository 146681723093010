import React, { FunctionComponent } from 'react';
import clsx from 'clsx';

import { Chip } from '@material-ui/core';
import { ClassNameMap } from '@material-ui/styles';

import { pipe } from 'fp-ts/function';
import * as O from 'fp-ts/Option';
import * as R from 'fp-ts/Record';

import { ChipData, CountsList, makeIsChecked } from '../../../shared/filterLogic';

type ChipsProps = {
    classes: ClassNameMap;
    chipData: ChipData[];
    chipCounts: CountsList;
    deleteHandler: (chipToDelete: ChipData) => ((event: MouseEvent) => void) | undefined;
    clickHandler: (chip: ChipData) => void;
    isVertical?: boolean;
};

const getChipLabel = (data: ChipData, selected: number, isTypeChecked: boolean): string =>
    pipe(
        O.fromNullable(data.possibleCount > 0 ? data.total : null),
        O.fold(
            () => data.label,
            (total) => `${data.label} ${selected}/${total}`
        ),
        (label) => `${isTypeChecked ? '✓ ' : ''}${label} • ${data.possibleCount}`
    );

export const Chips: FunctionComponent<ChipsProps> = (props) => {
    const { classes, chipData, chipCounts, clickHandler, deleteHandler, isVertical = false } = props;

    const chipsClasses = clsx([classes.chips, isVertical ? classes.chipsVertical : classes.chipsHorizontal]);
    const chipClasses = clsx([classes.chip, isVertical && classes.chipVertical]);

    return (
        <ul className={chipsClasses}>
            {chipData.map((chip) => {
                const selected = pipe(
                    R.lookup(chip.key, chipCounts),
                    O.fold(
                        () => 0,
                        (counts) => counts.events.length + counts.statuses.length
                    )
                );

                const isTypeChecked = makeIsChecked(chip.key, chipCounts)('types', chip.origin.id);
                const active = selected > 0 || isTypeChecked;

                return (
                    <li className={chipClasses} key={chip.key}>
                        <Chip
                            color="primary"
                            label={getChipLabel(chip, selected, isTypeChecked)}
                            variant={active ? 'default' : 'outlined'}
                            disabled={chip.total === null || chip.possibleCount === 0}
                            onClick={() => clickHandler(chip)}
                            onDelete={active ? deleteHandler(chip) : undefined}
                        />
                    </li>
                );
            })}
        </ul>
    );
};

import React, { FunctionComponent, useMemo, useState } from 'react';

import { Badge, Checkbox, Chip, FormControlLabel, FormGroup } from '@material-ui/core';
import { Cancel as CancelIcon, ExpandMore as ExpandMoreIcon } from '@material-ui/icons';
import { createStyles, makeStyles } from '@material-ui/styles';

import { mapWithIndex } from 'fp-ts/Array';

import { PaymentHistoryFilterPayer as FiltersApiPayer } from '../store/payment-history/types';
import { PayersPayloadType } from '../utils/types';

import { FilterTooltip, FilterTypes } from './FilterTooltip';

const useStyles = makeStyles(() => createStyles({ formCheckbox: { color: 'black' } }));

type TogglePayer = (payer: string, active: boolean) => void;
type PayersFilterViewBuilder = (
    labelClass: string,
    checkedByDefault: (payer: string) => boolean,
    togglePayer: TogglePayer
) => (items: FiltersApiPayer[]) => JSX.Element[];
const payersFilterViewBuilder: PayersFilterViewBuilder = (labelClass, checkedByDefault, togglePayer) =>
    mapWithIndex((index, payer: FiltersApiPayer) => (
        <FormControlLabel
            key={`filter-${payer.value}-${index}`}
            label={`${payer.value} • ${payer.total}`}
            classes={{ label: labelClass }}
            control={<Checkbox value={payer.value} color="primary" />}
            checked={checkedByDefault(payer.value)}
            onChange={(_, checked) => togglePayer(payer.value, checked)}
        />
    ));

type PayersPickerProps = {
    payers: PayersPayloadType;
    disabled: boolean;
    setPayers: (payers: PayersPayloadType) => void;
    payersList: FiltersApiPayer[];
    onDelete: () => void;
    onClose: () => void;
    onClick?: () => void;
};

export const PayersPicker: FunctionComponent<PayersPickerProps> = (props) => {
    const { payers, disabled, payersList, setPayers, onClick, onClose, onDelete } = props;

    const [opened, setOpened] = useState(false);
    const isPayersFilterActive = useMemo(() => payers.length > 0, [payers]);

    const classes = useStyles();

    const foldPayers = useMemo(
        () =>
            payersFilterViewBuilder(
                classes.formCheckbox,
                (payer) => payers.includes(payer),
                (payer, active) => {
                    const includesPayer = payers.includes(payer);
                    if (active && !includesPayer) setPayers([...payers, payer]);
                    else if (!active && includesPayer) setPayers(payers.filter((value) => value !== payer));
                }
            ),
        [classes, payers, setPayers]
    );

    return (
        <FilterTooltip
            title={<FormGroup>{foldPayers(payersList)}</FormGroup>}
            type={FilterTypes.Payer}
            openedFilter={opened ? FilterTypes.Payer : null}
            hideOpenedFilter={() => {
                setOpened(false);
                onClose();
            }}
        >
            <Badge color="error" badgeContent={payers.length}>
                <Chip
                    disabled={payersList.length < 2 || disabled}
                    color="primary"
                    label="Плательщик"
                    variant={opened || isPayersFilterActive ? 'default' : 'outlined'}
                    deleteIcon={isPayersFilterActive ? <CancelIcon /> : <ExpandMoreIcon />}
                    onClick={() => {
                        setOpened(!opened);
                        if (onClick instanceof Function) onClick();
                    }}
                    onDelete={() => {
                        if (opened || payers.length > 0) {
                            setOpened(false);
                            onDelete();
                        } else setOpened(true);
                    }}
                />
            </Badge>
        </FilterTooltip>
    );
};

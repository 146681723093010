import {
    ADD_SEEN_BILL,
    FLUSH_SETTINGS,
    HelpInfoKey,
    IS_USER_SEEN_GUIDE,
    REMOVE_SEEN_BILL,
    SET_BILLS_LIST_PAGE_SIZE,
    SET_CURRENT_LAST_USER_SEEN_AT,
    SET_LAST_PAGINATION_PAGE_BEFORE_SEARCH,
    SET_MANAGERS_VISIBLE,
    SET_ORDER_LIST_PAGE_SIZE,
    SET_PAYMENTS_LIST_PAGE_SIZE,
    SET_PREVIOUS_LAST_USER_SEEN_AT,
    SET_SEEN_INFO_KEY,
    SET_SETTINGS,
    SettingsActionTypes,
} from './types';
import { PaginatorPageSize } from '../../utils/types';

export const setSettings = (payload: Protocol.SettingsResponse): SettingsActionTypes => ({
    type: SET_SETTINGS,
    payload,
});

export const flushSettingsCache = (): SettingsActionTypes => ({
    type: FLUSH_SETTINGS,
});

export const setManagersVisible = (payload: boolean): SettingsActionTypes => ({
    type: SET_MANAGERS_VISIBLE,
    payload,
});

export const setOrderListPageSize = (payload: PaginatorPageSize): SettingsActionTypes => ({
    type: SET_ORDER_LIST_PAGE_SIZE,
    payload,
});

export const setBillsListPageSize = (payload: PaginatorPageSize): SettingsActionTypes => ({
    type: SET_BILLS_LIST_PAGE_SIZE,
    payload,
});

export const setPaymentsListPageSize = (payload: PaginatorPageSize): SettingsActionTypes => ({
    type: SET_PAYMENTS_LIST_PAGE_SIZE,
    payload,
});

export const setLastPaginationPageBeforeSearch = (payload: number): SettingsActionTypes => ({
    type: SET_LAST_PAGINATION_PAGE_BEFORE_SEARCH,
    payload,
});

export const addSeenBill = (payload: number): SettingsActionTypes => ({
    type: ADD_SEEN_BILL,
    payload,
});

export const removeSeenBill = (payload: number): SettingsActionTypes => ({
    type: REMOVE_SEEN_BILL,
    payload,
});

export const setPreviousUserLastSeenAt = (payload: number | null): SettingsActionTypes => ({
    type: SET_PREVIOUS_LAST_USER_SEEN_AT,
    payload,
});

export const setCurrentLastUserSeenAt = (payload: number | null): SettingsActionTypes => ({
    type: SET_CURRENT_LAST_USER_SEEN_AT,
    payload,
});

export const setUserSeenGuide = (payload: boolean): SettingsActionTypes => ({
    type: IS_USER_SEEN_GUIDE,
    payload,
});

export const setSeenInfoKey = (payload: HelpInfoKey): SettingsActionTypes => ({
    type: SET_SEEN_INFO_KEY,
    payload,
});

import { Theme } from '@material-ui/core';
import { createStyles, StyleRules } from '@material-ui/styles';

const circleSize = 17;

export const styles = (theme: Theme): StyleRules =>
    createStyles({
        root: {
            gridColumn: '1 / -1',
            width: '100%',
        },
        heading: {
            fontSize: 18,
            marginBottom: 8,
        },
        body: {
            display: 'flex',
            justifyContent: 'space-between',
            padding: 0,

            [theme.breakpoints.down('md')]: {
                flexDirection: 'column',
                // override inline styles (which are set dynamically)
                width: '100% !important',
            },
        },
        item: {
            display: 'flex',
            flex: '1 0 0%',
            flexDirection: 'column',
            paddingTop: 24,
            position: 'relative',
            textAlign: 'left',
            '&::before': {
                backgroundColor: 'white',
                border: `1px solid ${theme.palette.secondary.light}`,
                borderRadius: '50%',
                content: '""',
                height: circleSize,
                left: 0,
                position: 'absolute',
                top: 0,
                width: circleSize,
                zIndex: 2,
            },
            '&::after': {
                backgroundColor: theme.palette.secondary.light,
                content: '""',
                height: 1,
                left: '50%',
                position: 'absolute',
                top: circleSize / 2,
                transform: 'translateX(-50%)',
                width: '100%',
                zIndex: 1,
            },
            '&:only-child': {
                '&::after': {
                    display: 'none',
                },
            },
            '&:nth-child(n + 2)': {
                paddingLeft: 16,
                paddingRight: 16,
                textAlign: 'center',
                [theme.breakpoints.down('md')]: {
                    paddingBottom: 12,
                    paddingLeft: 32,
                    paddingRight: 0,
                    textAlign: 'left',
                },
                '&::before': {
                    left: '50%',
                    transform: 'translateX(-50%)',
                    [theme.breakpoints.down('md')]: {
                        left: 0,
                        transform: 'none',
                    },
                },
                '&:last-child': {
                    paddingLeft: 0,
                    paddingRight: 0,
                    textAlign: 'right',
                    [theme.breakpoints.down('md')]: {
                        paddingBottom: 12,
                        paddingLeft: 32,
                        paddingRight: 0,
                        textAlign: 'left',
                    },
                    '&::before': {
                        left: 'auto',
                        right: 0,
                        transform: 'none',
                        [theme.breakpoints.down('md')]: {
                            left: 0,
                            right: 'auto',
                        },
                    },
                    '&::after': {
                        [theme.breakpoints.down('md')]: {
                            display: 'none',
                        },
                    },
                },
            },
            [theme.breakpoints.down('md')]: {
                paddingBottom: 12,
                paddingLeft: 32,
                paddingRight: 0,
                paddingTop: 0,
                '&::before': {
                    top: 4,
                },
                '&::after': {
                    backgroundColor: theme.palette.secondary.light,
                    height: '100%',
                    left: Math.round(circleSize / 2 - 1),
                    top: 4,
                    width: 1,
                },
                '&:last-child': {
                    paddingBottom: 0,
                },
            },
        },
        itemInner: {
            fontSize: 14,
            letterSpacing: 0.15,
            lineHeight: 1.5,
            margin: 0,
        },
        itemLabel: {
            color: 'rgba(0, 0, 0, 0.6)',
        },
        itemValue: {
            color: 'rgba(0, 0, 0, 0.87)',
            marginTop: 2,
        },
    });

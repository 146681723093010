// ----- State ------
export type AppState = {
    isInvitationVisible: boolean;
};

// ----- Actions ------
export const SET_INVITATION_VISIBILITY = 'SET_INVITATION_VISIBILITY';

interface SetInvitationVisibilityAction {
    type: typeof SET_INVITATION_VISIBILITY;
    payload: boolean;
}

export type AppActionTypes = SetInvitationVisibilityAction;

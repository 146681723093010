import React, { FC } from 'react';
import * as A from 'fp-ts/Array';

import { GroupedReportListItem } from '../../types/order-reports';

import { DownloadCard } from '../DownloadCard/DownloadCard';

import './DownloadGroup.scss';

type DownloadGroupProps = { data: GroupedReportListItem };
export const DownloadGroup: FC<DownloadGroupProps> = (props) => {
    const { data } = props;

    if (A.isEmpty(data.reports) && A.isEmpty(data.templates)) return null;

    const orderTypeName = data.order_type?.name || 'По всем заказам';

    return (
        <div className="DownloadGroup">
            <div className="DownloadGroup__header">{orderTypeName}</div>
            <div className="DownloadGroup__list">
                {data.reports.map((item) => (
                    <DownloadCard key={`reports-${item.id}`} data={item} kind="report" />
                ))}
                {data.templates.map((item) => (
                    <DownloadCard key={`templates-${item.id}`} data={item} kind="template" />
                ))}
            </div>
        </div>
    );
};

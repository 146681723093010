import type { RequestState } from '../../utils/requestState';
import type {
    GroupedReportListItem,
    ReportColumn,
    ReportDetails,
    TemplatesListItem
} from '../../types/order-reports';

// ----- Types ------
export type ReportListRequestState = RequestState<GroupedReportListItem[]>;
export type ReportDetailsRequestState = RequestState<ReportDetails>;
export type ReportColumnsRequestState = RequestState<ReportColumn[]>;
export type ReportTemplatesListRequestState = RequestState<TemplatesListItem[]>;

// ----- Payload Types ------
export type ReportDetailsPayload = {
    selector: string;
    data: ReportDetailsRequestState;
};

// ----- State ------
export type ReportsState = {
    list: ReportListRequestState;
    details: Record<string, ReportDetailsRequestState>;
    columns: ReportColumnsRequestState;
};

// ----- Actions ------
export const SET_REPORT_LIST_REQUEST_STATE = '@@Reports/SetListRequestState';
export const SET_REPORT_DETAILS_REQUEST_STATE = '@@Reports/SetDetailsRequestState';
export const SET_REPORT_COLUMNS_REQUEST_STATE = '@@Reports/SetColumnsRequestState';

interface SetReportList {
    type: typeof SET_REPORT_LIST_REQUEST_STATE;
    payload: ReportListRequestState;
}

interface SetReportDetails {
    type: typeof SET_REPORT_DETAILS_REQUEST_STATE;
    payload: ReportDetailsPayload;
}

interface SetReportColumns {
    type: typeof SET_REPORT_COLUMNS_REQUEST_STATE;
    payload: ReportColumnsRequestState;
}

export type ReportsActionTypes = SetReportList | SetReportDetails | SetReportColumns;

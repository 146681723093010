import React, { FunctionComponent, useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { connect } from 'react-redux';

import { pipe } from 'fp-ts/function';

import { Button, Card, CircularProgress, FormControl, TextField, Typography } from '@material-ui/core';

import { makeMapState } from '../../store/root';
import { apiProfileUpdate, makeMapDispatch } from '../../store/dispatch';

import { isUserGranted } from '../../utils/user';
import { nullableEmptyStringObject } from '../../utils/data';

import { useAlerts } from '../../hooks/noty';

import { MaskInput } from '../../components/input/MaskInput';

type FormData = Required<Protocol.ProfileUpdateRequest>;

const mapState = makeMapState((state) => ({
    userProfile: state.user.profile,
}));

const mapDispatch = makeMapDispatch({ emitUpdate: apiProfileUpdate });

type Props = ReturnType<typeof mapState> & ReturnType<typeof mapDispatch>;

const ProfileData: FunctionComponent<Props> = (props) => {
    const { userProfile, emitUpdate } = props;
    const defaultValues: FormData = {
        full_name: userProfile?.full_name ?? '',
        phone: userProfile?.phone ?? '',
        email: userProfile?.email ?? '',
    };

    const [loading, setLoading] = useState(false);
    const [cantUpdate, setCantUpdate] = useState(false);
    const { register, setValue, errors, setError, clearError, handleSubmit, watch } = useForm<FormData>({
        defaultValues,
    });
    const { handleApiError, showNotification } = useAlerts<FormData>(setError);

    const values = watch();
    useEffect(() => {
        const isFullNameEq = values.full_name === defaultValues.full_name;
        const isPhoneEq = values.phone === defaultValues.phone;
        const isEmailEq = values.email === (defaultValues.email || '');
        setCantUpdate(isFullNameEq && isPhoneEq && isEmailEq);
    }, [values, defaultValues]);

    const onSubmit = (payload: FormData): void => {
        clearError();
        setLoading(true);
        emitUpdate(nullableEmptyStringObject(payload))
            .then(() => {
                showNotification('Данные профиля изменены', {
                    variant: 'success',
                });
            })
            .catch(handleApiError)
            .finally(() => setLoading(false));
    };

    useEffect(() => {
        register('phone');
    }, [register]);

    return (
        <Card className="ProfileSettings__card">
            <Typography variant="h5">Контакты</Typography>

            <p className="ProfileSettings__cardDescription">Используются для рассылки уведомлений</p>

            <form className="ProfileSettings__form" onSubmit={handleSubmit(onSubmit)}>
                <FormControl variant="outlined" className="ProfileSettings__formItem">
                    <TextField
                        inputRef={register}
                        name="full_name"
                        variant="outlined"
                        label="ФИО"
                        error={!!errors.full_name}
                        helperText={errors.full_name?.message}
                        disabled={!isUserGranted(userProfile, 'change_full_name')}
                    />
                </FormControl>

                <FormControl variant="outlined" className="ProfileSettings__formItem">
                    <MaskInput
                        mask="+7 (999) 999-9999"
                        value={values.phone}
                        onChange={(phoneNumber) => setValue('phone', phoneNumber)}
                        disabled={!isUserGranted(userProfile, 'change_phone')}
                    >
                        <TextField
                            name="phone"
                            variant="outlined"
                            label="Телефон"
                            error={!!errors.phone}
                            helperText={errors.phone?.message}
                        />
                    </MaskInput>
                </FormControl>

                <FormControl variant="outlined" className="ProfileSettings__formItem">
                    <TextField
                        inputRef={register}
                        name="email"
                        variant="outlined"
                        label="Почта"
                        error={!!errors.email}
                        helperText={errors.email?.message}
                        disabled={!isUserGranted(userProfile, 'change_email')}
                    />
                </FormControl>

                {/* Profile data form footer */}
                <footer className="ProfileSettings__formFooter">
                    <div className="ProfileSettings__formSubmit">
                        <Button
                            type="submit"
                            variant="contained"
                            color="primary"
                            size="large"
                            disabled={cantUpdate || loading}
                        >
                            Сохранить
                        </Button>

                        {loading && (
                            <CircularProgress
                                className="ProfileSettings__formSubmitLoading"
                                variant="indeterminate"
                                size={16}
                            />
                        )}
                    </div>
                </footer>
            </form>
        </Card>
    );
};

const component = withRouter(pipe(ProfileData, connect(mapState, mapDispatch)));

export { component as ProfileData };

import {
    REMEMBER_SEARCH_QUERY,
    RESET_SEARCH_RESULT,
    SET_CONFIG,
    SET_CONFIG_PAGE,
    SET_CONFIG_PAGE_SIZE,
    SET_SEARCH_RESULT,
    UPDATE_SEARCH_BILLS_CUSTOMER_COMMENT,
    UPDATE_SEARCH_DISPUTE,
    SearchActionTypes,
    SearchQuery,
    SearchResultPayload,
    SearchSectionConfig,
    SetSearchConfigPagePayload,
    SetSearchConfigPageSizePayload,
} from './types';

import type { UpdateBillsDisputeActionPayload } from '../../types/dispute';
import type { UpdateBillsCustomerCommentPayload } from '../../types/comments';

export const resetSearchResult = (): SearchActionTypes => ({ type: RESET_SEARCH_RESULT });
export const rememberSearchQuery = (payload: SearchQuery): SearchActionTypes => ({
    type: REMEMBER_SEARCH_QUERY,
    payload,
});

export const setSearchResult = (payload: SearchResultPayload): SearchActionTypes => ({
    type: SET_SEARCH_RESULT,
    payload,
});

export const updateSearchDisputeAction = (payload: UpdateBillsDisputeActionPayload): SearchActionTypes => ({
    type: UPDATE_SEARCH_DISPUTE,
    payload,
});

export const updateSearchBillsCustomerCommentAction = (
    payload: UpdateBillsCustomerCommentPayload
): SearchActionTypes => ({
    type: UPDATE_SEARCH_BILLS_CUSTOMER_COMMENT,
    payload,
});

export const setConfigPageAction = (payload: SetSearchConfigPagePayload): SearchActionTypes => ({
    type: SET_CONFIG_PAGE,
    payload,
});

export const setConfigPageSizeAction = (payload: SetSearchConfigPageSizePayload): SearchActionTypes => ({
    type: SET_CONFIG_PAGE_SIZE,
    payload,
});

export const setConfigAction = (payload: SearchSectionConfig[]): SearchActionTypes => ({
    type: SET_CONFIG,
    payload,
});

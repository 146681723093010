import React, { FunctionComponent } from 'react';
import { Error } from '@material-ui/icons';
import { formatDate } from '../../utils/view';
import { ContainerCriticalEvent } from '../../store/orders/types';

export const Notification: FunctionComponent<{
    event: ContainerCriticalEvent | null;
}> = ({ event }) => {
    return (
        <div className="OrdersListRow__fifth OrdersListProduct__fifth">
            {event !== null && (
                <>
                    <h3 className="OrdersList__mobileLabel SortButton">Уведомление</h3>
                    <p className="ContainerRow__messageLabel">
                        {event.type.id === 'attention_required' && (
                            <Error className="OrdersListRow__eventIcon OrdersListRow__eventIcon--warning" />
                        )}

                        {event.type.id === 'very_important' && (
                            <Error className="OrdersListRow__eventIcon OrdersListRow__eventIcon--error" />
                        )}

                        <span
                            dangerouslySetInnerHTML={{
                                __html: event.message,
                            }}
                        />
                    </p>
                    <p className="ContainerRow__messageDate">{formatDate(event.came_at)}</p>
                </>
            )}
        </div>
    );
};

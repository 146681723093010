import { none, Option } from 'fp-ts/Option';
import { reduce } from 'fp-ts/Array';
import { pipe } from 'fp-ts/function';
import { keys } from 'fp-ts/Record';

import { Base64, makeBase64 } from './branding';
import { isEmptyString, isString, isUndefined } from './guards';

export type ImageData = {
    name: string;
    bytes: Base64;
};

export const readBlobAsBase64 = (blob: Blob): Promise<Option<Base64>> => {
    const reader = new FileReader();
    return new Promise((resolve) => {
        reader.readAsDataURL(blob);
        reader.onloadend = () => {
            const res = reader.result;
            if (isString(res)) resolve(makeBase64(res));
            else resolve(none);
        };
    });
};

export function base64ToBlob(base64: string): Promise<Blob> {
    return fetch(base64).then((h) => h.blob());
}

type NullableScalarRecord<T> = { [K in keyof T]: T[K] extends string | number ? T[K] | null : T[K] };
export const clearObject = <A extends object, K extends keyof A = keyof A, B = NullableScalarRecord<A>>(obj: A): B =>
    pipe(
        keys(obj) as K[],
        reduce({} as B, (acc, key) => {
            const value = obj[key];
            const cond = isUndefined(value) || isEmptyString(value);
            return {
                ...acc,
                [key]: cond ? null : value,
            };
        })
    );

export const nullableEmptyStringObject = <T extends object>(obj: T): T =>
    keys(obj).reduce(
        (acc, key) => ({
            ...acc,
            [key]: String(obj[key]) || null,
        }),
        {} as T
    );

import React, { FunctionComponent, ReactNode, useState } from 'react';
import clsx from 'clsx';

import { flow } from 'fp-ts/function';

import {
    Checkbox,
    ExpansionPanel,
    ExpansionPanelDetails,
    ExpansionPanelSummary,
    FormControlLabel,
} from '@material-ui/core';
import { ExpandMore } from '@material-ui/icons';

import { ActionType, ChipData, foldFiltersCount } from '../../shared/filterLogic';
import { OrderListFilters } from '../../types/order';
import { countWithLoading } from '../../utils/view';
import { ReportDetailsFiltersAndSorting } from '../../types/order-reports';

type ExpandableFilterGroupProps = {
    data: ChipData;
    counts: OrderListFilters;
    loading: boolean;
    filters: ReportDetailsFiltersAndSorting;
    onChange: (type: ActionType) => (id: number) => void;
    isReportsAdmin: boolean;
};

export const ExpandableFilterGroup: FunctionComponent<ExpandableFilterGroupProps> = (props) => {
    const { data, counts, loading, filters, onChange, isReportsAdmin } = props;

    const [isTypeOpen, setTypeOpen] = useState(false);

    const foldCount = flow(foldFiltersCount, (val: number): ReactNode => countWithLoading(val, loading));

    const onTypeChange = (): void => onChange('types')(data.origin.id);
    const onStatusChange = onChange('statuses');

    const isGroupChecked = filters.type_ids.includes(data.origin.id);
    const isGroupPartiallyChecked = data.origin.statuses.some((status) => filters.status_ids.includes(status.id));

    const typeLabel = (
        <>
            {data.origin.name}
            {!isReportsAdmin && <> • {foldCount(counts.order_types, data.origin.id)}</>}
        </>
    );

    if (data.origin.statuses.length < 1) {
        return (
            <div className="DownloadSettings__singleCheckboxwrapper">
                <FormControlLabel
                    className="DownloadSettings__singleCheckbox DownloadSettings__checkboxControlLabel"
                    label={typeLabel}
                    control={
                        <Checkbox
                            color="primary"
                            name={data.origin.name}
                            checked={isGroupChecked}
                            onChange={() => onTypeChange()}
                        />
                    }
                />
            </div>
        );
    }

    return (
        <ExpansionPanel
            className={clsx('DownloadSettings__card', 'DownloadSettings__card--lastLevel')}
            expanded={isTypeOpen}
            onChange={() => setTypeOpen((value) => !value)}
        >
            <ExpansionPanelSummary
                className={clsx('DownloadSettings__summary', 'DownloadSettings__summary--lastLevel')}
            >
                <div className="DownloadSettings__headingWrapper">
                    <FormControlLabel
                        className="DownloadSettings__checkboxControlLabel"
                        label={typeLabel}
                        onClick={(event) => event.stopPropagation()}
                        onFocus={(event) => event.stopPropagation()}
                        control={
                            <Checkbox
                                color="primary"
                                indeterminate={isGroupPartiallyChecked && !isGroupChecked}
                                checked={isGroupPartiallyChecked || isGroupChecked}
                                onChange={() => onTypeChange()}
                            />
                        }
                    />
                    <ExpandMore
                        className={clsx(
                            'DownloadSettings__icon',
                            'DownloadSettings__icon--noMargin',
                            isTypeOpen && 'DownloadSettings__icon--rounded'
                        )}
                    />
                </div>
            </ExpansionPanelSummary>

            <ExpansionPanelDetails
                className={clsx('DownloadSettings__details', 'DownloadSettings__details--lastLevel')}
            >
                {data.origin.statuses.map((status) => (
                    <FormControlLabel
                        className="DownloadSettings__checkboxControlLabel"
                        key={status.id}
                        checked={filters.status_ids.includes(status.id)}
                        onChange={() => onStatusChange(status.id)}
                        control={<Checkbox color="primary" disabled={isGroupChecked} />}
                        label={
                            <>
                                {status.name}&nbsp;• {foldCount(counts.statuses, status.id)}
                            </>
                        }
                    />
                ))}
            </ExpansionPanelDetails>
        </ExpansionPanel>
    );
};

import React, { FunctionComponent, useEffect, useMemo, useState } from 'react';
import { NavLink, useParams, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import { createStyles, makeStyles } from '@material-ui/styles';
import { Breadcrumbs, CircularProgress, Theme, Typography } from '@material-ui/core';

import { Lens } from 'monocle-ts';

import { lookup } from 'fp-ts/Record';
import { pipe } from 'fp-ts/function';
import * as O from 'fp-ts/Option';

import { apiGetOrderContainer, apiGetRecipientsList, makeMapDispatch } from '../store/dispatch';
import { makeMapState } from '../store/root';
import { OrderContainer } from '../store/orders/types';

import { makeContainerTitle } from '../utils/view';
import { RecipientState } from '../utils/notifications';
import { setPageTitle } from '../utils/title';

import { useSearch } from '../hooks/search';
import { useAlerts } from '../hooks/noty';

import { ContainerNotificationCard } from '../components/ContainerNotificationCard';
import { Guide } from '../components/Guide/Guide';

import { AddRecipient } from './ProfileSettings/AddRecipient';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            maxWidth: 1100,
            margin: '0 auto',
            paddingTop: 32,
            paddingBottom: 32,
            paddingRight: 16,
            paddingLeft: 16,
            [theme.breakpoints.down('sm')]: {
                paddingRight: 8,
                paddingLeft: 8,
            },
        },
        title: {
            fontSize: 24,
            lineHeight: 1.16,
            color: 'rgba(0, 0, 0, 0.87)',
            fontWeight: 'normal',
            marginTop: 0,
            marginBottom: 20,
        },
    })
);

const containerOrderTypeLens = Lens.fromPath<OrderContainer>()(['order', 'type', 'id']).asGetter();

const mapState = makeMapState((state) => ({
    cacheList: state.orders.containers,
}));
const mapDispatch = makeMapDispatch({
    getContainer: apiGetOrderContainer,
    getRecipients: apiGetRecipientsList,
});

type ContainerNotificationsProps = ReturnType<typeof mapState> & ReturnType<typeof mapDispatch>;
const ContainerNotifications: FunctionComponent<ContainerNotificationsProps> = (props) => {
    const { cacheList, getContainer, getRecipients } = props;
    const { containerId } = useParams();
    const { hasSearchData } = useSearch();
    const [recipients, setRecipients] = useState<RecipientState[]>([]);
    const [openedCard, setOpenedCard] = useState(-1);
    const [loading, setLoading] = useState(false);
    const classes = useStyles();

    const { handleApiError } = useAlerts();
    const { generateSearchUrl, searchResultLink } = useSearch();

    const onUpdate = (recipientsToUpdate: RecipientState[]): void => setRecipients(recipientsToUpdate);
    const onAdd = (newRecipients: RecipientState[]): void => {
        if (newRecipients.length > 0) setOpenedCard(newRecipients[newRecipients.length - 1].id);
        setRecipients(recipients.concat(newRecipients));
    };

    const [container, containerOrderType] = useMemo(() => {
        const containerData = pipe(
            O.fromNullable(containerId),
            O.chain((id) => lookup(id, cacheList)),
            O.toNullable
        );

        const orderType = pipe(O.fromNullable(containerData), O.map(containerOrderTypeLens.get), O.toNullable);

        return [containerData, orderType];
    }, [containerId, cacheList]);

    const title = useMemo(() => makeContainerTitle(container), [container]);

    useEffect(() => {
        if (container === null && !loading && containerId) {
            setLoading(true);
            getContainer({ container_id: parseInt(containerId, 10) })
                .catch(handleApiError)
                .finally(() => setLoading(false));
        }
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (!loading) {
            setLoading(true);
            getRecipients({
                container_id: containerId ? parseInt(containerId, 10) : null,
            })
                .then((response: Protocol.RecipientsListResponse) => {
                    setRecipients(response.items);
                })
                .catch(handleApiError)
                .finally(() => setLoading(false));
        }
    }, [getRecipients]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (container !== null && !hasSearchData) setPageTitle(`${title} | Уведомления`);
    }, [container, hasSearchData, title]);

    if (loading)
        return (
            <div className="App__loader App__center">
                <CircularProgress className="App__fixedCenter" />
            </div>
        );

    return (
        <section className={classes.container}>
            <div className="OrderView__breadcrumbs">
                <Breadcrumbs aria-label="breadcrumb" separator="•">
                    <NavLink className="FilesView__breadcrumbsLink" color="inherit" to="/orders">
                        Мои заказы
                    </NavLink>
                    {searchResultLink(`/orders/${containerId}`)}
                    <NavLink
                        className="FilesView__breadcrumbsLink"
                        color="inherit"
                        to={generateSearchUrl(`/orders/${containerId}`, false)}
                    >
                        {title}
                    </NavLink>
                    <Typography color="textSecondary">Настройки уведомлений</Typography>
                </Breadcrumbs>
            </div>
            <h3 className={classes.title}>
                <span style={{ marginRight: 4 }}>Индивидуальные настройки уведомлений</span>
                <Guide />
            </h3>

            {recipients.length === 0 && (
                <p style={{ marginBottom: 48 }}>
                    Чтобы получать уведомления об&nbsp;изменениях ваших заказов по&nbsp;SMS, e-mail или в&nbsp;Telegram,
                    добавьте получателя:
                </p>
            )}
            {recipients.map((recipient) => {
                return (
                    <ContainerNotificationCard
                        isOpen={recipient.id === openedCard}
                        key={`notifications-${recipient.id}`}
                        data={recipient}
                        containerId={Number(containerId)}
                        containerOrderType={containerOrderType}
                        onUpdate={onUpdate}
                        setOpen={() => {
                            setOpenedCard(recipient.id === openedCard ? -1 : recipient.id);
                        }}
                    />
                );
            })}
            <AddRecipient recipients={recipients} containerId={Number(containerId)} onAdd={onAdd} />
        </section>
    );
};

const component = withRouter(pipe(ContainerNotifications, connect(mapState, mapDispatch)));

export { component as ContainerNotifications };

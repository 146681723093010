import React, { FunctionComponent } from 'react';

import { ExpansionPanel, ExpansionPanelSummary } from '@material-ui/core';

import styles from './NotificationCard.module.scss';

type SimpleNotificationCardProps = {
    title: React.ReactNode;
    content: React.ReactNode;
};

export const SimpleNotificationCard: FunctionComponent<SimpleNotificationCardProps> = (props) => {
    const { title, content } = props;

    return (
        <ExpansionPanel expanded={false}>
            <ExpansionPanelSummary classes={{ root: styles.summaryRoot, content: styles.summaryContent }}>
                <div>
                    <div className={styles.number}>{title}</div>

                    <div className={styles.event}>{content}</div>
                </div>
            </ExpansionPanelSummary>
        </ExpansionPanel>
    );
};

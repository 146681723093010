import React, { createContext, FunctionComponent } from 'react';
import { apiUpdateBillComment, apiUpdateOrderComment } from '../store/dispatch';
import { useThunkDispatch } from '../hooks/thunk';

type BuildUpdateBillCommentSignature = (billId: number) => (text: string) => Promise<void>;
type BuildUpdateOrderCommentSignature = (orderId: number) => (text: string) => Promise<void>;

export type CommentsProviderContext = {
    buildUpdateBillComment: BuildUpdateBillCommentSignature;
    buildUpdateOrderComment: BuildUpdateOrderCommentSignature;
};

type CommentsProviderProps = {};

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
export const CommentsContext = createContext<CommentsProviderContext>(null!);
export const CommentsProvider: FunctionComponent<CommentsProviderProps> = (props) => {
    const dispatch = useThunkDispatch();

    const buildUpdateOrderComment: BuildUpdateOrderCommentSignature = (orderId) => (text) =>
        dispatch(
            apiUpdateOrderComment({
                comment: text,
                order_id: orderId,
            })
        );

    const buildUpdateBillComment: BuildUpdateBillCommentSignature = (billId) => async (text) => {
        const payload = { comment: text, bill_id: billId };
        await dispatch(apiUpdateBillComment(payload));
    };

    return (
        <CommentsContext.Provider value={{ buildUpdateOrderComment, buildUpdateBillComment }}>
            {props.children}
        </CommentsContext.Provider>
    );
};

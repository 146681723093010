import React, { FunctionComponent } from 'react';

import { ChevronRight, ExpandMore } from '@material-ui/icons';
import { TreeItem, TreeView } from '@material-ui/lab';

import type { ChipData, CountsList, ToggleHandler } from '../../shared/filterLogic';
import { foldFiltersCount, makeIsChecked } from '../../shared/filterLogic';
import type { OrderListFilters } from '../../types/order';
import { mergeLabel } from '../../utils/strings';

import { CheckboxWithLabel } from '../input/CheckboxWithLabel';

type FiltersTreeViewProps = {
    data: ChipData;
    chipsCounts: CountsList;
    toggleFilter: ToggleHandler;
    listFilters: OrderListFilters;
    isCheckboxDisabled: boolean;
};

export const FiltersTreeView: FunctionComponent<FiltersTreeViewProps> = (props) => {
    const { data, chipsCounts, toggleFilter, listFilters, isCheckboxDisabled } = props;

    const isChecked = makeIsChecked(data.key, chipsCounts);

    return (
        <TreeView style={{ width: '100%' }} defaultCollapseIcon={<ExpandMore />} defaultExpandIcon={<ChevronRight />}>
            {data.origin.statuses.map((status) => {
                return (
                    <TreeItem
                        key={`${data.key}-status-${status.id}`}
                        nodeId={`${data.key}-status-${status.id}`}
                        label={
                            <CheckboxWithLabel
                                label={mergeLabel(status.name, foldFiltersCount(listFilters.statuses, status.id))}
                                checked={isChecked('statuses', status.id)}
                                disabled={isCheckboxDisabled}
                                callback={() => toggleFilter(data.key, 'statuses', status.id)}
                            />
                        }
                    />
                );
            })}
        </TreeView>
    );
};

import React, { FunctionComponent, useEffect, useState } from 'react';
import clsx from 'clsx';

import { pipe } from 'fp-ts/function';
import { connect } from 'react-redux';

import { Add } from '@material-ui/icons';
import { Button, Typography } from '@material-ui/core';

import { UserWithNotifications } from '../../types/user';

import { setPageTitle } from '../../utils/title';

import { useAlerts } from '../../hooks/noty';
import { useBusEffect } from '../../hooks/bus';

import { HelpInfoKey } from '../../store/settings/types';
import { apiManagedUsersList, makeMapDispatch } from '../../store/dispatch';

import { HelpInfo } from '../../components/HelpInfo/HelpInfo';
import { LoadingFixedCenter } from '../../components/LoadingFixedCenter';

import { NewEmployee } from './NewEmployee/NewEmployee';
import { EmployeeCard } from './EmployeeCard/EmployeeCard';
import { EmployeeDeleteConfirm } from './EmployeeDeleteConfirm/EmployeeDeleteConfirm';

import styles from './Employees.module.scss';

const mapDispatch = makeMapDispatch({ emitFetchList: apiManagedUsersList });

type EmployeesProps = ReturnType<typeof mapDispatch>;
const Employees: FunctionComponent<EmployeesProps> = (props) => {
    const { emitFetchList } = props;

    const [employees, setEmployees] = useState<UserWithNotifications[]>([]);
    const [isAddNewEmployeeOpen, setAddNewEmployeeOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [userToDelete, setUserToDelete] = useState<UserWithNotifications | null>(null);

    const { handleApiError } = useAlerts();

    const fetchEmployees = (): void => {
        if (loading) return;

        setLoading(true);
        emitFetchList()
            .then(({ items }) => setEmployees(items))
            .catch(handleApiError)
            .finally(() => setLoading(false));
    };

    useEffect(fetchEmployees, []); // eslint-disable-line react-hooks/exhaustive-deps
    useBusEffect('onReFetchEmployees', () => {
        setAddNewEmployeeOpen(false);
        fetchEmployees();
    });

    useEffect(() => {
        setPageTitle('Сотрудники');
    }, []);

    return (
        <div className={clsx('App__container', styles.container)}>
            <Typography variant="h1">Сотрудники компании</Typography>

            <HelpInfo
                uniqueKey={HelpInfoKey.Employees}
                title="Для чего нужен этот раздел?"
                description={
                    <>
                        В&nbsp;этом разделе вы&nbsp;можете добавить других сотрудников вашей компании. У&nbsp;каждого
                        будет свой логин и&nbsp;пароль, с&nbsp;которым они смогут заходить в&nbsp;личный кабинет.
                        У&nbsp;каждого пользователя будут свои персональные настройки личного кабинета.
                    </>
                }
                isWithTopGap
            />

            <Button
                className={styles.button}
                variant="outlined"
                size="large"
                color="primary"
                onClick={() => setAddNewEmployeeOpen((prevValue) => !prevValue)}
                startIcon={<Add />}
                fullWidth
            >
                Добавить сотрудника
            </Button>

            {isAddNewEmployeeOpen && (
                <div className={styles.newEmployee}>
                    <NewEmployee onClose={() => setAddNewEmployeeOpen(false)} />
                </div>
            )}

            {loading ? (
                <LoadingFixedCenter />
            ) : (
                <div className={styles.items}>
                    {!loading && employees.length === 0 ? (
                        <div>Сотрудников не найдено</div>
                    ) : (
                        employees.map((user) => <EmployeeCard key={user.id} user={user} onDelete={setUserToDelete} />)
                    )}
                </div>
            )}

            {userToDelete !== null && (
                <EmployeeDeleteConfirm onClose={() => setUserToDelete(null)} employee={userToDelete} isOpen />
            )}
        </div>
    );
};

const component = pipe(Employees, connect(null, mapDispatch));
export { component as Employees };

import type { AllFiltersTypes, InfoFilterType, ValuableFilters } from './types';
import type {
    DocumentStatus,
    FailedDocumentStatus,
    GetDocumentResponse,
    PreparedDocumentStatus,
    PreparedDocumentResponse,
} from '../types/documents';

const emailRegexp = /^[^@]+@[^@]+\.[^@]+$/;

export const isEmail = (value: string): boolean => emailRegexp.test(value);
export const isArray = (value: unknown): value is Array<unknown> => Array.isArray(value);
export const isString = (value: unknown): value is string => typeof value === 'string';
export const isNumber = (value: unknown): value is number => typeof value === 'number';
export const isUndefined = (value: unknown): value is undefined => typeof value === 'undefined';
export const isEmptyString = (value: unknown): boolean => isString(value) && value.length === 0;

export const aboveZero = (value: number): boolean => value > 0;

export const isValuableFilter = (filter: AllFiltersTypes): filter is ValuableFilters => filter.hasOwnProperty('value');
export const isInfoFilter = (filter: AllFiltersTypes): filter is InfoFilterType => !isValuableFilter(filter);

export const failedDocumentStatuses: DocumentStatus[] = ['no_documents_generated', 'prepared_document_does_not_exist'];
export const isFailedDocumentStatus = (status: DocumentStatus): status is FailedDocumentStatus =>
    failedDocumentStatuses.includes(status);
export const isPreparedDocumentStatus = (status: DocumentStatus): status is PreparedDocumentStatus =>
    status === 'document_prepared';
export const isPreparedDocument = (response: GetDocumentResponse): response is PreparedDocumentResponse =>
    isPreparedDocumentStatus(response.status);

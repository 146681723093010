import { Theme } from '@material-ui/core';
import { createStyles, StyleRules } from '@material-ui/styles';

const chipGutter = 16;
const drawerPadding = 16;

export const styles = (theme: Theme): StyleRules =>
    createStyles({
        filters: {
            marginBottom: 8,
            display: 'grid',
            alignItems: 'center',
            gridTemplateColumns: 'auto auto auto',
            gridColumnGap: 32,
            gridTemplateAreas: '"DocumentStatusChanger chips statusFilters"',

            '@media (max-width: 1366px)': {
                alignItems: 'start',
            },

            [theme.breakpoints.down('lg')]: {
                gridGap: '0 12px',
                gridTemplateColumns: '1fr',
                gridTemplateAreas: '"buttons"',
                gridTemplateRows: '1fr',
            },
        },
        DocumentStatusChanger: {
            gridArea: 'DocumentStatusChanger',

            [theme.breakpoints.down('lg')]: {
                display: 'none',
            },
        },
        chips: {
            display: 'flex',
            flexWrap: 'wrap',
            gridArea: 'chips',
            alignItems: 'center',
            margin: 0,
            marginLeft: -chipGutter,
            marginBottom: -chipGutter,
            padding: 0,
            listStyle: 'none',
        },
        chipsHorizontal: {
            [theme.breakpoints.down('lg')]: {
                display: 'none',
            },
        },
        chipsVertical: {
            alignItems: 'flex-start',
            marginLeft: 0,
            flexDirection: 'column',
            padding: drawerPadding,
        },
        chip: {
            margin: 0,
            paddingLeft: chipGutter,
            paddingBottom: chipGutter,
        },
        chipVertical: {
            [theme.breakpoints.down('lg')]: {
                paddingLeft: 0,
            },
        },
        statusFilters: {
            gridArea: 'statusFilters',
            display: 'flex',
            flexDirection: 'column',
            color: 'rgba(0, 0, 0, 0.6)',
        },
        statusFiltersHorizontal: {
            [theme.breakpoints.down('lg')]: {
                display: 'none',
                '& .MuiTypography-body1': {
                    fontSize: 13,
                },
            },
        },
        statusFilter: {
            [theme.breakpoints.down('lg')]: {
                justifyContent: 'space-between',
                marginLeft: 0,
                padding: '10px 20px',
                '&:last-child': {
                    marginTop: -8,
                },
                '&:not(:last-of-type)': {
                    borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
                },
            },
        },
        modal: {
            width: 620,
            maxWidth: '100%',
            marginLeft: 'auto',
            marginRight: 'auto',

            '& .MuiDialog-paperWidthSm': {
                width: 620,
                maxWidth: '100%',
            },
        },
        search: {
            gridArea: 'search',
            marginRight: 24,
            flexGrow: 1,
            [theme.breakpoints.down('lg')]: {
                marginRight: 0,
            },
        },
        resetButton: {
            gridArea: 'resetButton',
            marginRight: 24,
            marginLeft: 24,
            [theme.breakpoints.down('lg')]: {
                justifySelf: 'start',
                marginLeft: 0,
                marginRight: 0,
                alignSelf: 'end',
            },
        },
        buttons: {
            display: 'none',
            gridTemplateColumns: 'repeat(2, auto)',
            gridGap: 8,
            justifyContent: 'space-between',
            gridArea: 'buttons',
            [theme.breakpoints.down('lg')]: {
                display: 'grid',
            },
            [theme.breakpoints.down('xs')]: {
                gridTemplateColumns: 'repeat(2, auto)',
                gridGap: 8,

                '& .MuiButton-label': {
                    fontSize: 12,
                },
            },
        },
        drawerHeader: {
            alignItems: 'center',
            display: 'flex',
            justifyContent: 'space-between',
            padding: drawerPadding,
        },
        drawerHeading: {
            fontWeight: 'bold',
        },
        drawerBody: {
            padding: drawerPadding,
            overflow: 'hidden',
        },
        sortButton: {
            padding: '8px 0',
        },
        viewToggler: {
            padding: '0 16px 16px',
        },
    });

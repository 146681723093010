import { createStyles, StyleRules } from '@material-ui/styles';
import { Theme } from '@material-ui/core';

export const styles = (theme: Theme): StyleRules => createStyles({
    logo: {
        marginBottom: 128,
        [theme.breakpoints.down('md')]: {
            marginBottom: 92,
        },
        [theme.breakpoints.down('sm')]: {
            marginBottom: 80,
        },
    },
    defaultLogo: {
        minWidth: 300,
        width: 500,
        maxWidth: '80%',
    },
    smallLogo: {
        marginBottom: 64,
        maxWidth: '65%',
    },
});

import { applyMiddleware, combineReducers, createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly';
import thunk, { ThunkMiddleware } from 'redux-thunk';
import { persistStore } from 'redux-persist';

import type { SyncProblemsActionTypes } from './sync-problems/types';
import { FeedActionTypes } from './feed/types';
import type { DocumentsActionTypes } from './documents/types';
import type { SettingsActionTypes } from './settings/types';
import type { ReportsActionTypes } from './orderReports/types';
import type { SearchActionTypes } from './search/types';
import type { OrdersActionTypes } from './orders/types';
import type { PagesActionTypes } from './pages/types';
import type { BillsActionTypes } from './bills/types';
import type { UserActionTypes } from './user/types';
import type { AppActionTypes } from './app/types';

import { feedReducer } from './feed/reducer';
import { syncProblemsReducer } from './sync-problems/reducer';
import { documentsReducer } from './documents/reducer';
import { settingsReducer } from './settings/reducer';
import { reportReducer } from './orderReports/reducer';
import { ordersReducer } from './orders/reducer';
import { searchReducer } from './search/reducer';
import { pagesReducer } from './pages/reducer';
import { sortsReducer } from './sorts/reducer';
import { billsReducer } from './bills/reducer';
import { userReducer } from './user/reducer';
import { appReducer } from './app/reducer';

export const rootReducer = combineReducers({
    app: appReducer,
    user: userReducer,
    orders: ordersReducer,
    settings: settingsReducer,
    pages: pagesReducer,
    search: searchReducer,
    syncProblems: syncProblemsReducer,
    sorts: sortsReducer,
    bills: billsReducer,
    documents: documentsReducer,
    reports: reportReducer,
    criticalEvents: feedReducer,
});

export type RootState = ReturnType<typeof rootReducer>;
export type RootActionType =
    | AppActionTypes
    | UserActionTypes
    | SettingsActionTypes
    | OrdersActionTypes
    | PagesActionTypes
    | SearchActionTypes
    | SyncProblemsActionTypes
    | BillsActionTypes
    | FeedActionTypes
    | DocumentsActionTypes
    | ReportsActionTypes;

export const store = createStore(
    rootReducer,
    composeWithDevTools(applyMiddleware(thunk as ThunkMiddleware<RootState, RootActionType>))
);

export const persistor = persistStore(store);

export const makeMapState = <S>(f: (state: RootState) => S) => (state: RootState) => f(state);

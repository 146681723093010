import React, { FunctionComponent, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import clsx from 'clsx';
import moment from 'moment';
import InfiniteScroll from 'react-infinite-scroll-component';

import { pipe } from 'fp-ts/lib/function';
import * as R from 'fp-ts/lib/Record';

import { Close, Settings } from '@material-ui/icons';
import {
    Button,
    Card,
    CircularProgress,
    FormControlLabel,
    IconButton,
    Modal,
    SvgIcon,
    Switch,
    Typography,
} from '@material-ui/core';

import {
    makeMapDispatch,
    apiFeedList,
    apiCriticalEventsStats,
    apiCriticalEventsMarkAsViewed,
    apiCriticalEventsUpdateVisibility,
} from '../../store/dispatch';
import { makeMapState } from '../../store/root';
import { ManagerGroup } from '../../store/orders/types';

import { useUrl } from '../../hooks/url';
import { useAlerts } from '../../hooks/noty';

import { formatDate } from '../../utils/view';
import { setPageTitle } from '../../utils/title';

import { ManagersGroup } from '../../components/ManagersGroup/ManagersGroup';
import { NotificationPanel } from '../../components/NotificationPanel/NotificationPanel';

import { ReactComponent as IconEmpty } from '../../assets/file-icons/empty.svg';

import styles from './Feed.module.scss';
import { Link } from 'react-router-dom';
import { ROUTES } from '../../utils/location';

const mapDispatch = makeMapDispatch({
    getList: apiFeedList,
    updateEventVisibility: apiCriticalEventsUpdateVisibility,
    markAsViewed: apiCriticalEventsMarkAsViewed,
    getCriticalEventsStats: apiCriticalEventsStats,
});

const mapState = makeMapState((state) => ({
    list: state.criticalEvents.items,
    earliestShownDate: state.criticalEvents.earliestShownDate,
}));

type Props = ReturnType<typeof mapState> & ReturnType<typeof mapDispatch>;
const Feed: FunctionComponent<Props> = (props) => {
    const { list, earliestShownDate, getList, updateEventVisibility, markAsViewed, getCriticalEventsStats } = props;

    const [loading, setLoading] = useState(false);
    const [openedAccordionHash, setOpenedAccordionHash] = useState<string | null>(null);
    const [showCriticalOnly, setCriticalOnly] = useState<boolean | null>(null);
    const [showHidden, setShowHidden] = useState<boolean | null>(null);
    const [modalOpen, setModalOpen] = useState(false);
    const [modalInvisible, setModalInvisible] = useState(false);
    const [managersList, setManagersList] = useState<ManagerGroup[]>([]);
    const [modalOrderNumber, setModalOrderNumber] = useState('');

    const { addQueryParam, getQueryParam } = useUrl();

    const getFeedPayloadQueryParam = (key: keyof Protocol.FeedListRequest): string | null => {
        return getQueryParam(key);
    };

    const handleModalClose = (): void => setModalOpen(false);
    const handleModalOpen = (managers: ManagerGroup[], orderNumber: string): void => {
        setManagersList(managers);
        setModalOrderNumber(orderNumber);
        setModalOpen(true);
    };

    const { showNotification, handleApiError } = useAlerts();

    const updateList = (payload: Protocol.FeedListRequest, isResetData?: boolean): void => {
        setLoading(true);

        R.keys(payload as Required<Protocol.FeedListRequest>).forEach((key) => {
            const value = payload[key];
            if (value !== undefined && key !== 'earlier_than') addQueryParam(key, value as string, true);
        });

        getList(
            {
                show_hidden: payload.show_hidden ?? showHidden ?? undefined,
                show_critical_only: payload.show_critical_only ?? showCriticalOnly ?? undefined,
                earlier_than: payload.earlier_than,
            },
            isResetData ?? (payload.show_hidden !== undefined || payload.show_critical_only !== undefined)
        ).finally(() => setLoading(false));
    };

    const updateVisibility = (notificationsIds: number[], isHidden: boolean): void => {
        setLoading(true);

        updateEventVisibility({ notification_ids: notificationsIds, is_hidden: isHidden })
            .then(() => {
                updateList({}, true);
                showNotification(`Уведомление успешно ${isHidden ? 'скрыто' : 'показано'}`, {
                    variant: 'success',
                });
            })
            .catch(() => {
                showNotification('Не удалось скрыть уведомление', {
                    variant: 'warning',
                });
            })
            .finally(() => setLoading(false));
    };

    useEffect(() => {
        const isHiddenRaw = getFeedPayloadQueryParam('show_hidden');
        const isHidden = isHiddenRaw !== null ? isHiddenRaw === 'true' : undefined;
        if (isHidden !== undefined) setShowHidden(isHidden);

        const isCriticalOnlyRaw = getFeedPayloadQueryParam('show_critical_only');
        const isCriticalOnly = isCriticalOnlyRaw !== null ? isCriticalOnlyRaw === 'true' : undefined;
        if (isCriticalOnly !== undefined) setCriticalOnly(isCriticalOnly);

        const defaultPayload: Protocol.FeedListRequest = {
            show_hidden: isHidden,
            show_critical_only: isCriticalOnly,
            earlier_than: earliestShownDate ?? undefined,
        };

        setPageTitle('Уведомления');
        updateList(defaultPayload);

        markAsViewed()
            .then(() => {
                getCriticalEventsStats();
            })
            .catch(handleApiError);
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <div className="App__container">
            <div className={styles.headingWrapper}>
                <Typography variant="h1">Уведомления</Typography>

                <Button
                    className={styles.settingsButton}
                    variant="text"
                    color="primary"
                    component={Link}
                    to={`${ROUTES.PROFILE}#notifications`}
                    startIcon={<SvgIcon component={Settings} color="primary" />}
                >
                    Настройки уведомлений
                </Button>

                <div className={styles.filters}>
                    <FormControlLabel
                        className={styles.switch}
                        control={
                            <Switch
                                color="primary"
                                checked={showCriticalOnly ?? false}
                                onChange={() => {
                                    updateList({ show_critical_only: !showCriticalOnly });
                                    setCriticalOnly((currentValue) => !currentValue);
                                }}
                            />
                        }
                        label="Только важные"
                    />

                    <FormControlLabel
                        className={styles.switch}
                        control={
                            <Switch
                                color="primary"
                                checked={showHidden ?? false}
                                onChange={() => {
                                    updateList({ show_hidden: !showHidden });
                                    setShowHidden((currentValue) => !currentValue);
                                }}
                            />
                        }
                        label="Показать скрытые"
                    />
                </div>
            </div>

            <InfiniteScroll
                dataLength={list.length}
                loader={null}
                next={() => {
                    updateList({
                        earlier_than: earliestShownDate ?? undefined,
                    });
                }}
                hasMore={list.length === 0 || earliestShownDate !== null}
            >
                {list.length > 0 ? (
                    <div className={styles.groups}>
                        {list.map((group) => {
                            const date = formatDate(group.date, false, 'YYYY.MM.DD');
                            const now = moment().format('DD.MM.YYYY');
                            const yesterday = moment()
                                .subtract(1, 'days')
                                .format('DD.MM.YYYY');

                            let formattedDate = now === date ? `Сегодня (${date})` : date;
                            formattedDate = yesterday === formattedDate ? `Вчера (${date})` : formattedDate;

                            return (
                                <div key={group.date}>
                                    <NotificationPanel
                                        date={formattedDate}
                                        openedAccordionHash={openedAccordionHash}
                                        setOpenedAccordionHash={setOpenedAccordionHash}
                                        orders={group.orders}
                                        mutualSettlementsReport={group.mutual_settlements_report}
                                        updateVisibility={updateVisibility}
                                        handleModalOpen={handleModalOpen}
                                    />
                                </div>
                            );
                        })}
                    </div>
                ) : (
                    !loading && (
                        <Card className={styles.emptyState}>
                            <IconEmpty />

                            <span className={styles.emptyStateText}>У вас пока нет уведомлений</span>
                        </Card>
                    )
                )}

                {loading && (
                    <div className={styles.loaderWrapper}>
                        <CircularProgress />
                    </div>
                )}

                {!loading && earliestShownDate === null && list.length > 0 && (
                    <p className={styles.allLoadedText}>Все уведомления загружены</p>
                )}
            </InfiniteScroll>

            <Modal open={modalOpen} onClose={handleModalClose} className={styles.modal}>
                <div className={clsx(styles.modalBody, modalInvisible && styles.modalBodyInvisible)}>
                    <IconButton className={styles.modalClose} onClick={handleModalClose}>
                        <Close />
                    </IconButton>

                    <h2>Контактная информация</h2>

                    <div className={styles.modalManagers}>
                        <ManagersGroup
                            groups={managersList}
                            orderNumber={modalOrderNumber}
                            onCallModalClose={() => setModalInvisible(false)}
                            onCallModalOpen={() => setModalInvisible(true)}
                        />
                    </div>
                </div>
            </Modal>
        </div>
    );
};

const component = pipe(Feed, connect(mapState, mapDispatch));
export { component as Feed };

import { User } from '../../types/user';

export interface UserState {
    isAuthenticated: boolean;
    profile: User | null;
    redirectUrl: string | null;
}

export const USER_LOGIN = 'USER_LOGIN';
export const USER_LOGOUT = 'USER_LOGOUT';
export const USER_SET_PROFILE = 'USER_SET_PROFILE';
export const USER_SET_REDIRECT_URL = 'USER_SET_REDIRECT_URL';

interface UserLoginAction {
    type: typeof USER_LOGIN;
}

interface UserLogoutAction {
    type: typeof USER_LOGOUT;
}

interface UserSetProfileAction {
    type: typeof USER_SET_PROFILE;
    payload: User;
}

interface UserSetRedirectUrlAction {
    type: typeof USER_SET_REDIRECT_URL;
    payload: string | null;
}

export type UserActionTypes = UserLoginAction | UserLogoutAction | UserSetProfileAction | UserSetRedirectUrlAction;

import { BillsFiltersPayload, BillsPackageRequest } from '../bills/types';

type DocumentPayload =
    | Protocol.OrderContainerPackageRequestDocumentRequest
    | Protocol.OrderPackageRequestDocumentRequest
    | Protocol.OrderContainersPackageRequestDocumentRequest
    | Protocol.PaymentHistoryRequest
    | Protocol.TemplateDocumentRequest
    | Protocol.ReportsDocumentRequest
    | BillsPackageRequest
    | BillsFiltersPayload;

export type DocumentState = {
    payload: DocumentPayload;
    key: string;
    type: string;
};

export type DocumentsState = {
    items: DocumentState[];
};

export const REMOVE_DOCUMENT = 'REMOVE_DOCUMENT';
export const ADD_DOCUMENT = 'ADD_DOCUMENT';

interface AddDocumentAction {
    type: typeof ADD_DOCUMENT;
    data: DocumentState;
}

interface RemoveDocumentAction {
    type: typeof REMOVE_DOCUMENT;
    key: string;
}

export type DocumentsActionTypes = AddDocumentAction | RemoveDocumentAction;

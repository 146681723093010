import React, { FunctionComponent } from 'react';
import { Link } from 'react-router-dom';

import { Button, Typography } from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

import { ROUTES } from '../../utils/location';

import styles from './NotificationsHeader.module.scss';
import { useUrl } from '../../hooks/url';

type NotificationsHeaderProps = {
    title: string;
    isEmployee: boolean;
    userId?: string;
};
export const NotificationsHeader: FunctionComponent<NotificationsHeaderProps> = (props) => {
    const { title, isEmployee, userId } = props;
    const { getQueryParam } = useUrl();

    let link = isEmployee ? ROUTES.EMPLOYEES : `${ROUTES.PROFILE}#notifications`;

    if (isEmployee) {
        if (userId) link = `${link}?userId=${userId}`;
    } else {
        const container = getQueryParam('container');
        const order = getQueryParam('order');

        if (order) {
            link = `${ROUTES.ORDERS}?order=${order}`;

            if (container) link = `${ROUTES.ORDERS}/${container}#open_modal`;
        }
    }

    return (
        <div>
            <Button
                startIcon={<ArrowBackIcon />}
                className={styles.backButton}
                type="button"
                component={Link}
                to={link}
            >
                Назад
            </Button>

            <Typography variant="h1" className={styles.title}>
                {title}
            </Typography>
        </div>
    );
};

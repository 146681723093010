import React, { ReactNode, useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { useForm, FieldError } from 'react-hook-form';
import { connect } from 'react-redux';

import { pipe } from 'fp-ts/function';

import { withStyles } from '@material-ui/styles';
import { TextField, Card, Typography, WithStyles, Button } from '@material-ui/core';

import clsx from 'clsx';
import { apiUserLogin, makeMapDispatch } from '../../store/dispatch';
import { ApiError } from '../../api/client/errors';
import { appTitle } from '../../utils/title';
import { NewPersonalCabinetUrl } from '../../utils/const';

import { Logo } from '../../components/Logo/Logo';
import { LoadingButton } from '../../components/LoadingButton';
import { PasswordField } from '../../components/PasswordField/PasswordField';

import { styles } from './styles';

type FormData = Protocol.AuthLoginRequest;

const getFieldError = (err: FieldError | undefined): ReactNode => {
    if (!err) return null;
    if (err.type === 'required') return 'Необходимо заполнить поле';
    return err.message;
};

const mapDispatch = makeMapDispatch({ emitLogin: apiUserLogin });

type Props = ReturnType<typeof mapDispatch> & WithStyles<typeof styles>;
const Auth: React.FC<Props> = (props) => {
    const { emitLogin, classes } = props;

    const { register, errors, handleSubmit, setError, clearError } = useForm<FormData>();
    const [loading, setLoading] = useState(false);

    const onSubmit = (payload: FormData): void => {
        clearError();
        setLoading(true);
        emitLogin(payload).catch((error) => {
            setLoading(false);
            if (error instanceof ApiError && error.isCommon()) {
                const commonError = error.getCommonFirstMessage() || 'Неизвестная ошибка';
                setError([
                    { type: 'custom', name: 'login', message: commonError },
                    { type: 'custom', name: 'password', message: commonError },
                ]);
            }
        });
    };

    useEffect(() => {
        document.title = `${appTitle()} | Личный кабинет`;
    }, []);

    return (
        <section className={classes.root}>
            <Logo />

            <div className={classes.cardsWrapper}>
                <Card className={clsx(classes.card, classes.darkCard)}>
                    <Typography className={classes.darkCardHeading} variant="h5">
                        Личный кабинет 2.0
                    </Typography>

                    <span className={classes.darkCardText}>
                        Мы полностью обновили дизайн личного кабинета. Теперь он удобнее и проще, а скоро в нём будет
                        ещё больше информации по вашим заказам.
                    </span>

                    <div className={classes.linkButtonWrapper}>
                        <Button
                            href={NewPersonalCabinetUrl}
                            variant="contained"
                            color="primary"
                            size="large"
                            className={classes.linkButton}
                        >
                            Попробовать
                        </Button>
                    </div>
                </Card>
                <Card className={classes.card}>
                    <Typography className={classes.heading} variant="h5">
                        Вход в приложение
                    </Typography>

                    <form className={classes.form} onSubmit={handleSubmit(onSubmit)}>
                        <TextField
                            inputRef={register({ required: true })}
                            id="login"
                            name="login"
                            label="Логин"
                            className={classes.formInput}
                            variant="outlined"
                            error={!!errors.login}
                            helperText={getFieldError(errors.login)}
                            onKeyDown={() => clearError()}
                        />

                        <PasswordField
                            inputRef={register({ required: true })}
                            id="password"
                            name="password"
                            label="Пароль"
                            variant="outlined"
                            className={classes.formInput}
                            error={!!errors.password}
                            helperText={getFieldError(errors.password)}
                            onKeyDown={() => clearError()}
                        />

                        <LoadingButton
                            className={classes.submitButton}
                            variant="contained"
                            type="submit"
                            color="primary"
                            size="large"
                            loading={loading}
                        >
                            Войти
                        </LoadingButton>
                    </form>
                </Card>
            </div>
        </section>
    );
};

const component = withRouter(pipe(Auth, withStyles(styles), connect(null, mapDispatch)));

export { component as Auth };

import { fromTraversable, Iso } from 'monocle-ts';

import { Ord as StrOrd } from 'fp-ts/string';
import { flow } from 'fp-ts/function';
import * as R from 'fp-ts/Record';
import * as S from 'fp-ts/Semigroup';
import * as A from 'fp-ts/Array';

import { User } from '../types/user';

export type RecipientState = {
    id: number;
    phone: string | null;
    email: string | null;
    telegram: {
        url: string;
        subscribed: boolean;
    };
    use_template_with_comments: boolean;
    container_id: number | null;
    settings: NotificationEntity[];
    document_settings: PrintFormType[];
    mutual_settlements_report_settings: NotificationEntityTypes[][];
};

export type RecipientsState = {
    items: RecipientState[];
};

export type NotificationEntityKind = 'event' | 'task' | 'document' | 'mutual_settlements_report' | 'print_form_type';
export type NotificationEntityTypes = 'email' | 'feed' | 'sms' | 'telegram';

export type PrintFormTypeTypes = 'email' | 'feed' | 'telegram';
export type PrintFormTypeEnum = ApiEnum<number>;
export type PrintFormType = {
    id: number;
    types: PrintFormTypeTypes[];
};

export type NotificationEntity = {
    id: number;
    kind: NotificationEntityKind;
    types: NotificationEntityTypes[];
    container_id: number | null;
    order_type_id: number | null;
};

export type FormDataEntity = Record<NotificationEntityTypes, boolean>;

export type SettingsFormDataEntity = {
    id: number;
    kind: NotificationEntityKind;
    types: FormDataEntity;
    container_id: number | null;
    order_type_id: number | null;
};

export type NotificationFormData = Record<string, SettingsFormDataEntity>;
export type DocumentTypesFormData = Record<string, FormDataEntity>;

export type NotificationSettingsFormData = {
    data: NotificationFormData;
};

const reduceTypesIntoFormData = A.reduce<NotificationEntityTypes, FormDataEntity>(
    { email: false, sms: false, telegram: false, feed: false } as FormDataEntity,
    (entity, key) => R.insertAt(key, true)(entity)
);

export const reduceFormDataIntoTypes = R.reduceWithIndex<NotificationEntityTypes, boolean, NotificationEntityTypes[]>(
    [] as NotificationEntityTypes[],
    (key, accum, flag) => (flag ? [...accum, key] : accum)
);

const notificationTypesIso = new Iso(reduceTypesIntoFormData, reduceFormDataIntoTypes);

const formDataEntityIso = new Iso<NotificationEntity, SettingsFormDataEntity>(
    (s) => ({ ...s, types: notificationTypesIso.get(s.types) }),
    (a) => ({ ...a, types: notificationTypesIso.from(a.types) })
);

const reduceNotificationIntoForm = (list: NotificationEntity[]): NotificationFormData =>
    R.fromFoldableMap(S.last<SettingsFormDataEntity>(), A.Foldable)(list, (item) => [
        `${item.order_type_id}_${item.kind}_${item.id}`,
        formDataEntityIso.get(item),
    ]);

const reduceFormIntoNotification = fromTraversable(R.Traversable)<SettingsFormDataEntity>()
    .composeIso(formDataEntityIso.reverse())
    .asFold().getAll;

export const notificationEntityIso = new Iso(reduceNotificationIntoForm, reduceFormIntoNotification);

const filterNonFormTypes = flow(
    notificationTypesIso.from,
    A.filter((item): item is PrintFormTypeTypes => item !== 'sms')
);

export const notificationDocumentsIso = new Iso<PrintFormType[], DocumentTypesFormData>(
    (types) =>
        R.fromFoldableMap(S.last<FormDataEntity>(), A.Foldable)(types, (item) => [
            `id_${item.id}`,
            notificationTypesIso.to(item.types),
        ]),
    R.reduceWithIndex(StrOrd)([] as PrintFormType[], (key, acc, v: FormDataEntity) =>
        A.append({ id: Number(key.replace('id_', '')), types: filterNonFormTypes(v) })(acc)
    )
);

export const notificationTypeAvailabilityMessage = (type: NotificationEntityTypes, user: User): string | null => {
    if (type === 'telegram' && !(user.telegram && user.telegram.subscribed)) {
        return 'Необходимо подписаться на Telegram бота в настройках профиля';
    }

    if (type === 'sms' && !user.phone) {
        return 'Необходимо ввести номер телефона в настройках профиля';
    }

    if (type === 'email' && !user.email) {
        return 'Необходимо ввести электронную почту в настройках профиля';
    }

    return null;
};

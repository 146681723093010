import {
    BillsActionTypes,
    BillsListResponse,
    RESET_BILLS_RESULT,
    SET_BILLS_RESULT,
    UPDATE_BILLS_CUSTOMER_COMMENT,
    UPDATE_BILLS_DISPUTE,
} from './types';
import { UpdateBillsDisputeActionPayload } from '../../types/dispute';
import { UpdateBillsCustomerCommentPayload } from '../../types/comments';

export const resetBillsResultAction = (): BillsActionTypes => ({ type: RESET_BILLS_RESULT });
export const setBillsResultAction = (payload: BillsListResponse): BillsActionTypes => ({
    type: SET_BILLS_RESULT,
    payload,
});

export const updateBillsDisputeAction = (payload: UpdateBillsDisputeActionPayload): BillsActionTypes => ({
    type: UPDATE_BILLS_DISPUTE,
    payload,
});

export const updateBillsCustomerCommentAction = (payload: UpdateBillsCustomerCommentPayload): BillsActionTypes => ({
    type: UPDATE_BILLS_CUSTOMER_COMMENT,
    payload,
});

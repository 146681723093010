import React, { FunctionComponent, useEffect } from 'react';
import { pipe } from 'fp-ts/function';
import { connect } from 'react-redux';
import { ReactComponent as IconHamburgerSmall } from '../assets/hamburger-small.svg';
import { ReactComponent as IconHamburgerLarge } from '../assets/hamburger-large.svg';
import { makeMapDispatch } from '../store/dispatch';
import { setMinifiedViewAction } from '../store/orders/actions';
import { RootState } from '../store/root';
import clsx from 'clsx';

const ordersViewsTypesListArray = ['min', 'full'];
export type OrdersViewsTypes = typeof ordersViewsTypesListArray[number];
export type RadiosViewsTogglers = {
    key: string | number;
    icon: React.ReactElement;
    action: () => void;
    type: OrdersViewsTypes;
};

export const ordersViewTogglers: RadiosViewsTogglers[] = [
    { key: 1, icon: <IconHamburgerSmall />, action: () => {}, type: 'full' },
    { key: 2, icon: <IconHamburgerLarge />, action: () => {}, type: 'min' },
];

const STORAGE_VIEW_TYPE_KEY = 'storageViewType';

export const isCorrectViewType = (str: string): str is OrdersViewsTypes => ordersViewsTypesListArray.includes(str);
export const getPersistentViewType = (): OrdersViewsTypes => {
    const value = localStorage.getItem(STORAGE_VIEW_TYPE_KEY);
    if (value !== null && isCorrectViewType(value)) {
        return value as OrdersViewsTypes;
    }

    return 'full';
};
export const setPersistentViewType = (value: OrdersViewsTypes): void =>
    localStorage.setItem(STORAGE_VIEW_TYPE_KEY, value.toString());
const isMinified = (val: OrdersViewsTypes): boolean => val === 'min';

type StateProps = { isMinifiedView: boolean };
const mapState = (state: RootState): StateProps => ({ isMinifiedView: state.orders.isMinifiedView });
const mapDispatch = makeMapDispatch({ setMinifiedView: setMinifiedViewAction });

type TogglerComponentProps = {
    prefix?: string;
    className?: string;
};
type OrdersViewTogglerProps = TogglerComponentProps & ReturnType<typeof mapState> & ReturnType<typeof mapDispatch>;
const OrdersViewToggler: FunctionComponent<OrdersViewTogglerProps> = (props) => {
    const { isMinifiedView, setMinifiedView, className, prefix = 'orders-view-toggler' } = props;

    useEffect(() => {
        const type = getPersistentViewType();
        setMinifiedView(isMinified(type));
    }, [isMinifiedView]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <ul className={clsx('DocumentStatusChanger', className)}>
            {ordersViewTogglers.map((radio) => {
                const radioId = `${prefix}-${radio.key}-input`;
                return (
                    <li key={`${prefix}-${radio.key}`} className="DocumentStatusChanger__item">
                        <input
                            type="radio"
                            className="DocumentStatusChanger__radio OrdersListBase__radioInput"
                            id={radioId}
                            name={`${prefix}-inputs`}
                            onClick={() => {
                                setPersistentViewType(radio.type);
                                setMinifiedView(isMinified(radio.type));
                                radio.action();
                            }}
                            onChange={() => {}}
                            checked={
                                (radio.type === 'min' && isMinifiedView) || (radio.type === 'full' && !isMinifiedView)
                            }
                        />
                        <label htmlFor={radioId} className="DocumentStatusChanger__label OrdersListBase__radioLabel">
                            {radio.icon}
                        </label>
                    </li>
                );
            })}
        </ul>
    );
};

const component = pipe(OrdersViewToggler, connect(mapState, mapDispatch));

export { component as OrdersViewToggler };

import React, { FunctionComponent } from 'react';
import { Pagination } from '@material-ui/lab';

import { OrdersSearchResult } from '../../../store/search/types';
import { ResultWrapperProps } from '../types';

import { OrdersViewToggler } from '../../OrdersViewToggler';
import { OrderList } from '../../OrderList/OrderList';

import { PageSizeSelect } from '../../PageSizeSelect/PageSizeSelect';

type OrdersResultProps = ResultWrapperProps<OrdersSearchResult>;
export const OrdersResult: FunctionComponent<OrdersResultProps> = (props) => {
    const { data, loading, paginatorProps, onPageChange } = props;
    return (
        <div className="Orders__searchContainer">
            <div className="Orders__searchHeader">
                <h6 className="Orders__searchResultLabel">
                    Найдено в заказах • <span>{data.items_total}</span>
                </h6>

                <OrdersViewToggler prefix="orders-search-view-toggler-item" />
            </div>
            <OrderList key="section-orders" orders={data.items} hideSort />
            <div className="Orders__listFooter">
                <PageSizeSelect {...paginatorProps} />
                {data.page_last > 1 && (
                    <div className="Orders__pagination">
                        <Pagination
                            shape="rounded"
                            page={data.page_current}
                            count={data.page_last}
                            disabled={loading}
                            onChange={(evt, page) => onPageChange('orders', page)}
                        />
                    </div>
                )}
            </div>
        </div>
    );
};

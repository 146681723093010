import React, { FunctionComponent, useCallback } from 'react';
import { connect } from 'react-redux';

import orange from '@material-ui/core/colors/orange';
import { Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { createStyles, Theme } from '@material-ui/core/styles';

import { RobinPhones } from '../../providers/SIPProvider';
import { useSIP } from '../../hooks/sip';
import { apiOnlineCallCount, makeMapDispatch } from '../../store/dispatch';

type StyleProps = { active: boolean };
const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root({ active }: StyleProps) {
            const bgColor = active ? orange[500] : theme.palette.primary.main;
            const hoverColor = active ? orange[700] : theme.palette.primary.dark;
            return {
                marginTop: theme.spacing(2),
                marginBottom: theme.spacing(2),
                color: theme.palette.getContrastText(bgColor),
                backgroundColor: bgColor,
                '&:hover': {
                    backgroundColor: hoverColor,
                },
            };
        },
    })
);

const mapDispatch = makeMapDispatch({
    onlineCallCount: apiOnlineCallCount,
});

type SIPButtonProps = { orderId: string; robinPhones: RobinPhones } & ReturnType<typeof mapDispatch>;
const SIPButton: FunctionComponent<SIPButtonProps> = (props) => {
    const { callState, call, hangup } = useSIP();
    const { onlineCallCount } = props;

    const classes = useStyles({ active: callState !== null });

    const onClickAction = useCallback(
        (orderId: string, robinPhones: RobinPhones) => () => {
            onlineCallCount();
            if (callState === null) call(orderId, robinPhones);
            else hangup();
        },
        [callState, call, hangup, onlineCallCount]
    );

    return (
        <Button variant="contained" classes={classes} onClick={onClickAction(props.orderId, props.robinPhones)}>
            {callState === null ? 'Позвонить' : 'Завершить'}
        </Button>
    );
};

const Component = connect(null, mapDispatch)(SIPButton);
export { Component as SIPButton };

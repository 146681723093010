// ----- Types ------
import { PaginatorPageSize } from '../../utils/types';
import { NotificationEntityKind, PrintFormTypeEnum } from '../../utils/notifications';

export type OrderType = {
    id: number;
    name: string;
    tasks: OrderTaskType[];
    statuses: OrderStatus[];
};

export type OrderTaskType = {
    id: number;
    name: string;
};

export type OrderStatus = {
    id: number;
    name: string;
    tasks: OrderEvent[];
    events: OrderEvent[];
};

export type OrderEvent = {
    id: number;
    name: string;
};

export type NotificationAvailableType = 'email' | 'feed' | 'sms' | 'telegram';
export type NotificationEnumType = ApiEnum<NotificationAvailableType>;

export type NotificationType = {
    kind: NotificationEntityKind;
    types: NotificationEnumType[];
};

export type NotificationTypeWithAllowed = {
    kind: NotificationEntityKind;
    allowed_channels: NotificationEnumType[];
};

export type SipSettings = {
    aor: string;
    username: string;
    password: string;
    phone_switch_host: string;
    websocket_server_url: string;
    auto_phone_system_internal_code: number;
};

export enum HelpInfoKey {
    ProfileSettings = 'profile-settings',
    NotificationsSettings = 'notifications-settings',
    AdditionalSettings = 'additional-settings',
    Employees = 'employees',
}

export type WeekDays = 'monday' | 'tuesday' | 'wednesday' | 'thursday' | 'friday' | 'saturday' | 'sunday';
export type DayOffWeekDay = { id: WeekDays; name: string };
export type DayOffPeriod = { start_date: Time.Date; end_date: Time.Date };
export type DaysOff = { week_day: DayOffWeekDay | null; period: DayOffPeriod | null };
export type WorkingHours = {
    start_time_seconds: number;
    end_time_seconds: number;
    days_off: DaysOff[];
};
export type DocumentType = {
    id: number;
    name: string;
};

type PageSizeSectionType = 'orders' | 'bills' | 'payments';
type PageSizeConfig = Record<PageSizeSectionType, PaginatorPageSize>;

// ----- Actions ------
export const SET_SETTINGS = 'SET_SETTINGS';
export const FLUSH_SETTINGS = 'FLUSH_SETTINGS';
export const SET_MANAGERS_VISIBLE = 'SET_MANAGERS_VISIBLE';
export const SET_ORDER_LIST_PAGE_SIZE = 'SET_ORDER_LIST_PAGE_SIZE';
export const SET_BILLS_LIST_PAGE_SIZE = 'SET_BILLS_LIST_PAGE_SIZE';
export const SET_PAYMENTS_LIST_PAGE_SIZE = 'SET_PAYMENTS_LIST_PAGE_SIZE';
export const SET_LAST_PAGINATION_PAGE_BEFORE_SEARCH = 'SET_LAST_PAGINATION_PAGE_BEFORE_SEARCH';
export const SET_PREVIOUS_LAST_USER_SEEN_AT = 'SET_PREVIOUS_LAST_USER_SEEN_AT';
export const SET_CURRENT_LAST_USER_SEEN_AT = 'SET_CURRENT_LAST_USER_SEEN_AT';
export const ADD_SEEN_BILL = 'ADD_SEEN_BILL';
export const REMOVE_SEEN_BILL = 'REMOVE_SEEN_BILL';
export const IS_USER_SEEN_GUIDE = 'IS_USER_SEEN_GUIDE';
export const SET_SEEN_INFO_KEY = 'SET_SEEN_INFO_KEY';

interface SetSettingsAction {
    type: typeof SET_SETTINGS;
    payload: Protocol.SettingsResponse;
}

interface FlushSettingsAction {
    type: typeof FLUSH_SETTINGS;
}

interface SetManagersVisibleAction {
    type: typeof SET_MANAGERS_VISIBLE;
    payload: boolean;
}

interface SetOrderListPageSizeAction {
    type: typeof SET_ORDER_LIST_PAGE_SIZE;
    payload: PaginatorPageSize;
}

interface SetBillsListPageSizeAction {
    type: typeof SET_BILLS_LIST_PAGE_SIZE;
    payload: PaginatorPageSize;
}

interface SetPaymentsListPageSizeAction {
    type: typeof SET_PAYMENTS_LIST_PAGE_SIZE;
    payload: PaginatorPageSize;
}

interface SetLastPaginationPageBeforeSearch {
    type: typeof SET_LAST_PAGINATION_PAGE_BEFORE_SEARCH;
    payload: number;
}

interface AddSeenBill {
    type: typeof ADD_SEEN_BILL;
    payload: number;
}

interface RemoveSeenBill {
    type: typeof REMOVE_SEEN_BILL;
    payload: number;
}

// Два значения используются для того, чтобы можно было для сессии текущей брать предыдущее значение и обновлять для следующей сессии
interface SetPreviousLastUserSeenAt {
    type: typeof SET_PREVIOUS_LAST_USER_SEEN_AT;
    payload: number | null;
}

interface SetCurrentLastUserSeenAt {
    type: typeof SET_CURRENT_LAST_USER_SEEN_AT;
    payload: number | null;
}

interface SetIsUserSeenGuide {
    type: typeof IS_USER_SEEN_GUIDE;
    payload: boolean;
}

interface SetSeenInfoKey {
    type: typeof SET_SEEN_INFO_KEY;
    payload: HelpInfoKey;
}

export type SettingsActionTypes =
    | SetSettingsAction
    | FlushSettingsAction
    | SetManagersVisibleAction
    | SetOrderListPageSizeAction
    | SetBillsListPageSizeAction
    | SetPaymentsListPageSizeAction
    | SetLastPaginationPageBeforeSearch
    | SetPreviousLastUserSeenAt
    | SetCurrentLastUserSeenAt
    | AddSeenBill
    | RemoveSeenBill
    | SetIsUserSeenGuide
    | SetSeenInfoKey;

// ----- State ------
export interface SettingsState {
    fetched: boolean;
    orderTypes: OrderType[];
    notificationTypes: NotificationTypeWithAllowed[];
    sipSettings: SipSettings;
    workingHours: WorkingHours;
    documentTypes: DocumentType[];
    managersVisible: boolean;
    lastPaginationPageBeforeSearch: number;
    seenBills: number[];
    previousLastUserSeenAt: number | null;
    currentLastUserSeenAt: number | null;
    customerOrderTypeIds: number[];
    syncedOrderTypeIds: number[];
    isUserSeenGuide: boolean;
    pageSizeConfig: PageSizeConfig;
    printFormTypes: PrintFormTypeEnum[];
    seenHelpInfoKeys: HelpInfoKey[];
}

import React, { FunctionComponent, useCallback, useEffect, useMemo, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import { CircularProgress } from '@material-ui/core';
import { Pagination } from '@material-ui/lab';

import { debounce } from 'lodash-es';
import { pipe, flow } from 'fp-ts/function';

import { apiGetOrderDetails, apiGetOrderList, makeMapDispatch } from '../store/dispatch';
import { setOrderListPageSize } from '../store/settings/actions';
import { makeMapState } from '../store/root';

import { FiltersChangeHandler, FiltersChangePayload, filtersEquals, getSelectedOptions } from '../shared/filterLogic';

import { foldPageCount, foldView, scrollToTop } from '../utils/view';
import { PaginatorPageSizes } from '../utils/const';
import { setPageTitle } from '../utils/title';

import { useSearch } from '../hooks/search';
import { useAlerts } from '../hooks/noty';
import { useUrl } from '../hooks/url';

import { SortProvider } from '../providers/SortProvider';

import { LoadingFixedCenter } from '../components/LoadingFixedCenter';
import { PageSizeSelect } from '../components/PageSizeSelect/PageSizeSelect';
import { OrdersFilters } from '../components/OrderFilters/OrderFilters';
import { OrderList } from '../components/OrderList/OrderList';
import { ContainerNotificationsSettingsModal } from '../components/container/NotificationSettingsModal/ContainerNotificationsSettingsModal';
import { OrderListItem } from '../store/orders/types';

const mapState = makeMapState((state) => {
    const { items: orders, filters, ...paginator } = state.orders.list;
    const pageCount = foldPageCount(paginator);

    const { flags, counts, search } = state.orders.localFilters;
    const { statuses, events, types } = getSelectedOptions(counts);
    const defaultFilters = {
        ...flags,
        type_ids: types,
        event_ids: events,
        status_ids: statuses,
    };

    return {
        orders,
        search,
        sorting: state.sorts.orders,
        defaultFilters,
        listFilters: filters,
        pageCount,
        currentPage: paginator.page,
        pageSize: state.settings.pageSizeConfig.orders,
        total: state.orders.list.total,
    };
});

const mapDispatch = makeMapDispatch({
    getOrderList: apiGetOrderList,
    setPageSize: setOrderListPageSize,
    getOrder: apiGetOrderDetails,
});

type Props = ReturnType<typeof mapState> & ReturnType<typeof mapDispatch>;
const Orders: FunctionComponent<Props> = (props) => {
    const {
        getOrderList,
        orders,
        pageSize,
        pageCount,
        currentPage,
        defaultFilters,
        search,
        listFilters,
        sorting,
        setPageSize,
        total,
        getOrder,
    } = props;

    const { paginationPageParamValue, changePaginationPageUrlParam, initialIsArchivedValue, getQueryParam } = useUrl();
    const { handleApiError } = useAlerts();
    const { hasSearchData } = useSearch();

    const initialIsArchived = initialIsArchivedValue(false);

    const [isSubscriptionModalOpen, setSubscriptionModalOpen] = useState(false);
    const [order, setOrder] = useState<OrderListItem>();
    const [isInitialized, setInitialized] = useState(false);
    const [loading, setLoading] = useState(false);
    const [filters, setFilters] = useState<FiltersChangePayload>(defaultFilters);

    const fetchOrders = useCallback(
        debounce(
            async (page: number = currentPage, rawFilters: FiltersChangePayload = filters): Promise<void> => {
                setLoading(true);

                const requestPayload: Protocol.OrderListRequest = { page, page_size: pageSize };
                if (rawFilters.archived !== null) requestPayload.is_archived = rawFilters.archived;
                if (rawFilters.embargo) requestPayload.embargo = true;
                if (rawFilters.has_critical_events) requestPayload.has_critical_events = true;
                if (rawFilters.type_ids.length) requestPayload.type_ids = rawFilters.type_ids;
                if (rawFilters.event_ids.length) requestPayload.event_ids = rawFilters.event_ids;
                if (rawFilters.status_ids.length) requestPayload.status_ids = rawFilters.status_ids;
                if (sorting) requestPayload.sorting = sorting;
                if (search) requestPayload.search_query = search;

                try {
                    await getOrderList(requestPayload);
                } catch (e) {
                    handleApiError(e);
                    setLoading(false);
                }

                setLoading(false);
            },
            50,
            { leading: true, trailing: false }
        ),
        [search, sorting, currentPage, filters, getOrderList, pageSize]
    ); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (isInitialized) fetchOrders(paginationPageParamValue).catch(handleApiError);
    }, [sorting, search]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (!isInitialized) {
            setFilters((v) => ({ ...v, archived: initialIsArchived }));
            fetchOrders(paginationPageParamValue, {
                ...filters,
                archived: initialIsArchived,
            })
                .catch(handleApiError)
                .finally(() => setInitialized(true));

            const orderId = getQueryParam('order');

            if (orderId) {
                getOrder({ order_id: Number(orderId) })
                    .then((response) => {
                        setOrder(response);
                        setSubscriptionModalOpen(true);
                    })
                    .catch(handleApiError);
            }
        } else setInitialized(true);
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (!isInitialized) return;

        const page = Math.min(currentPage, foldPageCount({ total, page_size: Number(pageSize) }));
        if (page < currentPage) changePaginationPageUrlParam(page);

        fetchOrders(page);
    }, [pageSize]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (!hasSearchData) setPageTitle('Мои заказы');
    }, [hasSearchData]);

    const handleFiltersChange: FiltersChangeHandler = async (payload) => {
        if (filtersEquals(payload, filters)) return;
        setFilters(payload);
        changePaginationPageUrlParam(1);
        await fetchOrders(1, payload);
    };

    const isDefault = useMemo(() => filtersEquals(filters, defaultFilters), [filters, defaultFilters]);

    const viewState = foldView(orders, loading && orders.length === 0, null);

    return (
        <div className="Orders__container">
            <LoadingFixedCenter visible={orders.length > 0 && loading} />
            <section className="Orders">
                <SortProvider>
                    <OrdersFilters onChange={handleFiltersChange} listFilters={listFilters} />

                    {viewState(
                        () => (
                            <div className="App__centerLoader">
                                {isDefault ? (
                                    'Список заказов пуст'
                                ) : (
                                    <span>
                                        По выбранным параметрам, заказы не найдены
                                        <br />
                                        Проверьте выбранные фильтры
                                    </span>
                                )}
                            </div>
                        ),
                        () => (
                            <div className="App__centerLoader">
                                <CircularProgress />
                            </div>
                        ),
                        () => null,
                        (list) => (
                            <OrderList
                                orders={list}
                                onRefetch={() => {
                                    fetchOrders();
                                }}
                            />
                        )
                    )}
                    <div className="Orders__listFooter">
                        <PageSizeSelect
                            value={pageSize}
                            options={PaginatorPageSizes}
                            onChange={flow(setPageSize, scrollToTop)}
                        />
                        {isInitialized && pageCount > 1 && (
                            <div className="Orders__pagination">
                                <Pagination
                                    shape="rounded"
                                    page={currentPage}
                                    count={pageCount}
                                    disabled={loading}
                                    onChange={(evt, page) => {
                                        changePaginationPageUrlParam(page);
                                        fetchOrders(page)
                                            .then(scrollToTop)
                                            .catch(handleApiError);
                                    }}
                                />
                            </div>
                        )}
                    </div>
                </SortProvider>
            </section>

            {order && (
                <ContainerNotificationsSettingsModal
                    isOpen={isSubscriptionModalOpen}
                    container={null}
                    order={order}
                    onClose={() => {
                        setSubscriptionModalOpen(false);
                    }}
                />
            )}
        </div>
    );
};

const component = withRouter(pipe(Orders, connect(mapState, mapDispatch)));

export { component as Orders };

import { createStyles, StyleRules } from '@material-ui/styles';

export const styles = (): StyleRules => createStyles({
    list: {
        maxWidth: 280,
        width: '87.5vw',
    },
    listLarge: {
        maxWidth: 350,
        width: '90vw',
    },
});

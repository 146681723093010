import push from 'react-yandex-metrika';

interface KnownGoals {
    login: { login: string };
}

export function reachGoal<
    GoalName extends keyof KnownGoals,
    Payload extends KnownGoals[GoalName]
>(goalName: GoalName, payload: Payload): void {
    push('reachGoal', goalName, payload);
}

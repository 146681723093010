import React, { FunctionComponent } from 'react';
import clsx from 'clsx';

import { pipe } from 'fp-ts/function';
import * as A from 'fp-ts/Array';
import * as O from 'fp-ts/Option';

import { Card, LinearProgress } from '@material-ui/core';
import { Error } from '@material-ui/icons';

import {
    FeedItemGroupBillAndInvoice,
    FeedItemGroupBillOrInvoice,
    FeedItemGroupContainer,
    FeedItemGroupCriticalEvent,
    FeedItemGroupPrintFormTypes,
    FeedItemOrder,
    FeedItemOrderGroup,
    FeedItemOrderGroupEvent,
    MutualSettlementsReport,
    NotificationItem,
} from '../../store/feed/types';
import { ManagerGroup } from '../../store/orders/types';

import { formatDate, formatDateFromDateTime } from '../../utils/view';

import { NotificationCard } from '../NotificationCard/NotificationCard';
import { SimpleNotificationCard } from '../NotificationCard/SimpleNotificationCard';

import styles from './NotificationPanel.module.scss';

type GroupKind = Exclude<keyof FeedItemOrderGroup, 'manager_groups'>;
type DataItem<T extends GroupKind, D> = { kind: T; data: D };
type Data =
    | DataItem<'events', FeedItemOrderGroupEvent>
    | DataItem<'containers', FeedItemGroupContainer>
    | DataItem<'critical_events', FeedItemGroupCriticalEvent>
    | DataItem<'print_form_types', FeedItemGroupPrintFormTypes>
    | DataItem<'bills', FeedItemGroupBillOrInvoice>
    | DataItem<'invoices', FeedItemGroupBillOrInvoice>
    | DataItem<'bill_invoices', FeedItemGroupBillAndInvoice>;

type ExpandableNotificationsPanelProps = {
    date: string;
    orders: FeedItemOrder[];
    mutualSettlementsReport: MutualSettlementsReport | null;
    updateVisibility(criticalEventIds: number[], isHidden: boolean): void;
    handleModalOpen(managers: ManagerGroup[], orderNumber: string): void;
    openedAccordionHash: string | null;
    setOpenedAccordionHash(hash: string | null): void;
};
export const NotificationPanel: FunctionComponent<ExpandableNotificationsPanelProps> = (props) => {
    const {
        date,
        updateVisibility,
        orders,
        mutualSettlementsReport,
        handleModalOpen,
        openedAccordionHash,
        setOpenedAccordionHash,
    } = props;

    const groupKindsToMakeCards: GroupKind[] = [
        'events',
        'critical_events',
        'print_form_types',
        'bills',
        'invoices',
        'bill_invoices',
    ];

    const foldBillOrInvoice = (item: FeedItemGroupBillOrInvoice): React.ReactNode => {
        return (
            <>
                {item.change_state_type.name} {/* eslint-disable-next-line react/jsx-no-target-blank */}
                <a className={styles.link} href={item.app_documents_url} target="_blank">
                    {item.type.name} №{item.number} от {formatDateFromDateTime(item.placed_at)}
                </a>
                &thinsp;({formatDateFromDateTime(item.came_at)})
            </>
        );
    };

    const foldEventsText = (item: Data, group: FeedItemOrderGroup): React.ReactNode => {
        if (item.kind === 'events') {
            const { hash } = item.data;

            const eventDetails = pipe(
                group.containers,
                A.map((container) => container.event_details),
                A.flatten,
                A.findFirst((details) => details.hash === hash),
                O.toNullable
            );

            const status = eventDetails?.data.status_info ?? null;

            const percents = pipe(
                O.fromNullable(status),
                O.fold(
                    () => null,
                    (s) => {
                        if (
                            !s.rail_road.is_location ||
                            s.rail_road.distance_total === null ||
                            s.rail_road.distance_remain === null
                        ) {
                            return null;
                        }

                        return Math.ceil(
                            ((s.rail_road.distance_total - s.rail_road.distance_remain) / s.rail_road.distance_total) *
                                100
                        );
                    }
                )
            );

            const value = pipe(
                O.fromNullable(item.data.value.date),
                O.fold(
                    () => '',
                    (v) => `(${formatDate(v)})`
                )
            );

            return (
                <>
                    {status !== null && percents !== null ? (
                        <div className={styles.progressWrapper}>
                            Отслеживание дислокации ({status.rail_road.distance_remain} км):{' '}
                            {status.rail_road.current_station}
                            <LinearProgress
                                color="secondary"
                                variant="determinate"
                                className={styles.progress}
                                value={percents}
                            />
                        </div>
                    ) : (
                        `${item.data.name} ${value}`
                    )}
                </>
            );
        }

        if (item.kind === 'critical_events') {
            return (
                <React.Fragment key={`${item.kind}-${item.data.id}-${item.data.critical_event_type.id}`}>
                    {item.data.critical_event_type.id === 'attention_required' && (
                        <Error className={clsx(styles.eventIcon, styles.eventWarningIcon)} />
                    )}

                    {item.data.critical_event_type.id === 'very_important' && (
                        <Error className={clsx(styles.eventIcon, styles.eventErrorIcon)} />
                    )}

                    {/* eslint-disable-next-line react/no-danger */}
                    <span dangerouslySetInnerHTML={{ __html: item.data.message }} />
                </React.Fragment>
            );
        }

        if (item.kind === 'print_form_types') {
            return (
                <>
                    Новый документ {/* eslint-disable-next-line react/jsx-no-target-blank */}
                    <a className={styles.link} href={item.data.app_documents_url} target="_blank">
                        {item.data.name}
                    </a>
                    &thinsp;({formatDateFromDateTime(item.data.came_at)})
                </>
            );
        }

        if (item.kind === 'bills' || item.kind === 'invoices') {
            return foldBillOrInvoice(item.data);
        }

        if (item.kind === 'bill_invoices') {
            return (
                <>
                    Добавлены {/* eslint-disable-next-line react/jsx-no-target-blank */}
                    <a className={styles.link} href={item.data.app_documents_url} target="_blank">
                        {item.data.type.name} №{item.data.number} от
                        {formatDateFromDateTime(item.data.placed_at)}
                    </a>
                    &thinsp;({formatDateFromDateTime(item.data.came_at)})
                </>
            );
        }

        return null;
    };

    const foldIsImportant = (item: FeedItemGroupCriticalEvent, kind: GroupKind): boolean => {
        if (kind === 'critical_events') return item.critical_event_type.id === 'very_important';
        return false;
    };

    const foldIsCallbackRequired = (item: FeedItemGroupCriticalEvent, kind: GroupKind): boolean => {
        if (kind === 'critical_events') return item.callback_required;
        return false;
    };

    const foldNotification = (item: { notifications: NotificationItem[] }): NotificationItem[] => {
        return item.notifications ?? [];
    };

    return (
        <Card className="ExpandableNotificationsPanel">
            <div className={styles.header}>
                <div className={styles.date}>{date}</div>
            </div>

            <div className="ExpandableNotificationsPanel__eventsGroupsWrapper">
                {orders.map((order) =>
                    order.groups.map((group) => {
                        return groupKindsToMakeCards.map((kind) => {
                            const groupItems = group[kind] as Array<never>;
                            return groupItems.map((item) => (
                                <NotificationCard
                                    eventsText={foldEventsText({ kind, data: item }, group)}
                                    isCallbackRequired={foldIsCallbackRequired(item, kind)}
                                    isImportant={foldIsImportant(item, kind)}
                                    notifications={foldNotification(item)}
                                    openedAccordionHash={openedAccordionHash}
                                    setOpenedAccordionHash={setOpenedAccordionHash}
                                    order={order}
                                    group={group}
                                    updateVisibility={updateVisibility}
                                    handleModalOpen={handleModalOpen}
                                    key={`${kind}-${JSON.stringify(group)}-${order.id}-${JSON.stringify(item)}`}
                                />
                            ));
                        });
                    })
                )}

                {mutualSettlementsReport !== null && (
                    <SimpleNotificationCard
                        title={mutualSettlementsReport.title}
                        content={mutualSettlementsReport.content}
                    />
                )}
            </div>
        </Card>
    );
};

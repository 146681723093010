import { MouseEvent, useState } from 'react';

type AnyEvent = (evt: MouseEvent) => void;

type StopMousePropagationContext = {
    onMouseDown: AnyEvent;
    onMouseUp: AnyEvent;
    onClick: AnyEvent;
};

const threshold = 3;

export function useMousePropagation(): StopMousePropagationContext {
    const [lastEvent, rememberEvent] = useState<MouseEvent>();

    const onMouseDown: AnyEvent = (evt) => {
        evt.persist();
        rememberEvent(evt);
    };

    const computeAndStop: AnyEvent = (evt) => {
        evt.persist();
        if (lastEvent) {
            const deltaX = Math.abs(lastEvent.clientX - evt.clientX);
            const deltaY = Math.abs(lastEvent.clientY - evt.clientY);
            const maxDelta = Math.max(deltaX, deltaY);
            if (maxDelta >= threshold) {
                evt.stopPropagation();
            }
        } else {
            evt.stopPropagation();
        }
    };

    return { onMouseDown, onMouseUp: computeAndStop, onClick: computeAndStop };
}

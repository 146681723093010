export const camelize = (str: string): string => {
    return str.replace(/([-_][a-z])/gi, ($1: string) => {
        return $1.toUpperCase().replace('-', '').replace('_', '');
    });
};

export const convertHTMLEntity = (text: string): string => {
    const span = document.createElement('span');

    return text.replace(/&[#A-Za-z0-9]+;/gi, (entity) => {
        span.innerHTML = entity;
        return span.innerText;
    });
};

export const stripHTMLTags = (text: string): string => text.replace(/<\/?[^>]+(>|$)/g, '');

export const testEmail = (value: string | null): boolean => /\S+@\S+\.\S+/.test(String(value));

type PluralizeWords = [string, string, string];
export const pluralize = (count: number, words: PluralizeWords): string => {
    const cases = [2, 0, 1, 1, 1, 2];
    return words[count % 100 > 4 && count % 100 < 20 ? 2 : cases[Math.min(count % 10, 5)]];
};

export const pluralizeC = (words: PluralizeWords) => (count: number) => pluralize(count, words);

export const mergeLabel = (...str: (string | number)[]): string => str.join(' • ');

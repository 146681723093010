/* eslint-disable no-underscore-dangle */
import React, { FunctionComponent, useEffect, useMemo } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import { Button } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';

import { apiReportsAndTemplatesList, makeMapDispatch } from '../../store/dispatch';
import { makeMapState } from '../../store/root';

import { fold3, useStateInitializer } from '../../utils/requestState';
import { ROUTES } from '../../utils/location';
import { setPageTitle } from '../../utils/title';

import { useSearch } from '../../hooks/search';
import { useReportSubscribers } from '../../hooks/report-subscribers';

import { LoadingFixedCenter } from '../../components/LoadingFixedCenter';
import { DownloadGroup } from '../../components/DownloadGroup/DownloadGroup';
import { DownloadSubscriptionsModal } from '../../components/DownloadSubscriptionsModal/DownloadSubscriptionsModal';
import { FetchError } from '../../components/FetchError';

import './Downloads.scss';

const mapDispatch = makeMapDispatch({ getReportsAndTemplatesList: apiReportsAndTemplatesList });
const mapState = makeMapState((state) => ({ reportListState: state.reports.list, userProfile: state.user.profile }));

type DownloadsProps = ReturnType<typeof mapState> & ReturnType<typeof mapDispatch>;
const Downloads: FunctionComponent<DownloadsProps> = (props) => {
    const { reportListState, getReportsAndTemplatesList, userProfile } = props;

    const { hasSearchData } = useSearch();
    const { reportDetails, setReportDetails } = useReportSubscribers();

    const isReportsAdmin = useMemo(() => {
        return Boolean(
            userProfile &&
                userProfile._actions.length > 0 &&
                userProfile._actions.includes('manage_order_report_templates')
        );
    }, [userProfile]);

    const pageTitle = isReportsAdmin ? 'Шаблоны выгрузок' : 'Выгрузки по заказам';

    useEffect(() => setPageTitle(pageTitle), [hasSearchData, pageTitle]);

    useStateInitializer(reportListState, () => getReportsAndTemplatesList());

    const reportsListViewState = fold3(reportListState);

    const handleModalClose = (): void => {
        setReportDetails(null);
    };

    return (
        <div className="App__container">
            <h1>{pageTitle}</h1>
            <Button
                className="Downloads__addButton"
                variant="outlined"
                color="primary"
                startIcon={<AddIcon />}
                component={Link}
                to={ROUTES.DOWNLOAD_SETTINGS_CREATE}
            >
                Создать новую
            </Button>

            {reportsListViewState(
                () => (
                    <LoadingFixedCenter />
                ),
                (error) => (
                    <FetchError title={error.getCommonFirstMessage()} onRetry={() => getReportsAndTemplatesList()} />
                ),
                (data) => (
                    <>
                        {data.map((item) => (
                            <DownloadGroup key={item.order_type?.id || -1} data={item} />
                        ))}

                        <DownloadSubscriptionsModal
                            reportDetails={reportDetails}
                            isOpen={reportDetails !== null}
                            onClose={handleModalClose}
                        />
                    </>
                )
            )}
        </div>
    );
};

const EnchantedComponent = connect(mapState, mapDispatch)(Downloads);
export { EnchantedComponent as Downloads };

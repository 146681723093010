import React, { ReactNode, createContext, useState, useEffect, createRef, useCallback } from 'react';
import { connect } from 'react-redux';

import { CircularProgress, Grid } from '@material-ui/core';
import { SnackbarKey, useSnackbar } from 'notistack';

import { increment } from 'fp-ts/function';

import { SimpleUser, SimpleUserOptions } from 'sip.js/lib/platform/web';

import { ApiClient } from '../api';

import { RootState } from '../store/root';
import { SipSettings } from '../store/settings/types';

export type SIPPhone = string | number;
export type RobinPhones = SIPPhone[];
type CallState = 'created' | 'answered' | null;
type CallFunction = (orderId: string, robinPhones: RobinPhones) => void;
type HangupFunction = () => void;
export type SIPProviderContext = {
    call: CallFunction;
    hangup: HangupFunction;
    isOnline: boolean;
    callState: CallState;
    isSIPEnabled: boolean;
    timer: number;
};

export const defaultSIPRootNode = 9992;

const logLevel = 'warn'; // process.env.NODE_ENV === 'production' ? 'warn' : 'log';

const supportsRTCConnection = 'RTCPeerConnection' in window || 'RTCPeerConnection' in window;
const supportsWebSockets = 'WebSocket' in window || 'MozWebSocket' in window;
const isSIPEnabled = supportsRTCConnection && supportsWebSockets;

const buildUri = (phone: SIPPhone, ATC: string): string => `sip:${phone}@${ATC}`;

type StateProps = { sipSettings: SipSettings; displayName: string };
const mapState = (state: RootState): StateProps => ({
    sipSettings: state.settings.sipSettings,
    displayName: `[Web] UserID: ${state.user.profile?.id || 'null'}`,
});

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
export const SIPContext = createContext<SIPProviderContext>(null!);

type SIPProviderProps = { children: ReactNode } & StateProps;
const SIPProvider = (props: SIPProviderProps): JSX.Element => {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    const [timer, setTimer] = useState(0);
    const [isOnline, setOnline] = useState(false);
    const [callState, setCallState] = useState<CallState>(null);
    const [connection, setConnection] = useState<SimpleUser | null>(null);

    const audioRef = createRef<HTMLAudioElement>();
    const [snackRef, setSnackRef] = useState<SnackbarKey>();
    const [intervalRef, setIntervalRef] = useState(0);

    useEffect(() => {
        if (!isSIPEnabled) return () => {};

        const options: SimpleUserOptions = {
            aor: props.sipSettings.aor,
            media: {
                constraints: { audio: true, video: false },
                remote: { audio: audioRef.current ?? undefined },
            },
            userAgentOptions: {
                logLevel,
                displayName: props.displayName,
                authorizationUsername: props.sipSettings.username,
                authorizationPassword: props.sipSettings.password,
                sessionDescriptionHandlerFactoryOptions: {
                    iceGatheringTimeout: 500,
                    peerConnectionConfiguration: {
                        rtcpMuxPolicy: 'negotiate',
                    },
                },
            },
            delegate: {
                onServerConnect() {
                    setOnline(true);
                },
                onServerDisconnect() {
                    setOnline(false);
                },
                onCallCreated() {
                    setCallState('created');
                    setSnackRef(
                        enqueueSnackbar(
                            <Grid container alignItems="center">
                                <CircularProgress color="inherit" size={14} style={{ marginRight: 8 }} />
                                <span>Устанавливается соединение</span>
                            </Grid>,
                            {
                                persist: true,
                            }
                        )
                    );
                },
                onCallAnswered() {
                    setCallState('answered');
                    closeSnackbar(snackRef);

                    setTimer(0);
                    clearInterval(intervalRef);
                    setIntervalRef(window.setInterval(() => setTimer(increment), 1000));

                    enqueueSnackbar('Соединение установлено', {
                        variant: 'success',
                        persist: false,
                    });
                },
                onCallHangup() {
                    setTimer(0);
                    setCallState(null);
                    closeSnackbar(snackRef);
                    clearInterval(intervalRef);
                },
            },
        };

        let currentConnection: SimpleUser | null = null;
        const sentryHandler = ApiClient.getSentryHandler();
        try {
            currentConnection = new SimpleUser(props.sipSettings.websocket_server_url, options);

            currentConnection
                .connect()
                .then(() => setConnection(currentConnection))
                .catch((e) => {
                    if (sentryHandler !== null) sentryHandler(e);
                    currentConnection = null;
                });
        } catch (e) {
            if (sentryHandler !== null) sentryHandler(e);
        }

        return () => {
            if (currentConnection !== null) currentConnection.disconnect();
            clearInterval(intervalRef);
            setConnection(null);
        };
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const call: CallFunction = useCallback(
        (orderId, robinPhones) => {
            if (connection === null) return;
            connection.call(
                buildUri(
                    props.sipSettings.auto_phone_system_internal_code || defaultSIPRootNode,
                    props.sipSettings.phone_switch_host
                ),
                {
                    extraHeaders: [
                        `X-Callback-Agents: ${robinPhones.join('-')}`,
                        `X-Callback-Agents-CallerID: ${orderId}`,
                    ],
                }
            );
        },
        [connection, props.sipSettings]
    );

    const hangup: HangupFunction = useCallback(() => {
        if (connection === null) return;
        connection.hangup();
    }, [connection]);

    return (
        <SIPContext.Provider value={{ timer, call, hangup, isOnline, isSIPEnabled, callState }}>
            <audio ref={audioRef} />
            {props.children}
        </SIPContext.Provider>
    );
};

const Provider = connect(mapState)(SIPProvider);
export { Provider as SIPProvider };

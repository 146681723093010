import React, { FunctionComponent } from 'react';
import { Checkbox } from '@material-ui/core';

type CheckboxWithLabelProps = {
    label: string;
    checked: boolean;
    disabled: boolean;
    callback: Function;
};
export const CheckboxWithLabel: FunctionComponent<CheckboxWithLabelProps> = (props) => {
    const { label, checked, disabled, callback } = props;
    return (
        <>
            <Checkbox
                checked={checked}
                color="primary"
                onClick={(e) => {
                    e.stopPropagation();
                    callback();
                }}
                disabled={disabled}
            />
            {label}
        </>
    );
}

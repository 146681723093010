import React, { FunctionComponent } from 'react';
import clsx from 'clsx';

type SearchHeaderProps = {};
export const SearchHeader: FunctionComponent<SearchHeaderProps> = (props) => {
    return (
        <div className="PaymentHistoryList__grid">
            <div
                className={clsx([
                    'PaymentHistoryList__sortButton',
                    'PaymentHistoryList__column',
                    'PaymentHistoryList__column--nowrap',
                ])}
            >
                Дата документа
            </div>
            <div
                className={clsx([
                    'PaymentHistoryList__sortButton',
                    'PaymentHistoryList__column',
                    'PaymentHistoryList__column--nowrap',
                ])}
            >
                Плательщик
            </div>
            <div
                className={clsx([
                    'PaymentHistoryList__staticLabel',
                    'PaymentHistoryList__column',
                    'PaymentHistoryList__column--nowrap',
                ])}
            >
                Назначение платежа
            </div>
            <div
                className={clsx([
                    'PaymentHistoryList__sortButton',
                    'PaymentHistoryList__column',
                    'PaymentHistoryList__column--nowrap',
                ])}
            >
                Сумма
            </div>
        </div>
    );
};

import React, { ReactNode } from 'react';
import moment, { Moment } from 'moment';

import { CircularProgress } from '@material-ui/core';

import { fromNullable, getOrElse, map } from 'fp-ts/Option';
import { flow } from 'fp-ts/function';

import type { ConsolidationCellValue, ContainerEvent, OrderContainer } from '../store/orders/types';
import { KopecksIso } from './isomorph';
import { ApiError } from '../api/client/errors';
import { pluralize } from './strings';

export const formatPhone = (phone: string | null, fallback = 'Не указан'): string =>
    phone ? phone.replace(/^(?:\+7|7|8)(\d{3})(\d{3})(\d{2})(\d{2})/i, '+7 ($1) $2-$3-$4') : fallback;

export const formatCurrency = (
    value: Kopecks,
    removeTrailingZeros = false,
    digits = 2,
    separator = '&nbsp;',
): JSX.Element => {
    const val = KopecksIso.to(value);
    const formatted = val
        .toFixed(digits)
        .replace(/(\d)(?=(\d{3})+(\.|$))/g, `$1${separator}`)
        .replace(/\./g, ',');
    return (
        <span
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{
                __html:
                    removeTrailingZeros && KopecksIso.from(val) % 100 === 0
                        ? formatted.replace(/,\d{2}/, '')
                        : formatted,
            }}
        />
    );
};

export const sanitizePhone = (phone: string): string => phone.replace(/\D/g, '');

export const formatDate = (date: string, withTime = false, parseFormats: string | undefined = undefined): string =>
    moment(date, parseFormats).format(withTime ? 'DD.MM.YYYY [в ]HH:mm' : 'DD.MM.YYYY');
export const formatDateFromDateTime = (date: string, withTime = false): string => formatDate(date, withTime, 'YYYY-MM-DDTHH:mm:ss');

export const formatMomentDate = (date: Moment, withTime = false): string =>
    date.format(withTime ? 'DD.MM.YYYY [в ]HH:mm' : 'DD.MM.YYYY');

export const joinStrings = (...args: Array<string | null>): string => args.filter((chunk) => !!chunk).join(', ');

export const foldView = <T extends unknown>(
    data: T | null | undefined,
    loading: boolean,
    error: null | ApiError,
    emptyCondition?: boolean,
) => (
    emptyNode: () => ReactNode,
    loadingNode: () => ReactNode,
    errorNode: (errorData: ApiError) => ReactNode,
    viewNode: (componentData: T) => ReactNode,
): ReactNode => {
    if (loading) return loadingNode();
    if (error) return errorNode(error);
    if (emptyCondition || !data || (Array.isArray(data) && data.length === 0)) return emptyNode();
    return viewNode(data);
};

export const foldEventValue = (event: ContainerEvent): string | null => {
    if (event.value.date) return formatDate(event.value.date, false, 'YYYY.MM.DD');
    if (event.value.number && event.is_rail_location) return `Осталось ${event.value.number} км, ${event.station_name}`;
    return event.value.string;
};

export const foldEventDate = (event: ContainerEvent): string => {
    if (event.value.date) return formatDate(event.value.date, false, 'YYYY.MM.DD');
    return formatDate(event.came_at);
};

export const foldTimer = (seconds: number): string => {
    const timerMinutes = Math.floor(seconds / 60).toString();
    const timerSeconds = (seconds % 60).toString().padStart(2, '0');

    return `${timerMinutes}:${timerSeconds}`;
};

const ByteSize = ['Б', 'КБ', 'МБ', 'ГБ', 'ТБ'];
export const foldBytes = (bytes: number): string => {
    let size = 0;
    let current = bytes;
    while (current > 1024 && size !== ByteSize.length - 1) {
        current /= 1024;
        size += 1;
    }
    return `${current.toFixed(2)} ${ByteSize[size]}`;
};

export const setPageWhiteBackground = (isWhite: boolean): void => {
    const cls = 'whiteBackground';
    if (isWhite) document.documentElement.classList.add(cls);
    else document.documentElement.classList.remove(cls);
};

/** Curried version of {@link formatMomentDate} */
export const formatMomentDateC = (withTime = false) => (date: Moment): string => formatMomentDate(date, withTime);

/** Curried version of {@link formatCurrency} */
export const formatCurrencyC = (removeTrailingZeros = false, digits = 2, separator = '&nbsp;') => (
    value: Kopecks,
): JSX.Element => formatCurrency(value, removeTrailingZeros, digits, separator);

export const scrollToTop = (): void => {
    setTimeout(
        () => document.getElementById('main-app-container')?.scrollIntoView({ behavior: 'smooth', block: 'start' }),
        50,
    );
};

export const foldPageCount = (paginator: Omit<Paginator, 'page'>): number =>
    Math.max(1, Math.ceil(paginator.total / Math.max(1, paginator.page_size)));

export const pluralCargoSeats = (seats: number | null): string =>
    seats ? `${seats} ${pluralize(seats, ['место', 'места', 'мест'])}` : '';

const makeCargoContainerTitle = (container: OrderContainer): string => {
    const chunks: Array<string | number> = ['Сборный груз'];
    if (container.cargo_quantity_seats) chunks.push(pluralCargoSeats(container.cargo_quantity_seats));
    if (container.cargo_weight) chunks.push(`${container.cargo_weight} кг`);
    return `${chunks.join(' • ')} — Заказ №${container.order.number}`;
};

const makeRegularContainerTitle = (container: OrderContainer): string => {
    const chunks = [];
    if (container.number) chunks.push(`Контейнер №${container.number}`);
    if (container.type.size) chunks.push(`(${container.type.size}ft)`);
    return `${chunks.join(' ')} — Заказ №${container.order.number}`;
};

export const makeContainerTitle = flow(
    (value: OrderContainer | null) => fromNullable(value),
    map((container) =>
        container.order.type.is_groupage_cargo
            ? makeCargoContainerTitle(container)
            : makeRegularContainerTitle(container),
    ),
    getOrElse(() => ''),
);

export const countWithLoading = (value: string | number, condition: boolean): Required<ReactNode> =>
    condition ? <CircularProgress color='secondary' style={{ marginLeft: 6 }} size={12} /> : value;

export const formattedConsolidationTableValue = (value: ConsolidationCellValue): string | null | number => {
    if (value.date !== null) return formatDate(value.date, false, 'YYYY.MM.DD');
    if (value.number !== null) return value.number;
    if (value.string !== null) return value.string;

    return null;
};

export const generatePassword = (
    length = 16,
    wishlist = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz~!@-#$',
): string =>
    Array.from(crypto.getRandomValues(new Uint32Array(length)))
        .map((x) => wishlist[x % wishlist.length])
        .join('');

/* eslint-disable no-underscore-dangle */
import React, { FunctionComponent, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import clsx from 'clsx';

import { pipe } from 'fp-ts/lib/function';

import { Button, Card, ClickAwayListener, createStyles, IconButton, Tooltip, WithStyles } from '@material-ui/core';
import {
    MoreVert as MoreVertIcon,
    PeopleRounded as PeopleRoundedIcon,
    NotificationsRounded as NotificationsRoundedIcon,
    NotificationsOffRounded as NotificationsOffRoundedIcon,
    FileCopyOutlined as FileCopyOutlinedIcon,
    DeleteOutlined as DeleteOutlinedIcon,
    GetApp as GetAppIcon,
    ListAlt as ListIcon,
} from '@material-ui/icons';
import { StyleRules, withStyles } from '@material-ui/styles';

import { ReportListItem, TemplatesListItem, ActionButtonDetails } from '../../types/order-reports';

import { isUserGranted } from '../../utils/user';
import { buildRouteUrl } from '../../utils/location';
import { pluralizeC } from '../../utils/strings';

import { useAlerts } from '../../hooks/noty';
import { useDocumentsLoader } from '../../hooks/documentsLoader';
import { useReportSubscribers } from '../../hooks/report-subscribers';

import {
    apiReportsClone,
    apiReportsDelete,
    apiReportsTemplateDelete,
    apiReportsAndTemplatesList,
    apiTemplateCopy,
    makeMapDispatch,
    apiReportsSubscribe,
    apiReportsUnsubscribe,
} from '../../store/dispatch';
import { makeMapState } from '../../store/root';

import { LoadingFixedCenter } from '../LoadingFixedCenter';
import { LoadingButton } from '../LoadingButton';

import './DownloadCard.scss';

const styles = (): StyleRules =>
    createStyles({
        actionTooltip: {
            background: 'white',
            boxShadow: '0 2px 4px rgba(0, 0, 0, 0.14), 0 3px 4px rgba(0, 0, 0, 0.12), 0 1px 5px rgba(0, 0, 0, 0.2)',
            borderRadius: 10,
            margin: 0,
            maxWidth: 'none',
            overflow: 'hidden',
            padding: 0,
        },
    });

const pluralizeDays = pluralizeC(['день', 'дня', 'дней']);
const pluralizeEvery = pluralizeC(['каждый', 'каждые', 'каждые']);

const mapDispatch = makeMapDispatch({
    deleteReports: apiReportsDelete,
    deleteReportsTemplate: apiReportsTemplateDelete,
    getReportsList: apiReportsAndTemplatesList,
    cloneReports: apiReportsClone,
    subscribeToReport: apiReportsSubscribe,
    unsubscribeFromReport: apiReportsUnsubscribe,
    copyTemplate: apiTemplateCopy,
});

const mapState = makeMapState((state) => ({
    userProfile: state.user.profile,
    documentsStorage: state.documents.items,
}));

type DownloadCardComponentReportProps = { data: ReportListItem; kind: 'report' };
type DownloadCardComponentTemplateProps = { data: TemplatesListItem; kind: 'template' };
type DownloadCardComponentProps = DownloadCardComponentReportProps | DownloadCardComponentTemplateProps;

type DownloadCardProps = DownloadCardComponentProps &
    ReturnType<typeof mapDispatch> &
    ReturnType<typeof mapState> &
    WithStyles<typeof styles>;
const DownloadCard: FunctionComponent<DownloadCardProps> = (props) => {
    const {
        deleteReports,
        deleteReportsTemplate,
        data,
        getReportsList,
        cloneReports,
        subscribeToReport,
        unsubscribeFromReport,
        copyTemplate,
        userProfile,
        documentsStorage,
        classes,
        kind,
    } = props;

    const isTemplate = kind === 'template';

    const userIsAdmin = isUserGranted(userProfile, 'manage_order_report_recipients');

    const { handleApiError, showNotification } = useAlerts();
    const { downloadReportsDocument, downloadTemplateReportDocument } = useDocumentsLoader();
    const { setReportDetails } = useReportSubscribers();

    const [isLoading, setLoading] = useState(false);
    const [isActionsOpen, setActionsOpen] = useState(false);

    const isSubscribed = !isTemplate ? (data as ReportListItem).is_subscribed : undefined;

    const isReportsAdmin = useMemo(() => {
        return (
            userProfile &&
            userProfile._actions.length > 0 &&
            userProfile._actions.includes('manage_order_report_templates')
        );
    }, [userProfile]);

    const mailingText = useMemo((): string => {
        let text = '';
        const reportData = data as ReportListItem;

        if (!isTemplate && reportData.mailing_settings) {
            if (reportData.mailing_settings.frequency === 'interval_days') {
                const period = reportData.mailing_settings.interval_days;
                const daysCount = period === 1 ? '' : ` ${period}`;
                text = `${pluralizeEvery(period)}${daysCount} ${pluralizeDays(period)}`;
            } else {
                text = reportData.mailing_settings.weekdays.map((weekday) => weekday.name).join(', ');
            }
        }

        return text ? `Включена рассылка: ${text}` : '';
    }, [data, isTemplate]);

    const handleSubscribeButtonClick = (): void => {
        setLoading(true);

        subscribeToReport({ report_id: data.id })
            .then(() => getReportsList())
            .then(() =>
                showNotification(`Вы успешно подписались на рассылку`, {
                    variant: 'success',
                })
            )
            .catch(handleApiError)
            .finally(() => setLoading(false));
    };

    const handleUnsubscribeButtonClick = (): void => {
        setLoading(true);

        unsubscribeFromReport({ report_id: data.id })
            .then(() => getReportsList())
            .then(() =>
                showNotification(`Вы успешно отписались от рассылки`, {
                    variant: 'success',
                })
            )
            .catch(handleApiError)
            .finally(() => setLoading(false));
    };

    const handleDelete = (): void => {
        setLoading(true);

        const method = (): Promise<void> => {
            if (isReportsAdmin) return deleteReportsTemplate({ template_id: data.id });
            return deleteReports({ id: data.id });
        };

        method()
            .then(() => getReportsList())
            .then(() =>
                showNotification(`Выгрузка успешно удалена`, {
                    variant: 'success',
                })
            )
            .catch(handleApiError)
            .finally(() => setLoading(false));
    };

    const handleClone = async (): Promise<void> => {
        setLoading(true);

        try {
            if (isTemplate) {
                await copyTemplate({ template_id: data.id });
            } else {
                await cloneReports({ id: data.id });
            }
            await getReportsList();

            showNotification(`Копия выгрузки успешно создана`, {
                variant: 'success',
            });
        } catch (error) {
            handleApiError(error);
        } finally {
            setLoading(false);
        }
    };

    const handleChangeSubscribers = async (): Promise<void> => {
        const reportData = data as ReportListItem;

        setReportDetails(reportData);
    };

    const isReportsLoading = (key: string): boolean => {
        return Boolean(documentsStorage.find((document) => document.key === key));
    };

    let actionButtons: ActionButtonDetails[] = [];

    if (isReportsAdmin) {
        actionButtons = [
            {
                onClick: handleDelete,
                icon: <DeleteOutlinedIcon />,
                text: 'Удалить',
                id: 2,
                color: 'red',
            },
        ];
    } else if (isTemplate) {
        actionButtons = [
            {
                onClick: handleClone,
                icon: <FileCopyOutlinedIcon />,
                text: 'Копировать',
                id: 1,
            },
        ];
    } else {
        actionButtons = [
            {
                onClick: handleClone,
                icon: <FileCopyOutlinedIcon />,
                text: 'Копировать',
                id: 1,
            },
            {
                onClick: handleDelete,
                icon: <DeleteOutlinedIcon />,
                text: 'Удалить',
                id: 2,
                color: 'red',
            },
        ];

        if (isSubscribed) {
            actionButtons.unshift({
                onClick: handleUnsubscribeButtonClick,
                icon: <NotificationsOffRoundedIcon />,
                text: 'Отписаться от рассылки',
                id: actionButtons.length + 1,
            });
        } else {
            actionButtons.unshift({
                onClick: handleSubscribeButtonClick,
                icon: <NotificationsRoundedIcon />,
                text: 'Подписаться на рассылку',
                id: actionButtons.length + 1,
            });
        }
    }

    if (userIsAdmin && !isTemplate) {
        actionButtons.unshift({
            onClick: handleChangeSubscribers,
            icon: <PeopleRoundedIcon />,
            text: 'Изменить получателей',
            id: actionButtons.length + 1,
        });
    }

    const downloadReport = (): void => {
        if (isTemplate) {
            const payload = { template_id: data.id };

            downloadTemplateReportDocument(payload, {
                payload,
                key: `template_report_document_${data.id}`,
                type: 'template_report_document',
            });
        } else {
            const payload = { id: data.id };

            downloadReportsDocument(payload, {
                payload,
                key: `reports_document_${data.id}`,
                type: 'reports_document',
            });
        }
    };

    const cardLink =
        isTemplate && !isReportsAdmin
            ? buildRouteUrl('DOWNLOAD_SETTINGS_CREATE_FROM_TEMPLATE', { templateId: data.id })
            : buildRouteUrl('DOWNLOAD_SETTINGS_BY_ID', { settingsId: data.id });

    return (
        <Card className="DownloadCard">
            <Link to={cardLink} className="DownloadCard__text">
                <span className="DownloadCard__name">{data.name}</span>
                {kind === 'template' && (
                    <div className="DownloadCard__period">
                        <ListIcon style={{ fontSize: 18 }} />
                        <span className="DownloadCard__periodLabel">Шаблон выгрузки</span>
                    </div>
                )}
                {mailingText && <div className="DownloadCard__period">{mailingText}</div>}
            </Link>

            <div className="DownloadCard__buttons">
                {!isReportsAdmin && (
                    <LoadingButton
                        containerClass="DownloadCard__textButton"
                        startIcon={<GetAppIcon />}
                        loading={
                            isTemplate
                                ? isReportsLoading(`template_report_document_${data.id}`)
                                : isReportsLoading(`reports_document_${data.id}`)
                        }
                        onClick={downloadReport}
                    >
                        Скачать .xlsx
                    </LoadingButton>
                )}

                <div>
                    <ClickAwayListener onClickAway={() => setActionsOpen(false)}>
                        <div>
                            <Tooltip
                                classes={{ tooltip: classes.actionTooltip }}
                                open={isActionsOpen}
                                placement="bottom-end"
                                interactive
                                disableFocusListener
                                disableHoverListener
                                disableTouchListener
                                title={
                                    <div className="DownloadCard__tooltipButtons">
                                        {actionButtons.map((button) => (
                                            <Button
                                                className={clsx(
                                                    'DownloadCard__textButton DownloadCard__textButton--tooltip',
                                                    button.color &&
                                                        button.color === 'red' &&
                                                        'DownloadCard__textButton--red'
                                                )}
                                                onClick={() => {
                                                    setActionsOpen(false);
                                                    button.onClick();
                                                }}
                                                startIcon={button.icon}
                                                key={button.id}
                                            >
                                                {button.text}
                                            </Button>
                                        ))}
                                    </div>
                                }
                                PopperProps={{
                                    disablePortal: true,
                                    modifiers: {
                                        offset: {
                                            enabled: true,
                                            offset: '0, 4px',
                                        },
                                    },
                                }}
                            >
                                <IconButton
                                    className="DownloadCard__tooltipIcon"
                                    color="primary"
                                    onClick={() => setActionsOpen((val) => !val)}
                                >
                                    <MoreVertIcon />
                                </IconButton>
                            </Tooltip>
                        </div>
                    </ClickAwayListener>
                </div>
            </div>

            {isLoading && <LoadingFixedCenter />}
        </Card>
    );
};

const EnchantedComponent = pipe(DownloadCard, withStyles(styles), connect(mapState, mapDispatch));
export { EnchantedComponent as DownloadCard };

import React, { FunctionComponent } from 'react';
import { SortButton } from '../../SortButton/SortButton';
import { BillsSortBy, BillsSortingData } from '../../../store/bills/types';

type SortButtonConfig = { label: string; sortBy: BillsSortBy };
type SortHeaderProps = {
    sorts: BillsSortingData;
    onChange: (by: BillsSortBy) => void;
};

const sortButtons: SortButtonConfig[] = [
    { label: 'Дата документа', sortBy: 'date' },
    { label: 'Плательщик', sortBy: 'payer' },
    { label: 'Сумма', sortBy: 'amount' },
    { label: 'Оплачено', sortBy: 'amount_payments' },
    { label: 'Осталось', sortBy: 'amount_debt' },
    { label: 'Сумма СФ', sortBy: 'amount_invoices' },
];

export const SortHeader: FunctionComponent<SortHeaderProps> = (props) => {
    const { sorts, onChange } = props;

    return (
        <div className="BillsListHeaders BillsList__headers App__billsRow">
            {sortButtons.map((button, index) => (
                <div className="BillsListHeaders__button" key={`sort-${index}`}>
                    <SortButton
                        label={button.label}
                        order={sorts.direction}
                        active={sorts.by === button.sortBy}
                        clickHandler={() => onChange(button.sortBy)}
                    />
                </div>
            ))}
        </div>
    );
};

import React, { FC } from 'react';
import EmailIcon from '@material-ui/icons/Email';
import PhoneIphoneIcon from '@material-ui/icons/PhoneIphone';
import TelegramIcon from '@material-ui/icons/Telegram';
import ViewDayIcon from '@material-ui/icons/ViewDay';

import { NotificationChannels } from '../../../types/notification-subscriptions';

import styles from './ContainerNotificationsSettingsModal.module.scss';

type NotificationChannelIndicator = {
    channelType: NotificationChannels;
};

const channelTypeIconMap: Record<NotificationChannels, JSX.Element> = {
    email: <EmailIcon />,
    sms: <PhoneIphoneIcon />,
    telegram: <TelegramIcon />,
    feed: <ViewDayIcon />,
};

export const NotificationChannelIndicator: FC<NotificationChannelIndicator> = (props) => {
    const { channelType } = props;
    return <div className={styles.notificationChannelIcon}>{channelTypeIconMap[channelType]}</div>;
};

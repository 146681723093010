import React, { FunctionComponent, useState } from 'react';
import { connect } from 'react-redux';

import { createStyles, Theme, withStyles, WithStyles } from '@material-ui/core/styles';

import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';

import { Button, CircularProgress, FormControl, TextField } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { red, green } from '@material-ui/core/colors';
import { makeStyles } from '@material-ui/styles';

import { pipe } from 'fp-ts/function';
import { map } from 'fp-ts/Array';

import { User } from '../../types/user';

import { apiCallToManager, makeMapDispatch } from '../../store/dispatch';
import { ManagerData } from '../../store/orders/types';
import { RootState } from '../../store/root';
import { foldTimer } from '../../utils/view';

import { useAlerts } from '../../hooks/noty';
import { useSIP } from '../../hooks/sip';

import { MaskInput } from '../input/MaskInput';
import { SIPButton } from './SIPButton';

const styles = (theme: Theme): ReturnType<typeof createStyles> =>
    createStyles({
        closeButton: {
            top: theme.spacing(1),
            right: theme.spacing(1),
            color: theme.palette.grey[500],
            position: 'absolute',
        },
    });

export interface DialogTitleProps extends WithStyles<typeof styles> {
    id: string;
    children: React.ReactNode;
    onClose: () => void;
}

const DialogTitle = withStyles(styles)((props: DialogTitleProps) => {
    const { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

const AccentLabel: FunctionComponent<{ color: string }> = ({ color, children }) => (
    <span style={{ color }}>{children}</span>
);

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        actionButton: {
            marginTop: theme.spacing(2),
            marginBottom: theme.spacing(2),
        },
        inputBlock: {
            marginTop: theme.spacing(3),
            marginBottom: theme.spacing(2),
            display: 'block',
        },
        middleHeading: {
            marginTop: theme.spacing(2),
            marginBottom: theme.spacing(2),
        },
        timer: {
            display: 'inline-block',
            marginLeft: theme.spacing(2),
            width: 60,
            textAlign: 'right',
        },
        formSubmit: {
            position: 'relative',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            width: 'fit-content',
        },
        loading: {
            position: 'absolute',
        },
    })
);

const mapExternalPhonesForSIP = map((phone: string): string => phone.replace(/^7/, '8') + '#');

type StateProps = {
    profile: User | null;
};

const mapState = (state: RootState): StateProps => ({
    profile: state.user.profile,
});

const mapDispatch = makeMapDispatch({
    callToManager: apiCallToManager,
});

export type ManagersPhones = { internal: number[]; external: string[] };

type ComponentProps = {
    open: boolean;
    handleClose: () => void;
    person: ManagerData; // unused
    phones: ManagersPhones;
    orderNumber: string;
};

type CallModalProps = ComponentProps & ReturnType<typeof mapState> & ReturnType<typeof mapDispatch>;
const CallModal: FunctionComponent<CallModalProps> = (props) => {
    const { open, handleClose, callToManager, profile, phones, orderNumber } = props;

    const classes = useStyles();
    const { callState, isSIPEnabled, timer } = useSIP();
    const { handleApiError, showNotification } = useAlerts();

    const [phone, setPhone] = useState(profile?.phone || '');
    const [connecting, setConnecting] = useState(false);

    const handleConnect = (): void => {
        if (connecting) return;
        setConnecting(true);

        callToManager({
            internal_phone_codes: phones.internal,
            external_phones: phones.external,
            client_phone: phone,
            client_name: orderNumber,
        })
            .then(() => {
                showNotification('Запрос на АТС отправлен. Вам перезвонят в течение минуты', { variant: 'success' });
            })
            .catch(handleApiError)
            .finally(() => setConnecting(false));
    };

    if (phones.external.length === 0 && phones.internal.length === 0) return null;

    return (
        <Dialog aria-labelledby="customized-dialog-title" onClose={handleClose} open={open}>
            <DialogTitle id="customized-dialog-title" onClose={handleClose}>
                Онлайн-звонок из браузера
            </DialogTitle>
            <DialogContent>
                {isSIPEnabled ? (
                    <>
                        <Typography gutterBottom>
                            Ваш браузер
                            <AccentLabel color={green[500]}>&nbsp;поддерживает&nbsp;</AccentLabel>
                            онлайн-звонок. Для разговора нужен микрофон. Если есть проблемы со&nbsp;связью&nbsp;&mdash;
                            попросите перезвонить вам в&nbsp;форме ниже.
                        </Typography>
                        <SIPButton
                            orderId={orderNumber}
                            robinPhones={[...phones.internal, ...mapExternalPhonesForSIP(phones.external)]}
                        />
                        {callState === 'answered' && <div className={classes.timer}>{foldTimer(timer)}</div>}
                    </>
                ) : (
                    <Typography gutterBottom>
                        Ваш браузер
                        <AccentLabel color={red[500]}>&nbsp;не поддерживает&nbsp;</AccentLabel>
                        онлайн-звонок через интернет. Воспользуйтесь соединением через АТС или установите современный
                        браузер: Google Chrome, Mozilla Firefox, Apple Safari.
                    </Typography>
                )}
                <Typography variant="h6" className={classes.middleHeading}>
                    Звонок через АТС
                </Typography>
                <Typography gutterBottom>
                    Наша умная система за&nbsp;вас дозвонится до&nbsp;нужного менеджера или его руководителя,
                    а&nbsp;после осуществит дозвон с&nbsp;его телефона на&nbsp;указанный вами номер в&nbsp;ближайшее
                    время.
                </Typography>
                <FormControl variant="outlined" className={classes.inputBlock}>
                    <MaskInput mask="+7 (999) 999-9999" value={phone} onChange={setPhone}>
                        <TextField id="profile-phone" name="phone" variant="outlined" label="Телефон" size="small" />
                    </MaskInput>
                </FormControl>
                <div className={classes.formSubmit}>
                    <Button
                        color="primary"
                        variant="contained"
                        className={classes.actionButton}
                        onClick={handleConnect}
                        disabled={connecting}
                    >
                        Соединить
                    </Button>
                    {connecting && <CircularProgress className={classes.loading} variant="indeterminate" size={16} />}
                </div>
            </DialogContent>
        </Dialog>
    );
};

const component = pipe(CallModal, connect(mapState, mapDispatch));
export { component as CallModal };

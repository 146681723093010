import React, { FunctionComponent } from 'react';
import clsx from 'clsx';

import { Button } from '@material-ui/core';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';

import { LoadingButton } from '../LoadingButton';

import styles from './NotificationsActionsBar.module.scss';

type NotificationsActionsBarProps = {
    onSubmit?(): void;
    onDelete?(): void;
    isLoadingSubmit?: boolean;
};
export const NotificationsActionsBar: FunctionComponent<NotificationsActionsBarProps> = (props) => {
    const { onSubmit, onDelete, isLoadingSubmit = false } = props;

    return (
        <>
            <div className={styles.placeholder} />

            <div className={styles.root}>
                <div className={clsx('App__container', 'App__container--small', styles.container)}>
                    <div className={styles.inner}>
                        <LoadingButton
                            className={styles.button}
                            onClick={onSubmit}
                            variant="contained"
                            color="primary"
                            loading={isLoadingSubmit}
                        >
                            Сохранить
                        </LoadingButton>

                        {onDelete !== undefined && (
                            <Button className={styles.button} onClick={onDelete} startIcon={<DeleteOutlineIcon />}>
                                Удалить
                            </Button>
                        )}
                    </div>
                </div>
            </div>
        </>
    );
};

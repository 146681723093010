import React, { FunctionComponent, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { connect } from 'react-redux';
import clsx from 'clsx';

import { pipe } from 'fp-ts/function';

import {
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Fab,
    Fade,
    Grid,
    TextField,
    Theme,
    Tooltip,
    useTheme,
} from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/styles';
import WarningIcon from '@material-ui/icons/Warning';

import { apiFeedbackCreate, makeMapDispatch } from '../store/dispatch';
import { RootState } from '../store/root';

import { clearObject, ImageData } from '../utils/data';
import { buildNotification } from '../utils/noty';

import { useAlerts } from '../hooks/noty';

import { ImageInput } from './input/ImageInput';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        fab: {
            position: 'fixed',
            bottom: theme.spacing(8),
            right: theme.spacing(2),
            zIndex: 1299,
            '& > *': {
                marginTop: -2,
            },
        },
        loadingButton: {
            position: 'relative',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        },
        loading: {
            position: 'absolute',
        },
        form: {
            display: 'flex',
            flexDirection: 'column',
            maxHeight: 'calc(100% - 64px)',
            overflowY: 'auto',
        },
    })
);

const reportErrorText = {
    title: 'Сообщить об ошибке',
    message:
        'Если вы&nbsp;заметили ошибку в&nbsp;данных или в&nbsp;работе приложения, пожалуйста, сообщите нам о&nbsp;ней&nbsp;&mdash; мы&nbsp;обязательно ее&nbsp;решим.',
    requiredImage: 'Необходимо приложить изображение, либо сделать снимок экрана',
    successNotification: buildNotification(
        'Сообщение об ошибке отправлено',
        'Мы постараемся разобраться в сложившейся ситуации как можно скорее'
    ),
};

type StateProps = {
    shown: boolean;
    defaults: {
        phone: string | null;
        email: string | null;
    };
};

const mapState = (state: RootState): StateProps => ({
    shown: state.user.isAuthenticated,
    defaults: {
        phone: state.user.profile?.phone || null,
        email: state.user.profile?.email || null,
    },
});

const mapDispatch = makeMapDispatch({ sendReport: apiFeedbackCreate });

type FormData = {
    name: string | null;
    phone: string | null;
    email: string | null;
    message: string | null;
    image: ImageData | null;
};

export const requestRefinement = (data: FormData): data is Protocol.FeedbackCreateRequest => data.image !== null;

type ErrorReportProps = ReturnType<typeof mapState> & ReturnType<typeof mapDispatch> & { loading: boolean };
const ErrorReport: FunctionComponent<ErrorReportProps> = (props) => {
    const { shown, defaults, sendReport, loading } = props;

    const { register, handleSubmit, reset, setValue, setError, errors } = useForm<FormData>({
        defaultValues: {
            ...defaults,
            image: null,
        },
    });

    const theme = useTheme();
    const styles = useStyles();
    const { handleApiError, showNotification } = useAlerts(setError);

    const transitionDuration = {
        enter: theme.transitions.duration.enteringScreen,
        exit: theme.transitions.duration.leavingScreen,
    };

    const [open, setOpen] = useState(false);
    const [pending, setPending] = useState(false);

    const handleOpen = (): void => setOpen(true);
    const handleClose = (): void => {
        reset();
        setOpen(false);
    };

    const handleSuccess = (): void => {
        handleClose();
        showNotification(reportErrorText.successNotification, {
            variant: 'success',
        });
    };

    const onSubmit = (data: FormData): void => {
        if (pending) return;

        const payloadChunk = clearObject(data);
        const payload = { ...payloadChunk, url: window.location.href };

        if (!requestRefinement(payload)) {
            showNotification(reportErrorText.requiredImage, {
                variant: 'warning',
            });

            return;
        }

        setPending(true);

        sendReport(payload)
            .then(handleSuccess)
            .catch(handleApiError)
            .finally(() => setPending(false));
    };

    useEffect(() => {
        register('name');
        register('phone');
        register('image');
    }, [open]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <>
            <Fade in={shown && !loading} timeout={transitionDuration} unmountOnExit>
                <Tooltip title={reportErrorText.title} placement="left">
                    <Fab
                        color="primary"
                        className={clsx([styles.fab, 'ErrorReportButton'])}
                        aria-label={reportErrorText.title}
                        onClick={handleOpen}
                        data-error-report-fab
                    >
                        <WarningIcon />
                    </Fab>
                </Tooltip>
            </Fade>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="feedback-error-title"
                maxWidth="md"
                disableBackdropClick={pending}
            >
                <form onSubmit={handleSubmit(onSubmit)} className={styles.form}>
                    <DialogTitle id="feedback-error-title">
                        <span
                            dangerouslySetInnerHTML={{
                                __html: reportErrorText.title,
                            }}
                        />
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            <span
                                dangerouslySetInnerHTML={{
                                    __html: reportErrorText.message,
                                }}
                            />
                        </DialogContentText>
                        <Grid container spacing={1}>
                            <Grid item xs={12} sm={4}>
                                <TextField
                                    inputRef={register}
                                    margin="dense"
                                    id="email"
                                    name="email"
                                    label="Email"
                                    type="email"
                                    variant="outlined"
                                    fullWidth
                                    error={!!errors.email}
                                    helperText={errors.email?.message || 'Необязательно'}
                                    FormHelperTextProps={{
                                        focused: true,
                                        filled: true,
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    inputRef={register}
                                    margin="dense"
                                    id="message"
                                    name="message"
                                    label="Сообщение"
                                    rows={3}
                                    variant="outlined"
                                    multiline
                                    fullWidth
                                    error={!!errors.message}
                                    helperText={errors.message?.message || 'Необязательно'}
                                />
                            </Grid>
                            <Grid item container xs={12} justify="center">
                                <ImageInput shown={shown} onChange={(v) => setValue('image', v)} />
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Button disabled={pending} onClick={handleClose} color="primary" type="button">
                            Отменить
                        </Button>
                        <div className={styles.loadingButton}>
                            <Button disabled={pending} variant="contained" color="primary" type="submit">
                                Отправить
                            </Button>
                            {pending && <CircularProgress className={styles.loading} size={16} />}
                        </div>
                    </DialogActions>
                </form>
            </Dialog>
        </>
    );
};

const EnchantedComponent = pipe(ErrorReport, connect(mapState, mapDispatch));
export { EnchantedComponent as ErrorReport };

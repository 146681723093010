import React from 'react';
import ReactDOM from 'react-dom';

import './index.css';

import { ApiClient } from './api';

import { Root } from './containers/Root';

import './styles/global.scss';

if (process.env.REACT_APP_SENTRY_DSN) {
    import('@sentry/react')
        .then((Sentry) => {
            ApiClient.setSentryHandler(Sentry.captureException);
            Sentry.init({
                dsn: process.env.REACT_APP_SENTRY_DSN,
                environment: process.env.REACT_APP_ENV || 'local',
                normalizeDepth: 10,
            });
        })
        .catch(() => console.warn('[Bootstrap] Cannot initialize Sentry instance'));
}

ReactDOM.render(<Root />, document.getElementById('root'));

import React, { FunctionComponent, useState } from 'react';
import {
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
} from '@material-ui/core';

type TextLiveEditConfirmProps = {
    isOpen: boolean;
    setClose: () => void;
    setPreviousValue: () => void;
    handleSave: () => Promise<void>;
};

export const TextLiveEditConfirm: FunctionComponent<TextLiveEditConfirmProps> = (props) => {
    const { isOpen, setClose, setPreviousValue, handleSave } = props;

    const [isLoading, setLoading] = useState(false);

    return (
        <Dialog
            open={isOpen}
            onClose={setClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">Предыдущая заметка будет изменена. Продолжить?</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    Вы можете изменить заметку позже ещё раз
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button
                    color="primary"
                    disabled={isLoading}
                    onClick={() => {
                        setClose();
                        setPreviousValue();
                    }}
                >
                    Нет
                </Button>
                <Button
                    disabled={isLoading}
                    color="primary"
                    autoFocus
                    onClick={() => {
                        setLoading(true);
                        handleSave()
                            .catch((e) => {
                                console.log(e);
                            })
                            .finally(() => {
                                setLoading(false);
                                setClose();
                            });
                    }}
                >
                    <span>Да</span>
                    {isLoading && <CircularProgress style={{ marginLeft: 4 }} size={12} />}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

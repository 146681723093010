import React, { FunctionComponent } from 'react';
import { Button, createStyles, makeStyles } from '@material-ui/core';
import { PageInfo } from '../store/orders/types';
import { usePages } from '../hooks/pages';

const useStyles = makeStyles(() =>
    createStyles({
        root: {
            width: 'fit-content',
            minWidth: 'initial',
            padding: '0 6px',
        },
    })
);

type PageLinkProps = { label: string; info: PageInfo };
export const PageLink: FunctionComponent<PageLinkProps> = (props) => {
    const { fetchPage } = usePages();
    const styles = useStyles();

    if (props.info.is_external) {
        return (
            <Button
                component="a"
                variant="outlined"
                className={styles.root}
                color="primary"
                target="_blank"
                href={props.info.url}
            >
                {props.label}
            </Button>
        );
    }

    return (
        <Button
            variant="outlined"
            className={styles.root}
            color="primary"
            onClick={() => fetchPage(props.info.url)}
        >
            {props.label}
        </Button>
    );
};

import React, { FunctionComponent, useMemo, useState } from 'react';
import clsx from 'clsx';
import { Link, useHistory } from 'react-router-dom';

import { Button, Card, ClickAwayListener, IconButton, Tooltip } from '@material-ui/core';
import { Edit as EditIcon, DeleteOutlined as DeleteOutlinedIcon, MoreVert as MoreVertIcon } from '@material-ui/icons';

import { useAlerts } from '../../hooks/noty';

import { NotificationAvailableType } from '../../store/settings/types';
import { NotificationsEntityItems } from '../../utils/const';

import { ReactComponent as SmsIcon } from '../../assets/sms.svg';
import { ReactComponent as EmailIcon } from '../../assets/email.svg';
import { ReactComponent as TelegramIcon } from '../../assets/telegram.svg';
import { ReactComponent as FeedIcon } from '../../assets/feed.svg';

import './ProfileNotificationCard.scss';

type ActionButton = {
    onClick(): void;
    icon: JSX.Element;
    text: string;
    color?: string;
};

const indicators: Record<NotificationAvailableType, React.ReactNode> = {
    sms: <SmsIcon />,
    email: <EmailIcon />,
    telegram: <TelegramIcon />,
    feed: <FeedIcon />,
};

type ProfileNotificationCardProps = {
    id?: number;
    name: string;
    link: string;
    isDefault?: boolean;
    selectedChannels: NotificationAvailableType[];
    onDelete?(id: number): Promise<void>;
    isEmployee?: boolean;
};
export const ProfileNotificationCard: FunctionComponent<ProfileNotificationCardProps> = (props) => {
    const { id, name, link, isDefault, selectedChannels, onDelete, isEmployee } = props;

    const [isActionsOpen, setActionsOpen] = useState(false);

    const { push } = useHistory();
    const { handleApiError } = useAlerts();

    const orderedChannels = useMemo(() => {
        const result: NotificationAvailableType[] = [];

        NotificationsEntityItems.forEach((item) => {
            if (selectedChannels.find((channel) => channel === item.key)) {
                result.push(item.key);
            }
        });

        return result;
    }, [selectedChannels]);

    const actionButtons: ActionButton[] = [
        {
            onClick() {
                push(link);
            },
            icon: <EditIcon />,
            text: 'Редактировать',
        },
    ];

    if (onDelete !== undefined && !isDefault) {
        actionButtons.push({
            onClick() {
                if (onDelete !== undefined && id !== undefined) onDelete(id).catch(handleApiError);
            },
            icon: <DeleteOutlinedIcon />,
            text: 'Удалить',
            color: 'red',
        });
    }

    return (
        <Card className={clsx('ProfileNotificationCard', isEmployee && 'ProfileNotificationCard--employee')}>
            <Link to={link} className="ProfileNotificationCard__link">
                <span className="ProfileNotificationCard__name">{name}</span>
            </Link>

            <div className="ProfileNotificationCard__controls">
                {orderedChannels.length > 0 && (
                    <div className="ProfileNotificationCard__indicators">
                        {orderedChannels.map((indicator) => (
                            <div className="ProfileNotificationCard__indicator" key={indicator}>
                                {indicators[indicator]}
                            </div>
                        ))}
                    </div>
                )}

                <ClickAwayListener onClickAway={() => setActionsOpen(false)}>
                    <div>
                        <Tooltip
                            className="ProfileNotificationCard__actionTooltip"
                            open={isActionsOpen}
                            placement="bottom-end"
                            interactive
                            disableFocusListener
                            disableHoverListener
                            disableTouchListener
                            title={
                                <div className="ProfileNotificationCard__tooltipButtons">
                                    {actionButtons.map((button, index) => (
                                        <Button
                                            className={clsx(
                                                'ProfileNotificationCard__textButton ProfileNotificationCard__textButton--tooltip',
                                                button.color &&
                                                    button.color === 'red' &&
                                                    'ProfileNotificationCard__textButton--red'
                                            )}
                                            onClick={() => {
                                                setActionsOpen(false);
                                                button.onClick();
                                            }}
                                            startIcon={button.icon}
                                            key={index}
                                        >
                                            {button.text}
                                        </Button>
                                    ))}
                                </div>
                            }
                            PopperProps={{
                                disablePortal: true,
                                modifiers: {
                                    offset: {
                                        enabled: true,
                                        offset: '0, 4px',
                                    },
                                },
                            }}
                        >
                            <IconButton
                                className="ProfileNotificationCard__tooltipIcon"
                                color="primary"
                                onClick={() => setActionsOpen((val) => !val)}
                            >
                                <MoreVertIcon />
                            </IconButton>
                        </Tooltip>
                    </div>
                </ClickAwayListener>
            </div>
        </Card>
    );
};

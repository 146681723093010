import React, { FunctionComponent, useState } from 'react';
import { connect } from 'react-redux';

import { flow, pipe } from 'fp-ts/function';

import { Button, CircularProgress, Dialog, DialogActions, DialogTitle } from '@material-ui/core';

import { Bill } from '../store/bills/types';
import { apiBillCloseDispute, makeMapDispatch } from '../store/dispatch';

const mapDispatch = makeMapDispatch({ closeDispute: apiBillCloseDispute });

type LogoutConfirmProps = {
    bill: Bill | null;
    isOpen: boolean;
    setClose: () => void;
    afterDisputeClose: () => void;
};

type DisputeConfirmProps = LogoutConfirmProps & ReturnType<typeof mapDispatch>;
const DisputeConfirm: FunctionComponent<DisputeConfirmProps> = (props) => {
    const { isOpen, setClose, closeDispute, afterDisputeClose, bill } = props;

    const [isLoading, setLoading] = useState(false);
    const onHandle = (): void => {
        if (isLoading || bill === null) return;
        setLoading(true);
        closeDispute({ bill_id: bill.id }).finally(flow(() => setLoading(false), afterDisputeClose));
    };

    return (
        <Dialog open={isOpen} onClose={setClose} aria-labelledby="dispute-confirm-title">
            <DialogTitle id="dispute-confirm-title">Вы&nbsp;уверены, что хотите отменить диспут?</DialogTitle>
            <DialogActions>
                <Button disabled={isLoading} onClick={onHandle} color="primary" variant="contained" autoFocus>
                    <span>Да</span>
                    {isLoading && <CircularProgress style={{ marginLeft: 4 }} size={12} />}
                </Button>
                <Button disabled={isLoading} onClick={setClose} color="primary" variant="outlined">
                    Нет
                </Button>
            </DialogActions>
        </Dialog>
    );
};

const EnchantedComponent = pipe(DisputeConfirm, connect(null, mapDispatch));
export { EnchantedComponent as DisputeConfirm };

import { Theme } from '@material-ui/core';
import { createStyles, StyleRules } from '@material-ui/styles';

const circleSize = 17;

export const styles = (theme: Theme): StyleRules =>
    createStyles({
        root: {
            [theme.breakpoints.only('md')]: {
                display: 'grid',
                gridGap: 16,
                gridTemplateColumns: 'repeat(2, 1fr)',
            },
        },
        header: {
            marginBottom: 64,
            padding: 0,
        },
        content: {
            padding: 0,
            '&:last-child': {
                paddingBottom: 0,
            },
        },
        body: {
            overflow: 'hidden',
            padding: 32,

            [theme.breakpoints.down('md')]: {
                padding: '32px 24px 12px',
            },
        },
        items: {
            display: 'grid',
            gridTemplateColumns: 'repeat(5, 1fr)',
            gridTemplateRows: 'auto 1fr',
            listStyle: 'none',
            margin: '0 0 24px',
            padding: 0,
            marginTop: Math.round(-circleSize / 2 - 1),
            // styles copied from `itemsVertical`
            [theme.breakpoints.down('md')]: {
                display: 'inline-grid',
                gridTemplateColumns: '1fr',
                marginTop: 0,
                marginBottom: 12,
            },
        },
        item: {
            height: 124,
            paddingRight: 16,
            paddingTop: 28,
            position: 'relative',
            '&::before': {
                backgroundColor: 'white',
                border: `1px solid ${theme.palette.secondary.light}`,
                borderRadius: '50%',
                content: '""',
                height: circleSize,
                left: 0,
                position: 'absolute',
                top: 0,
                width: circleSize,
                zIndex: 2,
            },
            '&::after': {
                backgroundColor: theme.palette.secondary.light,
                content: '""',
                height: 1,
                left: 0,
                position: 'absolute',
                top: circleSize / 2,
                width: '100%',
                zIndex: 1,
            },
            '&:last-child': {
                '&::after': {
                    width: '200%',
                    [theme.breakpoints.down('md')]: {
                        width: 1,
                    },
                },
            },
            // styles copied from `itemVertical`
            [theme.breakpoints.down('md')]: {
                height: 'auto',
                paddingBottom: 12,
                paddingLeft: 32,
                paddingRight: 0,
                paddingTop: 0,
                '&::before': {
                    top: 4,
                },
                '&::after': {
                    backgroundColor: theme.palette.secondary.light,
                    height: '100%',
                    left: Math.round(circleSize / 2 - 1),
                    top: 4,
                    width: 1,
                },
            },
        },
        itemActive: {
            '&::before': {
                backgroundColor: theme.palette.secondary.main,
            },
        },
        itemNotFirst: {
            '&::after': {
                transform: 'translateX(-50%)',
                width: '100%',
                [theme.breakpoints.down('md')]: {
                    width: 1,
                },
            },
        },
        itemLast: {
            paddingBottom: 0,
            '&::after': {
                display: 'none',
                transform: 'translateX(-100%)',
            },
        },
        itemLabel: {
            color: 'rgba(0, 0, 0, 0.6)',
            fontSize: 14,
            lineHeight: 1.715,
            margin: 0,
        },
        itemValue: {
            color: 'rgba(0, 0, 0, 0.87)',
            fontSize: 14,
            lineHeight: 1.715,
            margin: 0,
        },
        itemsVertical: {
            gridTemplateColumns: '1fr',
            marginTop: 16,
            marginBottom: 0,
        },
        itemVertical: {
            height: 'auto',
            paddingBottom: 12,
            paddingLeft: 32,
            paddingRight: 0,
            paddingTop: 0,
            '&::before': {
                top: 4,
            },
            'html:root &::after': {
                backgroundColor: theme.palette.secondary.light,
                height: '100%',
                left: Math.round(circleSize / 2 - 1),
                top: 4,
                transform: 'translateX(-50%)',
                width: 1,
            },
            '&:last-child': {
                paddingBottom: 0,
                '&::after': {
                    display: 'none',
                },
            },
        },
        itemDots: {
            backgroundColor: '#F4F4F4',
            height: 4,
            left: 0,
            position: 'absolute',
            top: Math.round(circleSize / 2 - 2),
            width: 39,
            zIndex: 2,
            '&::before': {
                backgroundImage: `radial-gradient(${theme.palette.secondary.light} 20%, transparent 0)`,
                backgroundPosition: '1px 8px',
                backgroundSize: '12px 12px',
                content: '""',
                display: 'block',
                height: '100%',
                position: 'relative',
                width: '100%',
                zIndex: 1,
            },

            [theme.breakpoints.down('md')]: {
                left: -11,
                marginTop: -30,
                top: '50%',
                transform: 'rotateZ(90deg)',
            },
        },
        itemDotsOnVertical: {
            backgroundColor: 'white',
            left: -12,
            marginTop: 8,
            top: '50%',
            transform: 'rotateZ(90deg)',
        },
        itemPlan: {
            [theme.breakpoints.up('md')]: {
                '&::before': {
                    left: 'auto',
                    right: 0,
                },

                'html:root &::after': {
                    display: 'block',
                    transform: 'translateX(-33.33%)',
                    width: '150%',
                },
            },

            [theme.breakpoints.down('md')]: {
                minHeight: 40,

                '&::before': {
                    left: 0,
                    bottom: 0,
                    top: 'auto',
                    right: 'auto',
                },

                'html:root &::after': {
                    display: 'block',
                    height: '100%',
                    top: 0,
                    width: 1,
                },
            },
        },
        itemPlanVertical: {
            minHeight: 80,

            '&::before': {
                left: 0,
                bottom: 'auto',
                top: 0,
                right: 'auto',
            },

            'html:root &::after': {
                display: 'block',
                height: 'calc(100% + 4px)',
                transform: 'none',
                top: 0,
                width: 1,
            },
        },
        button: {
            display: 'block',
            marginLeft: -8,
        },
        modal: {
            overflowY: 'auto',
        },
        modalBody: {
            backgroundColor: 'white',
            borderRadius: '5px',
            left: '50%',
            marginBottom: 32,
            marginTop: 64,
            maxWidth: '90%',
            outline: 'none',
            padding: 32,
            position: 'absolute',
            top: 0,
            transform: 'translateX(-50%)',
            minWidth: 352,
            [theme.breakpoints.down('sm')]: {
                padding: '32px 24px',
            },
        },
        modalClose: {
            position: 'absolute',
            right: 0,
            top: 0,
        },
        tooltipContent: {
            margin: '0.25em 0',
            padding: 0,
            listStyleType: 'none',
        },
        verticalEventList: {
            padding: 0,
            marginTop: '0.5em',
            fontSize: '0.675em',
            listStyleType: 'none',
        },
        itemPositionLabel: {
            minHeight: 24,
            marginBottom: 8,
            marginTop: 'auto',
            position: 'absolute',
            bottom: 126,
            [theme.breakpoints.down('md')]: {
                position: 'static',
                marginBottom: 0,
                minHeight: 'auto',
            },
        },
        itemWrap: {
            display: 'flex',
            flexDirection: 'column',
        },
        routeInfo: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            [theme.breakpoints.down('md')]: {
                flexDirection: 'column-reverse',
            },
        },
    });

import React, { FunctionComponent } from 'react';

import { Button, LinearProgress } from '@material-ui/core';

import { withStyles } from '@material-ui/styles';

import { Logo } from './Logo/Logo';

const WhiteButton = withStyles(() => ({
    root: {
        color: 'white',
        borderColor: 'white',
        backgroundColor: 'transparent',
        '&:hover': {
            backgroundColor: 'rbga(255, 255, 255, 0.5)',
        },
    },
}))(Button);

type Props = {
    progress?: number;
    showReload?: boolean;
    reloadCallback?: () => void;
};

export const Loading: FunctionComponent<Props> = (props) => {
    const { showReload = false, reloadCallback = () => {} } = props;

    const variant = 'progress' in props ? 'determinate' : 'indeterminate';

    return (
        <div className="App__loadingFull">
            <Logo />
            {showReload ? (
                <WhiteButton onClick={reloadCallback} variant="outlined">
                    Повторить вход
                </WhiteButton>
            ) : (
                <div className="App__loadingContainer">
                    <LinearProgress
                        color="secondary"
                        variant={variant}
                        value={props?.progress || 0}
                        style={{
                            width: 350,
                            borderRadius: 4,
                            maxWidth: '90%',
                        }}
                    />
                </div>
            )}
        </div>
    );
};

import React, { FunctionComponent, useCallback, useMemo } from 'react';
import clsx from 'clsx';

import { filter, mapWithIndex } from 'fp-ts/Array';
import { pipe } from 'fp-ts/function';

import { WithStyles, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';

import { OrderContainer, PlanRoutePoint } from '../../store/orders/types';
import { OrderTypeStatusNames } from '../../utils/const';

import { styles } from './styles';

const MAX_ROUTE_ITEMS = 4;

type ComponentProps = { container: OrderContainer };
type Props = ComponentProps & WithStyles<typeof styles>;
const RoutePlanningCard: FunctionComponent<Props> = (props) => {
    const { classes, container } = props;

    const getPortDischargeLabel = (): string =>
        container.destination === null && container.final_destination === null ? 'Порт назначения' : 'Порт перевалки';
    const getDestinationLabel = (): string =>
        container.final_destination === null ? 'Пункт назначения' : 'Пункт перевалки';
    const getDestinationValue = (): string | null => {
        if (container.destination === null) return null;

        let result = container.destination;
        if (container.rail_road.station_discharge !== null) {
            result += ` (${container.rail_road.station_discharge})`;
        }

        return result;
    };

    const isImport = container.order.type.name.toLowerCase() === OrderTypeStatusNames.Import.toLowerCase();

    let routeItems: PlanRoutePoint[];
    if (isImport) {
        routeItems = [
            { position: 1, title: 'Порт отправления', value: container.sea.port_loading, show_title: true },
            {
                position: 2,
                title: getPortDischargeLabel(),
                value: container.sea.port_discharge,
                show_title: true,
            },
            { position: 3, title: getDestinationLabel(), value: getDestinationValue(), show_title: true },
            {
                position: 4,
                title: 'Пункт назначения',
                value: container.final_destination,
                show_title: true,
            },
        ];
    } else {
        routeItems = container.plan_route_points;
    }

    const foldStatusWithStyles = useCallback(
        (index: number, item: PlanRoutePoint): JSX.Element | null => {
            const statusName = item.title;
            const statusValue = item.value;

            if (isImport && statusValue === null) return null;

            return (
                <div className={classes.item} key={`planning-card-item-${item.position}`}>
                    <p
                        className={clsx(classes.itemInner, classes.itemLabel)}
                        dangerouslySetInnerHTML={{ __html: item.show_title ? statusName || '' : '' }}
                    />

                    {statusValue && (
                        <p
                            className={clsx(classes.itemInner, classes.itemValue)}
                            dangerouslySetInnerHTML={{ __html: statusValue || '' }}
                        />
                    )}
                </div>
            );
        },
        [classes, isImport]
    );

    const routePreviewItems = useMemo(
        () =>
            pipe(
                routeItems,
                mapWithIndex(foldStatusWithStyles),
                filter((route) => route !== null)
            ),
        [foldStatusWithStyles, routeItems]
    );

    const getPlanningRouteWidth = (amount: number): number => (amount / MAX_ROUTE_ITEMS) * 100;

    if (routePreviewItems.length === 0) return null;

    return (
        <div className={classes.root}>
            <Typography variant="h6" className={classes.heading}>
                Маршрут
            </Typography>

            <div
                className={classes.body}
                style={{
                    width: `${getPlanningRouteWidth(routePreviewItems.length)}%`,
                }}
            >
                {routePreviewItems}
            </div>
        </div>
    );
};

const component = withStyles(styles)(RoutePlanningCard);
export { component as RoutePlanningCard };

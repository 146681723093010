import { Reducer } from 'redux';

import { SortsState, SortsActionTypes } from './types';

const initialState: SortsState = {
    bills: {
        by: 'date',
        direction: 'desc',
    },
    orders: {
        by: 'latest_status_position',
        direction: 'desc',
    },
    payments: {
        by: 'date',
        direction: 'desc',
    },
};

export const sortsReducer: Reducer<SortsState, SortsActionTypes> = (state = initialState, action) => {
    switch (action.type) {
        case 'SET_SORTS_DATA':
            return action.payload;
        default:
            return state;
    }
};

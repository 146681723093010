import React, { FunctionComponent, useEffect, useState } from 'react';
import clsx from 'clsx';

import { ExpansionPanel, ExpansionPanelDetails, ExpansionPanelSummary } from '@material-ui/core';
import { ExpandMore } from '@material-ui/icons';

import { pipe } from 'fp-ts/function';

import { ReportColumn } from '../../types/order-reports';
import { groupByC } from '../../utils/collection';

import { DroppableGroups } from '../DroppadbleGroups/DroppableGroups';
import { ColumnsGroup } from '../ExpandableColumnsGroup/ExpandableColumnsGroup';

type ExpandableColumnsPanelProps = {
    columns: ReportColumn[];
    onChange: (columns: ReportColumn[]) => void;
};

const collectColumns = (groupsRecord: Record<string, ReportColumn[]>): ColumnsGroup[] =>
    Object.keys(groupsRecord).map((key) => {
        const list = groupsRecord[key];
        const selected = !list.some((item) => !item.selected);
        const partiallySelected = list.some((item) => item.selected);

        return {
            selected,
            partiallySelected,
            name: key,
            columns: list.map((item) => ({ ...item, selected: selected ? true : item.selected })),
        };
    });

export const ExpandableColumnsPanel: FunctionComponent<ExpandableColumnsPanelProps> = (props) => {
    const { columns, onChange } = props;

    const [isOpen, setOpen] = useState(true);
    const [formattedColumns, setFormattedColumns] = useState<ColumnsGroup[]>([]);

    useEffect(() => {
        setFormattedColumns(
            pipe(
                columns,
                groupByC((i) => i.group.name),
                collectColumns
            )
        );
    }, [columns]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (formattedColumns.length === 0) return;

        const result: ReportColumn[] = [];

        formattedColumns.forEach((group) => {
            group.columns.forEach((column) => {
                result.push({ ...column, selected: group.selected || column.selected });
            });
        });

        onChange(result);
    }, [formattedColumns]); // eslint-disable-line react-hooks/exhaustive-deps

    const toggleItem = (groupName: string, columnId: number): void => {
        setFormattedColumns((groups) => {
            const editedGroups = [...groups];

            const groupIndex = editedGroups.findIndex((group) => group.name === groupName);

            if (groupIndex !== -1) {
                const group = editedGroups[groupIndex];
                const itemIndex = group.columns.findIndex((item) => item.id === columnId);

                if (itemIndex !== -1) {
                    const item = group.columns[itemIndex];
                    item.selected = !item.selected;
                    group.partiallySelected = group.columns.some((column) => column.selected);
                }

                // TODO: refactor with better complexity
                if (group.columns.every((item) => item.selected)) {
                    group.selected = true;
                    group.partiallySelected = false;
                } else if (group.columns.some((item) => item.selected)) {
                    group.selected = false;
                    group.partiallySelected = true;
                } else {
                    group.selected = false;
                    group.partiallySelected = false;
                }
            }

            return editedGroups;
        });
    };

    const toggleGroup = (groupName: string): void => {
        setFormattedColumns((groups) => {
            const editedGroups = [...groups];

            const groupIndex = editedGroups.findIndex((group) => group.name === groupName);

            if (groupIndex !== -1) {
                const group = editedGroups[groupIndex];

                if (group.selected) {
                    group.selected = false;
                    group.partiallySelected = false;
                    group.columns = group.columns.map((column) => ({ ...column, selected: false }));
                } else {
                    group.selected = true;
                    group.partiallySelected = false;
                    group.columns = group.columns.map((column) => ({ ...column, selected: true }));
                }
            }

            return editedGroups;
        });
    };

    // FIXME: wtf, list **any** type?
    const reorder = (list: any[], currentIndex: number, destinationIndex: number): any[] => {
        const result = [...list];
        const [removed] = result.splice(currentIndex, 1);
        result.splice(destinationIndex, 0, removed);

        return result;
    };

    const moveGroup = (currentIndex: number, destinationIndex: number): void => {
        setFormattedColumns((list) => reorder(list, currentIndex, destinationIndex));
    };

    const moveColumn = (group: string, currentIndex: number, destinationIndex: number): void => {
        setFormattedColumns((list) => {
            const result = [...list];

            const groupIndex = result.findIndex((item) => item.name === group);

            if (groupIndex !== -1) {
                result[groupIndex].columns = reorder(result[groupIndex].columns, currentIndex, destinationIndex);
            }

            return result;
        });
    };

    return (
        <ExpansionPanel expanded={isOpen} onChange={() => setOpen((value) => !value)}>
            <ExpansionPanelSummary className="DownloadSettings__summary DownloadSettings__summary--main">
                <div className="DownloadSettings__headingWrapper">
                    <h2 className="DownloadSettings__heading">Настройка столбцов .xlsx</h2>
                    <ExpandMore
                        className={clsx('DownloadSettings__icon', isOpen && 'DownloadSettings__icon--rounded')}
                    />
                </div>
            </ExpansionPanelSummary>

            <ExpansionPanelDetails className="DownloadSettings__details DownloadSettings__details--withOffset">
                <DroppableGroups
                    toggleItem={toggleItem}
                    toggleGroup={toggleGroup}
                    moveGroup={moveGroup}
                    moveColumn={moveColumn}
                    columns={formattedColumns}
                />
            </ExpansionPanelDetails>
        </ExpansionPanel>
    );
};

import React, { FunctionComponent } from 'react';
import { KeyboardArrowDown } from '@material-ui/icons';
import clsx from 'clsx';

import './SortButton.scss';

type SortButtonProps = {
    label: string;
    active: boolean;
    order: string;
    clickHandler: () => void;
    isMinifiedView?: boolean;
};

export const SortButton: FunctionComponent<SortButtonProps> = (props) => {
    const { active, label, clickHandler, order, isMinifiedView = false } = props;

    return (
        <button
            type="button"
            onClick={clickHandler}
            className={clsx(
                'SortButton',
                active && 'SortButton--active',
                order === 'asc' && active && 'SortButton--ascOrder',
                isMinifiedView && 'SortButton--minified'
            )}
        >
            {label}
            <KeyboardArrowDown className="SortButton__chevron" />
        </button>
    );
};

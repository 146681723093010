import moment, { Moment } from 'moment';

import { pipe } from 'fp-ts/function';
import { Iso } from 'monocle-ts';

import { convertHoursToMinutes, convertMinutesToSeconds } from './date';
import { DocumentsStatus } from '../types/documents';
import { mapNullable } from './types';

export const MomentISO8601Iso = new Iso<Moment, Time.ISO8601>(
    (m) => m.format() as Time.ISO8601,
    (t) => moment(t)
);

const DateFormat = 'YYYY.MM.DD';
export const MomentDateIso = new Iso<Moment, Time.Date>(
    (m) => m.format(DateFormat) as Time.Date,
    (d) => moment(d, DateFormat)
);

export const NullableMomentDateIso = new Iso<Moment | null, Time.Date | null>(
    mapNullable(MomentDateIso.to),
    mapNullable(MomentDateIso.from)
);

export const KopecksIso = new Iso<Kopecks, number>(
    (k) => k / 100,
    (n) => Math.floor(n * 100) as Kopecks
);

export const DocumentsStatusIso = new Iso<boolean | null, DocumentsStatus>(
    (archivedFlag: boolean | null): DocumentsStatus => {
        if (archivedFlag === null) return DocumentsStatus.All;
        return archivedFlag ? DocumentsStatus.Archived : DocumentsStatus.Current;
    },
    (status) => {
        if (status === DocumentsStatus.All) return null;
        return status === DocumentsStatus.Archived;
    }
);

export const TimezoneIso = new Iso<string, number>(
    (k) => {
        const formattedValue = k.startsWith('-') || k.startsWith('+') ? k.substring(1) : k;
        const sign = k.startsWith('-') ? -1 : 1;
        const [hours = 0, minutes = 0] = formattedValue.split(':');
        return (
            sign *
            (pipe(Number(hours), convertHoursToMinutes, convertMinutesToSeconds) +
                convertMinutesToSeconds(Number(minutes)))
        );
    },
    (n) => {
        const date = moment().startOf('day');
        const method: keyof Moment = n > 0 ? 'add' : 'subtract';
        return `${n > 0 ? '+' : '-'}${date[method](n, 'seconds').format('HH:mm')}`;
    }
);

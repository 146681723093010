import { Checkbox, FormControlLabel } from '@material-ui/core';
import React, { FunctionComponent } from 'react';

import { SwapVert } from '@material-ui/icons';

import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

import { ReportColumn } from '../../types/order-reports';

type DroppableColumnsProps = {
    moveColumn: (group: string, currentIndex: number, destinationIndex: number) => void;
    toggleItem: (groupName: string, columnId: number) => void;
    columns: ReportColumn[];
    groupName: string;
};

export const DroppableColumns: FunctionComponent<DroppableColumnsProps> = (props) => {
    const { columns, moveColumn, groupName, toggleItem } = props;

    return (
        <DragDropContext
            onDragEnd={(result) => {
                if (result && result.destination) moveColumn(groupName, result.source.index, result.destination.index);
            }}
        >
            <Droppable droppableId="droppable-columns">
                {(provided) => (
                    <div {...provided.droppableProps} ref={provided.innerRef}>
                        {columns.map((item, index) => (
                            <Draggable key={item.id} draggableId={item.id.toString()} index={index}>
                                {(draggableProvided) => (
                                    <div ref={draggableProvided.innerRef} {...draggableProvided.draggableProps}>
                                        <div className="DownloadSettings__columnOption">
                                            <FormControlLabel
                                                className="DownloadSettings__checkboxControlLabel"
                                                label={item.name}
                                                control={
                                                    <Checkbox
                                                        checked={item.selected}
                                                        color="primary"
                                                        onChange={() => toggleItem(item.group.name, item.id)}
                                                    />
                                                }
                                            />
                                            <div
                                                className="DownloadSettings__secondIconWrapper"
                                                {...draggableProvided.dragHandleProps}
                                            >
                                                <SwapVert />
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </Draggable>
                        ))}
                        {provided.placeholder}
                    </div>
                )}
            </Droppable>
        </DragDropContext>
    );
};

import React, { FunctionComponent } from 'react';
import clsx from 'clsx';

import { makeStyles, createStyles, CardHeader, IconButton, Card, CardContent } from '@material-ui/core';
import { Close } from '@material-ui/icons';

import { DebtControlOption } from '../store/bills/types';
import { RightDrawer } from './RightDrawer';

const useStyles = makeStyles((theme) =>
    createStyles({
        container: {
            // maxWidth: 345,
            height: '100%',
            overflowY: 'auto',
        },
        header: {
            padding: '40px 40px 0',
            position: 'relative',
            '& .MuiCardHeader-title': {
                fontSize: 24,
                fontWeight: 'bold',
            },
        },
        content: {
            padding: '32px 40px',
            fontSize: 16,
            lineHeight: '1.35em',
        },
        close: {
            position: 'absolute',
            top: theme.spacing(1),
            right: theme.spacing(1),
        },
        list: {
            listStyle: 'none',
            margin: 0,
            padding: 0,
        },
        listItem: {
            '&:not(:last-child)': {
                marginBottom: 24,
            },
        },
        listItemChanged: {},
        itemHeader: {
            margin: 0,
            marginBottom: 12,
        },
        listItemInner: {
            paddingLeft: 22,
            position: 'relative',

            '&::before': {
                backgroundColor: '#004d78',
                borderRadius: '50%',
                content: '""',
                height: 10,
                left: 0,
                position: 'absolute',
                top: 5,
                width: 10,
            },

            '&:not(:last-child)': {
                marginBottom: 8,
            },
        },
    })
);

export type DebtInfoDrawerProps = { open: boolean; onClose: () => void; entries: DebtControlOption[] };
export const DebtInfoDrawer: FunctionComponent<DebtInfoDrawerProps> = (props) => {
    const { open, onClose, entries } = props;

    const classes = useStyles();

    return (
        <RightDrawer open={open} onClose={onClose}>
            <Card className={classes.container} square>
                <CardHeader
                    title="Индивидуальные кредитные условия"
                    className={classes.header}
                    action={
                        <IconButton className={classes.close} aria-label="close" onClick={onClose}>
                            <Close />
                        </IconButton>
                    }
                />
                <CardContent className={classes.content}>
                    {entries.length > 0 ? (
                        <ul className={classes.list}>
                            {entries.map((entry, index) => (
                                <li
                                    key={index}
                                    className={clsx([
                                        classes.listItem,
                                        entry.is_changed && classes.listItemChanged
                                    ])}
                                >
                                    <h4 className={classes.itemHeader}>{entry.name}</h4>

                                    <ul className={classes.list}>
                                        {entry.descriptions.map((desc, i) => (
                                            <li key={`par${i}-${index}`} className={classes.listItemInner}>
                                                {desc}
                                            </li>
                                        ))}
                                    </ul>
                                </li>
                            ))}
                        </ul>
                    ) : (
                        'Пусто'
                    )}
                </CardContent>
            </Card>
        </RightDrawer>
    );
};

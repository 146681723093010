export type FailedDocumentStatus = 'no_documents_generated' | 'prepared_document_does_not_exist';
export type PreparedDocumentStatus = 'document_prepared';
export type NonPreparedDocumentStatus = FailedDocumentStatus | 'new' | 'requests_processed';

export enum DocumentsStatus {
    All,
    Current,
    Archived,
}

export type DocumentStatus = PreparedDocumentStatus | NonPreparedDocumentStatus;

export type PreparedDocumentType = {
    url: string;
    display_name: string;
    download_name: string;
};

export type NonPreparedDocumentType = {
    id: number;
    display_name: string;
    status: DocumentStatus;
};

export type PreparedDocumentResponse = {
    status: PreparedDocumentStatus;
    document: PreparedDocumentType;
};

export type NonPreparedDocumentResponse = {
    status: NonPreparedDocumentStatus;
    document: NonPreparedDocumentType;
};

export type GetDocumentRequest = { document_id: number };
export type GetDocumentResponse = PreparedDocumentResponse | NonPreparedDocumentResponse;
export type RequestDocumentResponse = Pick<NonPreparedDocumentResponse, 'document'>;

import React, { FunctionComponent } from 'react';
import QRCode from 'qrcode.react';
import clsx from 'clsx';

import { Button, Card, Typography } from '@material-ui/core';

import styles from './TelegramBot.module.scss';

type TelegramBotProps = {
    isSubscribed?: boolean;
    link?: string;
    isWithoutCardStyles?: boolean;
    size?: number;
};
export const TelegramBot: FunctionComponent<TelegramBotProps> = (props) => {
    const { isSubscribed, link, isWithoutCardStyles, size } = props;

    return (
        <Card className={clsx(styles.root, isWithoutCardStyles && styles.noStyling)}>
            <div className={styles.leftColumn}>
                {isSubscribed !== undefined && (
                    <Typography variant="h5" gutterBottom classes={{ root: styles.heading }}>
                        <span className={clsx(styles.status, isSubscribed && styles.statusSuccess)} />

                        <span className={styles.titleInner}>
                            Telegram-бот {isSubscribed ? 'подключён' : 'отключен'}
                        </span>
                    </Typography>
                )}

                <div className={styles.text}>
                    Подключите чтобы получать уведомления по&nbsp;заказам. Если Telegram уже установлен, нажмите
                    на&nbsp;кнопку &laquo;Подключить&raquo; или отсканируйте QR-код
                </div>

                <div className={styles.buttons}>
                    {!isSubscribed && link !== undefined && (
                        <Button
                            href={link}
                            target="_blank"
                            variant="contained"
                            size="large"
                            color="primary"
                            className={styles.button}
                        >
                            Подключить
                        </Button>
                    )}

                    <Button
                        href="https://telegram.org/apps?setln=ru"
                        target="_blank"
                        variant="outlined"
                        size="large"
                        color="primary"
                        className={styles.button}
                    >
                        Скачать Telegram
                    </Button>
                </div>
            </div>

            {link !== undefined && (
                <div className={styles.qrWrapper}>
                    <QRCode value={link} size={size} />
                </div>
            )}
        </Card>
    );
};

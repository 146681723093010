import { useContext } from 'react';
import { SortContext, SortProviderContext } from '../providers/SortProvider';
import { SortingConfigKey } from '../store/sorts/types';

export function useSorts<K extends SortingConfigKey>(kind: K): SortProviderContext<K> {
    const context = useContext(SortContext);
    if (!context) throw new Error('useSorts must be used within a SortsProvider');

    return {
        sorts: context.sorts[kind],
        handleChange: context.buildHandleChange(kind),
    };
}

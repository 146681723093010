import React, { FunctionComponent } from 'react';

import { ExpansionPanel, ExpansionPanelDetails, ExpansionPanelSummary } from '@material-ui/core';
import { ExpandMore } from '@material-ui/icons';

import { ConsolidationRow } from '../../store/orders/types';

import { formatDate } from '../../utils/view';

import { ConsolidationTable } from '../ConsolidationTable/ConsolidationTable';

import './ExpandableConsolidationTable.scss';

type ExpandableConsolidationTableProps = {
    tableRows: ConsolidationRow[];
    updateTime: string | null;
};

export const ExpandableConsolidationTable: FunctionComponent<ExpandableConsolidationTableProps> = (props) => {
    const { tableRows, updateTime } = props;

    return (
        <ExpansionPanel className="ExpandableConsolidationTable">
            <ExpansionPanelSummary expandIcon={<ExpandMore />} className="ExpandableConsolidationTable__summary">
                <h2 className="ExpandableConsolidationTable__heading">Консолидация груза</h2>
                {updateTime && (
                    <span className="ExpandableConsolidationTable__date">Обновлено {formatDate(updateTime, true)}</span>
                )}
            </ExpansionPanelSummary>

            <ExpansionPanelDetails className="ExpandableConsolidationTable__details">
                <ConsolidationTable tableRows={tableRows} />
            </ExpansionPanelDetails>
        </ExpansionPanel>
    );
};

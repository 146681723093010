import React, { FunctionComponent, useEffect, useMemo, useState } from 'react';
import clsx from 'clsx';
import { Link, NavLink, useParams, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import 'moment-timezone';

import * as O from 'fp-ts/Option';
import { lookup } from 'fp-ts/Record';
import { pipe } from 'fp-ts/function';

import WarningTwoToneIcon from '@material-ui/icons/WarningTwoTone';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { yellow } from '@material-ui/core/colors';
import {
    Breadcrumbs,
    Button,
    CircularProgress,
    Typography,
    Card,
    ExpansionPanel,
    ExpansionPanelSummary,
    ExpansionPanelDetails,
} from '@material-ui/core';

import { apiGetOrderContainer, makeMapDispatch } from '../../store/dispatch';
import { setManagersVisible } from '../../store/settings/actions';
import { makeMapState } from '../../store/root';

import { useSearch } from '../../hooks/search';
import { useAlerts } from '../../hooks/noty';
import { useUrl } from '../../hooks/url';

import { makeContainerTitle, scrollToTop } from '../../utils/view';
import { setPageTitle } from '../../utils/title';
import { ROUTES } from '../../utils/location';

import { ApiError } from '../../api/client/errors';

import { ExpandableConsolidationSection } from '../../components/ExpandableConsolidationSection/ExpandableConsolidationSection';
import { ExpandableConsolidationTable } from '../../components/ExpandableConsolidationTable/ExpandableConsolidationTable';
import { StatusHistoryTable } from '../../components/StatusHistoryTable/StatusHistoryTable';
import { ContainerDetails } from '../../components/ContainerDetails/ContainerDetails';
import { ManagersGroup } from '../../components/ManagersGroup/ManagersGroup';
import { FetchError } from '../../components/FetchError';
import { RouteCard } from '../../components/RouteCard/RouteCard';

import './Order.scss';

const mapState = makeMapState((state) => ({
    cacheList: state.orders.containers,
    managersVisible: state.settings.managersVisible,
}));

const mapDispatch = makeMapDispatch({
    getContainer: apiGetOrderContainer,
    setVisible: setManagersVisible,
});

type Props = ReturnType<typeof mapState> & ReturnType<typeof mapDispatch>;
const Order: FunctionComponent<Props> = (props) => {
    const { getContainer, cacheList, managersVisible, setVisible } = props;

    const { containerId } = useParams();
    const { handleApiError } = useAlerts();
    const { hasSearchData, searchResultLink } = useSearch();
    const { getQueryParam } = useUrl();

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState<ApiError | null>(null);

    const container = pipe(
        O.fromNullable(containerId),
        O.chain((id) => lookup(id, cacheList)),
        O.toNullable
    );

    useEffect(() => {
        scrollToTop();
    }, []);

    useEffect(() => {
        if (container === null && error === null && !loading && containerId) {
            setError(null);
            setLoading(true);
            getContainer({ container_id: parseInt(containerId, 10) })
                .catch((e) => handleApiError(e, setError))
                .finally(() => setLoading(false));
        }
    }, [container, loading, containerId, error]); // eslint-disable-line react-hooks/exhaustive-deps

    const title = useMemo(() => makeContainerTitle(container), [container]);

    let prevPageTitle = 'Мои заказы';
    let prevPageLink = '/orders';

    const prevPageParams = getQueryParam('encodedNotificationPageParams');

    if (prevPageParams) {
        prevPageTitle = 'Важные уведомления';
        prevPageLink = `${ROUTES.FEED}${decodeURIComponent(prevPageParams)}`;
    }

    useEffect(() => {
        if (container !== null && !hasSearchData) setPageTitle(title);
    }, [container, hasSearchData, title]);

    if (loading)
        return (
            <section className="OrderView__info">
                <CircularProgress />
            </section>
        );

    if (error) {
        const commonError = error.getCommonFirstMessage();
        const isContainerIdInvalid = error.hasInvalidField('container_id');
        return (
            <FetchError
                onRetry={() => setError(null)}
                title={
                    isContainerIdInvalid
                        ? `Контейнер с номером №${containerId} не найден`
                        : [`Ошибка при запросе данных по контейнеру с номером №{containerId}`, commonError]
                }
            >
                <Button
                    to="/orders"
                    variant="outlined"
                    style={{ marginTop: '1em', marginRight: '1em' }}
                    component={Link}
                >
                    Вернуться к моим заказам
                </Button>
            </FetchError>
        );
    }

    if (container === null)
        return (
            <section className="OrderView__info">
                <WarningTwoToneIcon style={{ color: yellow[600], fontSize: '8em' }} />
                <div className="OrderView__infoLabel">Контейнер с номером №{containerId} не найден</div>
                <Button component={Link} to="/orders" variant="outlined" style={{ marginTop: '1em' }}>
                    Вернуться к моим заказам
                </Button>
            </section>
        );

    return (
        <section className="OrderView">
            <div className="OrderView__innerContent OrderView__breadcrumbs">
                <Breadcrumbs aria-label="breadcrumb" separator="•">
                    <NavLink className="FilesView__breadcrumbsLink" color="inherit" to={prevPageLink}>
                        {prevPageTitle}
                    </NavLink>
                    {searchResultLink(`/orders/${containerId}`)}
                    <Typography color="textSecondary">{title}</Typography>
                </Breadcrumbs>
            </div>

            <div className="OrderView__containerDetails OrderView__innerContent">
                <ContainerDetails container={container} />
            </div>

            <div className="OrderView__route OrderView__innerContent">
                <RouteCard container={container} />
            </div>

            <ExpansionPanel
                className={clsx([
                    'OrderView__accordion',
                    'OrderView__innerContent',
                    'OrderView__contacts',
                    !managersVisible && 'OrderView__contacts--collapsed',
                ])}
                expanded={managersVisible}
            >
                <ExpansionPanelSummary
                    className="OrderView__accordionSummary"
                    expandIcon={<ExpandMoreIcon />}
                    onClick={() => setVisible(!managersVisible)}
                >
                    Контактная информация
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                    <Card className="OrderView__managers">
                        <ManagersGroup
                            orderNumber={container.order.number}
                            groups={container.manager_groups}
                            visible={managersVisible}
                        />
                    </Card>
                </ExpansionPanelDetails>
            </ExpansionPanel>

            {container.consolidation.table_rows.length > 0 && (
                <div className="OrderView__consolidation OrderView__innerContent">
                    <ExpandableConsolidationTable
                        tableRows={container.consolidation.table_rows}
                        updateTime={container.order.consolidation_updated_at}
                    />
                </div>
            )}

            {container.consolidation.sections.map((section, index) => (
                <div
                    className="OrderView__consolidation OrderView__innerContent"
                    key={`${section.position}${section.name}${section.type}${section.data_updated_at}${index}`}
                >
                    <ExpandableConsolidationSection section={section} />
                </div>
            ))}

            <div className="OrderView__innerContent">
                <StatusHistoryTable container={container} />
            </div>
        </section>
    );
};

const EnchantedComponent = withRouter(pipe(Order, connect(mapState, mapDispatch)));
export { EnchantedComponent as Order };

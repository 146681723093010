import React, { FunctionComponent } from 'react';
import { CircularProgress } from '@material-ui/core';
import clsx from 'clsx';

export const LoadingFixedCenter: FunctionComponent<{ visible?: boolean; isDownload?: boolean }> = ({
    visible = true,
    isDownload,
}) =>
    visible ? (
        <div className={clsx('App__loader App__center App__loader--fixed', isDownload && 'App__loader--download')}>
            <div className="App__loaderWithText">
                <CircularProgress />
                {isDownload && <span className="App__loaderText">Формируем документ, загрузка скоро начнется</span>}
            </div>
        </div>
    ) : null;

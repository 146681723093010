import React, { FunctionComponent, useEffect, useRef, useState } from 'react';
import {
    ExpansionPanel,
    ExpansionPanelDetails,
    ExpansionPanelSummary,
    IconButton,
    Menu,
    MenuItem,
} from '@material-ui/core';
import { DeleteOutline, Edit, MoreVert } from '@material-ui/icons';

import { UserWithNotifications } from '../../../types/user';

import { formatPhone } from '../../../utils/view';

import { EmployeeForm, EmployeeFormKind } from '../EmployeeForm/EmployeeForm';
import { ProfileNotificationCard } from '../../../components/ProfileNotificationCard/ProfileNotificationCard';

import styles from './EmployeeCard.module.scss';
import { buildRouteUrl, ROUTES } from '../../../utils/location';
import { useUrl } from '../../../hooks/url';
import { useHistory } from 'react-router-dom';

type EmployeeCardProps = {
    user: UserWithNotifications;
    onDelete?(user: UserWithNotifications): void;
};
export const EmployeeCard: FunctionComponent<EmployeeCardProps> = (props) => {
    const { user, onDelete } = props;

    const [isMenuShown, setMenuShown] = useState(false);
    const [isInEditMode, setInEditMode] = useState(false);

    const ref = useRef<HTMLDivElement>(null);
    const { getQueryParam } = useUrl();
    const { push } = useHistory();

    useEffect(() => {
        const userId = getQueryParam('userId');

        if (Number(userId) === user.id) {
            setInEditMode(true);
            ref?.current?.scrollIntoView(true);
        }
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const toggleEditMode = (): void => {
        if (user.is_auth_user) {
            push(ROUTES.PROFILE);
        } else {
            setInEditMode((prevValue) => !prevValue);
        }
    };

    const formattedPhoneOrNull = user.phone !== null ? formatPhone(user.phone) : null;
    const info = [user.login, formattedPhoneOrNull, user.email].filter((item) => item !== null).join(' • ');

    return (
        <ExpansionPanel expanded={isInEditMode} classes={{ root: styles.root }}>
            <ExpansionPanelSummary
                classes={{ root: styles.panelSummary, content: styles.panelContent, expanded: styles.summaryExpanded }}
                focusRipple={false}
                expandIcon={
                    !isInEditMode && (
                        <div ref={ref}>
                            <IconButton onClick={() => setMenuShown(true)}>
                                <MoreVert />
                            </IconButton>

                            <Menu anchorEl={ref.current} open={isMenuShown} onClose={() => setMenuShown(false)}>
                                <MenuItem
                                    onClick={() => {
                                        if (user.is_auth_user) {
                                            push(ROUTES.PROFILE);
                                        } else {
                                            setInEditMode(true);
                                            setMenuShown(false);
                                        }
                                    }}
                                >
                                    <Edit className={styles.menuIcon} />
                                    Редактировать
                                </MenuItem>

                                <MenuItem
                                    onClick={() => {
                                        if (onDelete !== undefined) onDelete(user);
                                        setMenuShown(false);
                                    }}
                                    className={styles.deleteMenuItem}
                                >
                                    <DeleteOutline className={styles.menuIcon} />
                                    Удалить
                                </MenuItem>
                            </Menu>
                        </div>
                    )
                }
            >
                {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events */}
                <div onClick={toggleEditMode} role="button" className={styles.content} tabIndex={0}>
                    <div className={styles.name}>{user.full_name}</div>
                    {info.length > 0 && <div className={styles.info}>{info}</div>}
                </div>
            </ExpansionPanelSummary>

            <ExpansionPanelDetails className={styles.panelDetails}>
                <EmployeeForm
                    mode={{ kind: EmployeeFormKind.update, user }}
                    onClose={() => setInEditMode(false)}
                    isShowCancelButton
                    classes={{ root: styles.form }}
                />

                {user.notification_settings_sets.length > 0 && (
                    <div className={styles.notificationsSettings}>
                        {user.notification_settings_sets.map((item) => {
                            let link = '';
                            const { id } = item;

                            if (item.set_type.type === 'documents') {
                                link = buildRouteUrl('USER_NOTIFICATION_SETTINGS_DOCUMENTS', {
                                    userId: user.id,
                                    settingsId: id,
                                });
                            } else if (item.set_type.type === 'mutual_settlements_report') {
                                link = buildRouteUrl('USER_NOTIFICATION_SETTINGS_MUTUAL_SETTLEMENTS', {
                                    userId: user.id,
                                    settingsId: id,
                                });
                            } else {
                                link = buildRouteUrl('USER_NOTIFICATION_SETTINGS_BY_ID', {
                                    userId: user.id,
                                    settingsId: id,
                                });
                            }

                            return (
                                <ProfileNotificationCard
                                    id={id}
                                    name={item.name}
                                    link={link}
                                    isDefault={item.is_default}
                                    selectedChannels={item.selected_channels}
                                    key={id}
                                    isEmployee
                                />
                            );
                        })}
                    </div>
                )}
            </ExpansionPanelDetails>
        </ExpansionPanel>
    );
};

import React, { FunctionComponent } from 'react';
import { YMInitializer } from 'react-yandex-metrika';

import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';

import { isSome } from 'fp-ts/Option';
import { flow } from 'fp-ts/function';

import { SnackbarProvider } from 'notistack';
import { ThemeProvider } from '@material-ui/styles';
import { SnackbarOrigin, createMuiTheme, responsiveFontSizes } from '@material-ui/core';

import { persistor, store } from '../store/root';
import { makeYMAccount } from '../utils/branding';

import { App } from './App/App';
import { DocumentLoaderProvider } from '../providers/DocumentLoaderProvider';

// Material UI theme
const theme = flow(
    createMuiTheme,
    responsiveFontSizes
)({
    palette: {
        primary: {
            main: '#004D78',
            light: '#4479A7',
            dark: '#00264C',
        },
        secondary: {
            main: '#770069',
            light: '#A93F97',
            dark: '#47003E',
        },
        error: {
            main: '#BF0000',
        },
    },
    breakpoints: {
        values: {
            xs: 375,
            sm: 425,
            md: 640,
            lg: 1000,
            xl: 1200,
        },
    },
    typography: {
        h1: {
            fontWeight: 500,
            fontSize: 32,
            lineHeight: 1.15625,
        },
        h2: {
            fontWeight: 500,
            fontSize: 24,
            lineHeight: 1.167,
        },
        h4: {
            fontWeight: 500,
            fontSize: 18,
            lineHeight: 1.167,
        },
        h6: {
            lineHeight: 1.25,
        },
    },
    overrides: {
        MuiOutlinedInput: {
            input: {
                boxSizing: 'border-box',
                height: 48,
                padding: '8px 12px',
            },
        },
        MuiInputLabel: {
            outlined: {
                transform: 'translate(14px, 16px) scale(1)',
            },
        },
        MuiButton: {
            textSecondary: {
                color: '#00000099',
            },
        },
    },
});

export const Root: FunctionComponent = () => {
    const account = makeYMAccount(process.env.REACT_APP_YANDEX_METRIKA_ACCOUNT);
    const snackbarAnchorOptions: SnackbarOrigin = {
        vertical: 'top',
        horizontal: 'right',
    };

    return (
        <Provider store={store}>
            <PersistGate persistor={persistor}>
                <ThemeProvider theme={theme}>
                    <SnackbarProvider anchorOrigin={snackbarAnchorOptions}>
                        <DocumentLoaderProvider>
                            <App />
                            {isSome(account) && (
                                <YMInitializer
                                    accounts={[account.value]}
                                    options={{
                                        clickmap: true,
                                        trackLinks: true,
                                        accurateTrackBounce: true,
                                        webvisor: true,
                                        trackHash: true,
                                    }}
                                />
                            )}
                        </DocumentLoaderProvider>
                    </SnackbarProvider>
                </ThemeProvider>
            </PersistGate>
        </Provider>
    );
};

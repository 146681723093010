import { reduceWithIndex } from 'fp-ts/Record';
import { eqString } from 'fp-ts/Eq';
import { pipe } from 'fp-ts/function';

import type { PreparedDocumentType } from '../types/documents';

export const defaultPath = '/';
export const appPath = process.env.REACT_APP_ROOT_PATH || defaultPath;

export const ROUTES = {
    ORDERS: '/orders',
    BILLS: '/bills',
    PAYMENT_HISTORY: '/payment-history',
    PROFILE: '/profile',
    FEED: '/feed',
    EMPLOYEES: '/employees',
    NOTIFICATION_SETTINGS_DOCUMENTS: '/notification-settings-documents',
    NOTIFICATION_SETTINGS_MUTUAL_SETTLEMENTS: '/notification-settings-mutual-settlements',
    NOTIFICATION_SETTINGS_BY_ID: '/notification-settings/:settingsId',
    NOTIFICATION_SETTINGS_CREATE: '/notification-settings/create',
    DOWNLOAD_SETTINGS: '/download-settings',
    USER_NOTIFICATION_SETTINGS_MUTUAL_SETTLEMENTS: '/user-notification-settings-mutual-settlements/:userId/:settingsId',
    USER_NOTIFICATION_SETTINGS_BY_ID: '/user-notification-settings/:userId/:settingsId',
    USER_NOTIFICATION_SETTINGS_DOCUMENTS: '/user-notification-settings-documents/:userId/:settingsId',
    DOWNLOAD_SETTINGS_BY_ID: '/download-settings/:settingsId',
    DOWNLOAD_SETTINGS_CREATE: '/download-settings/create',
    DOWNLOAD_SETTINGS_CREATE_FROM_TEMPLATE: '/download-settings/create?template=:templateId',
};

export const buildRouteUrl = (route: keyof typeof ROUTES, params: Record<string, string | number>): string =>
    pipe(
        params,
        reduceWithIndex(ROUTES[route], (key, acc, value) => acc.replace(`:${key}`, value.toString())),
    );

export type LinkData = {
    key: string;
    path: string;
    rootPath?: string;
    amount?: number;
    label: string;
};

export const APP_LINKS = (notificationsCount?: number, isUserGrantedManageRegularUsers?: boolean): LinkData[] => {
    const items = [
        { key: 'orders', label: 'Заказы', path: ROUTES.ORDERS },
        { key: 'bills', label: 'Счета', path: `${ROUTES.BILLS}?is_archived=0`, rootPath: ROUTES.BILLS },
        { key: 'payment-history', label: 'Платежи', path: ROUTES.PAYMENT_HISTORY },
        {
            key: 'notifications-settings',
            label: 'Уведомления',
            path: `${ROUTES.FEED}`,
            amount: notificationsCount,
        }
    ];

    if (isUserGrantedManageRegularUsers) {
        items.push({ key: 'employees', label: 'Сотрудники', path: `${ROUTES.EMPLOYEES}` });
    }

    return items;
};

export const REPORT_ADMIN_APP_LINKS: LinkData[] = [
    { key: 'download-settings', label: 'Выгрузки', path: ROUTES.DOWNLOAD_SETTINGS },
];

export const getPathName = (): string => {
    const keepPrefix = eqString.equals(appPath, defaultPath);
    const path = window.location.pathname;
    return keepPrefix ? path : path.replace(appPath, '');
};

export const getCurrentPath = (): string => getPathName() + window.location.search;

export const downloadDocument = (doc: PreparedDocumentType): void => {
    const link = document.createElement('a');
    link.href = doc.url;
    link.hidden = true;
    link.target = '_blank';
    link.download = doc.download_name;

    const body = document.querySelector('body');
    if (!body) return;

    body.appendChild(link);
    link.click();
    setTimeout(() => body.removeChild(link));
};

import React, { FunctionComponent, useCallback, useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import { flow, pipe } from 'fp-ts/function';

import { Pagination } from '@material-ui/lab';
import { Sort } from '@material-ui/icons';

import {
    PaymentHistoryFiltersPayload,
    PaymentHistoryListFilters,
    PaymentHistoryListItem,
} from '../../store/payment-history/types';
import { makeMapState } from '../../store/root';
import { setPaymentsListPageSize } from '../../store/settings/actions';
import { apiGetPaymentHistoryList, makeMapDispatch } from '../../store/dispatch';

import { foldPageCount, foldView, scrollToTop } from '../../utils/view';
import { PaginatorPageSizes } from '../../utils/const';
import { debounceWrapper } from '../../utils/debounce';
import { ApiError } from '../../api/client/errors';

import { useDocumentsLoader } from '../../hooks/documentsLoader';
import { useAlerts } from '../../hooks/noty';
import { useOnce } from '../../hooks/lifecycle';
import { useUrl } from '../../hooks/url';

import { SortProvider } from '../../providers/SortProvider';

import { PaymentHistoryFilters } from '../../components/PaymentHistoryFilters/PaymentHistoryFilters';
import { PaymentHistoryList } from '../../components/PaymentHistoryList/PaymentHistoryList';
import { LoadingFixedCenter } from '../../components/LoadingFixedCenter';
import { PageSizeSelect } from '../../components/PageSizeSelect/PageSizeSelect';
import { LoadingButton } from '../../components/LoadingButton';
import { FetchError } from '../../components/FetchError';
import { Drawer } from '../../components/Drawer/Drawer';

import iconExcelFit from '../../assets/file-icons/excel-fit.svg';
import './PaymentHistory.scss';

const mapState = makeMapState((state) => ({
    paymentsSorts: state.sorts.payments,
    pageSize: state.settings.pageSizeConfig.payments,
    documentsStorage: state.documents.items,
}));
const mapDispatch = makeMapDispatch({
    getPaymentHistoryList: apiGetPaymentHistoryList,
    setPageSize: setPaymentsListPageSize,
});

type PaymentHistoryProps = ReturnType<typeof mapState> & ReturnType<typeof mapDispatch>;
const PaymentHistory: FunctionComponent<PaymentHistoryProps> = (props) => {
    const { getPaymentHistoryList, paymentsSorts, pageSize, setPageSize, documentsStorage } = props;

    const { handleApiError } = useAlerts();
    const { downloadPaymentsDocument } = useDocumentsLoader();
    const { paginationPageParamValue, changePaginationPageUrlParam } = useUrl();

    const [list, setList] = useState<PaymentHistoryListItem[]>([]);
    const [total, setTotal] = useState(0);
    const [error, setError] = useState<ApiError | null>(null);
    const [loading, setLoading] = useState(false);
    const [pageCount, setPageCount] = useState(0);
    const [currentPage, setCurrentPage] = useState(paginationPageParamValue);
    const [isInitialized, setInitialized] = useState(false);
    const [filtersPayload, setFiltersPayload] = useState<PaymentHistoryFiltersPayload>({});

    const [filtersState, setFiltersState] = useState<PaymentHistoryListFilters>({
        period: { from: null, to: null },
        amount: { from: null, to: null },
        payer: [],
        currency_id: [],
    });

    const collectPayload = (page: number = currentPage): Protocol.PaymentHistoryRequest => ({
        page,
        page_size: pageSize,
        sorting: paymentsSorts,
        ...filtersPayload,
    });

    const fetchHistory = useCallback(
        debounceWrapper((page: number = currentPage) => {
            setError(null);
            setLoading(true);
            return getPaymentHistoryList(collectPayload(page))
                .then(({ items, filters, ...paginator }) => {
                    setList(items);
                    setTotal(paginator.total);
                    setFiltersState(filters);
                    setPageCount(foldPageCount(paginator));
                })
                .catch((e) => handleApiError(e, setError))
                .finally(() => setLoading(false));
        }),
        [currentPage, pageSize, filtersPayload, paymentsSorts]
    );

    useOnce(() => fetchHistory().then(() => setInitialized(true)));
    useEffect(() => {
        if (isInitialized) fetchHistory();
    }, [filtersPayload, paymentsSorts]); // eslint-disable-line react-hooks/exhaustive-deps
    useEffect(() => {
        if (!isInitialized) return;

        const page = Math.min(currentPage, foldPageCount({ total, page_size: Number(pageSize) }));
        if (page < currentPage) {
            setCurrentPage(page);
            changePaginationPageUrlParam(page);
        }

        fetchHistory(page);
    }, [pageSize]); // eslint-disable-line react-hooks/exhaustive-deps

    const viewState = foldView(list, loading, error);

    return (
        <SortProvider>
            <div className="App PaymentHistory">
                <LoadingFixedCenter visible={loading} />
                <div className="App__container">
                    <div className="PaymentHistory__grid">
                        <div className="PaymentHistory__filters">
                            <PaymentHistoryFilters
                                filterDefaults={filtersState}
                                filterValues={filtersPayload}
                                onChange={setFiltersPayload}
                                disabled={error !== null || loading}
                            />
                        </div>

                        <div className="PaymentHistory__filters PaymentHistory__filters--mobile">
                            <Drawer
                                button={{
                                    className: 'PaymentHistory__filtersButton',
                                    size: 'medium',
                                    text: 'Фильтры',
                                    variant: 'outlined',
                                    startIcon: <Sort />,
                                }}
                                isLarge
                            >
                                <PaymentHistoryFilters
                                    filterDefaults={filtersState}
                                    filterValues={filtersPayload}
                                    onChange={setFiltersPayload}
                                    disabled={error !== null || loading}
                                    vertical
                                />
                            </Drawer>
                        </div>

                        <LoadingButton
                            variant="text"
                            color="primary"
                            size="large"
                            className="PaymentHistory__button"
                            loading={Boolean(documentsStorage.find((item) => item.key === 'payment_document'))}
                            disabled={list.length === 0}
                            onClick={() => {
                                if (list.length === 0) return;

                                const payload = collectPayload();
                                downloadPaymentsDocument(payload, {
                                    payload,
                                    key: 'payment_document',
                                    type: 'payment_document',
                                });
                            }}
                        >
                            <img alt="Excel" src={iconExcelFit} style={{ marginRight: 8 }} />
                            <span>Скачать</span>
                        </LoadingButton>
                    </div>
                    {viewState(
                        () => (
                            <div className="App__centerLoader">Данных не найдено, попробуйте изменить фильтры</div>
                        ),
                        () => null,
                        (apiError) => (
                            <FetchError title={apiError.getCommonFirstMessage()} onRetry={() => fetchHistory()} />
                        ),
                        (data) => (
                            <div className="PaymentHistory__list">
                                <PaymentHistoryList items={data} />
                            </div>
                        )
                    )}

                    {isInitialized && pageCount > 1 && (
                        <div className="PaymentHistory__footer">
                            <PageSizeSelect
                                value={pageSize}
                                options={PaginatorPageSizes}
                                onChange={flow(setPageSize, scrollToTop)}
                            />
                            <div className="PaymentHistory__pagination">
                                <Pagination
                                    shape="rounded"
                                    page={currentPage}
                                    count={pageCount}
                                    disabled={loading}
                                    onChange={(evt, page) => {
                                        changePaginationPageUrlParam(page);
                                        setCurrentPage(page);
                                        fetchHistory(page).finally(scrollToTop);
                                    }}
                                />
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </SortProvider>
    );
};

const component = withRouter(pipe(PaymentHistory, connect(mapState, mapDispatch)));
export { component as PaymentHistory };

import React, { FunctionComponent, useEffect, useState } from 'react';
import clsx from 'clsx';

import { Button, Tooltip } from '@material-ui/core';

import { Iso } from 'monocle-ts';

import { eqBoolean, eqNumber, eqStrict, eqString, getStructEq } from 'fp-ts/Eq';
import { getEq, reduce as reduceArray } from 'fp-ts/Array';
import { pipe } from 'fp-ts/function';
import * as O from 'fp-ts/Option';

import { CurrenciesPayloadType, eqPartialCombinator, PayersPayloadType } from '../../utils/types';
import { DocumentsStatusIso } from '../../utils/isomorph';
import { useStateCallback } from '../../hooks/lifecycle';
import { DocumentsStatus } from '../../types/documents';

import type { BillsFiltersPayload, BillsListFilters, DebtInfoState } from '../../store/bills/types';

import { ChipData, Chips } from './Components/Chips';
import { PayersPicker } from '../PayersPicker';
import { DocumentStatusChanger, DocumentStatusChangerData } from '../DocumentStatusChanger/DocumentStatusChanger';

import { LoadingButton } from '../LoadingButton';

import { ReactComponent as IconExcelFit } from '../../assets/file-icons/file.svg';
import { ReactComponent as IconNotification } from '../../assets/file-icons/notification-default.svg';
import { ReactComponent as IconNotificationHasChanges } from '../../assets/file-icons/notification-has-changes.svg';

import './BillsFilters.scss';
import { foldFilterCount, foldFiltersCount } from '../../shared/filterLogic';
import { CurrenciesPicker } from '../CurrencyPicker';

type BillsChipsKeys = 'is_payment_required' | 'is_payment_delayed' | 'has_debt' | 'has_overpayment';
type BillsChipData = ChipData<BillsChipsKeys>[];

type FlagsPayload = Pick<BillsFiltersPayload, BillsChipsKeys>;
type FlagsValues = BillsChipsKeys[];

const FlagsPayloadIso = new Iso<FlagsValues, FlagsPayload>(
    reduceArray({} as FlagsPayload, (acc, key) => ({ ...acc, [key]: true })),
    (p) => Object.keys(p) as FlagsValues
);

// eslint-disable-next-line @typescript-eslint/camelcase
const getActiveFlagsFromPayload = ({ payer, is_archived, ...flags }: BillsFiltersPayload): FlagsValues =>
    FlagsPayloadIso.from(flags);

const getPayersFromPayload = (filterValues: BillsFiltersPayload): PayersPayloadType =>
    pipe(
        O.fromNullable(filterValues.payer),
        O.getOrElse(() => [] as PayersPayloadType)
    );

const getCurrenciesFromPayload = (filterValues: BillsFiltersPayload): CurrenciesPayloadType =>
    pipe(
        O.fromNullable(filterValues.currency_id),
        O.getOrElse(() => [] as CurrenciesPayloadType)
    );

const eqBillsPayload = getStructEq<BillsFiltersPayload>({
    currency_id: eqPartialCombinator(getEq(eqNumber)),
    payer: eqPartialCombinator(getEq(eqString)),
    is_archived: eqPartialCombinator(eqBoolean),
    is_payment_delayed: eqPartialCombinator(eqStrict),
    is_payment_required: eqPartialCombinator(eqStrict),
    has_debt: eqPartialCombinator(eqStrict),
    has_overpayment: eqPartialCombinator(eqStrict),
});

type BillsFiltersProps = {
    filterValues: BillsFiltersPayload;
    filterDefaults: BillsListFilters;
    onChange: (payload: BillsFiltersPayload) => void;
    onDownload: () => void;
    isDownloading: boolean;
    hasData: boolean;
    debtInfoState: DebtInfoState;
    showDebtInfo: () => void;
    vertical?: boolean;
    documentStatus: DocumentsStatus;
    setDocumentStatus: (status: DocumentsStatus) => void;
};

export const BillsFilters: FunctionComponent<BillsFiltersProps> = (props) => {
    const {
        filterDefaults,
        filterValues,
        onChange,
        onDownload,
        hasData,
        isDownloading,
        debtInfoState,
        showDebtInfo,
        documentStatus,
        setDocumentStatus,
        vertical = false,
    } = props;

    const archivedOptions: DocumentStatusChangerData[] = [
        {
            key: DocumentsStatus.All,
            label: 'Все',
            possibleCount: foldFiltersCount(filterDefaults.is_archived, null),
        },
        {
            key: DocumentsStatus.Current,
            label: 'Текущие',
            possibleCount: foldFiltersCount(filterDefaults.is_archived, false),
        },
        {
            key: DocumentsStatus.Archived,
            label: 'Архив',
            possibleCount: foldFiltersCount(filterDefaults.is_archived, true),
        },
    ];

    const chipsData: BillsChipData = [
        { key: 'is_payment_required', label: 'К оплате', count: foldFilterCount(filterDefaults.is_payment_required) },
        { key: 'is_payment_delayed', label: 'Просрочен', count: foldFilterCount(filterDefaults.is_payment_delayed) },
        { key: 'has_debt', label: 'Недоплата', count: foldFilterCount(filterDefaults.has_debt) },
        { key: 'has_overpayment', label: 'Переплата', count: foldFilterCount(filterDefaults.has_overpayment) },
    ];

    const [flags, setFlags] = useState(getActiveFlagsFromPayload(filterValues));
    const [payers, setPayers] = useStateCallback(getPayersFromPayload(filterValues));
    const [currencies, setCurrencies] = useStateCallback(getCurrenciesFromPayload(filterValues));

    const collectAndReceivePayload = (actualPayers?: PayersPayloadType, actualCurrencies?: CurrenciesPayloadType): void => {
        const payload: BillsFiltersPayload = { ...FlagsPayloadIso.to(flags) };

        const documentsStatusValue = DocumentsStatusIso.from(documentStatus);
        if (documentsStatusValue !== null) payload.is_archived = documentsStatusValue;

        const currentPayers = actualPayers || payers;
        if (currentPayers.length > 0) payload.payer = currentPayers;

        const currentCurrencies = actualCurrencies || currencies;
        if (currentCurrencies.length > 0) payload.currency_id = currentCurrencies;
        
        if (!eqBillsPayload.equals(filterValues, payload)) onChange(payload);
    };

    const onPayersDelete = (): void => {
        if (payers.length > 0) setPayers([], collectAndReceivePayload);
    };

    const onCurrenciesDelete = (): void => {
        if (currencies.length > 0) setCurrencies([], () => collectAndReceivePayload(payers, []));
    };

    useEffect(collectAndReceivePayload, [flags]);

    return (
        <div className={clsx('BillsFilters', vertical && 'BillsFilters--vertical')}>
            <div className="BillsFilters__radios">
                <DocumentStatusChanger
                    data={archivedOptions}
                    isVertical={vertical}
                    selectedValue={documentStatus}
                    onChange={(status) => {
                        setFlags([]);
                        setDocumentStatus(status);
                    }}
                />
            </div>

            <div className={clsx('BillsFilters__chips', vertical && 'BillsFilters--vertical')}>
                <CurrenciesPicker
                    currencies={currencies}
                    setCurrencies={setCurrencies}
                    currenciesList={filterDefaults.currency_id}
                    onClose={collectAndReceivePayload}
                    onDelete={onCurrenciesDelete}
                    disabled={false}
                />
                <PayersPicker
                    payers={payers}
                    setPayers={setPayers}
                    payersList={filterDefaults.payer}
                    onClose={collectAndReceivePayload}
                    onDelete={onPayersDelete}
                    disabled={false}
                />
                <Chips vertical={vertical} chipData={chipsData} onChange={setFlags} activeFlags={flags} />
            </div>

            <div className="BillsFilters__buttons">
                <Button
                    variant="text"
                    color="primary"
                    onClick={showDebtInfo}
                    disabled={debtInfoState === 'unknown'}
                    className='BillsFilters__notificationButton'
                >
                    {debtInfoState === 'hasChanges' ? <IconNotificationHasChanges /> : <IconNotification/>}
                </Button>

                <Tooltip
                    disableFocusListener={hasData}
                    disableHoverListener={hasData}
                    disableTouchListener={hasData}
                    placement="left"
                    title={
                        <div className="App__basicTooltip">
                            Для выбранного набора фильтров невозможно сформировать единый документ
                        </div>
                    }
                >
                    <span>
                        <LoadingButton
                            variant="text"
                            color="primary"
                            loading={isDownloading}
                            disabled={!hasData}
                            onClick={onDownload}
                            className='BillsFilters__documentButton'
                        >
                            <IconExcelFit />
                        </LoadingButton>
                    </span>
                </Tooltip>
            </div>
        </div>
    );
};

// ----- Types ------
import type { OrderListFilters } from '../../types/order';
import type { SortingData } from '../../utils/types';
import type { CountsList } from '../../shared/filterLogic';

export type OrderInfoType = {
    id: number;
    name: string;
    is_groupage_cargo: boolean;
};

export type OrderInfoEmbargo = {
    is_prohibited: boolean;
    is_not_released: boolean;
};

export type OrderInfo = {
    id: number;
    number: string;
    placed_at: Protocol.Types.ApiDatetime;
    changed_at: Protocol.Types.ApiDatetime | null;
    type: OrderInfoType;
    is_archived: boolean;
    embargo: OrderInfoEmbargo;
    manager_comment: string | null;
    customer_comment: string | null;
    document_count: number;
    container_groups: OrderContainerGroups<Container>[];
    consolidation_status: {
        id: string;
        name: string;
    } | null;
    consolidation_updated_at: string|null
    consolidation_complete: boolean
    consolidation_row_count: number | null
    consolidation_shipper_ready_count: number | null
    consolidation_shipper_received_count: number | null
};

export type OrderContainerType = {
    id: number;
    name: string;
    size: string | null;
};

export type OrderContainerGroupsByType<T> = {
    group_type: 'by_type',
    type: string;
    containers: T[];
};

export type OrderContainerGroupsBySize<T> = {
    group_type: 'by_size',
    size: string;
    containers: T[];
};

export type OrderContainerGroups<T> = OrderContainerGroupsByType<T> | OrderContainerGroupsBySize<T>;

export type OrderListItem = OrderInfo & {
    latest_any_critical_event: ContainerCriticalEvent | null;
    latest_self_critical_event: ContainerCriticalEvent | null;
    earliest_status: ContainerStatus | null;
    latest_status: ContainerStatus | null;
    latest_event: ContainerEvent | null;
    bill_count: number;
    uses_non_default_notifications_settings_set: boolean;
};

export type ContainerCriticalEvent = {
    came_at: string;
    message: string;
    type: {
        id: string;
        name: string;
    };
};

export type CustomFieldType = 'one' | 'two' | 'three';
export type CustomFieldData = {
    is_visible: boolean;
    show_title: boolean;
    title: string | null;
    value: Protocol.Types.MultiTypedValue;
};

export type CustomFields = Record<CustomFieldType, CustomFieldData>;

export type ContainerStatus = {
    id: number;
    status_id: number;
    name: string;
    came_at: string;
    custom_fields: CustomFields;
    sea: {
        is_in_transit: boolean;
        is_in_port_loading: boolean;
        is_in_port_discharge: boolean;
    };
    rail_road: {
        is_location: boolean;
        is_on_station_loading: boolean;
        is_on_station_discharge: boolean;
        distance_total: number | null;
        distance_remain: number | null;
        current_station: string | null;
    };
    is_plan: boolean;
};

export type PageInfo = {
    is_external: boolean;
    url: string;
};

export type ContainerEvent = {
    id: number;
    name: string;
    value: Protocol.Types.MultiTypedValue;
    came_at: string;
    is_rail_location: boolean;
    station_name: string | null;
    is_plan: boolean;
    page?: PageInfo;
};

export type ContainerSea = {
    vessel: string | null;
    voyage: string | null;
    port_loading: string | null;
    port_discharge: string | null;
};

export type ContainerRailRoad = {
    rail_wagon: string | null;
    station_loading: string | null;
    station_discharge: string | null;
};

export type Container = {
    id: number;
    number: string | null;
    booking_number: string | null;
    destination: string | null;
    final_destination: string | null;
    latest_status: ContainerStatus | null;
    latest_event: ContainerEvent | null;
    latest_critical_event: ContainerCriticalEvent | null;
    sea: ContainerSea;
    rail_road: ContainerRailRoad;
    cargo_weight: number | null;
    cargo_quantity_seats: number | null;
    document_count: number;
    uses_non_default_notifications_settings_set: boolean;
    order: OrderInfo;
};

type PhotoSize = {
    url: string;
    width: number;
    height: number;
};

type Photo = { sizes: PhotoSize[] };

export type ManagerData = {
    full_name: string;
    phones: string[];
    email: string | null;
    phone_switch_internal_code: number | null;
    work_position: string | null;
    photo: Photo;
    timezone: string;
};

export type ManagerGroup = {
    name: string;
    name_single_in_ablative_case: string | null;
    name_plural_in_ablative_case: string | null;
    managers: ManagerData[]
}

export type ContainerStatusListItem = ContainerStatus & {
    events: ContainerEvent[];
};

export type ContainerHistoryStatus = Pick<ContainerStatus, 'id' | 'name' | 'came_at' | 'is_plan'>;
export type ContainerHistoryItem = {
    came_at: Protocol.Types.ApiDatetime;
    status: ContainerHistoryStatus;
    event: ContainerEvent | null;
    critical_event: ContainerCriticalEvent | null;
};

export type PlanRoutePoint = {
    title: string | null;
    value: string | null;
    show_title: boolean;
    position: number;
};

export type ConsolidationCellValue = {
    string: null | string;
    date: null | string;
    number: null | number;
}

export type ConsolidationCell = {
    column_key: string
    column_title: string
    value: ConsolidationCellValue
}

export type ConsolidationRow = {
    position: number;
    columns: ConsolidationCell[];
}

export type ConsolidationBase = {
    table_rows: ConsolidationRow[];
}

type TableSectionCell = {
    title: string
    value: ConsolidationCellValue
}

export type TableSection = {
    type: 'table';
    position: number;
    name: string;
    data_updated_at: string;
    value: TableSectionCell[][]
}

export type StructureSection = {
    type: 'structure';
    position: number;
    name: string;
    data_updated_at: string;
    value: TableSectionCell[]
}

export type StringSection = {
    type: 'string';
    position: number;
    name: string;
    data_updated_at: string;
    value: string | null
}

export type Section = StringSection | StructureSection | TableSection

type Consolidation = ConsolidationBase & {
    sections: Section[]
}

export type OrderContainer = {
    id: number;
    number: string | null;
    booking_number: string | null;
    destination: string | null;
    final_destination: string | null;
    latest_status: ContainerStatus | null;
    latest_event: ContainerEvent | null;
    latest_critical_event: ContainerCriticalEvent | null;
    sea: ContainerSea;
    rail_road: ContainerRailRoad;
    order: OrderInfo;
    type: OrderContainerType;
    cargo_weight: number | null;
    cargo_quantity_seats: number | null;
    transit_period_days: number | null;
    manager_comment: string | null;
    customer_comment: string | null;
    shipper: string | null;
    payer: string | null;
    notify: string | null;
    consignee: string | null;
    consignee_text: string | null;
    manager_groups: ManagerGroup[];
    statuses: ContainerStatusListItem[];
    history: ContainerHistoryItem[];
    plan_route_points: PlanRoutePoint[];
    document_count: number;
    consolidation: Consolidation;
    uses_non_default_notifications_settings_set: boolean;
};

export type OrderListFiltersValues = number | boolean | null | undefined;

export type OrderListSortBy = 'critical_event_came_at' | 'latest_event_came_at' | 'latest_status_position';
export type OrderListSortingData = SortingData<OrderListSortBy>;

// ----- State ------
export type OrdersList = PaginateLikeList<OrderListItem> & {
    filters: OrderListFilters;
};
export type ContainersList = Record<string, OrderContainer>;
export type LocalFiltersFlags = Record<'embargo' | 'archived' | 'has_critical_events', boolean | null>;
export type LocalFilters = {
    flags: LocalFiltersFlags;
    counts: CountsList;
    search: string | null;
};

export type OrdersState = {
    list: OrdersList;
    containers: ContainersList;
    localFilters: LocalFilters;
    openedCard: number;
    isMinifiedView: boolean;
};

export type UpdateCommentPayload = {
    kind: 'order' | 'container';
    text: string;
    id: number;
};

// ----- Actions ------
export const SET_ORDERS = 'SET_ORDERS';
export const SET_ORDERS_CONTAINER = 'SET_ORDERS_CONTAINER';
export const SET_FILTERS_FLAGS = 'SET_FILTERS_FLAGS';
export const SET_FILTERS_COUNTS = 'SET_FILTERS_COUNTS';
export const FLUSH_ORDER_CACHE = 'FLUSH_ORDER_CACHE';
export const UPDATE_COMMENT = 'UPDATE_COMMENT';
export const SET_OPENED_CARD = 'SET_OPENED_CARD';
export const SET_MINIFIED_VIEW = 'SET_MINIFIED_VIEW';

interface SetOrdersAction {
    type: typeof SET_ORDERS;
    payload: OrdersList;
}

interface SetOrdersContainerAction {
    type: typeof SET_ORDERS_CONTAINER;
    payload: OrderContainer;
}

interface SetFiltersFlagsAction {
    type: typeof SET_FILTERS_FLAGS;
    payload: LocalFiltersFlags;
}

interface SetFiltersCountsAction {
    type: typeof SET_FILTERS_COUNTS;
    payload: CountsList;
}

interface FlushOrderCacheAction {
    type: typeof FLUSH_ORDER_CACHE;
}

interface UpdateCommentAction {
    type: typeof UPDATE_COMMENT;
    payload: UpdateCommentPayload;
}

interface SetOpenedCardAction {
    type: typeof SET_OPENED_CARD;
    payload: number;
}

interface SetMinifiedViewAction {
    type: typeof SET_MINIFIED_VIEW;
    payload: boolean;
}

export type OrdersActionTypes =
    | SetOrdersAction
    | SetOrdersContainerAction
    | SetFiltersFlagsAction
    | SetFiltersCountsAction
    | FlushOrderCacheAction
    | UpdateCommentAction
    | SetOpenedCardAction
    | SetMinifiedViewAction;

import React, { FC, useEffect, useState } from 'react';

import { Eq as eqString } from 'fp-ts/string';
import { Eq as eqNumber } from 'fp-ts/number';
import { pipe } from 'fp-ts/function';
import * as A from 'fp-ts/Array';
import * as O from 'fp-ts/Option';

import { ManagerData, ManagerGroup } from '../../store/orders/types';

import { SIPProvider } from '../../providers/SIPProvider';

import { ManagersPhones } from '../MangerCard/CallModal';
import { ManagerCard } from '../MangerCard/ManagerCard';

import './ManagersGroup.scss';

const mergePhones = (i1: number[], i2: number[], e1: string[], e2: string[]): ManagersPhones => ({
    internal: A.union(eqNumber)(i1, i2),
    external: A.union(eqString)(e1, e2),
});

const mapPhones = (dataList: ManagerData[], name: string): ManagersPhones =>
    pipe(
        dataList,
        A.reduce({ internal: [], external: [] } as ManagersPhones, (acc, item) => {
            const internal = item.phone_switch_internal_code ? [item.phone_switch_internal_code] : [];
            const external = item.phones;

            return name === item.full_name
                ? mergePhones(internal, acc.internal, external, acc.external)
                : mergePhones(acc.internal, internal, acc.external, external);
        })
    );

type ManagersGroupProps = {
    orderNumber: string;
    groups: ManagerGroup[];
    visible?: boolean;
    onCallModalOpen?: () => void;
    onCallModalClose?: () => void;
};
export const ManagersGroup: FC<ManagersGroupProps> = (props) => {
    const { orderNumber, groups, onCallModalClose, onCallModalOpen, visible } = props;

    const [currentDepth, setDepth] = useState(0);
    const incrementDepth = (): void => {
        if (currentDepth < groups.length) setDepth(currentDepth + 1);
    };

    useEffect(() => {
        if (typeof visible === 'undefined' || visible) return;
        setDepth(0);
    }, [visible]);

    const normalizedDepth = currentDepth + 1;

    let nextStackRow: JSX.Element | null = null;
    const nextGroupInfo = pipe(groups, A.lookup(normalizedDepth));
    if (O.isSome(nextGroupInfo)) {
        const ablativeName =
            nextGroupInfo.value.managers.length > 1
                ? nextGroupInfo.value.name_plural_in_ablative_case
                : nextGroupInfo.value.name_single_in_ablative_case;

        nextStackRow = (
            <div className="ManagersGroup__next">
                Не получилось решить вопрос? Можно{' '}
                <button type="button" className="ManagersGroup__nextButton" onClick={incrementDepth}>
                    связаться с {ablativeName || nextGroupInfo.value.name}
                </button>
            </div>
        );
    }

    const managers = pipe(
        groups,
        A.takeLeft(normalizedDepth),
        A.mapWithIndex(
            (index, group: ManagerGroup): JSX.Element => (
                <React.Fragment key={`group-${index}`}>
                    {group.managers.map((manager) => (
                        <ManagerCard
                            key={`group-${index}manager-${manager.full_name}`}
                            person={manager}
                            phones={mapPhones(group.managers, manager.full_name)}
                            orderNumber={orderNumber}
                            onCallModalClose={onCallModalClose}
                            onCallModalOpen={onCallModalOpen}
                        />
                    ))}
                </React.Fragment>
            )
        )
    );

    return (
        <SIPProvider>
            <>
                {managers}
                {nextStackRow}
            </>
        </SIPProvider>
    );
};

import { PersistConfig } from 'redux-persist/es/types';

import { fromNullable, toNullable } from 'fp-ts/Option';
import { flow, identity } from 'fp-ts/function';
import { Semigroup } from 'fp-ts/Semigroup';
import { Eq } from 'fp-ts/Eq';

export const toTuple = <A>(a: A): [A, A] => [a, a];
export const optional = <A, B>(condition: boolean, f: (a: A) => B): ((a: A) => A | B) => (condition ? f : identity);
export const castToNullable = flow(fromNullable, toNullable);

export const tap: <A>(fa: (a: A) => void) => (a: A) => A = (fa) => (a) => {
    fa(a);
    return a;
};

export const mapNullable = <A, B>(f: (a: A) => B) => (value: A | null | undefined): B | null =>
    value === null || value === undefined ? null : f(value);

export const eqPartialCombinator = <A>(eq: Eq<A>): Eq<A | undefined> => ({
    equals: (x, y) => {
        if (x && y) return eq.equals(x, y);
        return x === y;
    },
});

export const getLastSemigroup = <A>(): Semigroup<A> => ({ concat: (x, y) => y });

declare namespace Tag {
    const OpaqueTagSymbol: unique symbol;
    class OpaqueTag<S extends symbol> {
        private [OpaqueTagSymbol]: S;
    }

    export type OpaqueType<T, S extends symbol> = T & OpaqueTag<S>;
}

declare const KopecksTag: unique symbol;
declare const DateTag: unique symbol;
declare const ISO8601Tag: unique symbol;
declare const RFC3339Tag: unique symbol;
declare const SecondsTag: unique symbol;
declare const MinutesTag: unique symbol;

declare global {
    type Opaque<T, S extends symbol> = Tag.OpaqueType<T, S>;
    type SelectOption<T> = { value: T; label: string };
    type KindOf<S, T, K extends S> = T & { kind: K };
    type KindOfWrapper<S, K extends string> = { kind: K; payload: S };
    type AtLeastOne<T, Keys extends keyof T = keyof T> = Partial<T> & { [K in Keys]: Required<Pick<T, K>> }[Keys];
    type PartialLike<T, Keys extends keyof T = keyof T> = Partial<T> & Omit<T, Keys>;
    type InverseOptional<T> = {
        [K in keyof T]-?: T[K] extends T[K] | undefined
            ? T[K] extends (infer R)[] | undefined
                ? R[]
                : T[K] | null
            : T[K];
    };
    type Paginator = { total: number; page_size: number; page: number };
    type PaginatorPayload = { page?: number; page_size?: PaginatorPageSize };
    type SortingPayload<T> = { sorting?: T };
    type List<T> = { items: T[] };
    type PaginateLikeList<V> = Paginator & List<V>;
    type PaginateLikeCacheList<V, K extends string = string> = Paginator & { items: Record<K, V[]> };
    type PaginateLikeListWithFilters<I, F> = PaginateLikeList<I> & { filters: F };
    type Nullable<T, K extends keyof T = keyof T> = { [Key in keyof T]: Key extends K ? T[Key] | null : T[Key] };
    type NonNullableRecord<T extends Record<string, unknown>> = { [K in keyof T]: NonNullable<T[K]> };
    type EmptyObject = Record<any, never>;
    type ApiEnum<T> = { id: T; name: string };
    type Kopecks = Opaque<number, typeof KopecksTag>;
    namespace Time {
        type Date = Opaque<string, typeof DateTag>;
        type ISO8601 = Opaque<string, typeof ISO8601Tag>;
        type RFC3339 = Opaque<string, typeof RFC3339Tag>;
        type Seconds = Opaque<number, typeof SecondsTag>;
        type Minutes = Opaque<number, typeof MinutesTag>;
    }
}

type AnyRecord = Record<string, any>;
type PersistConfigChunk<S> = Omit<PersistConfig<S>, 'blacklist' | 'whitelist'>;
type PersistConfigOverride<KS> = { blacklist?: KS[]; whitelist?: KS[] };

export type SafePersistConfig<S extends AnyRecord, KS = keyof S> = PersistConfigChunk<S> & PersistConfigOverride<KS>;

export type SelectProps<T> = { value: T; options: T[]; onChange: (value: T) => void };
export type SelectPropsGenerator<T extends AnyRecord, Keys extends keyof T = keyof T> = {
    [K in Keys]: SelectProps<T[K]>;
}[Keys];

export type PaginatorPageSize = '10' | '20' | '50';
export type PageSizeSelectProps = SelectProps<PaginatorPageSize>;
export type SizeTypeMap = { orderPage: PaginatorPageSize };

export type SortDirection = 'asc' | 'desc';
export type SortingData<K extends string> = { by: K; direction: SortDirection };

export type ExtractKeysByType<T, K> = { [Key in keyof T]: T[Key] extends K ? Key : never }[keyof T];

export type FilterType = { total: number };
export type ValuableFilterType<T> = FilterType & { value: T };

export type InfoFilterType = FilterType;
export type StringFilterType = ValuableFilterType<string>;
export type NumberFilterType = ValuableFilterType<number>;
export type BooleanFilterType = ValuableFilterType<boolean | null>;

export type ValuableFilters = StringFilterType | NumberFilterType | BooleanFilterType;
export type AllFiltersTypes = InfoFilterType | ValuableFilters;

export type PayersPayloadType = string[];
export type CurrenciesPayloadType = number[];

import { FormContextValues, ManualFieldError, FieldName } from 'react-hook-form';
import { useSnackbar } from 'notistack';
import { ApiError } from '../api/client/errors';

type SchemaSignature = { [key: string]: unknown };
type ErrorSetter<T extends SchemaSignature> = FormContextValues<T>['setError'];
type ApiErrorCallback = (apiError: ApiError) => void;

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const useAlerts = <Schema extends SchemaSignature>(errorSetter: ErrorSetter<Schema> | null = null) => {
    const { enqueueSnackbar: showNotification } = useSnackbar();

    const handleApiError = (error: unknown, callback: ApiErrorCallback = () => {}): void => {
        if (error instanceof ApiError) {
            const commonError = error.getCommonFirstMessage();
            if (commonError) showNotification(commonError, { variant: 'warning' });
            if (errorSetter) errorSetter(error.getInvalidFields<FieldName<Schema>>() as ManualFieldError<Schema>[]);
            callback(error);
        }
    };

    return { showNotification, handleApiError };
};

import React, { FunctionComponent } from 'react';
import clsx from 'clsx';

import { SortButton } from '../../SortButton/SortButton';
import { useSorts } from '../../../hooks/sorts';

type SortHeaderProps = {};
export const SortHeader: FunctionComponent<SortHeaderProps> = (props) => {
    const { sorts, handleChange } = useSorts('payments');

    return (
        <div className="PaymentHistoryList__grid">
            <div
                className={clsx([
                    'PaymentHistoryList__sortButton',
                    'PaymentHistoryList__column',
                    'PaymentHistoryList__column--nowrap',
                ])}
            >
                <SortButton
                    label="Дата документа"
                    order={sorts.direction}
                    active={sorts.by === 'date'}
                    clickHandler={() => handleChange('date')}
                />
            </div>
            <div
                className={clsx([
                    'PaymentHistoryList__sortButton',
                    'PaymentHistoryList__column',
                    'PaymentHistoryList__column--nowrap',
                ])}
            >
                <SortButton
                    label="Плательщик"
                    order={sorts.direction}
                    active={sorts.by === 'payer'}
                    clickHandler={() => handleChange('payer')}
                />
            </div>
            <div
                className={clsx([
                    'PaymentHistoryList__staticLabel',
                    'PaymentHistoryList__column',
                    'PaymentHistoryList__column--nowrap',
                ])}
            >
                Назначение платежа
            </div>
            <div
                className={clsx([
                    'PaymentHistoryList__sortButton',
                    'PaymentHistoryList__column',
                    'PaymentHistoryList__column--nowrap',
                ])}
            >
                <SortButton
                    label="Сумма"
                    order={sorts.direction}
                    active={sorts.by === 'amount'}
                    clickHandler={() => handleChange('amount')}
                />
            </div>
        </div>
    );
};

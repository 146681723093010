import React, { createContext, useState, useEffect, FunctionComponent } from 'react';
import { connect } from 'react-redux';

import { toNullable } from 'fp-ts/Option';
import { lookup } from 'fp-ts/Record';
import { pipe } from 'fp-ts/function';

import { apiPageData, makeMapDispatch } from '../store/dispatch';
import { makeMapState } from '../store/root';
import { PageDetails } from '../store/pages/types';

import { ApiError } from '../api/client/errors';

type CurrentPageData = PageDetails | null;
type SetOpenState = (value: boolean) => void;
type FetchPage = (url: string) => Promise<void>;

export type PagesProviderContext = {
    error: ApiError | null;
    isOpen: boolean;
    setOpen: SetOpenState;
    fetching: boolean;
    fetchPage: FetchPage;
    currentPageData: CurrentPageData;
};

const mapStateToProps = makeMapState((state) => ({ storeCache: state.pages }));
const mapDispatchToProps = makeMapDispatch({ apiFetchPage: apiPageData });

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
export const PagesContext = createContext<PagesProviderContext>(null!);

type PagesProviderProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;
const PagesProvider: FunctionComponent<PagesProviderProps> = (props) => {
    const { storeCache, apiFetchPage, children } = props;

    const [error, setError] = useState<ApiError | null>(null);
    const [isOpen, setOpen] = useState(false);
    const [fetching, setFetching] = useState(false);
    const [currentPageData, setCurrentPageData] = useState<CurrentPageData>(null);

    const fetchPage: FetchPage = async (url) => {
        setOpen(true);

        const cachedPage = pipe(storeCache, lookup(url), toNullable);
        if (cachedPage) {
            setCurrentPageData(cachedPage);
            return;
        }

        setError(null);
        setFetching(true);
        try {
            const pageResponse = await apiFetchPage({ url });
            setCurrentPageData(pageResponse);
        } catch (e) {
            setError(e as ApiError);
        } finally {
            setFetching(false);
        }
    };

    useEffect(() => {
        if (!isOpen) setCurrentPageData(null);
    }, [isOpen]);

    return (
        <PagesContext.Provider value={{ error, isOpen, setOpen, fetching, fetchPage, currentPageData }}>
            {children}
        </PagesContext.Provider>
    );
};

const Provider = connect(mapStateToProps, mapDispatchToProps)(PagesProvider);
export { Provider as PagesProvider };

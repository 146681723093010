import React, { FunctionComponent, useState } from 'react';
import clsx from 'clsx';

import { ExpansionPanel, ExpansionPanelDetails, ExpansionPanelSummary } from '@material-ui/core';
import { ExpandMore } from '@material-ui/icons';

import { ExpandableFilterGroup } from '../ExpandableFilterGroup/ExpandableFilterGroup';

import { ActionType, ChipData } from '../../shared/filterLogic';
import { OrderListFilters } from '../../types/order';
import { ReportDetailsFiltersAndSorting } from '../../types/order-reports';

type ExpandableOrderTypesPanelProps = {
    data: ChipData[];
    counts: OrderListFilters;
    loading: boolean;
    filters: ReportDetailsFiltersAndSorting;
    onChange: (type: ActionType) => (id: number) => void;
    isReportsAdmin: boolean;
};

export const ExpandableOrderTypesPanel: FunctionComponent<ExpandableOrderTypesPanelProps> = (props) => {
    const { data, counts, loading, filters, onChange, isReportsAdmin } = props;

    const [isTypesOpen, setTypesOpen] = useState(true);

    return (
        <ExpansionPanel
            className={clsx('DownloadSettings__card', 'DownloadSettings__card--secondLevel')}
            expanded={isTypesOpen}
            onChange={() => setTypesOpen((value) => !value)}
        >
            <ExpansionPanelSummary
                className={clsx(
                    'DownloadSettings__summary',
                    'DownloadSettings__summary--secondLevel',
                    !isTypesOpen && 'DownloadSettings__summary--closed'
                )}
            >
                <div className="DownloadSettings__headingWrapper">
                    <h3 className={clsx('DownloadSettings__heading', 'DownloadSettings__heading--secondLevel')}>
                        Тип и статус заказа
                    </h3>
                    <ExpandMore
                        className={clsx(
                            'DownloadSettings__icon',
                            'DownloadSettings__icon--smallMargin',
                            isTypesOpen && 'DownloadSettings__icon--rounded'
                        )}
                    />
                </div>
            </ExpansionPanelSummary>

            <ExpansionPanelDetails
                className={clsx('DownloadSettings__details', 'DownloadSettings__details--secondLevel')}
            >
                {data.map((item) => (
                    <ExpandableFilterGroup
                        key={item.key}
                        data={item}
                        counts={counts}
                        loading={loading}
                        onChange={onChange}
                        isReportsAdmin={isReportsAdmin}
                        filters={filters}
                    />
                ))}
            </ExpansionPanelDetails>
        </ExpansionPanel>
    );
};

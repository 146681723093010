import { useEffect, useState } from 'react';

import { pipe } from 'fp-ts/function';
import * as R from 'fp-ts/Record';
import * as O from 'fp-ts/Option';

import { ApiError, CustomError } from '../api/client/errors';

export type RequestStateNotAsked = { kind: 'NotAsked' };
export type RequestStateFetching = { kind: 'Fetching' };
export type RequestStateFailure = { kind: 'Failure'; error: ApiError };
export type RequestStateSuccess<D> = { kind: 'Success'; data: D };

export type RequestState<D> =
    | RequestStateNotAsked
    | RequestStateFetching
    | RequestStateFailure
    | RequestStateSuccess<D>;

export const isNotAsked = <A>(state: RequestState<A>): state is RequestStateNotAsked => state.kind === 'NotAsked';
export const isFetching = <A>(state: RequestState<A>): state is RequestStateFetching => state.kind === 'Fetching';
export const isFailure = <A>(state: RequestState<A>): state is RequestStateFailure => state.kind === 'Failure';
export const isSuccess = <A>(state: RequestState<A>): state is RequestStateSuccess<A> => state.kind === 'Success';

export const constNotAsked = <A>(): RequestState<A> => ({ kind: 'NotAsked' });
export const constFetching = <A>(): RequestState<A> => ({ kind: 'Fetching' });
export const constFailure = <A>(error: ApiError): RequestState<A> => ({ kind: 'Failure', error });
export const constSuccess = <A>(data: A): RequestState<A> => ({ kind: 'Success', data });
export const constCustomFailure = <A>(message: string): RequestState<A> => ({
    kind: 'Failure',
    error: CustomError(message),
});

export const foldOption = <A>(state: RequestState<A>): O.Option<A> =>
    state.kind === 'Success' ? O.some(state.data) : O.none;

export const fold = <D extends unknown = unknown, R = unknown>(state: RequestState<D>) => (
    emptyNode: () => R,
    loadingNode: () => R,
    errorNode: (error: ApiError) => R,
    viewNode: (data: D) => R
): R => {
    switch (state.kind) {
        case 'Fetching':
            return loadingNode();
        case 'Success':
            return viewNode(state.data);
        case 'Failure':
            return errorNode(state.error);
        case 'NotAsked':
        default:
            return emptyNode();
    }
};

export const fold3 = <D extends unknown = unknown, R = unknown>(state: RequestState<D>) => (
    loadingNode: () => R,
    errorNode: (error: ApiError) => R,
    viewNode: (data: D) => R
): R => {
    switch (state.kind) {
        case 'Success':
            return viewNode(state.data);
        case 'Failure':
            return errorNode(state.error);
        case 'NotAsked':
        case 'Fetching':
        default:
            return loadingNode();
    }
};

export const mapRequestState = <A extends unknown = unknown, B extends unknown = unknown>(fab: (f: A) => B) => (
    state: RequestState<A>
): RequestState<B> => {
    if (isSuccess(state)) return { ...state, data: fab(state.data) };
    return state;
};

export function useStateInitializer<A>(state: RequestState<A>, f: () => unknown): void {
    const [needAsk, askState] = useState(true);
    useEffect(() => {
        if (isNotAsked(state) && needAsk) {
            askState(false);
            f();
        }
    }, [state, f, needAsk]);
}

export function buildSelector<A>(RSList: Record<string, RequestState<A>>): (selector: string) => RequestState<A> {
    return (selector) =>
        pipe(
            RSList,
            R.lookup(selector),
            O.getOrElse(() => constNotAsked<A>())
        );
}

import React, { FunctionComponent, ReactNode, useMemo } from 'react';
import { renderToStaticMarkup } from 'react-dom/server';

import { fold, fromNullable } from 'fp-ts/Option';
import { filter, mapWithIndex } from 'fp-ts/Array';
import { pipe } from 'fp-ts/function';

import { Card } from '@material-ui/core';

import { ContainerCriticalEvent, ContainerEvent, ContainerHistoryItem, OrderContainer } from '../../store/orders/types';

import { foldEventValue, formatDate } from '../../utils/view';

import './StatusHistoryTable.scss';
import { PageLink } from '../PageLink';
import { convertHTMLEntity } from '../../utils/strings';

const foldEvent = (nullableEvent: ContainerEvent | null): ReactNode =>
    pipe(
        fromNullable(nullableEvent),
        fold(
            () => null,
            (event) => {
                const value = foldEventValue(event);
                return (
                    <>
                        <h4 className="StatusHistoryTable__mobileHeading">Последнее событие</h4>
                        <div className="StatusHistoryTable__event">
                            <span className="StatusHistoryTable__eventName">{event.name}</span>
                            {!!value && !event.page && <span className="StatusHistoryTable__eventValue">{value}</span>}
                            {!!value && !!event.page && <PageLink label={value} info={event.page} />}
                        </div>
                    </>
                );
            }
        )
    );

const foldCriticalEvent = (nullableEvent: ContainerCriticalEvent | null): JSX.Element | null =>
    pipe(
        fromNullable(nullableEvent),
        fold(
            () => null,
            (event) => (
                <>
                    <h4 className="StatusHistoryTable__mobileHeading">Уведомление</h4>
                    {event.message}
                </>
            )
        )
    );

const mapStatusesIntoView = mapWithIndex<ContainerHistoryItem, JSX.Element>((index, item) => {
    const eventContent = foldEvent(item.event);
    const criticalEventContent = foldCriticalEvent(item.critical_event);
    return (
        <div className="StatusHistoryTable__row" key={index}>
            <div className="StatusHistoryTable__cell">{formatDate(item.came_at)}</div>
            <div className="StatusHistoryTable__cell">
                <h4 className="StatusHistoryTable__mobileHeading">Статус</h4>
                {item.status.name}
            </div>
            <div className="StatusHistoryTable__cell">{eventContent}</div>
            {criticalEventContent !== null && (
                <div
                    className="StatusHistoryTable__cell"
                    dangerouslySetInnerHTML={{
                        __html: convertHTMLEntity(renderToStaticMarkup(criticalEventContent)),
                    }}
                />
            )}
        </div>
    );
});

export const StatusHistoryTable: FunctionComponent<{
    container: OrderContainer;
}> = (props) => {
    const { container } = props;

    const history = useMemo(
        () =>
            pipe(
                container.history,
                filter((status) => status.event !== null || status.critical_event !== null), // TODO: remove in future
                mapStatusesIntoView
            ),
        [container]
    );
    return (
        <div>
            <h3 className="StatusHistoryTable__heading StatusHistoryTable__heading--mobile">История</h3>
            <Card className="StatusHistoryTableCard">
                <div className="StatusHistoryTable">
                    <div className="StatusHistoryTable__row StatusHistoryTable__header">
                        <h3 className="StatusHistoryTable__heading">История</h3>
                        <h4 className="StatusHistoryTable__heading">Статус</h4>
                        <h4 className="StatusHistoryTable__heading">Последнее событие</h4>
                        <h4 className="StatusHistoryTable__heading">Уведомление</h4>
                    </div>

                    <div className="StatusHistoryTable__body">{history}</div>
                </div>
            </Card>
        </div>
    );
};

import React, { FunctionComponent, ReactNode, useCallback, useMemo, useState } from 'react';
import clsx from 'clsx';

import { filter, last, map, mapWithIndex, reverse, takeRight } from 'fp-ts/Array';
import { toNullable } from 'fp-ts/Option';
import { bimap } from 'fp-ts/Tuple';
import { flow } from 'fp-ts/function';
import { pipe } from 'fp-ts/function';

import {
    Button,
    Card,
    CardContent,
    CardHeader,
    Fade,
    IconButton,
    Modal,
    Tooltip,
    Typography,
    WithStyles,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { withStyles } from '@material-ui/styles';

import { ContainerStatusListItem, OrderContainer } from '../../store/orders/types';

import { foldEventValue, formatDate, joinStrings } from '../../utils/view';
import { toTuple } from '../../utils/types';

import { styles } from './styles';

type ComponentProps = { container: OrderContainer };
type Props = ComponentProps & WithStyles<typeof styles>;
const RouteCard: FunctionComponent<Props> = (props) => {
    const { classes, container } = props;

    const [modalOpen, setModalOpen] = useState(false);

    const handleModalOpen = (): void => setModalOpen(true);
    const handleModalClose = (): void => setModalOpen(false);

    const foldStatusWithStyles = useCallback(
        (
            status: ContainerStatusListItem,
            isNotFirst: boolean,
            isActive: boolean,
            isVertical = false,
            isLast = false
        ): ReactNode => {
            const classList = [classes.item];
            if (isVertical) classList.push(classes.itemVertical);
            if (isNotFirst) classList.push(classes.itemNotFirst);
            if (isActive) classList.push(classes.itemActive);
            if (isLast) classList.push(classes.itemLast);

            const lastEvent = pipe(status.events, last, toNullable);

            let statusValue: ReactNode = null;
            if (status.rail_road.is_location && status.rail_road.distance_remain) {
                statusValue = (
                    <>
                        <span>Осталось {status.rail_road.distance_remain} км</span>
                        <br />
                        <span>{status.rail_road.current_station}</span>
                    </>
                );
            } else if (status.rail_road.is_on_station_loading) {
                statusValue = container.rail_road.station_loading;
            } else if (status.rail_road.is_on_station_discharge) {
                statusValue = container.rail_road.station_discharge;
            } else if (status.sea.is_in_transit) {
                statusValue = joinStrings(container.sea.vessel, container.sea.voyage);
            } else if (status.sea.is_in_port_loading) {
                statusValue = container.sea.port_loading;
            } else if (status.sea.is_in_port_discharge) {
                statusValue = container.sea.port_discharge;
            } else if (status.is_plan && lastEvent) {
                statusValue = foldEventValue(lastEvent);
            }

            const events = pipe(
                status.events,
                filter((event) => !event.is_rail_location && !event.is_plan),
                map((event) => {
                    const val = foldEventValue(event);
                    return (
                        <li key={`status-${status.id}-event-${event.id}`}>
                            {event.name}
                            {val !== null && ` — ${val}`}
                        </li>
                    );
                })
            );

            const statusName = status.is_plan ? lastEvent?.name : status.name;

            let statusDate = null;
            if (status.is_plan && lastEvent) {
                statusDate = foldEventValue(lastEvent);
            } else if (status.custom_fields.one.value.date) {
                statusDate = formatDate(status.custom_fields.one.value.date, false, 'YYYY.MM.DD');
            } else if (status.came_at) {
                statusDate = formatDate(status.came_at);
            }

            if (isVertical) {
                if (status.is_plan) classList.push(classes.itemPlanVertical);
                return (
                    <li className={clsx(classList)} key={`status-route-item-${status.id}`}>
                        {status.is_plan && <div className={`${classes.itemDots} ${classes.itemDotsOnVertical}`} />}
                        <p className={classes.itemValue}>
                            {statusName}
                            {!status.is_plan && ` — ${statusDate}`}
                        </p>
                        <p className={classes.itemLabel}>{statusValue}</p>
                        {!status.is_plan && <ul className={classes.verticalEventList}>{events}</ul>}
                    </li>
                );
            }

            if (status.is_plan) classList.push(classes.itemPlan, classes.itemLast);

            const content = (
                <div className={classes.itemWrap} key={`status-item-${status.id}`}>
                    <div className={clsx(classList)}>
                        {!status.is_plan && statusValue && (
                            <p className={clsx(classes.itemLabel, classes.itemPositionLabel)}>{statusValue}</p>
                        )}
                        {status.is_plan && <div className={classes.itemDots} />}

                        <p className={classes.itemValue}>{statusName}</p>
                        <p className={classes.itemLabel}>{statusDate}</p>
                    </div>
                </div>
            );

            return !status.is_plan && events.length ? (
                <Tooltip
                    key={`status-route-item-${status.id}`}
                    arrow={false}
                    placement="top"
                    title={<ul className={classes.tooltipContent}>{events}</ul>}
                >
                    {content}
                </Tooltip>
            ) : (
                content
            );
        },
        [classes, container]
    );

    const [fullRoute, routePreview] = useMemo(
        () =>
            pipe(
                toTuple(container.statuses),
                bimap(
                    flow(
                        takeRight(5),
                        mapWithIndex<ContainerStatusListItem, ReactNode>((index, status) =>
                            foldStatusWithStyles(
                                status,
                                (container.statuses.length > 5 && index === 0) || index > 0,
                                !status.is_plan,
                                false,
                                index === 4
                            )
                        )
                    ),
                    flow(
                        reverse,
                        mapWithIndex<ContainerStatusListItem, ReactNode>((index, status) =>
                            foldStatusWithStyles(status, false, !status.is_plan, true)
                        )
                    )
                )
            ),
        [container, foldStatusWithStyles]
    );

    return (
        <Card className={classes.body}>
            <CardHeader title="Статус доставки" className={classes.header} />
            <CardContent className={classes.content}>
                <div className={classes.items}>{routePreview}</div>
                <div className={classes.routeInfo}>
                    <Button className={classes.button} color="primary" onClick={handleModalOpen}>
                        Детализация
                    </Button>
                    {!!container.transit_period_days && (
                        <Typography color="secondary" gutterBottom>
                            Транзитное время: {container.transit_period_days} дн.
                        </Typography>
                    )}
                </div>
            </CardContent>

            <Modal
                open={modalOpen}
                onClose={handleModalClose}
                className={classes.modal}
                aria-labelledby="Статус доставки"
            >
                <Fade in={modalOpen}>
                    <div className={classes.modalBody}>
                        <IconButton className={classes.modalClose} onClick={handleModalClose}>
                            <CloseIcon />
                        </IconButton>

                        <Typography variant="h5" component="h3">
                            Статус доставки
                        </Typography>

                        <ul className={`${classes.items} ${classes.itemsVertical}`}>{fullRoute}</ul>
                    </div>
                </Fade>
            </Modal>
        </Card>
    );
};

const component = withStyles(styles)(RouteCard);
export { component as RouteCard };

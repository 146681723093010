import React, { FunctionComponent, ReactNode } from 'react';
import { LinearProgress } from '@material-ui/core';
import { Container, ContainerStatus } from '../../store/orders/types';
import { joinStrings } from '../../utils/view';

const foldStatusValue = (
    container: Container,
    status: ContainerStatus
): ReactNode => {
    let statusValue = null;
    if (status) {
        if (
            status.rail_road.is_location &&
            status.rail_road.distance_remain &&
            status.rail_road.distance_total
        ) {
            const percents = Math.ceil(
                ((status.rail_road.distance_total -
                    status.rail_road.distance_remain) /
                    status.rail_road.distance_total) *
                    100
            );
            statusValue = (
                <div className="ContainerRow__progress">
                    <div className="ContainerRow__progressLine">
                        <LinearProgress
                            style={{ borderRadius: '4px', flexGrow: 1 }}
                            color="secondary"
                            variant="determinate"
                            value={percents}
                        />
                        <span className="ContainerRow__progressLabel">
                            {status.rail_road.distance_remain} км
                        </span>
                    </div>
                    <span className="ContainerRow__progressStation">
                        {status.rail_road.current_station}
                    </span>
                </div>
            );
        } else if (status.rail_road.is_on_station_loading) {
            statusValue = joinStrings(
                container.rail_road.rail_wagon,
                container.rail_road.station_loading
            );
        } else if (status.rail_road.is_on_station_discharge) {
            statusValue = container.rail_road.station_discharge;
        } else if (status.sea.is_in_transit) {
            statusValue = joinStrings(
                container.sea.vessel,
                container.sea.voyage
            );
        } else if (status.sea.is_in_port_loading) {
            statusValue = container.sea.port_loading;
        } else if (status.sea.is_in_port_discharge) {
            statusValue = container.sea.port_discharge;
        }
    }
    return statusValue;
};

type StatusProps = { container: Container, isHeader?: boolean; };
export const Status: FunctionComponent<StatusProps> = (props) => {
    const { container, isHeader = false } = props;

    return (
        <div className={ `OrdersListRow__third OrdersListProduct__third ${ isHeader ? 'OrdersListHeader__third' : ''}`}>
            {container.latest_status !== null && (
                <>
                    <h3 className="OrdersList__mobileLabel SortButton">
                        Статус
                    </h3>
                    <p className="ContainerRow__statusLabel">
                        {container.latest_status.name}
                    </p>
                    <div className="ContainerRow__statusAddition">
                        {foldStatusValue(container, container.latest_status)}
                    </div>
                </>
            )}
        </div>
    );
};

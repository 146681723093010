import { Reducer } from 'redux';

import { DocumentsActionTypes, DocumentsState } from './types';

const initialState: DocumentsState = {
    items: [],
};

const baseReducer: Reducer<DocumentsState, DocumentsActionTypes> = (state = initialState, action) => {
    switch (action.type) {
        case 'ADD_DOCUMENT': {
            const document = state.items.find(item => item.key === action.data.key);

            return document ? state : {...state, items: [...state.items, action.data]};
        }
        case 'REMOVE_DOCUMENT': {
            return {...state, items: state.items.filter(item => item.key !== action.key)};
        }
        default:
            return state;
    }
};

//TODO: реализовать продолжение незавершенных загрузок из persist storage

export const documentsReducer = baseReducer;
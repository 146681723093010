import storage from 'redux-persist/lib/storage';
import { persistReducer } from 'redux-persist';
import { Reducer } from 'redux';

import { UserActionTypes, UserState } from './types';
import { SafePersistConfig } from '../../utils/types';

const initialState: UserState = {
    isAuthenticated: false,
    profile: null,
    redirectUrl: null,
};

const baseReducer: Reducer<UserState, UserActionTypes> = (state = initialState, action) => {
    switch (action.type) {
        case 'USER_LOGIN':
            return { ...state, isAuthenticated: true };
        case 'USER_LOGOUT':
            return { ...state, isAuthenticated: false, profile: null };
        case 'USER_SET_PROFILE':
            return { ...state, profile: action.payload };
        case 'USER_SET_REDIRECT_URL':
            return { ...state, redirectUrl: action.payload };
        default:
            return state;
    }
};

const persistConfig: SafePersistConfig<UserState> = {
    key: 'userData',
    storage,
    whitelist: ['isAuthenticated', 'redirectUrl'],
};

export const userReducer = persistReducer(persistConfig, baseReducer);

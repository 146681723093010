import React, { FunctionComponent, useEffect, useMemo, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { connect } from 'react-redux';

import CheckIcon from '@material-ui/icons/Check';
import { Button, Checkbox, CircularProgress } from '@material-ui/core';

import * as A from 'fp-ts/Array';
import * as R from 'fp-ts/Record';
import * as O from 'fp-ts/Option';
import { pipe, flow } from 'fp-ts/function';

import { makeMapState } from '../../store/root';
import { NotificationAvailableType } from '../../store/settings/types';
import { apiUpdateRecipient, makeMapDispatch } from '../../store/dispatch';

import {
    NotificationEntity,
    NotificationFormData,
    RecipientState,
    notificationEntityIso,
    DocumentTypesFormData,
    notificationDocumentsIso,
    PrintFormTypeTypes,
} from '../../utils/notifications';
import { groupByC } from '../../utils/collection';

import { useAlerts } from '../../hooks/noty';

import { OrderTypeRow } from './Components/OrderTypeRow';
import { DocumentTypeRow } from './Components/DocumentTypeRow';

import './NotificationSettingsTable.scss';

const MutualSettlementsReportSettingsCheckboxId = 'mutual-settlements-report';

const mapEntity = A.map<NotificationEntity, Protocol.UpdateRecipientEventData>((event) => ({
    id: Number(event.id),
    types: event.types,
}));

/** TODO: move to {@link ./src/utils/notifications.ts} */
const foldSettingsPayload = flow(
    notificationEntityIso.from,
    groupByC<NotificationEntity>((i) => i.order_type_id),
    R.collect(
        (key, list): Protocol.UpdateRecipientSettingsData => {
            const separated = A.partition<NotificationEntity>((event) => event.kind === 'task')(list);
            return {
                order_type_id: Number(key),
                events: mapEntity(separated.left),
                tasks: mapEntity(separated.right),
            };
        }
    )
);

export type NotificationSettingsFormData = Required<{
    events?: NotificationFormData;
    use_template_with_comments: boolean;
    document_types: DocumentTypesFormData;
}>;

type ComponentProps = {
    data: RecipientState;
    afterSave: (state: RecipientState[]) => void;
    containerId: number | null;
    containerOrderType: number | null;
    isCommentsStateChanged: boolean;
};

const mapState = makeMapState((state) => ({ settings: state.settings }));
const mapDispatch = makeMapDispatch({ updateRecipient: apiUpdateRecipient });
type Props = ReturnType<typeof mapState> & ReturnType<typeof mapDispatch> & ComponentProps;
const NotificationSettingsTableComponent: FunctionComponent<Props> = (props) => {
    const {
        settings,
        containerId,
        containerOrderType,
        data,
        updateRecipient,
        isCommentsStateChanged,
        afterSave,
    } = props;

    const firstMutualSettlementsReportSettings = pipe(data.mutual_settlements_report_settings, A.head, O.toNullable);

    const [isSaving, setSave] = useState(false);
    const [isDisabled, setDisabled] = useState(true);
    const [mutualSettlementsReportItems, setMutualSettlementsReportItems] = useState<PrintFormTypeTypes[]>(
        (firstMutualSettlementsReportSettings !== null
            ? firstMutualSettlementsReportSettings.filter((item) => item !== 'sms')
            : []) as PrintFormTypeTypes[]
    );

    const { watch, setValue } = useFormContext<NotificationSettingsFormData>();
    const { handleApiError, showNotification } = useAlerts();

    const value = watch({ nest: true });

    useEffect(() => {
        if (isCommentsStateChanged) setDisabled(false);
    }, [isCommentsStateChanged]);

    const onChange = (): void => {
        if (isDisabled) setDisabled(false);
    };

    const onChangeMutualSettlements = (key: NotificationAvailableType): void => {
        if (key === 'sms') return;

        setDisabled(false);

        setMutualSettlementsReportItems((currentItems) =>
            pipe(
                currentItems,
                A.findIndex((item) => item === key),
                O.fold(
                    () => [...currentItems, key],
                    (index) => A.unsafeDeleteAt(index, currentItems)
                )
            )
        );
    };

    const onSubmit = (): void => {
        if (!value) return;

        setSave(true);

        const payload: Protocol.UpdateRecipientRequest = {
            recipient_id: data.id,
            container_id: containerId,
            settings: value.events && foldSettingsPayload(value.events),
            use_template_with_comments: value.use_template_with_comments,
            documents_settings: value.document_types ? notificationDocumentsIso.from(value.document_types) : [],
            mutual_settlements_report_settings: {
                types: mutualSettlementsReportItems,
            },
        };

        updateRecipient(payload)
            .then((result) => {
                afterSave(result.items);
                showNotification('Настройки успешно изменены', { variant: 'success' });
            })
            .catch(handleApiError)
            .finally(() => {
                setSave(false);
                setDisabled(true);
            });
    };

    const orderTypes = useMemo(
        () =>
            settings.orderTypes.filter(
                (ot) => ot.statuses.length > 0 && (containerOrderType === null || ot.id === containerOrderType)
            ),
        [settings, containerOrderType]
    );

    const filteredOrderTypes = useMemo(
        () => orderTypes.filter((type) => settings.customerOrderTypeIds.includes(type.id)),
        [orderTypes] // eslint-disable-line react-hooks/exhaustive-deps
    );

    const changeFormType = (type: NotificationAvailableType, everyActive: boolean): void => {
        onChange();
        settings.printFormTypes.forEach(({ id }) => {
            const pointer = `id_${id}`;
            const currentValue = value.document_types[pointer];
            setValue(`document_types.${pointer}` as `document_types.id_0`, { ...currentValue, [type]: !everyActive });
        });
    };

    const printFormTypes = useMemo(
        () =>
            pipe(
                settings.notificationTypes,
                A.findFirst((item) => item.kind === 'print_form_type'),
                O.fold(
                    () => [],
                    (item) => item.allowed_channels
                )
            ),
        [settings.notificationTypes]
    );

    const mutualSettlementsReportTypes = useMemo(
        () =>
            pipe(
                settings.notificationTypes,
                A.findFirst((item) => item.kind === 'mutual_settlements_report'),
                O.fold(
                    () => [],
                    (item) =>
                        pipe(
                            item.allowed_channels,
                            A.map((t) => t.id)
                        )
                )
            ),
        [settings.notificationTypes]
    );

    const availableTypes = printFormTypes.map((item) => item.id);
    const availableTypesView = (showCheckboxes: boolean): JSX.Element[] =>
        printFormTypes.map((type) => {
            const isAllActive =
                !R.isEmpty(value.document_types) &&
                pipe(
                    value.document_types,
                    R.every((v) => v[type.id])
                );

            const isSomeActive =
                !isAllActive &&
                pipe(
                    value.document_types,
                    R.some((v) => v[type.id])
                );

            return (
                <div
                    key={type.id}
                    className="NotificationSettingsTable__newDocumentsHeadItem NotificationSettingsTable__documentTypeRowCheckbox"
                >
                    <span>{type.name}</span>
                    {showCheckboxes && (
                        <Checkbox
                            checked={isSomeActive || isAllActive}
                            indeterminate={isSomeActive}
                            color="primary"
                            onChange={() => changeFormType(type.id, isAllActive)}
                        />
                    )}
                </div>
            );
        });

    return (
        <div className="NotificationSettingsTable">
            <h2 className="NotificationSettingsTable__sectionTitle">Уведомления по событиям и уведомлениям</h2>

            <div className="NotificationSettingsTable__eventsSection">
                {filteredOrderTypes.map((ot) => (
                    <OrderTypeRow key={`${data.id}-type-${ot.id}`} orderType={ot} onChange={onChange} />
                ))}
            </div>

            <div className="NotificationSettingsTable__newDocumentsSectionTitleWrapper">
                <h2 className="NotificationSettingsTable__sectionTitle">Уведомления по новым документам</h2>

                <div className="NotificationSettingsTable__newDocumentsHead">{availableTypesView(true)}</div>
            </div>

            <div className="NotificationSettingsTable__newDocumentsHead NotificationSettingsTable__newDocumentsHead--mobile">
                <span />
                {availableTypesView(true)}
            </div>

            <div className="NotificationSettingsTable__newDocumentsSection">
                {settings.printFormTypes.map((row) => (
                    <DocumentTypeRow
                        id={row.id.toString()}
                        key={`${data.id}-type-${row.id}`}
                        name={row.name}
                        availableTypes={availableTypes}
                        onChange={onChange}
                    />
                ))}
            </div>

            {containerId === null && (
                <div>
                    <div className="NotificationSettingsTable__newDocumentsSectionTitleWrapper">
                        <div className="NotificationSettingsTable__newDocumentsHead NotificationSettingsTable__newDocumentsHead--noHide NotificationSettingsTable__newDocumentsHead--withGap NotificationSettingsTable__newDocumentsHead--right">
                            {availableTypesView(false)}
                        </div>
                    </div>

                    <div className="NotificationSettingsTable__newDocumentsSection">
                        <DocumentTypeRow
                            id={MutualSettlementsReportSettingsCheckboxId}
                            name="Отчет о взаиморасчетах"
                            availableTypes={mutualSettlementsReportTypes}
                            activeItems={mutualSettlementsReportItems ?? []}
                            onChange={onChangeMutualSettlements}
                        />
                    </div>
                </div>
            )}

            <div className="NotificationSettingsTable__button">
                <Button
                    type="button"
                    color="primary"
                    onClick={onSubmit}
                    variant="outlined"
                    disabled={isSaving || isDisabled}
                >
                    {isSaving ? <CircularProgress size={20} /> : <CheckIcon />}
                    <span style={{ marginLeft: 8 }}>Сохранить</span>
                </Button>
            </div>
        </div>
    );
};

const component = pipe(NotificationSettingsTableComponent, connect(mapState, mapDispatch));
export { component as NotificationSettingsTable };

import React, { ReactNode, createContext, useEffect, useState, useMemo, FunctionComponent } from 'react';
import { useLocation, useHistory, Link } from 'react-router-dom';
import { connect } from 'react-redux';

import type { Location } from 'history';

import * as D from 'io-ts/Decoder';

import { getEq } from 'fp-ts/Array';
import { fold, isNone, isSome } from 'fp-ts/Option';
import { constNull, flow, pipe } from 'fp-ts/function';
import { eqBoolean, eqStrict, getStructEq } from 'fp-ts/Eq';
import { IOEither, tryCatch, fromEither, chain, getOrElse, mapLeft } from 'fp-ts/IOEither';

import { useUrl } from '../hooks/url';

import { setPageTitle } from '../utils/title';
import { encode, decode } from '../utils/base64';
import { makeNonEmptyQuery } from '../utils/branding';
import { getCurrentPath, getPathName } from '../utils/location';

import { makeMapState } from '../store/root';
import { makeMapDispatch } from '../store/dispatch';
import { searchConfigConst } from '../store/search/reducer';
import { SearchSectionConfig } from '../store/search/types';
import { rememberSearchQuery, setConfigAction } from '../store/search/actions';

// TODO: fix cycle
import { TopBar } from '../components/TopBar/TopBar';

type SearchQueryData = {
    open: boolean;
    query: string | null;
    config: SearchSectionConfig[];
};

const hydrateData = flow(JSON.stringify, encode);
const rehydrateData = flow(decode, JSON.parse);

const getDefault = (): SearchQueryData => ({ query: null, open: false, config: searchConfigConst() });

const readQuerySearchData = (location: Location): IOEither<null, SearchQueryData> =>
    tryCatch(() => {
        const queryParams = new URLSearchParams(location.search);
        const querySearchData = queryParams.get('search');
        if (querySearchData === null) return getDefault();
        return rehydrateData(querySearchData);
    }, constNull);

const configDecoder: D.Decoder<unknown, SearchSectionConfig> = D.type({
    type: D.literal('bills', 'orders', 'payments'),
    page: D.number,
    page_size: D.literal('10', '20', '50'),
});

const searchQueryDataDecoder: D.Decoder<unknown, SearchQueryData> = D.type({
    query: D.nullable(D.string),
    open: D.boolean,
    config: D.array(configDecoder),
});

const searchQueryDataReader = flow(
    readQuerySearchData,
    chain(
        flow(
            searchQueryDataDecoder.decode,
            fromEither,
            mapLeft(() => null)
        )
    ),
    getOrElse(() => getDefault)
);

const configEq = getStructEq<SearchSectionConfig>({
    type: eqStrict,
    page: eqStrict,
    page_size: eqStrict,
});

const searchDataEq = getStructEq<SearchQueryData>({
    query: eqStrict,
    open: eqBoolean,
    config: getEq(configEq),
});

const mapState = makeMapState((state) => ({ searchConfig: state.search.config }));
const mapDispatch = makeMapDispatch({ setQuery: rememberSearchQuery, setConfig: setConfigAction });

type SearchProviderProps = ReturnType<typeof mapState> & ReturnType<typeof mapDispatch>;

type SetSearchStateSignature = (data: Omit<SearchQueryData, 'config'>, withReplaceHistory?: boolean) => void;
type SetSearchConfigSignature = (cfg: SearchSectionConfig[]) => void;
type SearchResultLinkSignature = (path: string) => ReactNode;
type GenerateSearchUrlSignature = (
    path?: string,
    open?: boolean,
    newQuery?: string | null,
    config?: SearchSectionConfig[]
) => string;

export type SearchProviderContext = {
    goBack: () => void;
    canGoBack: boolean;
    searchData: SearchQueryData;
    hasSearchData: boolean;
    setSearchState: SetSearchStateSignature;
    setSearchConfig: SetSearchConfigSignature;
    searchResultLink: SearchResultLinkSignature;
    generateSearchUrl: GenerateSearchUrlSignature;
};

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
export const SearchContext = createContext<SearchProviderContext>(null!);
const SearchProvider: FunctionComponent<SearchProviderProps> = (props) => {
    const { setQuery, setConfig, searchConfig, children } = props;

    const history = useHistory();
    const location = useLocation();
    const { addQueryParam, clearUrlRootPath, removeQueryParam } = useUrl();

    const [canGoBack, setCanGo] = useState(false);
    const [searchData, setSearchData] = useState<SearchQueryData>(searchQueryDataReader(location)());

    const hasSearchData = useMemo(() => isSome(makeNonEmptyQuery(searchData.query)), [searchData]);

    const generateSearchUrl: GenerateSearchUrlSignature = (
        path,
        open = searchData.open,
        newQuery = searchData.query,
        config = searchConfig
    ) =>
        pipe(
            makeNonEmptyQuery(newQuery),
            fold(
                () => clearUrlRootPath(path || getPathName()),
                (query) =>
                    clearUrlRootPath(
                        history.createHref({
                            pathname: path || getPathName(),
                            search: addQueryParam('search', hydrateData({ query, open, config })).toString(),
                        })
                    )
            )
        );

    const setSearchState: SetSearchStateSignature = (data, withReplaceHistory = true) => {
        setSearchData({ ...data, config: searchConfig });
        if (withReplaceHistory) history.replace(generateSearchUrl(getPathName(), data.open, data.query));
    };

    const setSearchConfig: SetSearchConfigSignature = (config) => {
        setSearchData((data) => ({ ...data, config }));
        history.replace(generateSearchUrl(getPathName(), searchData.open, searchData.query, config));
    };

    const goBack = (): void => {
        if (canGoBack) history.goBack();
    };

    const searchResultLink: SearchResultLinkSignature = (path) =>
        searchData.query ? (
            <Link className="FilesView__breadcrumbsLink" color="inherit" to={generateSearchUrl(`${path}/`, true)}>
                Поиск по запросу «{searchData.query}»
            </Link>
        ) : null;

    useEffect(() => {
        const newState = searchQueryDataReader(location)();
        if (!searchDataEq.equals(newState, searchData)) {
            setCanGo(true);
            setSearchData(newState);
        }
    }, [location]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (isNone(makeNonEmptyQuery(searchData.query))) {
            history.replace(getCurrentPath());
            setCanGo(false);
        }
    }, [searchData]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (hasSearchData) setPageTitle(`Поиск ${searchData.query}`);
    }, [hasSearchData, searchData]);

    useEffect(() => {
        if (hasSearchData) {
            setQuery(searchData.query);
            setConfig(searchData.config);
        } else {
            removeQueryParam('search');
        }
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <SearchContext.Provider
            value={{
                goBack,
                canGoBack,
                searchData,
                hasSearchData,
                setSearchState,
                setSearchConfig,
                generateSearchUrl,
                searchResultLink,
            }}
        >
            <TopBar />
            <div
                id="#search-result-container"
                className="App__searchContainer"
                style={{ display: searchData.open ? 'block' : 'none' }}
            />
            <div id="main-app-container" style={{ display: searchData.open ? 'none' : 'block' }}>
                {children}
            </div>
        </SearchContext.Provider>
    );
};

const EnchantedProvider = connect(mapState, mapDispatch)(SearchProvider);
export { EnchantedProvider as SearchProvider };

/* eslint-disable jsx-a11y/click-events-have-key-events,jsx-a11y/no-noninteractive-element-interactions */
import React, { FunctionComponent, ReactNode, useCallback, useEffect, useMemo, useState } from 'react';

import {
    Theme,
    makeStyles,
    createStyles,
    Card,
    CardHeader,
    IconButton,
    CardContent,
    GridList,
    GridListTile,
    CircularProgress,
} from '@material-ui/core';
import { Close } from '@material-ui/icons';

import { head, last, map, mapWithIndex, sort } from 'fp-ts/Array';
import { contramap, getMonoid, ordNumber } from 'fp-ts/Ord';
import { toNullable } from 'fp-ts/Option';
import { pipe } from 'fp-ts/function';
import { fold } from 'fp-ts/Monoid';

import mediumZoom from 'medium-zoom';

import { ImageSize, ImageType } from '../../store/pages/types';
import { usePages } from '../../hooks/pages';
import { foldView } from '../../utils/view';

import { RightDrawer } from '../RightDrawer';

import LegendImg from './images/legend.png';

const useCardStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            // maxWidth: 345,
            height: '100%',
            overflowY: 'auto',
        },
        media: {
            display: 'block',
            paddingBottom: '56.25%', // 16:9
            position: 'relative',
        },
        mediaImage: {
            height: '100%',
            left: 0,
            objectFit: 'cover',
            position: 'absolute',
            top: 0,
            width: '100%',
        },
        galleryImage: {
            height: '100%',
            left: 0,
            objectFit: 'cover',
            position: 'absolute',
            top: 0,
            width: '100%',
        },
        block: {
            padding: '8px 40px 32px',
        },
        galleryPicture: {
            display: 'block',
            paddingBottom: '100%',
            position: 'relative',
        },
        galleryItem: {
            [theme.breakpoints.down('md')]: {
                // no idea how to override in other way
                // component doesn't provide any breakpoints
                width: '50% !important',
            },
        },
        header: {
            padding: '40px 40px 12px',
            position: 'relative',
            '& .MuiCardHeader-title': {
                fontSize: 24,
                fontWeight: 'bold',
            },
        },
        content: {
            padding: '12px 40px 32px',
            fontSize: 16,
            lineHeight: '1.35em',
        },
        close: {
            position: 'absolute',
            top: theme.spacing(1),
            right: theme.spacing(1),
        },
        legendHeader: {
            margin: '0 0 24px',
            fontStyle: 'normal',
            fontWeight: 'bold',
            fontSize: 24,
        },
        legendList: {
            margin: '0 0 40px',
            fontSize: 16,
            paddingLeft: 16,
            listStyle: 'none',
            counterReset: 'li',
            '& li': {
                counterIncrement: 'li',
                '&:not(:last-child)': {
                    marginBottom: '0.2em',
                },
                '&::before': {
                    content: 'counter(li) "."',
                    display: 'inline-block',
                    width: '1em',
                    marginLeft: '-1em',
                    color: '#1E4E76',
                },
            },
        },
        legendImage: {
            height: 'auto',
            width: '100%',
        },
    })
);

const zoomBg = {
    light: 'rgba(255, 255, 255, 0.9)',
    dark: 'rgba(0, 0, 0, 0.6)',
};

const byWidth = pipe(
    ordNumber,
    contramap((size: ImageSize) => size.width)
);
const byHeight = pipe(
    ordNumber,
    contramap((size: ImageSize) => size.height)
);

const sizeMonoid = getMonoid<ImageSize>();
const sizeOrd = fold(sizeMonoid)([byHeight, byWidth]);

const foldImageSize = map<ImageSize, JSX.Element>((image) => (
    <source srcSet={`${image.url} ${image.width}w`} key={`pic-${image.url}-source-${image.width}`} />
));

export const PagePreview: FunctionComponent = () => {
    const { isOpen, setOpen, currentPageData, fetching } = usePages();

    const cardStyles = useCardStyles();

    const zoom = useMemo(() => mediumZoom().update({ margin: 40 }), []);
    const [zoomOpened, setZoomOpened] = useState(false);
    useEffect(() => {
        if (zoom) {
            const onOpen = (): void => setZoomOpened(true);
            const onClose = (): void => setZoomOpened(false);

            zoom.on('open', onOpen);
            zoom.on('close', onClose);

            return () => {
                zoom.off('open', onOpen);
                zoom.off('close', onClose);
            };
        }

        return () => {};
    }, [zoom]);

    const close = useCallback(() => setOpen(false), [setOpen]);
    const foldImageGallery = useCallback(
        mapWithIndex<ImageType, ReactNode>((picIndex, item) => {
            const orderedSizes = sort(sizeOrd)(item.sizes);

            const thumb = pipe(head(orderedSizes), toNullable);
            const highRes = pipe(last(orderedSizes), toNullable);

            if (thumb === null || highRes === null) return null;

            return (
                <GridListTile className={cardStyles.galleryItem} key={`gallery-item-${picIndex}`}>
                    <picture className={cardStyles.galleryPicture}>
                        {foldImageSize(item.sizes)}
                        <img
                            alt=""
                            src={thumb.url}
                            data-zoom-src={highRes.url}
                            className={cardStyles.galleryImage}
                            ref={(el) => {
                                if (el) zoom.attach(el);
                            }}
                            onClick={() => {
                                zoom.update({ background: zoomBg.dark });
                            }}
                        />
                    </picture>
                </GridListTile>
            );
        }),
        []
    );

    const viewState = foldView(currentPageData, fetching, null);

    return (
        <RightDrawer open={isOpen} onClose={close} disableEscapeKeyDown={zoomOpened}>
            {viewState(
                () => null,
                () => (
                    <div className="App__center">
                        <CircularProgress />
                    </div>
                ),
                () => null,
                (pageData) => (
                    <Card className={cardStyles.root} square>
                        <CardHeader
                            title={pageData.title}
                            className={cardStyles.header}
                            action={
                                <IconButton className={cardStyles.close} aria-label="close" onClick={close}>
                                    <Close />
                                </IconButton>
                            }
                        />

                        {pageData.title_image && pageData.title_image.sizes.length > 0 && (
                            <picture className={cardStyles.media}>
                                {foldImageSize(pageData.title_image.sizes)}
                                <img
                                    src={pageData.title_image.sizes[0].url}
                                    alt={pageData.title}
                                    className={cardStyles.mediaImage}
                                />
                            </picture>
                        )}

                        {pageData.text && (
                            <CardContent
                                className={cardStyles.content}
                                dangerouslySetInnerHTML={{
                                    __html: pageData.text,
                                }}
                            />
                        )}

                        {pageData.gallery.length > 0 && (
                            <GridList className={cardStyles.block} cols={3} cellHeight="auto">
                                {foldImageGallery(pageData.gallery)}
                            </GridList>
                        )}
                        <div className={cardStyles.block}>
                            <h5 className={cardStyles.legendHeader}>Этапы транспортировки</h5>
                            <ol className={cardStyles.legendList}>
                                <li>Завод продавца</li>
                                <li>Первый перевозчик</li>
                                <li>Карго-терминал отправления</li>
                                <li>Борт транспортного средства основного перевозчика</li>
                                <li>Карго-терминал назначения</li>
                                <li>Склад покупателя</li>
                            </ol>
                            <img
                                className={cardStyles.legendImage}
                                src={LegendImg}
                                alt="legend"
                                ref={(el) => {
                                    if (el) zoom.attach(el);
                                }}
                                onClick={() => {
                                    zoom.update({ background: zoomBg.light });
                                }}
                            />
                        </div>
                    </Card>
                )
            )}
        </RightDrawer>
    );
};

import React, { createRef, FunctionComponent, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useHistory, withRouter } from 'react-router-dom';

import clsx from 'clsx';

import { pipe } from 'fp-ts/function';
import { fold, isSome } from 'fp-ts/Option';

import { Fade, IconButton, Dialog, createStyles, useMediaQuery } from '@material-ui/core';
import { Close, Search } from '@material-ui/icons';

import { makeStyles } from '@material-ui/styles';

import { makeMapState } from '../../store/root';
import { makeMapDispatch } from '../../store/dispatch';
import { rememberSearchQuery } from '../../store/search/actions';

import { UrlQueryParams } from '../../utils/const';
import { getPathName } from '../../utils/location';
import { makeNonEmptyQuery } from '../../utils/branding';
import { useSearch } from '../../hooks/search';
import { useUrl } from '../../hooks/url';

import { SearchResults } from '../SearchResults/SearchResults';
import { SearchTooltip } from '../OrderFilters/Components/SearchTooltip';
import { Transition } from '../Transition';

import './SearchBar.scss';
import { setLastPaginationPageBeforeSearch } from '../../store/settings/actions';

declare module '../../hooks/bus' {
    interface BusEvents {
        onHeaderNavClick: void;
    }
}

const mapState = makeMapState((state) => ({
    lastPaginationPageBeforeSearch: state.settings.lastPaginationPageBeforeSearch,
    storeQuery: state.search.query,
}));

const mapDispatch = makeMapDispatch({
    rememberQuery: rememberSearchQuery,
    setLastPaginationPage: setLastPaginationPageBeforeSearch,
});

type SearchBarProps = ReturnType<typeof mapState> & ReturnType<typeof mapDispatch>;

enum SearchState {
    Closed,
    Opened,
}

const useStyles = makeStyles(() =>
    createStyles({
        root: {
            top: 200,
            backgroundColor: '#f4f4f4',
            '& .MuiBackdrop-root': {
                display: 'none',
            },
        },
    })
);

const SearchBar: FunctionComponent<SearchBarProps> = (props) => {
    const { lastPaginationPageBeforeSearch, setLastPaginationPage, rememberQuery } = props;

    const classes = useStyles();

    const inputRef = createRef<HTMLInputElement>();
    const isSmallScreen = useMediaQuery('(max-width:624px)');

    const { addQueryParam } = useUrl();
    const history = useHistory();
    const { searchData, setSearchState } = useSearch();
    const { paginationPageParamValue, changePaginationPageUrlParam, removeQueryParam } = useUrl();

    const state = searchData.query === null ? SearchState.Closed : SearchState.Opened;
    const query = searchData.query || '';
    const searchModalVisible = searchData.open;
    const [search, setSearch] = useState(query);
    const [searchInputState, setSearchInputState] = useState(state);
    const [isInitialized, setInitialized] = useState(false);

    useEffect(() => {
        setSearchInputState(state);
        setSearch(query);
    }, [searchData.query, state, query]);

    const triggerSearch = (): void =>
        pipe(
            makeNonEmptyQuery(search),
            fold(
                () => rememberQuery(null),
                (searchQuery) => {
                    changePaginationPageUrlParam(paginationPageParamValue);
                    rememberQuery(searchQuery);
                    setSearchState({
                        query: searchQuery,
                        open: true,
                    });
                }
            )
        );

    const openSearch = (): void => {
        setSearchInputState(SearchState.Opened);
        if (inputRef.current) inputRef.current.focus();
        setLastPaginationPage(paginationPageParamValue);
        removeQueryParam(UrlQueryParams.Page);
    };

    const closeSearch = (): void => {
        setSearch('');
        rememberQuery(null);
        setSearchInputState(SearchState.Closed);
        setSearchState(
            {
                query: null,
                open: false,
            },
            false
        );
        history.replace({
            pathname: getPathName(),
            search: addQueryParam(UrlQueryParams.Page, String(lastPaginationPageBeforeSearch)).toString(),
        });

        removeQueryParam('search');
    };

    useEffect(() => {
        if (isInitialized) {
            const q = makeNonEmptyQuery(searchData.query);
            if (isSome(q)) rememberQuery(q.value);
            else closeSearch();
        }
    }, [searchData.query]); // eslint-disable-line react-hooks/exhaustive-deps

    const requiredSymbolsLength = Math.max(3 - search.length, 1);
    const tooltipTitle = `Осталось ввести символов: ${requiredSymbolsLength}`;
    const container = (): HTMLElement | null => document.getElementById('#search-result-container');
    const triggerSearchHandler: React.KeyboardEventHandler<HTMLInputElement> = (evt) => {
        evt.persist();
        if (evt.which === 13 || evt.keyCode === 13) {
            const trimmedValue = search.trim();
            setSearch(trimmedValue);
            if (trimmedValue.length < 1 || trimmedValue.length >= 3) triggerSearch();
        }
    };

    useEffect(() => setInitialized(true), []);

    return (
        <div className={clsx('SearchBar', searchInputState === SearchState.Opened ? 'SearchBar--opened' : '')}>
            <SearchTooltip arrow open={search.length > 0 && search.length < 3} title={tooltipTitle}>
                <div className="SearchBar__inputWrapper">
                    <Fade in={searchInputState === SearchState.Opened}>
                        <button className="SearchBar__close" onClick={closeSearch} type="button">
                            <Close />
                            <span className="SearchBar__closeLabel">Закрыть поиск</span>
                        </button>
                    </Fade>

                    <input
                        ref={inputRef}
                        id="search-input"
                        className={clsx('SearchBar__input', search.length > 0 && 'SearchBar__input--notEmpty')}
                        value={search}
                        onChange={(evt) => setSearch(evt.target.value)}
                        placeholder=" "
                        autoComplete="off"
                        onKeyDown={triggerSearchHandler}
                    />

                    <Fade timeout={50} in={searchInputState === SearchState.Opened}>
                        <label className="SearchBar__label" htmlFor="search-input">
                            <span className="SearchBar__labelText">
                                {'Поиск '}
                                {!isSmallScreen && (
                                    <>по номеру, описанию, заметкам, отправителю и получателю заказа или контейнера</>
                                )}
                            </span>
                        </label>
                    </Fade>

                    <div className="SearchBar__searchIconWrapper">
                        <IconButton
                            color="inherit"
                            className="SearchBar__searchIcon"
                            onClick={searchInputState === SearchState.Closed ? openSearch : triggerSearch}
                        >
                            <Search />
                        </IconButton>
                    </div>
                </div>
            </SearchTooltip>

            <Dialog
                fullScreen
                open={searchModalVisible}
                TransitionComponent={Transition}
                disableEnforceFocus
                disableAutoFocus
                disableBackdropClick
                keepMounted
                container={container}
                style={{ position: 'absolute' }}
                classes={classes}
                className="keepVisible"
            >
                <SearchResults />
            </Dialog>
        </div>
    );
};

const component = withRouter(pipe(SearchBar, connect(mapState, mapDispatch)));
export { component as SearchBar };

import moment, { Moment } from 'moment';

import { WeekDays } from '../store/settings/types';

export const mapWeekDays: Record<WeekDays, number> = {
    'monday': 0,
    'tuesday': 1,
    'wednesday': 2,
    'thursday': 3,
    'friday': 4,
    'saturday': 5,
    'sunday': 6,
};
// TODO: add ISO
export const getWeekdayIndex = (weekday: WeekDays): number => mapWeekDays[weekday];

export const addSecondsToDatetime = (seconds: number): Moment =>
    moment().startOf('day').add(seconds, 'seconds');
export const formatDatetimeFromDayBegin = (seconds: number): number =>
    addSecondsToDatetime(seconds).hour();
export const convertMinutesToSeconds = (minutes: number): number => minutes * 60;
export const convertHoursToMinutes = (hours: number): number => hours * 60;

import React, { FunctionComponent } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';

import { pipe } from 'fp-ts/function';

import { RootState } from '../store/root';
import { setRedirectUrl } from '../store/user/actions';
import { makeMapDispatch } from '../store/dispatch';

type StateProps = { authenticated: boolean; redirectUrl: string | null };
const mapState = (state: RootState): StateProps => ({
    authenticated: state.user.isAuthenticated,
    redirectUrl: state.user.redirectUrl,
});

const mapDispatch = makeMapDispatch({ rememberRedirect: setRedirectUrl });

const urlBlacklist = ['/login'];

type Props = ReturnType<typeof mapState> & ReturnType<typeof mapDispatch>;
const RememberGuestRoute: FunctionComponent<Props> = (props) => {
    const { authenticated, redirectUrl, rememberRedirect, children } = props;

    if (authenticated && redirectUrl && !urlBlacklist.includes(redirectUrl)) {
        rememberRedirect(null);
        return <Redirect to={redirectUrl} />;
    }

    return <>{children}</>;
};

const EnchantedComponent = pipe(RememberGuestRoute, connect(mapState, mapDispatch));

export { EnchantedComponent as RememberGuestRoute };

import React, { FunctionComponent } from 'react';

import { DragDropContext, Droppable, Draggable, DropResult } from 'react-beautiful-dnd';

import { ColumnsGroup, ExpandableColumnsGroup } from '../ExpandableColumnsGroup/ExpandableColumnsGroup';

type DroppableGroupsProps = {
    moveGroup: (currentIndex: number, destinationIndex: number) => void;
    moveColumn: (group: string, currentIndex: number, destinationIndex: number) => void;
    toggleItem: (groupName: string, columnId: number) => void;
    toggleGroup: (groupName: string) => void;
    columns: ColumnsGroup[];
};

export const DroppableGroups: FunctionComponent<DroppableGroupsProps> = (props) => {
    const { moveGroup, moveColumn, columns, toggleItem, toggleGroup } = props;

    const onDragEndHandler = (result: DropResult): void => {
        if (result && result.destination) moveGroup(result.source.index, result.destination.index);
    }

    return (
        <DragDropContext onDragEnd={onDragEndHandler}>
            <Droppable droppableId="droppable-groups">
                {(provided) => (
                    <div {...provided.droppableProps} ref={provided.innerRef}>
                        {columns.map((item, index) => (
                            <Draggable key={item.name} draggableId={item.name} index={index}>
                                {(draggableProvided) => (
                                    <div ref={draggableProvided.innerRef} {...draggableProvided.draggableProps}>
                                        <ExpandableColumnsGroup
                                            key={item.name}
                                            group={item}
                                            parentDragHandleProps={draggableProvided.dragHandleProps}
                                            moveColumn={moveColumn}
                                            toggleItem={toggleItem}
                                            toggleGroup={toggleGroup}
                                        />
                                    </div>
                                )}
                            </Draggable>
                        ))}
                        {provided.placeholder}
                    </div>
                )}
            </Droppable>
        </DragDropContext>
    );
};

import React, { FunctionComponent, useEffect, useState } from 'react';
import { FormContext, useForm } from 'react-hook-form';
import { useHistory, useLocation, useParams, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import * as A from 'fp-ts/Array';
import { pipe } from 'fp-ts/function';

import { CircularProgress } from '@material-ui/core';

import {
    apiManagedUsersList,
    apiNotificationsSettingsMutualSettlementsReportsDetails,
    apiNotificationsSettingsMutualSettlementsReportsUpdate,
    apiUserNotificationsSettingsDetails,
    apiUserNotificationsSettingsMutualSettlementsReportsUpdate,
    makeMapDispatch,
} from '../../store/dispatch';

import { useAlerts } from '../../hooks/noty';
import { useSearch } from '../../hooks/search';

import {
    NotificationAvailableTypeWithoutSMS,
    NotificationsSettingsMutualSettlementsDetails,
} from '../../types/notifications';

import { ROUTES } from '../../utils/location';
import { setPageTitle } from '../../utils/title';
import { notificationEntityIso, SettingsFormDataEntity } from '../../utils/notifications';

import { NotificationsHeader } from '../../components/NotificationsHeader/NotificationsHeader';
import { NotificationsActionsBar } from '../../components/NotificationsActionsBar/NotificationsActionsBar';
import { TasksHeaderLabels, TasksRow } from '../../components/NotificationSettingsTable/Components/TasksRow';

import styles from './MutualSettlementsNotifications.module.scss';
import { User } from '../../types/user';

const mapDispatch = makeMapDispatch({
    fetchMutualSettlementsDetails: apiNotificationsSettingsMutualSettlementsReportsDetails,
    fetchEmployeeMutualSettlementsDetails: apiUserNotificationsSettingsDetails,
    updateMutualSettlements: apiNotificationsSettingsMutualSettlementsReportsUpdate,
    updateEmployeeMutualSettlements: apiUserNotificationsSettingsMutualSettlementsReportsUpdate,
    getEmployeesList: apiManagedUsersList,
});

const defaultOrderTypeId = 1;
const pageTitle = 'Настройка уведомлений по взаиморасчетам';

export type NotificationSettingsFormData = {
    data: Record<string, SettingsFormDataEntity>;
};

type FormProps = {
    data: Protocol.NotificationsSettingsMutualSettlementsReportsDetailsResponse;
    onChange(value: NotificationSettingsFormData): void;
    employee?: User;
};
const Form: FunctionComponent<FormProps> = (props) => {
    const { data, onChange, employee } = props;

    const defaultValues: NotificationSettingsFormData = {
        data: notificationEntityIso.to([
            {
                id: data.id,
                kind: 'mutual_settlements_report',
                types: data.selected_channels,
                container_id: -1,
                order_type_id: defaultOrderTypeId,
            },
        ]),
    };

    const formMethods = useForm<NotificationSettingsFormData>({
        defaultValues,
    });

    const { getValues } = formMethods;

    return (
        <div className={styles.card}>
            <FormContext {...formMethods}>
                <TasksHeaderLabels excludeTypes={['sms']} withoutBottomBorder />

                <TasksRow
                    kind="mutual_settlements_report"
                    tasks={[{ id: data.id, name: data.name }]}
                    orderType={{
                        id: defaultOrderTypeId,
                        name: '',
                        tasks: [],
                        statuses: [],
                    }}
                    onChange={() => {
                        // small delay is to prevent some bugs with non-updated state
                        setTimeout(() => {
                            onChange(getValues({ nest: true }));
                        }, 100);
                    }}
                    excludeTypes={['sms']}
                    employee={employee}
                />
            </FormContext>
        </div>
    );
};

type Params = { settingsId: string; userId: string };

type MutualSettlementsNotificationsProps = ReturnType<typeof mapDispatch>;
const MutualSettlementsNotifications: FunctionComponent<MutualSettlementsNotificationsProps> = (props) => {
    const {
        fetchMutualSettlementsDetails,
        updateMutualSettlements,
        fetchEmployeeMutualSettlementsDetails,
        updateEmployeeMutualSettlements,
        getEmployeesList,
    } = props;

    const { hasSearchData } = useSearch();
    const history = useHistory();

    const [value, setValue] = useState<NotificationSettingsFormData>({
        data: {},
    });
    const [data, setData] = useState<Protocol.NotificationsSettingsMutualSettlementsReportsDetailsResponse | null>(
        null
    );
    const [isLoading, setLoading] = useState(false);
    const [isUpdating, setUpdating] = useState(false);
    const [employee, setEmployee] = useState<User>();

    const { showNotification, handleApiError } = useAlerts();
    const { pathname } = useLocation();
    const params = useParams<Params>();

    const isEmployee = pathname.includes('user-notification-settings-mutual-settlements');

    useEffect(() => setPageTitle(pageTitle), [hasSearchData]);

    useEffect(() => {
        if (params.userId) {
            getEmployeesList()
                .then((response) => {
                    setEmployee(response.items.find((item) => item.id === Number(params.userId)));
                })
                .catch(handleApiError);
        }
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const onSubmit = (): void => {
        const payload: Protocol.NotificationsSettingsMutualSettlementsReportsUpdateRequest = {
            channels: pipe(
                notificationEntityIso.from(value.data),
                A.map((item) => item.types),
                A.flatten,
                A.filter((item) => item !== 'sms')
            ) as NotificationAvailableTypeWithoutSMS[],
        };

        const successCallback = (): void => {
            showNotification('Настройки уведомлений по взаиморасчетам сохранены', {
                variant: 'success',
            });

            let link = isEmployee ? ROUTES.EMPLOYEES : `${ROUTES.PROFILE}#notifications`;

            if (params.userId) {
                link = `${link}?userId=${params.userId}`;
            }

            history.push(link);
        };

        setUpdating(true);

        if (isEmployee) {
            updateEmployeeMutualSettlements({ ...payload, notification_settings_set_id: Number(params.settingsId) })
                .then(() => successCallback())
                .catch(handleApiError)
                .finally(() => setUpdating(false));
        } else {
            updateMutualSettlements(payload)
                .then(() => successCallback())
                .catch(handleApiError)
                .finally(() => setUpdating(false));
        }
    };

    useEffect(() => {
        setLoading(true);

        if (isEmployee) {
            fetchEmployeeMutualSettlementsDetails({ notification_settings_set_id: Number(params.settingsId) })
                .then((result) => setData(result.settings_set.details as NotificationsSettingsMutualSettlementsDetails))
                .catch(handleApiError)
                .finally(() => setLoading(false));
        } else {
            fetchMutualSettlementsDetails()
                .then(setData)
                .catch(handleApiError)
                .finally(() => setLoading(false));
        }
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <div className={styles.root}>
            <div className="App__container App__container--small">
                <NotificationsHeader userId={params.userId} isEmployee={isEmployee} title={pageTitle} />

                {isLoading || data === null ? (
                    <div className={styles.loaderWrapper}>
                        <CircularProgress size={24} />
                    </div>
                ) : (
                    <Form data={data} onChange={setValue} employee={employee} />
                )}
            </div>

            <NotificationsActionsBar onSubmit={onSubmit} isLoadingSubmit={isUpdating} />
        </div>
    );
};

const connected = withRouter(pipe(MutualSettlementsNotifications, connect(null, mapDispatch)));
export { connected as MutualSettlementsNotifications };

import React, { FunctionComponent } from 'react';

import type { SearchResultData, SearchSections } from '../../../store/search/types';
import type { PageSizeSelectProps } from '../../../utils/types';
import type { OnSearchPageChange } from '../types';

import { PaymentsResult } from './PaymentsResult';
import { OrdersResult } from './OrdersResult';
import { BillsResult } from './BillsResult';

export type SearchResultPageSizeConfig = Record<SearchSections, PageSizeSelectProps>;

type SearchResultViewProps = {
    data: SearchResultData;
    loading: boolean;
    onPageChange: OnSearchPageChange;
    paginatorProps: SearchResultPageSizeConfig;
};

export const SearchResultView: FunctionComponent<SearchResultViewProps> = (props) => {
    const { data, loading, onPageChange, paginatorProps } = props;

    return (
        <>
            {data.orders.items.length > 0 && (
                <OrdersResult
                    data={data.orders}
                    loading={loading}
                    onPageChange={onPageChange}
                    paginatorProps={paginatorProps.orders}
                />
            )}
            {data.bills.items.length > 0 && (
                <BillsResult
                    data={data.bills}
                    loading={loading}
                    onPageChange={onPageChange}
                    paginatorProps={paginatorProps.bills}
                />
            )}
            {data.payments.items.length > 0 && (
                <PaymentsResult
                    data={data.payments}
                    loading={loading}
                    onPageChange={onPageChange}
                    paginatorProps={paginatorProps.payments}
                />
            )}
        </>
    );
};

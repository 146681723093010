import { fromTraversable, Lens, Optional } from 'monocle-ts';

import { Eq as eqNumber, Ord as ordNumber } from 'fp-ts/number';
import { constFalse, flow, pipe } from 'fp-ts/function';
import * as Eq from 'fp-ts/Eq';
import * as A from 'fp-ts/Array';
import * as M from 'fp-ts/Monoid';
import * as R from 'fp-ts/Record';
import * as O from 'fp-ts/Option';

import type { OrderStatus, OrderType } from '../store/settings/types';
import type { OrderListFiltersValues } from '../store/orders/types';

import { isValuableFilter } from '../utils/guards';
import { AllFiltersTypes } from '../utils/types';
import { constZero } from '../utils/const';

import type { OrderListFilters } from '../types/order';
import { ReportDetailsFiltersAndSorting } from '../types/order-reports';

export type ChipData = {
    key: string;
    label: string;
    total: number | null;
    origin: OrderType;
    possibleCount: number;
};

export type ActionType = 'statuses' | 'events' | 'types';
export type Counts = Record<ActionType, number[]>;
export type CountsList = Record<string, Counts>;
export type ToggleHandler = (key: string, action: ActionType, id: number) => CountsList;

export type FiltersChangePayload = {
    embargo: boolean | null;
    archived: boolean | null;
    event_ids: number[];
    type_ids: number[];
    status_ids: number[];
    has_critical_events: boolean | null;
};

export type FiltersChangeHandler = (payload: FiltersChangePayload) => void;

export const getFilterKey = (orderFilter: OrderType): string => `filter-${orderFilter.id}`;

export const statusesLens = Lens.fromProp<OrderType>()('statuses');
export const orderStatusesTraversal = fromTraversable(A.Traversable)<OrderStatus>();
export const statusesTraversal = statusesLens.composeTraversal(orderStatusesTraversal);

export const calculateTotal = (orderFilter: OrderType): number | null => {
    const statuses = statusesTraversal.asFold().getAll(orderFilter);
    const total = statuses.length;
    return total || null;
};

export const foldFilterCount = (filter: AllFiltersTypes): number => filter.total;
export const foldFiltersCount = (filters: AllFiltersTypes[], needle?: OrderListFiltersValues): number =>
    pipe(
        filters,
        A.findFirst((filter) => (isValuableFilter(filter) ? filter.value === needle : true)),
        O.map(foldFilterCount),
        O.getOrElse(constZero)
    );

export const mapFiltersIntoData = (list: OrderType[], filters: OrderListFilters): ChipData[] =>
    list.map(
        (orderFilter): ChipData => ({
            key: getFilterKey(orderFilter),
            total: calculateTotal(orderFilter),
            label: orderFilter.name,
            origin: orderFilter,
            possibleCount: foldFiltersCount(filters.order_types, orderFilter.id),
        })
    );

export const reduceFiltersIntoCounts = A.reduce<OrderType, CountsList>({}, (acc, orderFilter) =>
    pipe(acc, R.upsertAt(getFilterKey(orderFilter), { events: [], types: [], statuses: [] } as Counts))
);

export const countsTraversable = fromTraversable(R.Traversable)<Counts>();

const sortCountsValues = fromTraversable(R.Traversable)<number[]>().modify(A.sort(ordNumber));

const countsMonoid = M.struct<Counts>({
    statuses: A.getMonoid<number>(),
    events: A.getMonoid<number>(),
    types: A.getMonoid<number>(),
});

const filtersEq = Eq.struct<FiltersChangePayload>({
    embargo: Eq.eqStrict,
    archived: Eq.eqStrict,
    has_critical_events: Eq.eqStrict,
    type_ids: A.getEq(eqNumber),
    event_ids: A.getEq(eqNumber),
    status_ids: A.getEq(eqNumber),
});

const filtersPayloadEq = Eq.struct<ReportDetailsFiltersAndSorting>({
    embargo: Eq.eqStrict,
    is_archived: Eq.eqStrict,
    has_critical_events: Eq.eqStrict,
    type_ids: A.getEq(eqNumber),
    event_ids: A.getEq(eqNumber),
    status_ids: A.getEq(eqNumber),
});

export const filtersEquals = filtersEq.equals;
export const filtersPayloadEquals = filtersPayloadEq.equals;

export const getSelectedOptions = flow(countsTraversable.asFold().getAll, M.concatAll(countsMonoid), sortCountsValues);

export const makeIsChecked = (key: string, list: CountsList) => (type: ActionType, id: number): boolean => {
    const listLens = Optional.fromPath<CountsList>()([key, type]);
    return pipe(listLens.getOption(list), O.fold(constFalse, A.elem(eqNumber)(id)));
};

import { Theme, Tooltip, withStyles } from '@material-ui/core';
import { pipe } from 'fp-ts/function';

export const SearchTooltip = pipe(
    Tooltip,
    withStyles((theme: Theme) => ({
        tooltip: {
            backgroundColor: 'rgba(0,68,123,0.87)',
            color: 'rgba(255,255,255,0.87)',
            maxWidth: 220,
            fontSize: theme.typography.pxToRem(12),
            border: '1px solid rgba(0,68,123,0.87)',
            boxShadow: '0 0 3px 0px #0055ab',
        },
        arrow: { color: 'rgba(0,68,123,0.8)' },
    }))
);

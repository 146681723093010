import React, { FunctionComponent, useEffect, useMemo, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';

import { Checkbox, ExpansionPanel, ExpansionPanelDetails, ExpansionPanelSummary, Theme } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/styles';

import * as R from 'fp-ts/Record';
import { pipe } from 'fp-ts/lib/function';

import { OrderEvent, OrderType } from '../../../store/settings/types';
import { StatusRow, StatusRowHeader } from './StatusRow';
import { TasksRow, TasksRowHeader } from './TasksRow';
import { NotificationsEntityItems } from '../../../utils/const';
import {
    NotificationEntityKind,
    NotificationEntityTypes,
    NotificationSettingsFormData,
    notificationTypeAvailabilityMessage,
} from '../../../utils/notifications';
import { makeMapState } from '../../../store/root';
import { useAlerts } from '../../../hooks/noty';
import { User } from '../../../types/user';
import { apiManagedUsersList, makeMapDispatch } from '../../../store/dispatch';
import clsx from 'clsx';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        panelRoot: {
            boxShadow: 'none',
            '&::before': {
                display: 'none',
            },
            '&:not(:first-child)': {
                borderTop: '1px solid #BDBDBD',
                marginTop: 16,
                paddingTop: 16,
            },
        },
        panelRootExpanded: {
            'html:root &': {
                margin: 0,
                marginTop: 16,
            },
        },
        detailsRoot: {
            display: 'block',
            padding: 0,
            paddingTop: 24,
        },
        summaryRoot: {
            minHeight: 'auto',
            padding: 0,
        },
        summaryRootExpanded: {
            'html:root &': {
                margin: 0,
                minHeight: 'auto',
            },
        },
        summaryContent: {
            alignItems: 'center',
            margin: 0,
        },
        innerPanelRoot: {
            boxShadow: 'none',
            '&::before': {
                display: 'none',
            },
            '&:last-child': {
                '& .NotificationSettingsTable__headRow': {
                    'html:root &': {
                        border: 'none',
                        marginBottom: 0,
                        paddingBottom: 0,
                    },
                },
            },
        },
        innerPanelExpanded: {
            'html:root &': {
                margin: 0,
                marginBottom: 32,
            },
            '&:not(:last-child)': {
                borderBottom: '1px solid #BDBDBD',
                paddingBottom: 16,
            },
            '&:last-child': {
                '& .NotificationSettingsTable__headRow': {
                    'html:root &': {
                        borderBottom: '1px solid #BDBDBD',
                        marginBottom: 16,
                        paddingBottom: 16,
                    },
                },
            },
            '& .NotificationSettingsTable__headCell--title': {
                '& svg': {
                    transform: 'rotateZ(180deg)',
                },
            },
        },
        innerSummaryRoot: {
            minHeight: 'auto',
            padding: 0,
            display: 'block',
        },
        innerSummaryRootExpanded: {
            'html:root &': {
                minHeight: 'auto',
                margin: 0,
            },
        },
        innerSummaryContent: {
            display: 'block',
            margin: 0,
        },
        innerDetailsRoot: {
            display: 'block',
            padding: 0,
        },
        expandIcon: {
            transition: 'transform 0.25s ease-out',
        },
        expandIconActive: {
            transform: 'rotateZ(180deg)',
        },
    })
);

const mapState = makeMapState((state) => ({
    user: state.user.profile,
}));

const mapDispatch = makeMapDispatch({ getEmployeesList: apiManagedUsersList });

type OrderTypeRowProps = {
    orderType: OrderType;
    onChange: () => void;
} & ReturnType<typeof mapState> &
    ReturnType<typeof mapDispatch>;

const OrderTypeRow: FunctionComponent<OrderTypeRowProps> = (props) => {
    const { orderType, onChange, user, getEmployeesList } = props;

    const classes = useStyles();

    const { showNotification, handleApiError } = useAlerts();
    const params = useParams<{ userId?: string }>();
    const [employee, setEmployee] = useState<User>();

    const { watch, setValue } = useFormContext<NotificationSettingsFormData>();
    const value = watch({ nest: true });

    useEffect(() => {
        if (params.userId) {
            getEmployeesList()
                .then((response) => {
                    setEmployee(response.items.find((item) => item.id === Number(params.userId)));
                })
                .catch(handleApiError);
        }
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const changeFormType = (isEvent: boolean, isAllActive: boolean, type: NotificationEntityTypes): void => {
        let events: OrderEvent[] = [];
        orderType.statuses.forEach((item) => {
            events = [...events, ...item.events];
        });

        const entities = isEvent ? events : orderType.tasks;
        const entityKind: NotificationEntityKind = isEvent ? 'event' : 'task';

        entities.forEach((entity) => {
            const id = `${orderType.id}_${entityKind}_${entity.id}`;

            setValue(`data.${id}.types.${type}`, !isAllActive);
        });

        const currentUser = employee || user;

        if (!isAllActive && currentUser && notificationTypeAvailabilityMessage(type, currentUser)) {
            showNotification(notificationTypeAvailabilityMessage(type, currentUser), {
                variant: 'warning',
            });
        }
    };

    const availableTypesView = (
        isEvent: boolean,
        excludeTypes: string[],
        missedCheckboxTypes: string[]
    ): JSX.Element[] => {
        let events: OrderEvent[] = [];
        orderType.statuses.forEach((item) => {
            events = [...events, ...item.events];
        });

        const entities = isEvent ? events : orderType.tasks;
        const entityKind = isEvent ? 'event' : 'task';

        const filteredEntities = pipe(
            value.data,
            R.filter((v) => {
                return Number(v.order_type_id) === orderType.id && v.kind === entityKind;
            })
        );

        return NotificationsEntityItems.map((type) => {
            const isAllActive =
                !R.isEmpty(filteredEntities) &&
                entities.every((entity) => {
                    const currentEntity = filteredEntities[`${orderType.id}_${entityKind}_${entity.id}`];

                    return currentEntity && currentEntity.types[type.key];
                });

            const isSomeActive =
                !isAllActive &&
                entities.some((entity) => {
                    const currentEntity = filteredEntities[`${orderType.id}_${entityKind}_${entity.id}`];

                    return currentEntity && currentEntity.types[type.key];
                });
            return (
                <div
                    key={type.key}
                    className={clsx(
                        'NotificationSettingsTable__newDocumentsHeadItem NotificationSettingsTable__documentTypeRowCheckbox',
                        missedCheckboxTypes.includes(type.key) &&
                            'NotificationSettingsTable__documentTypeRowCheckbox--mobileHide'
                    )}
                >
                    {!excludeTypes.includes(type.key) && (
                        <>
                            <span>{type.name}</span>
                            {!missedCheckboxTypes.includes(type.key) && (
                                <Checkbox
                                    disabled={missedCheckboxTypes.includes(type.key)}
                                    checked={isSomeActive || isAllActive}
                                    indeterminate={isSomeActive}
                                    color="primary"
                                    onChange={() => changeFormType(isEvent, isAllActive, type.key)}
                                />
                            )}
                        </>
                    )}
                </div>
            );
        });
    };

    const innerItems = useMemo(
        () => {
            const sections = [];

            let events: OrderEvent[] = [];

            orderType.statuses.forEach((status) => {
                events = [...events, ...status.events];
            });

            if (events.length > 0) {
                sections.push({
                    key: 'status',
                    header: <StatusRowHeader />,
                    details: (
                        <>
                            <div className="NotificationSettingsTable__bodyRow">
                                <div className="NotificationSettingsTable__bodyCell NotificationSettingsTable__bodyCell--middle" />
                                <div className="NotificationSettingsTable__bodyRowCheckboxes">
                                    {availableTypesView(true, [], ['sms'])}
                                </div>
                            </div>

                            {orderType.statuses
                                .filter((status) => status.events.length > 0)
                                .map((status) => (
                                    <StatusRow
                                        key={status.id}
                                        status={status}
                                        orderType={orderType}
                                        onChange={onChange}
                                        employee={employee}
                                    />
                                ))}
                        </>
                    ),
                });
            }

            if (orderType.tasks.length > 0) {
                sections.push({
                    key: 'tasks',
                    header: <TasksRowHeader />,
                    details: (
                        <>
                            <div className="NotificationSettingsTable__bodyRow">
                                <div className="NotificationSettingsTable__bodyCell NotificationSettingsTable__bodyCell--middle" />
                                <div className="NotificationSettingsTable__bodyRowCheckboxes">
                                    {availableTypesView(false, ['sms'], [])}
                                </div>
                            </div>
                            {orderType.tasks.length > 0 && (
                                <TasksRow
                                    excludeTypes={['sms']}
                                    tasks={orderType.tasks}
                                    orderType={orderType}
                                    onChange={onChange}
                                    employee={employee}
                                />
                            )}
                        </>
                    ),
                });
            }

            return sections;
        },
        [orderType, value] // eslint-disable-line react-hooks/exhaustive-deps
    );

    return (
        <div>
            {innerItems.map((item) => (
                <ExpansionPanel
                    key={item.key}
                    classes={{ root: classes.innerPanelRoot, expanded: classes.innerPanelExpanded }}
                >
                    <ExpansionPanelSummary
                        classes={{
                            root: classes.innerSummaryRoot,
                            expanded: classes.innerSummaryRootExpanded,
                            content: classes.innerSummaryContent,
                        }}
                    >
                        {item.header}
                    </ExpansionPanelSummary>

                    <ExpansionPanelDetails classes={{ root: classes.innerDetailsRoot }}>
                        {item.details}
                    </ExpansionPanelDetails>
                </ExpansionPanel>
            ))}
        </div>
    );
};

const connected = pipe(OrderTypeRow, connect(mapState, mapDispatch));
export { connected as OrderTypeRow };
